<div class="flex column" style="background-color: #ffffff;
padding: 25px 25px 0px 25px;" [ngClass]="{'vhfull': activeTab === 'rooms'}">
    <h1><span class="breadcrumb" style="cursor:pointer" (click)="navigateBack()">{{'Locations' | translate}}</span> > {{location.name}} </h1>
    <span style="color: #b6b6b7;align-self:baseline;margin-bottom: 10px;">{{'This is the place for your locations. Here you can add new rooms, create bookables and manage everything.' | translate}}
</span>
    <div class="flex nav-hold">
        <span (click)="setActiveTab('rooms')" [ngClass]="{'active': activeTab === 'rooms'}" class="nav-item"><i class="fa fas fa-cube" style="margin-right:5px"></i>{{'Overview' | translate}}</span>
        <span (click)="setActiveTab('settings')" [ngClass]="{'active': activeTab === 'settings'}" class="nav-item"><i  *ngIf="!location.mapUrl" title="Location Map missing." style="margin-right: 5px;
            color: #ffb352;" class="fa fas fa-exclamation-triangle" ></i><i  *ngIf="location.mapUrl" class="fa fas fa-cog" style="margin-right:5px"></i>{{'Location Settings' | translate}}</span>
    </div>




    <div class="flex mobile-column" style="flex:1;" *ngIf="activeTab === 'rooms'">

        <div class="room-select">

            <div class="flex">
                <button class="cta-button" style="background:var(--accent);font-weight:normal" (click)="showCreateRoom() "><i class="fas fa-plus " style="margin-right:5px "></i> <span>{{'Create New Room' | translate}}</span></button>
            </div>
            <h2 *ngIf="location.rooms.length> 0" style="padding-left:15px">{{'Rooms' | translate}}</h2>
            <div style="max-height: 75vh;
            overflow: scroll;margin-top:15px">
                <div *ngFor="let room of location.rooms">
                    <div class="flex room-item" [ngClass]="{'active': activeRoom === room}" (click)="setActiveRoom(room)">
                        <div style="flex:1">
                            <div class="flex column">
                                <div>{{room.name}}</div>
                                <div style="font-size: 12px;
                                color: #b6b6b7;">{{room.bookableItems.length}} Bookables</div>
                            </div>
                        </div>
                        <div><i style="color:var(--primary)" class="fa fas fa-chevron-right"></i></div>
                    </div>
                </div>
            </div>
        </div>

        <div style="flex:1;padding-top:15px" *ngIf="activeRoom">


            <div class="flex column nav-hold" style="padding-left:22px">
                <button class="cta-button" style="background:var(--accent);font-weight:normal;max-width: 250px;margin-bottom:20px;" (click)="showCreateBookable()"><i class="fas fa-plus" style="margin-right:5px"></i> <span>{{'Create New Bookable' | translate}}</span></button>
                <h2 style="    margin-bottom: 15px;
                    margin-block-start: 0;"> {{activeRoom.name}}</h2>

                <div class="flex">
                    <span (click)="setActiveDetailTab('overview-detail')" [ngClass]="{'active': activeDetailTab === 'overview-detail'}" class="nav-item"><i class="fa fas fa-cube" style="margin-right:5px"></i>{{'Overview' | translate}}</span>
                    <span (click)="setActiveDetailTab('settings-detail')" [ngClass]="{'active': activeDetailTab === 'settings-detail'}" class="nav-item"><i class="fa fas fa-cog" style="margin-right:5px"></i>{{'Room Settings' | translate}}</span>
                </div>
            </div>






            <div *ngIf="activeDetailTab === 'overview-detail'" class="card-holder flex mobile-column">

                <div *ngFor="let bookable of activeRoom.bookableItems" class="card flex column" style="border-top-left-radius: 0px;border-top-right-radius: 0px;" (click)="setActiveItem(bookable)">



                    <div class="flex column name-holder">
                        <div class="card-name" [title]="bookable.name"> {{bookable.name}}</div>
                    </div>

                </div>

                <div *ngIf="activeRoom.bookableItems.length === 0" class=" flex column" style="border-top-left-radius: 0px;border-top-right-radius: 0px;margin:auto;opacity:0.3">

                    {{'No bookables found'| translate}}
                </div>

            </div>

            <div *ngIf="activeDetailTab === 'settings-detail'">

                <div class="flex mobile-column">

                    <div class="settings flex column">
                        <h1 style="margin-block-start: 0;">{{'Settings' | translate}}</h1>
                        <div class="flex settings-item mobile-column">
                            <div style="flex:1;line-height:30px">{{'Room Name' | translate}}</div>
                            <div><input (keyup.enter)="saveRoomName()" type="text" [(ngModel)]="activeRoom.name" [value]="activeRoom.name"></div>
                        </div>


                        <div class="flex settings-item mobile-column">
                            <div style="flex:1;line-height:40px"><span *ngIf="saveSuccess">{{'Settings saved' | translate}}! <i _ngcontent-mai-c56="" class="fas fa-check success" aria-hidden="true" style="color:#4fc168"></i></span></div>
                            <div><button [disabled]="loadingService.isItLoading()" (click)="saveRoomName()" class="cta-button" style="background:var(--secondary)">Save</button></div>
                        </div>

                        <div class="flex settings-item mobile-column" style="flex: 1;
                        align-items: flex-end;">

                            <div style="flex:1"><button style="width:100%" (click)="showDeleteRoom()" class="alt-button">{{'Delete Room' | translate}}</button></div>
                        </div>

                    </div>
                </div>

            </div>


        </div>



    </div>



</div>



<div *ngIf="activeTab === 'settings'" class="flex" style="background-color: #ffffff;
padding: 25px 25px 0px 25px;">

    <div class="flex mobile-column">
        <div>
            <app-map-upload [location]="location"></app-map-upload>
        </div>


        <div class="settings flex column">
            <h1 style="margin-block-start: 0;">{{'Settings' | translate}}</h1>
            <div class="flex settings-item mobile-column">
                <div style="flex:1;line-height:30px">{{'Location Name' | translate}}</div>
                <div><input (keyup.enter)="save()" type="text" [(ngModel)]="location.name" [value]="location.name"></div>
            </div>


            <div class="flex settings-item mobile-column">
                <div style="flex:1;line-height:40px"><span *ngIf="saveSuccess">{{'Settings saved' | translate}}! <i _ngcontent-mai-c56="" class="fas fa-check success" aria-hidden="true" style="color:#4fc168"></i></span></div>
                <div><button [disabled]="oldLocation.name === location.name || loadingService.isItLoading()" (click)="save()" class="cta-button" style="background:var(--secondary)">{{'Save' | translate}}</button></div>
            </div>

            <div class="flex settings-item mobile-column" style="flex: 1;
                align-items: flex-end;">

                <div style="flex:1"><button style="width:100%" (click)="delete()" class="alt-button">{{'Delete Location' | translate}}</button></div>
            </div>

        </div>
    </div>


</div>

<div class="room-modal modal " [ngClass]="{'active': roomModalActive}">

    <div class="content  ">
        <i class="fas fa-times" (click)="roomModalActive = false"></i>
        <div class="flex column overflow-hidden">


            <div class="slider" id="onboarding-slider">

                <div class="flex column step" *ngIf="!showDeleteRoomModal">
                    <span class="subtitle">{{'Create New Room' | translate}}</span>
                    <img src="../../assets/img/room.svg">

                    <span class="description">{{'Give your room a name. Such as Meeting Room.' | translate}}</span>
                    <input #roomInput (keyup.enter)="roomName ? createRoom() : false" [(ngModel)]="roomName" type="text" placeholder="{{'Please enter your Room Name' | translate}}">

                    <span *ngIf="error" class="description errorText">{{error}}</span>
                </div>
                <div class="flex column step" *ngIf="showDeleteRoomModal">

                    <img src="../../assets/img/delete-now.svg">
                    <span class="subtitle">{{'Delete Room' | translate}}</span>
                    <span class="description">{{'Everything will be deleted.The Room and its Items. This is
                            permanent.' | translate}}</span>
                    <span *ngIf="error" class="description errorText">{{error}}</span>
                </div>





            </div>

            <button class="create" [ngClass]="{'delete-button': showDeleteRoomModal}" [disabled]="!showDeleteRoomModal && !roomName" (click)="showDeleteRoomModal ? deleteRoom() : createRoom() ">
                  
                    
                        <span *ngIf="!showDeleteRoomModal">{{'Create Room' | translate}}</span>
                        <span *ngIf="showDeleteRoomModal">{{'Delete Room' | translate}}</span>
                      
                       
    
                   
                </button>

        </div>
    </div>
    <div class="backdrop"></div>
</div>

<div class="bookable-modal modal" [ngClass]="{'active': bookableModalActive}">

    <div class="content">
        <i class="fas fa-times" (click)="bookableModalActive = false"></i>
        <div class="flex column overflow-hidden">


            <div class="slider" id="onboarding-slider">

                <div class="flex column step" *ngIf="!showDeleteRoomModal">
                    <span class="subtitle">{{'Create New Bookable' | translate}}</span>
                    <img src="../../assets/img/room.svg">

                    <span class="description">{{'Give your bookable a name. Such as Table #1.' | translate}}</span>
                    <input #bookableInput (keyup.enter)="bookableName ? createBookable() : false" [(ngModel)]="bookableName" type="text" placeholder="{{'Table #1' | translate}}">

                    <span *ngIf="error" class="description errorText">{{error}}</span>
                </div>
                <div class="flex column step" *ngIf="showDeleteRoomModal">

                    <img src="../../assets/img/delete-now.svg">
                    <span class="subtitle">{{'Delete Bookable' | translate}}</span>
                    <span class="description">{{'Everything will be deleted. This is
                            permanent.' | translate}}</span>
                    <span *ngIf="error" class="description errorText">{{error}}</span>
                </div>





            </div>

            <button class="create" [ngClass]="{'delete-button': showDeleteRoomModal}" [disabled]="!showDeleteRoomModal && !bookableName" (click)="createBookable()">
                  
                    
                        <span *ngIf="!showDeleteRoomModal">{{'Create Bookable' | translate}}</span>
                        <span *ngIf="showDeleteRoomModal">{{'Delete Bookable' | translate}}</span>
                      
                       
    
                   
                </button>

        </div>
    </div>
    <div class="backdrop"></div>
</div>

<div class="item-modal modal" [ngClass]="{'active': itemModalActive}">

    <div class="content">
        <i class="fas noprint fa-times" (click)="closeItemModal()"></i>
        <div class="flex column overflow-hidden">


            <div class="slider" id="onboarding-slider">

                <div class="flex column step">
                    <span class="subtitle" style="margin-bottom:25px">{{activeItem?.name}}</span>

                    <qr-code [value]="getSmallImgUrl(activeItem)" [size]="200"></qr-code>


                    <span class="description noprint">{{'You can print this QR Code to let your team quickly checkin.' | translate}}</span>
                    <span class="description onlyprint">{{'Powered by' | translate}} Crewcus</span>

                </div>

            </div>

            <button class="create" (click)="printQR()">
                  
                    
                   
                        <span class="noprint" >{{'Print Now' | translate}}</span>
                      
                       
    
                   
                </button>

            <button class="alt-button noprint" (click)="deleteBookable(activeItem)">
                  
                    
                   
                    <span class="" >{{'Delete Bookable' | translate}}</span>
                  
                   

               
            </button>

        </div>
    </div>
    <div class="backdrop whiteprint "></div>
</div>