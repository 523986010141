import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APP } from './app.config';
import { OrganisationService } from './organisation.service';

@Injectable({
  providedIn: 'root',
})
export class BookableService {
  constructor(
    private organisationService: OrganisationService,
    private http: HttpClient
  ) {}

  createBookable(locationid, roomid, name, orga?) {
    const activeOrganisation = orga ? orga : this.organisationService.getActiveOrganisations()[0];
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-organisation-id':activeOrganisation._id,
      }),
    };

    return this.http
      .post(
        APP.baseurl + '/location/' + locationid + '/' + roomid + '/item',
        {
          name,
        },
        httpOptions
      )
      .toPromise();
  }

  deleteBookable(locationid,roomid,itemid) {
    const activeOrganisation = this.organisationService.getActiveOrganisations()[0];
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-organisation-id': activeOrganisation
          ._id,
      }),
    };

    return this.http.delete(APP.baseurl + '/location/' + locationid + "/" + roomid +"/" + itemid, httpOptions).toPromise();
  }


  updateBookable(location,room,bookable,orga?) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-organisation-id': orga ? orga._id : this.organisationService.getActiveOrganisations()[0]
          ?._id,
      }),
    };

 

    return this.http
      .patch(
        APP.baseurl + '/location/' + location._id + "/" + room._id + "/" + bookable._id, {name: bookable.name},
        
        httpOptions
      )
      .toPromise();

  }
  
}
