import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APP } from '../app.config';
import { GuestHashService } from '../guest-hash.service';

@Injectable({
  providedIn: 'root'
})
export class SyncDevicesService {

  constructor(private guestService: GuestHashService, private http: HttpClient) { }

  selection;

  generateCode() {

    const user = this.guestService.getUser();


    return this.http
    .post(APP.baseurl + '/user-id/generateCode', {
      userId: user.id,
      alias: user.username ? user.username : 'Anonymous'
    })
    .toPromise();

  }

  claimIdByCode(code) {
    
    return this.http
    .get(APP.baseurl + '/user-id/getIdWithCode/' + code)
    .toPromise();
  }

  setSelection(selection) {
    this.selection = selection;
  }
}
