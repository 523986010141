import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APP } from './app.config';

@Injectable({
  providedIn: 'root'
})
export class WhereismyteamService {
 

  constructor(private http: HttpClient) { }

  getTeam(orgaid,date,currentslot) {

    //http://localhost:3000/reservationItem/teamView/2020-10-12/47
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-organisation-id': orgaid,
      }),
    };

    return this.http
      .get(
        APP.baseurl + '/statistics/teamView/' + date + '/' + currentslot,
        httpOptions
      )
      .toPromise();
  }
}
