import { ThrowStmt } from '@angular/compiler';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BookableService } from 'src/app/bookable.service';
import { LoadingService } from 'src/app/loading.service';
import { LocationService } from 'src/app/location.service';
import { IOrganisationObserver, OrganisationService } from 'src/app/organisation.service';
import { RoomService } from 'src/app/room.service';
import { isDeepStrictEqual } from 'util';

@Component({
  selector: 'app-location-detail',
  templateUrl: './location-detail.component.html',
  styleUrls: ['./location-detail.component.scss']
})
export class LocationDetailComponent implements OnInit, IOrganisationObserver {

  @Input() location;
  @Output() locationEmitter = new EventEmitter<string>();
  @Output() deleteEmitter = new EventEmitter<string>();

  oldLocation;
  roomName;
  showDeleteRoomModal;
  roomModalActive = false;
  bookableModalActive = false;
  error;
  bookableName;
  activeBookable;
  activeDetailTab = 'overview-detail';
  itemModalActive = false;
  activeItem;

  @ViewChild('roomInput') input;
  @ViewChild('bookableInput') bookableInput;

  activeRoom;
  activeOrganisation;

  constructor(private locationService: LocationService, public  loadingService: LoadingService, private roomService: RoomService, private bookableService: BookableService, private organisationService: OrganisationService) { }


  observerId: number = 199595;
  onOrganisationChange(organisations: any) {
    if(organisations && organisations.length > 0) {
  
      if(!this.activeOrganisation) {
        this.activeOrganisation = organisations[0];
      } else {
        this.navigateBack();
      }

   
        
  }
  }

  activeTab = 'rooms';
  saveSuccess = false;

  ngOnInit(): void {
 
    this.oldLocation = JSON.parse(JSON.stringify(this.location));
    this.organisationService.subscribe(this);

    this.activeRoom = this.location.rooms[0];

    if(!this.activeRoom) {
      this.showCreateRoom();
    }

  }

  navigateBack() {
    this.locationEmitter.emit(null);
  }

  setActiveTab(name) {
    this.activeTab = name;
    this.activeDetailTab = 'overview-detail';

  }

  delete() {


    this.deleteEmitter.emit(this.location._id);


  }

  saveRoomName() {

    this.loadingService.startLoading();
    let name = this.activeRoom.name;

    if(name.length > 0) {
      name = name.replace(/<br>+/g, '').replace(/<div>+/g, '').replace(/<\/div>+/g, '').replace(/\&nbsp;+/g, '');
   

      

    this.roomService.updateRoom(this.location, this.activeRoom).then(() => {
     


      this.loadingService.stopLoading();
      this.oldLocation = JSON.parse(JSON.stringify(this.location));
      this.saveSuccess = true;
      setTimeout(()=> {
        this.saveSuccess = false;
      },5000)


    }).catch(err => {
      this.loadingService.stopLoading();
      console.log("Location Name updated failed");
    })
          
  }
  

  }


  save() {

    this.loadingService.startLoading();
    let name = this.location.name;

    if(name.length > 0) {
      name = name.replace(/<br>+/g, '').replace(/<div>+/g, '').replace(/<\/div>+/g, '').replace(/\&nbsp;+/g, '');
   

      

    this.locationService.updateLocation(this.location).then(() => {
     


      this.loadingService.stopLoading();
      this.oldLocation = JSON.parse(JSON.stringify(this.location));
      this.saveSuccess = true;
      setTimeout(()=> {
        this.saveSuccess = false;
      },5000)


    }).catch(err => {
      this.loadingService.stopLoading();
      console.log("Location Name updated failed");
    })
          
  }
  
    
  }

  showCreateRoom() {
    this.roomModalActive = true;

    setTimeout(() => {
      this.input.nativeElement.focus();
    }, 1);


  }

  showCreateBookable() {
    this.bookableModalActive = true;

    setTimeout(() => {
      this.bookableInput.nativeElement.focus();
    }, 1);


  }

  createBookable() {



    this.loadingService.startLoading();
    this.error = null;
    this.bookableModalActive = false;
    this.bookableService
      .createBookable(this.location._id, this.activeRoom._id,this.bookableName)
      .then((data) => {
       
      this.location = data;
      this.oldLocation = JSON.parse(JSON.stringify(this.location));

      this.location.rooms.forEach(room => {
        if(room._id === this.activeRoom._id) {
          this.activeRoom = room;
          return;
        }
      });
   
        this.bookableName = null;
       
        this.loadingService.stopLoading();
      })
      .catch((err) => {
        this.bookableModalActive = true;
        this.loadingService.stopLoading();
        this.error =
          err.error.message;
      });
  
  }


  createRoom() {

      this.loadingService.startLoading();
      this.error = null;
      this.roomModalActive = false;
      this.roomService
        .createRoom(this.location._id, this.roomName)
        .then((data) => {
        this.location = data;
        this.oldLocation = JSON.parse(JSON.stringify(this.location));
          this.roomName = null;
         
          this.activeRoom = this.location.rooms[this.location.rooms.length -1];
  

          this.loadingService.stopLoading();
        })
        .catch((err) => {
  
          this.loadingService.stopLoading();
          this.error =
            err.error.message;
        });
    


  }

  showDeleteRoom() {
    this.roomModalActive = true;
    this.bookableModalActive = false;
    this.showDeleteRoomModal = true;

  }

  deleteRoom() {

    this.loadingService.startLoading();
    this.error = null;
    this.roomModalActive = false;
    this.roomService
      .deleteRoom(this.location._id, this.activeRoom._id)
      .then((data) => {
          this.showDeleteRoomModal = false;
        this.location.rooms.forEach((room,index) => {
          if(room._id === this.activeRoom._id) {
            this.location.rooms.splice(index,1);
          }
        });

   
 
        this.activeRoom = this.location.rooms[0];
       this.activeDetailTab = 'overview-detail';
        this.loadingService.stopLoading();
      })
      .catch((err) => {

        this.loadingService.stopLoading();
        this.error =
          err.error.message;
      });
  


  }



  getSmallImgUrl(item) {
    if (item) {
      return (
      //  'https://chart.googleapis.com/chart?chs=500x500&cht=qr&chl=' +
        window.location.origin +
        '/checkin/' +
        this.location._id +
        '/' +
        this.activeRoom._id +
        '/' +
        item._id +
        '/' +
       this.urlify(item.name) +
        '/' +
        this.location.belongsToOrganisation +
        '/' +
        this.activeOrganisation.slug
      );
    } else {
      return null;
    }
  }


  private urlify(a) {
    return a
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^-+|-+$/g, '-')
      .replace(/^-+|-+$/g, '');
  }


  setActiveRoom(room) {
    this.activeRoom = room
    this.activeBookable = null;
    this.activeDetailTab = 'overview-detail';
  }

  setActiveDetailTab(tab) {
    this.activeDetailTab = tab;
  }

  setActiveItem(bookable) {
    this.activeItem = bookable;
    this.itemModalActive = true;
  }

  printQR() {
    window.print();
  }

  deleteBookable(item) {
this.loadingService.startLoading();
    this.bookableService
      .deleteBookable(
        this.location._id,
        this.activeRoom._id,
        item._id
      )
      .then((data) => {
        this.activeItem = null;
        this.itemModalActive = false;
        this.loadingService.stopLoading();
        this.showDeleteRoomModal = false;
        this.activeRoom.bookableItems.forEach((bookable,index) => {
          
          if(bookable._id === item._id) {
            this.activeRoom.bookableItems.splice(index,1);
          }

        });
        
      })
      .catch((err) => {
        //todo err
        this.loadingService.stopLoading();
      });
  }

  closeItemModal() {
    this.itemModalActive = false
    this.showDeleteRoomModal = false;
    
  }
}
