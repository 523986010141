import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APP } from './app.config';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
 
  constructor(private http: HttpClient) {}

  getOrgaBySlug(slug: string) {
    return this.http
      .get(APP.baseurl + '/organisation/sluginfo/' + slug)
      .toPromise();
  }

  getLocationsFromOrgaWithoutDetails(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-organisation-id': id,
      }),
    };

  

    return this.http.get(APP.baseurl + '/location', httpOptions).toPromise();
  }

  getLocationsFromOrgaWithDetails(locationid,orgaid) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-organisation-id': orgaid,
      }),
    };

  

    return this.http.get(APP.baseurl + '/location/' + locationid, httpOptions).toPromise();
  }


  getSlotsByLocationAndDate(locationid,orgaid,date,fullMonth?) {
    //http://localhost:3000/reservationItem/location/5f856923a8b61d343c74c209/2020-10-15

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-organisation-id': orgaid,
      }),
    };

    if(fullMonth) {
   
      date = new Date(date).getFullYear() + "-" + ('0' + (new Date(date).getMonth()+1)).slice(-2);
    }

  

    return this.http.get(APP.baseurl + '/reservationItem/location/' + locationid+ '/' + date, httpOptions).toPromise();


  }

  blockBookable(userid,locationid,date,slots,bookableid,username,orgaid) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-organisation-id': orgaid,
      }),
    };

    let body = {
      userId: userid,
      belongsToLocationId: locationid,
      blockedOnDay: date,
      blockedSlots: slots,
      belongsToId: bookableid,
      userName: username
    }

    return this.http.post(APP.baseurl + '/reservationItem/block', body, httpOptions).toPromise();


  }
  }

