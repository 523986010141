import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  userlang;

  constructor(private translateService: TranslateService) {

    this.userlang = this.initLang();

    this.translateService.setDefaultLang('en');
    const language = this.getLang();
    switch(language) {

      case 'de': this.translateService.use('de'); break;
      case 'de-AT': this.translateService.use('de'); break;
   

    }
   }

  private initLang() : string {

    if(localStorage.getItem('activelang')) {
      return JSON.parse(localStorage.getItem('activelang'));
    }

    // @ts-ignore
    if (window.Intl && typeof window.Intl === 'object') {

      // @ts-ignore
      let lang = Intl.getCanonicalLocales(window.navigator.language)[0];
    
      if (lang.indexOf('-') > 0) {
       
        lang = lang.split('-', 1)[0];
      }
    
      //if (lang === 'de') {
      return lang;
      /*} else {
                return 'en';
            }*/
    }
    return 'en';
  }

  public setLang(code) {
    this.userlang = code;
    this.translateService.use(code);
    localStorage.setItem('activelang',JSON.stringify(code));

  }


  public getLang() :string {
    return this.userlang;
  }


}
