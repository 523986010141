import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { SlackService } from '../slack.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {
  constructor(public router: Router,private slackService: SlackService, private GAService: GoogleAnalyticsService) {}

  name;
  email;
  message;
  success;
  ngOnInit(): void {
    this.GAService.send('Landing Page');
    this.router.navigateByUrl('/auth/login')
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  sendMessage() {
    const message = this.name + "(" + this.email + ")" + " says: " + this.message;
    this.slackService.sendMessagetoServer(message);
    this.success = true;
    this.name = null;
    this.email = null;
    this.message =null;

    setTimeout(() => {
      this.success = false;
    },10000)
  }

}
