<div class="header noprint" [ngClass]="{'shadow': router.url === '/administration' || router.url.startsWith('/o/')}">

    <h2 style="display: flex;
    align-items: center;" [routerLink]="orgaSlug ? ['/o/', orgaSlug ] :  ['/']"><img style="margin-right:5px;height:30px" src="../../../assets/img/crewcus-logo"></h2>

    <span class="beta only-desktop">{{'Hot Desk Solution' | translate}}</span>

    <div class="dropdown" *ngIf="(user)?.token" style="margin-left:auto;margin-right:15px">
        <span (click)="goToPricing()" *ngIf="plan" class="priceplan only-desktop" [ngClass]="{'pro': getPlan() === 'Pro', 'enterprise': getPlan() === 'Enterprise'}" style="
        background: white;
        padding: 5px 15px;
        color: var(--secondary);
    border: 1px solid var(--secondary);
        border-radius:5px;
        font-weight: bold;">{{getPlan()}} Plan <span *ngIf=" getPlan() !== 'Free'"><span *ngIf="days_between(getPlanRemaining()) > 0 && getPlan()">({{days_between(getPlanRemaining())}} {{'Days left' | translate}})</span>
        <span *ngIf="getPlanRemaining() && days_between(getPlanRemaining()) <= 0 ">({{'Expired' | translate}})</span></span>
        </span>
    </div>


    <div class="dropdown" *ngIf="(user)?.token" style="  margin-right:15px">

        <span>{{user.username}} </span>
        <div class="dropdown-content">
            <span> <a [routerLink]="['/administration']">Administration</a></span>
            <span *ngIf="orgaSlug"> <a [routerLink]="['/o/', orgaSlug ]">{{'User View' | translate}} </a></span>
            <span (click)="showSync()"><a >{{'Sync Devices' | translate}} </a></span>
            <span style="border-bottom: 1px solid var(--third);"></span>
            <span (click)="showCookiePolicy()"><a >{{'Cookie Policy' | translate}}</a></span>
            <span (click)="termsofuse()"><a>{{'Terms of Use' | translate}}</a></span>
            <span (click)="privacypolicy()"><a>{{'Privacy Policy' | translate}}</a></span>
            <span (click)="impress()"><a>{{'Impress' | translate}}</a></span>
            <span style="border-bottom: 1px solid var(--third);"></span>
            <span (click)="logout()"><a>{{'Logout' | translate}}</a></span>

        </div>
    </div>
    <div class="dropdown" *ngIf="!(user)?.token" style="    margin-left: auto;margin-right:15px">
        {{'Menu' | translate}}
        <div class="dropdown-content">
            <span><a *ngIf="user && !user.token" [routerLink]="['/auth/login']" routerLinkActive="router-link-active">{{'Login' | translate}}</a></span>
            <span><a [routerLink]="['/sync-devices']">{{'Sync Devices' | translate}}</a></span>
            <span (click)="showCookiePolicy()"><a >{{'Cookie Policy' | translate}}</a></span>
            <span (click)="termsofuse()">{{'Terms of Use' | translate}}</span>

        </div>
    </div>

    <div class="dropdown">
        <i class="fa fa-globe" aria-hidden="true"></i> <span style="text-transform:uppercase;margin-left:5px">{{lastLang}}</span>
        <div class="dropdown-content-lang">

            <div *ngIf="lastLang != 'en'" (click)="switchLang('en')"><a>EN</a></div>
            <div *ngIf="lastLang === 'en'" (click)="switchLang('de')"><a>DE</a></div>
        </div>
    </div>

</div>