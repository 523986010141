<!-- <app-survey style="z-index:1000">
</app-survey> -->
<div class="content flex-column items-center">
    <img class="hero-img" src="../../../assets/img/sync.svg">
    <div *ngIf="!syncService.selection" style="text-align: center;">
        <h1 style="margin-block-end: 0">Device Synchronization</h1>
        <p style="color: #a5a5a5">Get all your bookings on every device.<br>Create a new Sync Pin on your main device and enter it on your secondary device. </p>
    </div>
    <div class="selection-sync" *ngIf="!syncService.selection">
        <span (click)="syncService.selection = 'request'">I want to create<br>a new sync pin</span>
        <span (click)="syncService.selection = 'accept'">I want to enter<br>a sync pin</span>
    </div>
    <app-request-sync *ngIf="syncService.selection === 'request'"></app-request-sync>
    <app-accept-sync *ngIf="syncService.selection === 'accept'"></app-accept-sync>
    <p *ngIf="!syncService.selection" (click)="back()" class="go-back">I want to go back</p>
</div>