import { Component, OnInit, ViewChild } from '@angular/core';
import { GoogleAnalyticsService } from 'src/app/google-analytics.service';
import { LocationService } from 'src/app/location.service';
import {
  IOrganisationObserver,
  OrganisationService,
} from 'src/app/organisation.service';
import { RoomService } from 'src/app/room.service';
@Component({
  selector: 'app-room',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.scss'],
})
export class RoomComponent implements OnInit, IOrganisationObserver {
  constructor(
    private locationService: LocationService,
    private roomService: RoomService,
    private organisationService: OrganisationService,
    private GAService: GoogleAnalyticsService
  ) {}
  observerId = 102;
  onOrganisationChange(organisations: any) {
    if (organisations && organisations[0]) {
      this.activeLocation = null;
      this.error = null;
      this.toDeleteId = null;
      this.roomName = null;
      this.modalActive = false;
      this.activeRoom = null;
      this.getAllLocations();
    }
  }
  locations;
  modalActive = false;
  error;
  isLoading = false;
  roomName;
  showDeleteModal = false;
  toDeleteId;
  activeRoom;
  activeLocation;

  @ViewChild('roomInput') input;

  locationMissing = false;

  ngOnInit(): void {
    this.GAService.send('Admin - Rooms Page');
    this.organisationService.subscribe(this);
  }

  ngOnDestroy(): void {
    this.organisationService.unsubscribe(this);
  }

  getAllLocations() {
    this.locationService
      .getAllLocations()
      .then((data) => {
        this.locations = data;

        if (this.locations.length === 0) {
          this.modalActive = true;
        }
        if (!this.activeRoom) {
          if (
            this.locations.length === 1 &&
            this.locations[0].rooms.length === 0
          ) {
            this.modalActive = true;
          }
          this.getRoomInfo(this.locations[0]._id);
          return;
        }

        this.getRoomInfo(this.activeLocation._id);
      })
      .catch((err) => {
        this.locationMissing = true;
      });
  }

  getRoomInfo(id) {
    this.locations.forEach((element) => {
      if (element._id === id) {
        this.activeRoom = element.rooms;
        this.activeLocation = element;
      }
    });
  }

  createRoom() {
    this.isLoading = true;
    this.error = null;

    this.roomService
      .createRoom(this.activeLocation._id, this.roomName)
      .then((data) => {
        this.getAllLocations();

        this.roomName = null;
        this.modalActive = false;
        this.isLoading = false;
      })
      .catch((err) => {
        //todo err
        this.isLoading = false;
        this.error = err.error.message;
      });
  }

  deleteRoomModal(id) {
    this.error = null;
    this.showDeleteModal = true;
    this.modalActive = true;
    this.toDeleteId = id;
  }

  showCreateRoom() {
    this.modalActive = true;
    this.showDeleteModal = false;
    this.error = null;
    this.toDeleteId = null;
    setTimeout(() => {
      this.input.nativeElement.focus();
    }, 1);
  }

  deleteRoom() {
    this.isLoading = true;
    this.error = null;

    this.roomService
      .deleteRoom(this.activeLocation._id, this.toDeleteId)
      .then((data) => {
        this.getAllLocations();
        this.toDeleteId = null;
        this.roomName = null;
        this.modalActive = false;
        this.showDeleteModal = false;
        this.isLoading = false;
      })
      .then((err) => {
        //todo err
        this.isLoading = false;
        this.showDeleteModal = false;
        this.error =
          'There was an error deleting the Room. If this problem persists, please contact support.';
      });
  }

  lastChangeDelta;
  newNameDelta;

  onNameChange(room, $event) {
    this.newNameDelta = $event.target.innerHTML;
    if (!this.lastChangeDelta) {
      this.lastChangeDelta = room;
    }
  }

  onNewNameSave() {
    //@ts-ignore
    document.activeElement.blur();

    if (this.newNameDelta.length > 0) {
      this.newNameDelta = this.newNameDelta.replace(/<br>+/g, '');
      this.newNameDelta = this.newNameDelta.replace(/<div>+/g, '');
      this.newNameDelta = this.newNameDelta.replace(/<\/div>+/g, '');
      this.newNameDelta = this.newNameDelta.replace(/\&nbsp;+/g, '');

      console.log(this.newNameDelta);

      this.lastChangeDelta.name = this.newNameDelta;

      this.error = null;
      this.isLoading = true;

      this.roomService
        .updateRoom(this.activeLocation, this.lastChangeDelta)
        .then(() => {
          this.error = null;
          this.isLoading = false;
          this.lastChangeDelta = false;
          this.newNameDelta = null;
        })
        .catch((err) => {
          this.error = 'Location Name updated failed';
          this.isLoading = false;
          console.log('Location Name updated failed');
        });
    }
  }

  /**
   * Prevent Rich Text to be copyed into the contenteditable field
   * @param $event
   */
  onPaste($event) {
    $event.preventDefault();
    $event.stopPropagation();
    var plaintext = $event.clipboardData.getData('text/plain');
    document.execCommand('inserttext', false, plaintext);
  }
}
