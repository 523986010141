<div>
    <div class="flex center-items">
        <i (click)="goToPreviousMonth()" class="fas fa-chevron-left"></i>
        <div class="title"><span (click)="today()">{{viewTitle}}</span>

        </div>
        <i (click)="goToNextMonth()" class="fas fa-chevron-right"></i>
    </div>
    <calendar [monthviewDisplayEventTemplate]="monthEventTemplate" [monthviewEventDetailTemplate]="template" [locale]="calendar.locale" [noEventsLabel]="calendar.noEventsLabel" [dateFormatter]="calendar.dateFormatter" [showEventDetail]="showEventDetail" [eventSource]="events"
        [calendarMode]="calendar.mode" [currentDate]="calendar.currentDate" (onCurrentDateChanged)="onCurrentDateChanged($event)" (onEventSelected)="onEventSelected($event)" (onTitleChanged)="onViewTitleChanged($event)" (onTimeSelected)="onTimeSelected($event)"
        step="30" startingDayMonth="1">
    </calendar>
</div>



<ng-template #template let-showEventDetail="showEventDetail" let-selectedDate="selectedDate" let-noEventsLabel="noEventsLabel">
    <div class="hide-desktop">
        <div class="schedule" *ngIf="selectedDate.events.length > 0 && showEventDetail">{{'Reservations' | translate}} {{'(' + selectedDate.events.length + ')'}}</div>
        <div class="schedule" *ngIf="selectedDate.events.length === 0 && showEventDetail">{{'Reservations' | translate}} (0)</div>
        <div style="padding: 15px 20px;border-bottom: 1px solid #ececec;" (click)="onEventSelected(event)" class="schedule-item flex" *ngFor="let event of selectedDate.events">
            <div style="width: 25px">
                <div class="dot"></div>
            </div>
            <div style="cursor:pointer" class="schedule-item-content flex column">
                <div class="room-and-time">{{event.startTime | date: 'mediumDate':undefined:calendar.locale}}, {{event.startTime | date: 'shortTime':undefined:calendar.locale}} - {{event.endTime | date: 'shortTime':undefined:calendar.locale}} </div>
                <div class="schedule-item-title">{{event.room}} - {{event.title}}</div>
                <div class="schedule-item-user">{{event.username}}</div>
            </div>
        </div>
    </div>
    <br>
</ng-template>

<ng-template #monthEventTemplate let-view="view" let-row="row" let-col="col">
    {{view.dates[row*7+col].label}}
</ng-template>