import { Component, OnInit } from '@angular/core';
import { ILoadingObserver, LoadingService } from 'src/app/loading.service';

@Component({
  selector: 'app-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss']
})
export class LoadingBarComponent implements OnInit, ILoadingObserver {

  constructor(private loadingService: LoadingService) { }
  isLoading = false;

  onLoadingChange(isLoading) {

   this.isLoading = isLoading;
  }

  ngOnInit(): void {
    this.loadingService.subscribe(this);
  }

  ngOnDestroy(): void {

    this.loadingService.unsubscribe(this);
  }

}
