import { Component, OnInit, ViewChild } from '@angular/core';
import { OrganisationService } from 'src/app/organisation.service';
import * as introJs from 'intro.js/intro.js';
import { LocationService } from 'src/app/location.service';
import { RoomService } from 'src/app/room.service';
import { BookableService } from 'src/app/bookable.service';
import { GoogleAnalyticsService } from 'src/app/google-analytics.service';
import { LoadingService } from 'src/app/loading.service';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent implements OnInit {
  constructor(
    private organisationService: OrganisationService,
    private locationService: LocationService,
    private roomService: RoomService,
    private bookableService: BookableService,
    private GAService: GoogleAnalyticsService,
    private loadingService: LoadingService
  ) {}
  @ViewChild('focusorga') focusOrga;
  @ViewChild('focuslocation') focuslocation;
  @ViewChild('focusroom') focusroom;
  @ViewChild('focusbookable') focusbookable;

  step = 1;
  organisationName;
  isLoading;
  error;
  activeModal = true;
  orga;
  locationName;
  location;
  roomName;
  room;
  bookableName;

  ngOnInit(): void {
    this.GAService.send('Onboarding Setup');
    document.body.style.overflow = 'hidden';
   
    setTimeout(() => {
      this.loadingService.stopLoading();
    },1)
  }

  nextStep() {
    if (this.step === 2) {
      this.createOrganisation();
    
    }

    if (this.step === 3) {
      this.createLocation();
    
    }

    if (this.step === 4) {
      this.createRoom();
      
    }

    if (this.step === 5) {
      this.createBookable();
     
    }

    if (this.step === 6) {
      //set active orga ..this is the last step as the modal hides
     
      this.finishAll();
      return;
    }

    if (this.step < 6) {
      this.step++;
      this.scrollToStep(this.step);
      if (this.step === 2) {
        setTimeout(() => {
          this.focusOrga.nativeElement.focus();
        }, 400);
        return;
      }
      if (this.step === 3) {
        setTimeout(() => {
          this.focuslocation.nativeElement.focus();
        }, 400);
        return;
      }
      if (this.step === 4) {
        setTimeout(() => {
          this.focusroom.nativeElement.focus();
        }, 400);
        return;
      }
      if (this.step === 5) {
        setTimeout(() => {
          this.focusbookable.nativeElement.focus();
        }, 400);
        return;
      }
    }
  }

  finishAll() {
    document.body.style.overflow = 'inherit';
    this.organisationService.setActiveOrganisation([this.orga]);
  }

  previousStep() {
    if (this.step - 1 >= 1) {
      this.step--;
      this.scrollToStep(this.step);
    }
  }

  showStep(step) {
    if (step < this.step) {
      this.step = step;
      this.scrollToStep(this.step);
    }
  }

  scrollToStep(step) {
    var slider = document.getElementById('onboarding-slider');
    slider.style.overflow = 'auto';
    var element = document.querySelector('#step-' + step);
    element.scrollIntoView({ behavior: 'smooth', block: 'end' });
    slider.style.overflow = 'hidden';
  }

  createLocation() {
    let self = this;
    this.error = null;
    this.isLoading = true;
    this.locationService
      .createLocation(this.locationName, this.orga)
      .then((data) => {
        this.isLoading = false;
        this.step = 4;
        this.location = data;
        this.scrollToStep(this.step);
      })
      .catch((err) => {
        this.isLoading = false;
        this.error =
        err.error.message[0].length > 1 ?  err.error.message[0] : err.error.message;
        this.step = 3;
        this.scrollToStep(this.step);
      });
  }

  createRoom() {
    let self = this;
    this.error = null;
    this.isLoading = true;

    this.roomService
      .createRoom(this.location._id, this.roomName, this.orga)
      .then((data) => {
        this.isLoading = false;
        this.step = 5;
        //@ts-ignore
        this.room = data.rooms[0];
      
        this.scrollToStep(this.step);
      })
      .catch((err) => {
        this.isLoading = false;
        this.error =
        err.error.message[0].length > 1 ?  err.error.message[0] : err.error.message;
        this.step = 4;
        this.scrollToStep(this.step);
      });
  }

  createOrganisation() {
    let self = this;
    this.error = null;
    this.isLoading = true;
    this.organisationService
      .createOrganisation(this.organisationName)
      .then((data) => {
        this.isLoading = false;
        this.step = 3;
        this.orga = data;
        this.scrollToStep(this.step);
      })
      .catch((err) => {
        this.isLoading = false;
        this.error =
        err.error.message[0].length > 1 ?  err.error.message[0] : err.error.message;
        this.step = 2;
        this.scrollToStep(this.step);
      });
  }

  createBookable() {
    let self = this;
    this.error = null;
    this.isLoading = true;
    this.bookableService
      .createBookable(this.location._id, this.room._id, this.bookableName, this.orga)
      .then((data) => {
        this.isLoading = false;
        this.step = 6;
     
        this.scrollToStep(this.step);
      })
      .catch((err) => {
        this.isLoading = false;
        this.error =
        err.error.message[0].length > 1 ?  err.error.message[0] : err.error.message;
        this.step = 5;
        this.scrollToStep(this.step);
      });
  }
}
