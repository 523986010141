<div class="hero-bg"></div>
<div class="content">
    <h1>Imprint</h1>

    <p style="font-weight: bold;">Provider:</p>

    <p style="font-size: 24px;font-weight:bold">REHAU AG + Co</p>

    <p>Rhenium Haus</p>
    <p>Otto-Hahn-Str. 2</p>
    <p>95111 Rehau</p>
    <p>Phone: 0 92 83-77 0</p>
    <p>e-mail: <a href="mailto:info@rehau.com">info@rehau.com</a></p>

    <p>Personally liable partner: Helmut Wagner AG - 3074 Muri b. Bern - Switzerland - President of the Board of Directors: Clarissa Wagner de Thomas Tewes - HR Bern CHE-107.882.879</p>

    <p>Commercial register at the local court Hof, HRA 2232</p>
    <p>Sales tax identification number: DE132944654</p>





    <h1 style="margin-top:50px">Legal notice</h1>
    <p>Welcome to our website. REHAU is pleased about your interest in our company and our products. In the following, we would like to provide you with legal information on how to handle the information we have put on the Internet for you.</p>

    <p style="font-weight: bold;">I. Agreements on the use of our website</p>

    <p>The information and recommendations available on this website (hereinafter "Information") are provided to the best of our knowledge and belief. We endeavour to keep the Information constantly updated so that it should be accurate and up to date at
        the time you access it. However, the REHAU Group (in the future the company) does not under any circumstances undertake to update or correct the information published on its websites. Furthermore, the company reserves the right to change or correct
        the content of its websites at any time without prior notice. No representations or warranties are made as to the completeness or accuracy of this information.

        <p>The company is not liable for any damages of any kind arising from the use of this information, the trust placed in this information or any product related to it.</p>

        <p>This information should not be construed as recommendations for the use of any information, products, processes, equipment or formulations. All warranties, express or implied, regarding merchantability, fitness for a particular purpose or non-infringement
            are also excluded.</p>

        <p>The Company makes no representations or warranties, express or implied, that the use of this information will not infringe any patents, copyrights or trademarks. The information must not be understood as wording that is in conflict with any patents,
            copyrights or trademarks.</p>

        <p style="font-weight: bold;">II Intellectual Property</p>


        <p>Copyright</p>
        <p>All information contained on this website is the property of the company and is subject to the copyrights applicable at the time it is made available to the public on the website. Copies of documents contained on this website may only be made
            for information purposes and for purely private use.</p>

        <p>Trademarks</p>
        <p>Unless otherwise stated or clearly evident from the circumstances, all company names, logos, registered products and trademarks (indicated by ® or ™) mentioned on our Internet pages belong to the company. They may not be used without prior written
            consent of the company.</p>

        <p>Obligation of the user</p>
        <p>Any visitor to the Internet sites who provides information to the Company grants the Company unrestricted transfer rights with respect to such information and authorizes the Company to use such information as it deems appropriate. Information
            provided by visitors is considered non-confidential. It must be accurate and lawful and must not harm the interests of third parties.</p>

        <p>Hypertext links</p>
        <p>Under no circumstances is the company liable for the content of websites to which links are provided. These links are provided as a service to users of the Company's website(s). The Company expressly does not adopt their content as its own.</p>


</div>