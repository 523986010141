<div class="bg-img">

    <h2>{{'My Account' | translate}}</h2>





    <mat-tab-group animationDuration="0ms">

        <mat-tab>
            <ng-template mat-tab-label>
                <i class='fa fa-id-card-o' aria-hidden='true'></i> {{'Subscription' | translate}}
            </ng-template>


            <div class="subscription-card">

                <div class="flex column">

                    <div class="flex">
                        <div style="width: 100px; flex: 0 0 15%">
                            <img style="width: 100%;position: relative;
                            top: 12px;" src="../../../assets/img/crewcus-logo">
                        </div>
                        <div style="flex: 0 0 5%"></div>
                        <div *ngIf="this.userplan" class="subscriptionTitle flex column">

                            <h2 style="text-transform: capitalize;">{{this.userplan[0].name === 'freePlan' ? 'Free Plan' : this.userplan[0].name === 'proPlan' ? 'Pro Plan' : 'Enterprise Plan' }}</h2>
                            <div style="text-transform: capitalize;" class="pill" *ngIf="timelineData && timelineData.paymentStatus && timelineData?.paymentStatus !== 'Successful'  " [ngClass]="{ 'pending': timelineData?.paymentStatus === 'Open', 'pending': timelineData?.paymentStatus === 'Pending',  'fail': timelineData?.paymentStatus === 'Denied'}">
                                {{timelineData?.paymentStatus | translate}} </div>
                            <!-- <div style="text-transform: capitalize;" class="pill success" *ngIf="!timelineData || timelineData.length === 0 || timelineData.paymentStatus === null">{{'Successful' | translate}}</div> -->
                            <h3 style="    font-size: 12px;
                            margin-bottom: 10px;
                            margin-top: 10px;"></h3>


                            <div class="flex">
                                <button [disabled]="timelineData?.paymentStatus === 'Open' || timelineData?.paymentStatus === 'Pending'" (click)="showUpgradePlan()" class="cta-button" style="    background: var(--accent);
                                margin-right: 15px;">{{'Upgrade Plan' | translate}}</button>
                                <button (click)="showCancelSubscription()" class="cta-button" style="background: none;
                                color: var(--pink);">{{'Cancel subscription' | translate}}</button>
                            </div>
                        </div>
                        <div class="flex" style="flex: 0 0 5%"></div>
                        <div *ngIf="this.userplan" class="flex column" style="flex: 1">


                            <h2>{{this.userplan[0].name === 'freePlan' ? '0€' : this.userplan[0].name === 'proPlan' ? (timelineData?.paymentStatus === 'Trial' ? '0€' : '99€') : ('Individual' | translate) }} {{'/ month' | translate}}</h2>

                            <h3 style="font-size: 14px;
                            color: grey;
                            margin-block-start: 5px;
                           ">{{'Expires' | translate}}: {{activeOrga?.planExpireDate ? (activeOrga?.planExpireDate | date: 'shortDate':undefined:lang) : 'No Expiration' | translate }}</h3>
                            <h3 style="font-size: 14px;
                             color: grey;
                             margin-block-start: 0;
                             ;">Max. {{'Bookables' | translate}}: {{this.userplan[0]?.bookableMaxCount}}</h3>


                            <h3 style="font-size: 14px;
                            color: grey;
                            margin-block-start: 0;
                            margin-block-end: 20px;display:flex" *ngFor="let feature of featureList"><span style="margin-right:10px">{{feature.name | translate}}: </span><span *ngIf="hasPlanFeature(this.userplan[0]?.features,feature);else not"><i class="fa fa-check" style="    color: var(--accent) !important;" aria-hidden="true"></i> </span>
                                <ng-template #not><i class="fa fa-times" style="    color: red !important;-webkit-text-stroke-color: red !important;" aria-hidden="true"></i></ng-template>
                            </h3>


                        </div>

                    </div>
                </div>

            </div>
            <!-- <table mat-table [dataSource]="userplan" class="mat-elevation-z8">


               
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> {{'Subscription' | translate}}</th>
                <td mat-cell *matCellDef="let element" style="text-transform: capitalize;"> {{activePlanTitle}} </td>
            </ng-container>

          
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element">
                    <span style="text-transform: capitalize;" class="pill" *ngIf="timelineData && timelineData.paymentStatus  " [ngClass]="{ 'pending': timelineData?.paymentStatus === 'Open', 'pending': timelineData?.paymentStatus === 'Pending', 'success': timelineData?.paymentStatus === 'Successful', 'fail': timelineData?.paymentStatus === 'Denied'}"> {{timelineData?.paymentStatus | translate}} </span>
                    <span style="text-transform: capitalize;" class="pill success" *ngIf="!timelineData || timelineData.length === 0 || timelineData.paymentStatus === null">{{'Successful' | translate}}</span></td>
            </ng-container>

          
            <ng-container matColumnDef="planExpireDate">
                <th mat-header-cell *matHeaderCellDef> {{'Renewal Date' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.planExpireDate ? (element.planExpireDate | date: 'shortDate':undefined:lang) : 'No Expiration' | translate }} </td>
            </ng-container>



            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> {{'Actions' | translate}} </th>
                <td mat-cell *matCellDef="let element" (click)=" showCancelInfo() " style="
             
    cursor: pointer;"> <span style=" color: var(--accent);">{{'Change' | translate}}</span> </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;">

            </tr>

            </table> -->


            <ng-container *ngIf="userService.isSuperAdmin()">
                <div>
                    <h4 style="margin-top:50px;font-size:12px;font-weight: normal;">{{'Admin Tools' | translate}}</h4>
                    <h2>{{'Order History' | translate }}</h2>

                    <button *ngIf="userService.isSuperAdmin()" (click)="addHistory()" style="background:var(--accent);margin:15px 0px;margin-right:15px" class="cta-button">{{'Add Entry' | translate}}</button>
                    <mat-form-field appearance="fill">
                        <mat-label>{{'Change Order Status' | translate}}</mat-label>
                        <mat-select (selectionChange)="changeStatus($event)">
                            <mat-option value="Trial">{{'Trial' | translate}}</mat-option>
                            <mat-option value="Open">{{'Open' | translate}}</mat-option>
                            <mat-option value="Pending">{{'Pending' | translate}}</mat-option>
                            <mat-option value="Successful">{{'Successful' | translate}}</mat-option>
                            <mat-option value="Denied">{{'Denied' | translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <app-timeline *ngIf="timelineData" [timelineData]="timelineData?.history"></app-timeline>
                </div>
            </ng-container>



            <!--
            <div class="flex" style="    justify-content: space-evenly;
transform: scale(0.9);">

                <div class="pricingplan" *ngFor="let plan of pricingplans">

                    <div class="title" style="flex:inherit" [style.color]="plan.color">{{plan.title}}</div>



                    <div class="price">{{plan.price | translate }}<span style="font-size:1.2rem" *ngIf="plan.price >= 0">€ / {{'month' | translate }}</span></div>



                    <div class="list flex column">

                        <div class="list-item flex">
                            <div class="key">{{'Location' | translate}}</div>
                            <div class="val">{{plan.locations | translate}}</div>
                        </div>

                        <div class="list-item flex">
                            <div class="key">{{'Rooms' | translate}}</div>
                            <div class="val">{{plan.rooms | translate}}</div>
                        </div>

                        <div class="list-item flex">
                            <div class="key">{{'Bookables' | translate}}</div>
                            <div class="val">{{plan.bookables}}</div>
                        </div>


                        <div class="list-item flex">
                            <div class="key">{{'Mobile Office' | translate}}</div>
                            <div class="val">{{plan.homeoffice ? '✓' : '✘' }}</div>
                        </div>

                        <div class="list-item flex">
                            <div class="key">{{'Statistics' | translate}}</div>
                            <div class="val">{{plan.statistics | translate}}</div>
                        </div>

                        <div class="list-item flex">
                            <div class="key">{{'Support' | translate}}</div>
                            <div class="val">{{plan.support ? plan.support : '✘' }}</div>
                        </div>


                        <div class="list-item flex">
                            <div class="key">{{'Feature Requests' | translate}}</div>
                            <div class="val">{{plan.featureRequests ? '✓' : '✘' }}</div>
                        </div>

                        <div class="list-item flex">
                            <div class="key">{{'Supported Languages' | translate}}</div>
                            <div class="val">{{plan.supportedLanguages}}</div>
                        </div>

                        <div class="list-item flex">
                            <div class="key">{{'Single Sign-On Integration' | translate}}</div>
                            <div class="val">{{plan.sso ? '✓' : '✘' }}</div>
                        </div>


                    </div>
                     <span *ngIf="plan?.isActive" style="    background: var(--accent);
            font-weight: bold;
            padding: 5px;
            color: white;
            border-radius: 5px;
            font-size: 12px;
            text-align: center;
        }">{{'Expires' | translate }}: {{userplan?.planExpireDate ? (userplan?.planExpireDate | date: 'shortDate':undefined:lang ) : 'No Expiration' | translate }}</span>
        <div style="flex:1"></div>

        <button [ngClass]="{'gscale': !plan.isActive}" [style.opacity]="plan.isActive ? 1 : 0.5">{{plan.isActive ? 'Active' : 'Contact Us' | translate}} </button> 


</div>

</div>
-->

        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <i class="fa fa-pencil-square-o" aria-hidden="true"></i> {{'Edit Profile' | translate}}
            </ng-template>


            <h2 style="margin-block-end: 15px;">{{'Your Profile' | translate}}</h2>

            <form [formGroup]="detailsForm" style="display: flex;
            flex-direction: column;">

                <mat-form-field appearance="fill">
                    <mat-label>{{'Title' | translate}} *</mat-label>
                    <mat-select formControlName="gender">
                        <mat-option value="male">{{'Mr' | translate}}</mat-option>
                        <mat-option value="female">{{'Mrs' | translate}}</mat-option>
                        <mat-option value="other">{{'Mx' | translate}}</mat-option>
                    </mat-select>

                </mat-form-field>


                <mat-form-field appearance="fill">
                    <mat-label>{{'First Name' | translate}} *</mat-label>
                    <input matInput cdkFocusInitial formControlName="firstName">

                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>{{'Last Name' | translate}} *</mat-label>
                    <input matInput cdkFocusInitial formControlName="lastName">

                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>{{'Company' | translate}}</mat-label>
                    <input matInput cdkFocusInitial formControlName="companyName">

                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>{{'Tax Id' | translate}}</mat-label>
                    <input matInput cdkFocusInitial formControlName="taxId">

                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>{{'Address' | translate}} *</mat-label>
                    <input matInput cdkFocusInitial formControlName="street">

                </mat-form-field>

                <div class="flex" style="justify-content: space-between;">
                    <mat-form-field appearance="fill" style="flex: 0 0 33%;margin-right:15px">
                        <mat-label>{{'Zip' | translate}} *</mat-label>
                        <input matInput cdkFocusInitial formControlName="zip">

                    </mat-form-field>
                    <mat-form-field appearance="fill" style="flex: 1">
                        <mat-label>{{'City' | translate}} *</mat-label>
                        <input matInput cdkFocusInitial formControlName="city">

                    </mat-form-field>



                </div>


                <mat-form-field appearance="fill">
                    <mat-label>{{'Country' | translate}} *</mat-label>
                    <mat-select formControlName="country">
                        <mat-option value="germany">{{'Germany' | translate}}</mat-option>
                        <mat-option value="austria">{{'Austria' | translate}}</mat-option>
                    </mat-select>

                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>{{'E-Mail' | translate}} *</mat-label>
                    <input matInput cdkFocusInitial formControlName="billingMail">

                </mat-form-field>


                <p style="font-size:12px">* {{'Required' | translate}}</p>

                <button (click)="updateProfile()" [disabled]="!detailsForm.valid || loadingService.isItLoading()" mat-button class="cta-button" style="background:var(--accent)">{{'Save' | translate}}</button>


            </form>


        </mat-tab>

        <!-- <mat-tab>
            <ng-template mat-tab-label>
                <i class='fa fa-id-card-o' aria-hidden='true'></i> {{'Purchase History' | translate}}
            </ng-template>
        </mat-tab> -->
    </mat-tab-group>




</div>