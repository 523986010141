<div class="flex-column" style="width:250px">

    <h1 style="    align-self: flex-start;
    margin-block-start: 0;"><i *ngIf="!location.mapUrl" title="Location Map missing." style="margin-right: 3px;
            color: #ffb352;" class="fa fas fa-exclamation-triangle"></i> {{'Location Map' | translate}}</h1>
    <span *ngIf="!location.mapUrl" style="
    color: #ffb352;align-self:baseline;
    margin-bottom: 10px;">{{'Location Map missing.' | translate}}</span>

    <span style="
    color: #b6b6b7;align-self:baseline;
    margin-bottom: 10px;">{{'To make booking easier for your users, you should upload a map to your location showing all bookable items.' | translate}}</span>



    <img class="plan-image" *ngIf="location.mapUrl  && !showUpload" src="{{location.mapUrl}}?{{now}}">



    <app-file-upload *ngIf=" !location.mapUrl || (  showUpload)" [config]="afuConfig" (ApiResponse)="UploadDone($event)">
    </app-file-upload>
    <div style="flex:1"></div>
    <div style="margin-top:5px;width:100%">
        <span (click)="showUploadMask(true)" class="secondary-click" *ngIf=" location.mapUrl && !showUpload "><i class="fa fas fa-pencil" style="margin-right:5px"></i>{{'Change Map' | translate}}</span>
        <span (click)="deleteMap()" class="secondary-click float-right" *ngIf="location.mapUrl && !showUpload "><i class="fa fas fa-trash" style="margin-right:5px"></i>{{'Delete Map' | translate}}</span>
        <span (click)="showUploadMask(false)" class="secondary-click" *ngIf="showUpload ">{{'Show Map' | translate}}</span>
    </div>



    <img *ngIf="isLoading" class="spin" src="../../../assets/img/loading.svg" style="width:20px;margin-top:0">
</div>