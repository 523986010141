import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { UserService } from 'src/app/user.service';

@Component({
  selector: 'app-change-plan-stepper',
  templateUrl: './change-plan-stepper.component.html',
  styleUrls: ['./change-plan-stepper.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class ChangePlanStepperComponent implements OnInit {
  address;
  selectedPlan;
  mail;
  checkboxes = new FormGroup({
    privacy: new FormControl('', [Validators.requiredTrue]),
    terms: new FormControl('', [Validators.requiredTrue]),
  });

  detailsForm = new FormGroup({
    gender: new FormControl('', [Validators.required, Validators.minLength(1)]),
    firstName: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(30),
    ]),
    lastName: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(30),
    ]),
    companyName: new FormControl('', []),
    taxId: new FormControl('', []),
    street: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(30),
    ]),
    zip: new FormControl('', [
      Validators.required,
      Validators.minLength(4),
      Validators.maxLength(5),
    ]),
    city: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(30),
    ]),
    country: new FormControl('', [
      Validators.minLength(1),
      Validators.required,
    ]),
    billingMail: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
      Validators.email,
    ]),
  });

  pricingplans = [
    {
      title: 'pro plan',
      price: 99,
      locations: 'Unlimited',
      rooms: 'Unlimited',
      bookables: 75,
      statistics: 'Standard',
      support: 'E-Mail',
      featureRequests: true,
      supportedLanguages: 2,
      sso: false,
      color: '#bb6fce',
      isActive: false,
      homeoffice: true,
    },
    {
      title: 'enterprise plan',
      price: 'Individual',
      locations: 'Unlimited',
      rooms: 'Unlimited',
      bookables: 'Unlimited',
      statistics: 'Custom',
      support: 'E-Mail',
      featureRequests: true,
      supportedLanguages: 2,
      sso: true,
      color: '#ffd000',
      isActive: false,
      homeoffice: true,
    },
  ];

  constructor(@Inject(MAT_DIALOG_DATA) public data) {
    this.address = data.address;
    this.mail = data.mail;
  }

  ngOnInit(): void {
    this.detailsForm.reset();

   this.markFormGroupTouched(this.detailsForm);
    
    if(this.address) {
      this.detailsForm.setValue({
        gender: this.address.gender,
        firstName: this.address.firstName,
        lastName: this.address.lastName,
        companyName: this.address.companyName,
        taxId: this.address.taxId,
        street: this.address.street,
        zip: this.address.zip,
        city: this.address.city,
        country: this.address.country,
        billingMail: this.address.billingMail,
      });
    } else {
      this.detailsForm.patchValue({
  
        billingMail: this.mail,
      });
    }
   
  }

    /**
   * Marks all controls in a form group as touched
   * @param formGroup - The form group to touch
   */
     private markFormGroupTouched(formGroup: FormGroup) {
      (<any>Object).values(formGroup.controls).forEach(control => {
        control.markAsTouched();
  
        if (control.controls) {
          this.markFormGroupTouched(control);
        }
      });
    }



  selectPlan(plan, stepper: MatStepper) {
    if (this.selectedPlan != plan) {
      this.selectedPlan = plan;
    } else {
      if (stepper) {
        stepper.next();
      }
    }
  }

  tempForm;
  setForm(detailsForm) {
    this.tempForm = detailsForm;
  }
}
