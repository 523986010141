import { ChangeDetectorRef, Component, isDevMode, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { APP } from '../app.config';
import { GuestHashService } from '../guest-hash.service';
import {
  IOrganisationObserver,
  OrganisationService,
} from '../organisation.service';
import { ILocationObserver, LocationService } from '../location.service';
import { UserService } from '../user.service';
import { MatDialog } from '@angular/material/dialog';
import { OnboardingV2Component } from '../utils/onboarding-v2/onboarding-v2.component';
import { CONFIG } from 'src/config/config.nb';
import { CONFIG as CONFIGQA } from 'src/config/config.qa';
import { CONFIG as CONFIGPROD } from 'src/config/config.prod';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ConfirmDialogComponent } from '../utils/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss'],
})
export class AdminDashboardComponent
  implements OnInit, IOrganisationObserver, ILocationObserver
{
  constructor(
    private router: Router,
    private http: HttpClient,
    private guestHashService: GuestHashService,
    private organisationService: OrganisationService,
    private locationService: LocationService,
    public userService: UserService,
    private dialog: MatDialog,
    private translateService: TranslateService
  ) {}

  observerId: number = 1;

  user;
  locations;
  items;
  rooms;

  isLoading = false;

  newLocation;
  selectedLocation;
  newRoom;
  selectedRoom;
  newItem;
  printItem;

  activeNavigation = 'dashboard';

  organisations;

  showOnboarding = false;

  firstRun = true;

  oldOrga;

  environments = 'nightly';

  onLocationChange(locations) {
    this.locations = locations;
  }

  onOrganisationChange(organisations) {
    this.organisations = organisations;

    if (this.firstRun && this.organisations) {
      this.oldOrga = this.organisations[0];
      this.getAllLocations();
      this.firstRun = false;
    } else if (
      !this.firstRun &&
      this.organisations &&
      this.organisations[0] !== this.oldOrga
    ) {
      this.oldOrga = this.organisations[0];
      this.getAllLocations();
    }

    if (
      (this.organisations && this.organisations.length === 0) ||
      this.showOnboarding === true
    ) {
      this.showOnboardinV2();
    }
  }

  selectLoc(loc) {
    this.selectedLocation = loc;
    this.setActive('locations');
  }

  setActive(name) {
    this.activeNavigation = null;
    setTimeout(() => {
      this.activeNavigation = name;
      localStorage.setItem('lastNav', JSON.stringify(this.activeNavigation));
    }, 1);
  }
  ngOnInit(): void {
    this.organisationService.subscribe(this);
    this.locationService.subscribe(this);

    if (localStorage.getItem('adminEnv')) {
      this.environments = JSON.parse(localStorage.getItem('adminEnv'));
      this.changeMasterEnv(this.environments);
    }

    if (localStorage.getItem('lastNav')) {
      this.setActive(JSON.parse(localStorage.getItem('lastNav')));
    }

    window.onafterprint = function () {
      this.printItem = null;
    };

    if (localStorage.getItem('user')) {
      this.user = JSON.parse(localStorage.getItem('user'));

      this.guestHashService.init();
      if (!this.user.token) {
        this.router.navigate(['/auth/login']);
      }
    } else {
      this.router.navigate(['/auth/login']);
    }
  }

  showOnboardinV2() {
    const dialogRef = this.dialog.open(OnboardingV2Component, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.organisations.unshift(result);
        this.showOnboarding = false;

        this.setLastOrganisation(result._id);
      } else {
        window.location.reload(false);
      }
    });
  }

  setLastOrganisation(orgaid) {
    localStorage.setItem('lastOrga', JSON.stringify(orgaid));
    this.setOrganisation(orgaid);
  }

  setOrganisation(orgaid) {
    let allOrgas = this.organisations;
    allOrgas.sort((a, b) =>
      a.organisationName.localeCompare(b.organisationName)
    );

    let result;
    let rIndex;
    allOrgas.forEach((orga, index) => {
      if (orga._id === orgaid) {
        rIndex = index;
        result = orga;
        return;
      }
    });

    allOrgas.splice(rIndex, 1);

    allOrgas.unshift(result);

    this.firstRun = true;

    this.organisationService.setActiveOrganisation(allOrgas);
  }

  ngOnDestroy(): void {
    this.organisationService.unsubscribe(this);
    this.locationService.unsubscribe(this);
  }

  getAllLocations() {
    this.rooms = null;
    this.items = null;
    this.isLoading = true;

    this.locationService
      .getAllLocations()
      .then((data) => {
        this.locations = data;
        this.isLoading = false;
        this.locationService.setActiveLocations(this.locations);
      })
      .catch((err) => {
        this.isLoading = false;
      });
  }

  getAllRooms(id) {
    this.items = null;
    this.isLoading = true;
    this.http
      .get(APP.baseurl + '/room/location/' + id)
      .toPromise()
      .then((data) => {
        this.rooms = data;
        this.isLoading = false;
      })
      .catch((err) => {
        console.log('Error Rooms', err);
        this.isLoading = false;
      });
  }

  getAllItems(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.user.token,
      }),
    };

    this.isLoading = true;
    this.http
      .get(APP.baseurl + '/reservationItem/room/' + id, httpOptions)
      .toPromise()
      .then((data) => {
        this.items = data;

        this.isLoading = false;
      })
      .catch((err) => {
        console.log('Items', err);
        this.isLoading = false;
      });
  }

  addNewLocation() {
    this.isLoading = true;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.user.token,
      }),
    };

    this.http
      .post(APP.baseurl + '/location', { name: this.newLocation }, httpOptions)
      .toPromise()
      .then((data) => {
        this.isLoading = false;
        this.getAllLocations();
        this.newLocation = '';
      })
      .catch((err) => {
        console.log('Error Locations Add', err);
        this.isLoading = false;
        // this.router.navigate(['/auth/login']);
      });
  }

  addNewRoom() {
    this.isLoading = true;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.user.token,
      }),
    };

    this.http
      .post(
        APP.baseurl + '/room',
        { name: this.newRoom, belongsToLocation: this.selectedLocation },
        httpOptions
      )
      .toPromise()
      .then((data) => {
        this.isLoading = false;
        this.getAllRooms(this.selectedLocation);
        this.newRoom = '';
      })
      .catch((err) => {
        console.log('Error Room Add', err);
        this.isLoading = false;
        // this.router.navigate(['/auth/login']);
      });
  }

  selectLocation(id) {
    this.selectedLocation = id;
    this.getAllRooms(id);
  }

  selectRoom(id) {
    this.selectedRoom = id;
    this.getAllItems(id);
  }

  addNewItem() {
    this.isLoading = true;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.user.token,
      }),
    };

    //{ "userId": "3912389-haxn-pt-39", "belongsToLocation": "95e9096d-9e6e-418d-afcc-c8dee3584a20", "belongsToRoom": "4eb91645-da78-4842-b1da-a22943eb400d", "name": "Deluxe Tisch 9000"}
    this.http
      .post(
        APP.baseurl + '/reservationItem',
        {
          userId: this.user.id,
          belongsToLocation: this.selectedLocation,
          belongsToRoom: this.selectedRoom,
          name: this.newItem,
        },
        httpOptions
      )
      .toPromise()
      .then((data) => {
        this.isLoading = false;
        // this.getAllRooms(this.selectedLocation);
        this.newItem = '';
        this.selectRoom(this.selectedRoom);
      })
      .catch((err) => {
        console.log('Error Room Item Add', err);
        this.isLoading = false;
        // this.router.navigate(['/auth/login']);
      });
  }

  // redundant; logout code in navbar.component is used
/*   logout() {
    console.log("admin dashboard logout triggered");
    this.http
    .post(APP.cidaas_revoke + this.user.token + "&post_logout_redirect_uri=app.crewcus.com", {})
    .toPromise();
    this.user.token = null;
    this.user.username = null;
    localStorage.setItem('user', JSON.stringify(this.user));
    localStorage.removeItem('lastNav');
    localStorage.removeItem('lastOrga');
    localStorage.removeItem('adminEnv');
    this.organisationService.clearActiveOrganisation();
    this.router.navigate(['/auth/login']);
  } */

  deleteItem(item) {
    //localhost:3000/reservationItem/a0b9eeb7-d03b-4609-9592-a7f669eb34d

    this.isLoading = true;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.user.token,
      }),
    };

    this.http
      .delete(
        APP.baseurl + '/reservationItem/' + item.id,

        httpOptions
      )
      .toPromise()
      .then((data) => {
        this.isLoading = false;
        // this.getAllRooms(this.selectedLocation);
        this.selectRoom(this.selectedRoom);
      })
      .catch((err) => {
        console.log('Error Item Delete', err);
        this.isLoading = false;
        // this.router.navigate(['/auth/login']);
      });
  }

  deleteRoom(item) {
    this.isLoading = true;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.user.token,
      }),
    };

    this.http
      .delete(
        APP.baseurl + '/room/' + item.id,

        httpOptions
      )
      .toPromise()
      .then((data) => {
        this.isLoading = false;
        // this.getAllRooms(this.selectedLocation);
        this.selectLocation(this.selectedLocation);
      })
      .catch((err) => {
        console.log('Error Room Delete', err);
        this.isLoading = false;
        // this.router.navigate(['/auth/login']);
      });
  }

  deleteLocation(item) {
    this.isLoading = true;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.user.token,
      }),
    };

    this.http
      .delete(
        APP.baseurl + '/location/' + item.id,

        httpOptions
      )
      .toPromise()
      .then((data) => {
        this.isLoading = false;
        // this.getAllRooms(this.selectedLocation);
        this.getAllLocations();
      })
      .catch((err) => {
        console.log('Error Location Delete', err);
        this.isLoading = false;
        // this.router.navigate(['/auth/login']);
      });
  }

  showPrintView(item) {
    this.printItem = item;

    setTimeout(() => {
      window.print();
    }, 100);
    setTimeout(() => {
      this.printItem = null;
    }, 200);
  }

  getImgUrl() {
    if (this.printItem) {
      return (
        'https://chart.googleapis.com/chart?chs=500x500&cht=qr&chl=https://www.Crewcus.ddbdigit.de/checkin/' +
        this.selectedLocation +
        '/' +
        this.selectedRoom +
        '/' +
        this.printItem.id +
        '/' +
        this.printItem.name.replace(/\s/g, '%20') +
        '&choe=UTF-8'
      );
    } else {
      return null;
    }
  }

  getSmallImgUrl(item) {
    if (item) {
      return (
        'https://chart.googleapis.com/chart?chs=500x500&cht=qr&chl=https://www.Crewcus.ddbdigit.de/checkin/' +
        item.belongsToLocation +
        '/' +
        item.belongsToRoom +
        '/' +
        item.id +
        '/' +
        item.name.replace(/\s/g, '%20') +
        '&choe=UTF-8'
      );
    } else {
      return null;
    }
  }

  getOrganisation() {
    this.organisationService.getOrganisation().then((data) => {
      this.organisationService.setActiveOrganisation(data);
    });
  }

  isNightly() {
    return isDevMode();
  }

  envChange(event) {
  
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        showCancel: true,
        cta: this.translateService.instant('Change Environment'),
        dialogTitle: this.translateService.instant('Environment Change'),
        dialogText: this.translateService.instant(
          'Your last selected environment is saved. Do you want to change the environment now?'
        ),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        localStorage.setItem('adminEnv', JSON.stringify(this.environments));

        this.changeMasterEnv(this.environments);
        window.location.reload(false);
      } else {
        this.environments = JSON.parse(localStorage.getItem('adminEnv'));
      }
    });
  }

  changeMasterEnv(env) {
    if (env === 'localhost') {
      APP.baseurl = 'http://localhost:3000';
    } else if (env === 'nightly') {
      APP.baseurl = CONFIG.baseurl;
      APP.cidaas = CONFIG.cidaas;
    } else if (env === 'qa') {
      APP.baseurl = CONFIGQA.baseurl;
      APP.cidaas = CONFIGQA.cidaas;
    } else if (env === 'prod') {
      APP.baseurl = CONFIGPROD.baseurl;
      APP.cidaas = CONFIGPROD.cidaas;
    }
  }
}
