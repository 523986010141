import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { QrCheckInComponent } from './qr-check-in/qr-check-in.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { PrivacyPolicyComponent } from './imprint/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './imprint/terms-of-use/terms-of-use.component';
import { SyncDevicesSelectionComponent } from './device-sync/sync-devices-selection/sync-devices-selection.component';
import { SingleBookingComponent } from './dashboard/single-booking/single-booking.component';
import { MyBookingsComponent } from './dashboard/my-bookings/my-bookings.component';
import { MaintenanceComponent } from './utils/maintenance/maintenance.component';
import { NotFoundComponent } from './utils/not-found/not-found.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent,
  },
  {
    path: 'my-bookings',
    component: MyBookingsComponent,
  },
  {
    path: 'o/:organisation',
    component: DashboardComponent,
  },
  {
    path: 'o/:organisation/:bookingid',
    component: SingleBookingComponent,
  },
  {
    path: 'imprint/privacy-notice',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'sync-devices',
    component: SyncDevicesSelectionComponent,
  },
  {
    path: 'imprint',
    component: TermsOfUseComponent,
  },
  {
    path: '',
    component: AdminDashboardComponent,
  },
  { path: 'administration', component: AdminDashboardComponent },
 
  {
    path: 'checkin/:loc/:room/:id/:name/:orgaid/:slug',
    component: QrCheckInComponent,
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
