<div class="survey-button" [ngClass]="{'open': isOpen}">
    <div *ngIf="!isOpen" class="open-button" (click)="openSurvey()"></div>
    <div class="">
        <svg class="animate__animated animate__pulse bulb" fill="#fff" width="30px" height="30px" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m68.699 29c-5.8984-5.3008-13.898-7.8984-21.898-7-12.801 1.3984-23.199 11.699-24.699 24.398-1.3008 10.602 3.3984 20.902 12.199 26.801 1.3008 0.89844 2.1016 2.3008 2.1016 3.8008v12.301c0 1.6992 0.69922 3.1992 2 4.3008l3.1016 2.6016c1 0.80078 2.3008 1.3008 3.6016 1.3008h9.6992c1.3008 0 2.6016-0.5 3.6016-1.3008l3.1016-2.6016c1.3008-1.1016 2-2.6016 2-4.3008v-12.199c0-1.6016 0.69922-3 1.8984-3.8008 7.8984-5.1992 12.602-14 12.602-23.5 0.09375-7.9023-3.3086-15.5-9.3086-20.801zm-27 48.602h16.699v4.6016l-16.699-0.003906zm16.5 12-3.1016 2.6016c-0.10156 0.10156-0.19922 0.10156-0.30078 0.10156h-9.6992c-0.10156 0-0.19922 0-0.30078-0.10156l-3.1016-2.6016c-0.10156-0.10156-0.10156-0.19922-0.10156-0.30078v-1.8984h16.699v1.8984c0.007812 0.097657 0.007812 0.30078-0.09375 0.30078zm4.4023-20.602c-1.3008 0.89844-2.3008 2-3.1016 3.3008h-19.102c-0.80078-1.3984-1.8008-2.5-3.1992-3.3984-7.1992-4.8008-11-13.199-10-21.898 1.3008-10.398 9.6992-18.699 20.102-19.898 1-0.10547 1.8984-0.10547 2.6992-0.10547 5.6992 0 11 2 15.199 5.8008 4.8008 4.3008 7.6016 10.602 7.6016 17.102 0.097657 7.6992-3.8008 14.898-10.199 19.098z"/>
  <path d="m50 16.199c1.3984 0 2.6016-1.1992 2.6016-2.6016v-8.4961c0-1.3984-1.1992-2.6016-2.6016-2.6016-1.3984 0-2.6016 1.1992-2.6016 2.6016v8.5c0 1.3984 1.1016 2.5977 2.6016 2.5977z"/>
  <path d="m29.602 19.801c0.5 0.80078 1.3984 1.3008 2.3008 1.3008 0.39844 0 0.89844-0.10156 1.3008-0.30078 1.1992-0.69922 1.6992-2.3008 1-3.6016l-4.3008-7.3984c-0.69922-1.1992-2.3008-1.6992-3.6016-1-1.1992 0.69922-1.6992 2.3008-1 3.6016z"/>
  <path d="m10 29.699 7.3984 4.3008c0.39844 0.19922 0.89844 0.30078 1.3008 0.30078 0.89844 0 1.8008-0.5 2.3008-1.3008 0.69922-1.1992 0.30078-2.8008-1-3.6016l-7.3984-4.3008c-1.1992-0.69922-2.8008-0.30078-3.6016 1-0.69922 1.3008-0.30078 2.9023 1 3.6016z"/>
  <path d="m16.398 49.801c0-1.3984-1.1992-2.6016-2.6016-2.6016h-8.4961c-1.3984 0-2.6016 1.1992-2.6016 2.6016 0 1.3984 1.1992 2.6016 2.6016 2.6016h8.5c1.3984-0.003906 2.5977-1.1016 2.5977-2.6016z"/>
  <path d="m17.398 65.699-7.3984 4.1992c-1.1992 0.69922-1.6992 2.3008-1 3.6016 0.5 0.80078 1.3984 1.3008 2.3008 1.3008 0.39844 0 0.89844-0.10156 1.3008-0.30078l7.3984-4.3008c1.1992-0.69922 1.6992-2.3008 1-3.6016-0.80078-1.1992-2.3984-1.5977-3.6016-0.89844z"/>
  <path d="m90 69.898-7.3984-4.3008c-1.1992-0.69922-2.8008-0.30078-3.6016 1-0.69922 1.1992-0.30078 2.8008 1 3.6016l7.3984 4.3008c0.39844 0.19922 0.89844 0.30078 1.3008 0.30078 0.89844 0 1.8008-0.5 2.3008-1.3008 0.69922-1.3008 0.30078-2.8984-1-3.6016z"/>
  <path d="m94.699 47.199h-8.5c-1.3984 0-2.6016 1.1992-2.6016 2.6016 0 1.3984 1.1992 2.6016 2.6016 2.6016h8.5c1.3984 0 2.6016-1.1992 2.6016-2.6016 0-1.4023-1.1992-2.6016-2.6016-2.6016z"/>
  <path d="m81.301 34.301c0.39844 0 0.89844-0.10156 1.3008-0.30078l7.3984-4.3008c1.1992-0.69922 1.6992-2.3008 1-3.6016-0.69922-1.1992-2.3008-1.6992-3.6016-1l-7.3984 4.3008c-1.1992 0.69922-1.6992 2.3008-1 3.6016 0.60156 0.80078 1.3984 1.3008 2.3008 1.3008z"/>
  <path d="m66.801 20.699c0.39844 0.19922 0.89844 0.30078 1.3008 0.30078 0.89844 0 1.8008-0.5 2.3008-1.3008l4.3008-7.3984c0.69922-1.1992 0.30078-2.8008-1-3.6016-1.1992-0.69922-2.8008-0.30078-3.6016 1l-4.3008 7.3984c-0.69922 1.3008-0.30078 2.9023 1 3.6016z"/>
 </g>



</svg>
        <span class="title" [hidden]="!isOpen">{{'Give Us Feedback' | translate}}</span>


        <svg *ngIf="isOpen" (click)="closeSurvey()" class="close" fill="#fff" width="30px" height="30px" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
 <path d="m75.801 10.801l-25.801 25.801-25.801-25.801c-2-2-5.1016-2-7.1016 0l-5.6992 5.6992c-2 2-2 5.1016 0 7.1016l25.801 25.801-25.699 25.699c-2 2-2 5.1016 0 7.1016l5.6992 5.6992c2 2 5.1016 2 7.1016 0l25.699-25.902 25.801 25.801c2 2 5.1016 2 7.1016 0l5.6992-5.6992c2-2 2-5.1016 0-7.1016l-25.902-25.699 25.801-25.801c2-2 2-5.1016 0-7.1016l-5.6992-5.6992c-1.9023-1.8984-5.1016-1.8984-7 0.10156z"/>
</svg>

    </div>
    <div *ngIf="isOpen && !feedBackSuccess" class="content">

        <span class="survey-title">{{'What is your E-Mail?' | translate}}</span>
        <input [(ngModel)]="email" type="email" value="{{getEmail()}}">
        <span class="survey-title">{{'Please choose a category' | translate}}</span>
        <div class="select-wrapper">
            <select [(ngModel)]="category" [ngClass]="{'dirty': category === 'Please choose a category' }">
             <option [disabled]="true">{{'Please choose a category' | translate}}</option>
             <option>{{'General Website' | translate}}</option>
             <option>{{'Administration Area' | translate}}</option>
             <option>{{'Booking Area' | translate}}</option>
             <option>{{'User Experience' | translate}} </option>
             <option>{{'Device Synchronization' | translate}}</option>
             <option>{{'Translation' | translate}}</option>
            </select>
        </div>
        <span class="survey-title">{{'What is your comment or suggestion?' | translate}}</span>
        <textarea maxlength="2000" [(ngModel)]="text"></textarea>
        <span *ngIf="errorMessage" style="color:red">{{errorMessage}}</span>
        <button [disabled]="!text  || isLoading || !email || category === 'Please choose a category'" (click)="sendFeedback()">
            <ng-container *ngIf="!isLoading">{{'Send My Feedback' | translate}}</ng-container>
            <img *ngIf="isLoading" class="spin" src="../../../assets/img/loading.svg" style="width:20px">
        </button>
    </div>
    <div *ngIf="isOpen && feedBackSuccess" class="content flex">
        <span style="text-align: center;">{{'Thank you for your feedback!' | translate}}</span>
        <img src="../../assets/img/review.svg" style="height:30vh;max-height:300px">

    </div>

</div>