import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'src/app/google-analytics.service';
import { SyncDevicesService } from '../sync-devices.service';
@Component({
  selector: 'app-request-sync',
  templateUrl: './request-sync.component.html',
  styleUrls: ['./request-sync.component.scss'],
})
export class RequestSyncComponent implements OnInit {
  pin = [];
  expireDate;
  expireDateString;
  progressPercent;
  TTL = 1;
  waitForCode = false;
  oldPin;
  updateInterval;
  numbersInterval;
  repeatInterval;
  constructor(private syncService: SyncDevicesService, private location: Location, private GAService: GoogleAnalyticsService) {}

  ngOnInit(): void {
    this.GAService.send('Request Sync Page');
    this.generateCode();
  }

  generateCode() {
    this.syncService
      .generateCode()
      .then((data) => {
       
        this.parseData(data);
      })
      .catch((err) => {
        //todo err
      });
  }

  parseData(data) {
    this.pin = data.paringCode.toString().split('');
    this.TTL = data.expiresInMin;
    const startDate = new Date(data.createdAt).getTime();

   

    //60000 milliseconds equals 1 minute
    const endDate = startDate + (this.TTL * 60000);
  


    // tslint:disable-next-line: radix
    const seconds = parseInt((((endDate - new Date().getTime()) / 60000) * 60).toString());
    this.progressPercent = Math.round(
      (seconds / (this.TTL * 60)) * 100
    ).toFixed(0);
    
    this.expireDate = seconds;

    if (this.expireDate >= 0) {
      this.updateExpires();
    } else {
      this.waitForNewCode();
    }
  }

  waitForNewCode() {
    clearInterval(this.updateInterval);
    clearInterval(this.numbersInterval);
    clearInterval(this.repeatInterval);
    this.repeatInterval = null;
    this.numbersInterval = null;
    this.updateInterval = null;
    this.expireDateString = null;
    this.oldPin = this.pin;
    this.waitForCode = true;
    this.numbersInterval = setInterval(() => {
      this.pin = Math.floor(10000 + Math.random() * 90000)
        .toString()
        .split('');
    }, 150);

    this.repeatInterval = setInterval(() => {
      this.syncService
        .generateCode()
        .then((data) => {
          if (
            //@ts-ignore
            JSON.stringify(data.paringCode.toString().split('')) !==
            JSON.stringify(this.oldPin)
          ) {
            clearInterval(this.numbersInterval);
            clearInterval(this.repeatInterval);
            this.parseData(data);
          }
        })
        .catch((err) => {
          //todo err
        });
    }, 10000);
  }

  updateExpires() {
    this.makeTimeString();
    this.updateInterval = setInterval(() => {
      this.makeTimeString();
      if (this.expireDate <= 0) {
        clearInterval(this.updateInterval);
        this.waitForNewCode();
      }
    }, 1000);
  }

  makeTimeString() {
    this.waitForCode = false;
    const minutes = Math.floor(this.expireDate / 60);
    const seconds = this.expireDate - minutes * 60;
    this.progressPercent = Math.round(
      (this.expireDate / (this.TTL * 60)) * 100
    ).toFixed(0);

    this.expireDateString =
      this.str_pad_left(minutes, '0', 2) +
      ':' +
      this.str_pad_left(seconds, '0', 2);
    this.expireDate = this.expireDate - 1;
  }

  str_pad_left(string, pad, length) {
    return (new Array(length + 1).join(pad) + string).slice(-length);
  }

  back() {

    if(this.syncService.selection) {
      this.syncService.setSelection(null);
      return;
    }
    
    this.location.back();
  }


}

