<div class="content flex">
    <div class="sidebar flex ">
        <h1 class="h1-title">Map Builder</h1>
        <label>Select A Form</label>
        <div style="display: flex;">
            <button class="form-button" [ngClass]="{'active': activeForm === 'rect'}" (click)="setActiveForm('rect')"><div class="rect" [ngStyle]="{'background': activeColor}"></div></button>
            <button class="form-button" [ngClass]="{'active': activeForm === 'circle'}" (click)="setActiveForm('circle')"><div class="circle" [ngStyle]="{'background': activeColor}"></div></button>

            <button class="form-button" [ngClass]="{'active': activeForm === 'text'}" (click)="setActiveForm('text')"><div class="text" [ngStyle]="{'color': activeColor,'font-size': '22px'}">Text</div></button>

        </div>

        <select #t (change)="getRoomInfo(t.value)" style="margin-top:10px">
            <option [selected]="activeLocation === location" [value]="location._id" *ngFor="let location of locations">{{location.name}}</option>
        </select>

        <select #r (change)="setActiveRoom(r.value)">
            <option  [selected]="selectedRoom === room" [value]="room._id" *ngFor="let room of activeRoom">{{room.name}}</option>
        </select>

        <input type="text" style="margin-top:15px" placeholder="Item Name (optional)" [(ngModel)]="inputName">
        <div class="flex">
            <label class="title">Bookable Item</label>
            <input class="bookable-checkbox" type="checkbox" [(ngModel)]="isBookable" (change)="changeBookableOption()">
        </div>
        <div class="flex">
            <!--
            <label class="title">Choose A Color</label>
          
            <input class="color-pick" (change)="changeColor()" [(ngModel)]="activeColor" type="color">
            -->
        </div>
        <div class="grow"></div>
        <div class="btn-holder">
            <button (click)="addTable()" [disabled]="activeForm === 'text' && !inputName ">Add Item</button>
            <button (click)="saveToServer()">Save Map</button>
            <hr>
            <!--
            <button (click)="save()">Save</button>
            <button (click)="load()">Load</button>
            -->
            <button style="background: #424242 !important" *ngIf="canvas.getActiveObject()" (click)="sendBackward()">Send Backwards</button>
            <button style="background: #424242 !important" *ngIf="canvas.getActiveObject()" (click)="bringForward()">Bring Forwards</button>
            <button style="background: #424242 !important" *ngIf="canvas.getActiveObject()" (click)="sendToBack()">Send To Back</button>
            <button style="background: #424242 !important" *ngIf="canvas.getActiveObject()" (click)="bringToFront()">Bring To Front</button>
            <button style="background: #424242 !important" *ngIf="canvas.getActiveObject()" style="background: #fa675b !important" (click)="delete()">Delete</button>
            <hr *ngIf="canvas.getActiveObject()">


            <button style="line-height:30px;  background: transparent !important;color:black " (click)="import()">Load from Disk
                <svg style="width: 30px;
                height: 20px;
                position: relative;
                transform: rotate(180deg);
                top: 7px;" fill="#000" width="100pt" height="100pt" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                 <g>
                  <path d="m70.938 49.062-4.375-4.375-10.312 10.25v-39.312h-6.25v39.312l-10.312-10.25-4.375 4.375 17.812 17.875z"/>
                  <path d="m87.5 56.25v31.25h-68.75v-31.25h-6.25v37.5h81.25v-37.5z"/>
                 </g>
                </svg>
            </button>
            <button style="line-height:30px; background: transparent !important;color:black" (click)="export()">Download Map
                <svg style="width: 30px;
                height: 20px;
                position: relative;
                top: 3px;" fill="#000" width="100pt" height="100pt" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                 <g>
                  <path d="m70.938 49.062-4.375-4.375-10.312 10.25v-39.312h-6.25v39.312l-10.312-10.25-4.375 4.375 17.812 17.875z"/>
                  <path d="m87.5 56.25v31.25h-68.75v-31.25h-6.25v37.5h81.25v-37.5z"/>
                 </g>
                </svg>
                </button>

        </div>

    </div>
    <div class="map-builder" id="map-builder" (click)="clickOnCanvas($event)">
        <canvas id="c">
        </canvas>
    </div>

</div>


<div *ngIf="isLoading" class="loading-overlay">
    <img class="spin" src="../../../assets/img/loading.svg" style="width:30px;margin-top:0">
</div>