<app-location-detail (deleteEmitter)="deleteLocationModal($event)" (locationEmitter)="setActiveLocation($event)" [location]="this.activeLocation" *ngIf="this.activeLocation; else locationSelect"></app-location-detail>
<ng-template #locationSelect>

    <div class="flex bg-img column">
        <h1>{{'Locations' | translate}}</h1>
        <span style="color: #b6b6b7;align-self:baseline;margin-bottom: 10px;">{{'This is the place for your locations. Here you can add new rooms, create bookables and manage everything.' | translate}}</span>
    </div>

    <div class="flex">
        <button class="cta-button" style="background:var(--accent);font-weight:normal" (click)="showCreateLocation()"><i class="fas fa-plus" style="margin-right:5px"></i> <span class="">{{'Create New Location' | translate}}</span></button>
    </div>

    <div class="card-holder flex">

        <div *ngFor="let location of locations" (click)="setActiveLocation(location)" class="card flex column">
            <div class="bookable-tag"> {{getBookableCount(location)}} {{'Bookables' | translate}} </div>
            <div class="room-tag">{{location?.rooms?.length}} {{'Rooms' | translate}} </div>



            <img [src]="location.mapUrl ? location.mapUrl : '../../assets/img/location.svg'" [ngClass]="{'placeholder': !location.mapUrl}">
            <div class="block-bg">
                <i style="cursor:pointer;    cursor: pointer;
            color: white;
            padding: 15px;
            border-radius: 5px;
            background: #ffffff8a;" (click)="deleteLocationModal(location._id)" class="far fa-trash-alt"></i>
            </div>
            <div class="flex column name-holder">
                <div class="card-name" [title]="location.name"> <i *ngIf="!location.mapUrl" title="Location Map missing." style="margin-right: 3px;
                    color: #ffb352;" class="fa fas fa-exclamation-triangle"></i> {{location.name}}</div>
            </div>

        </div>

    </div>

</ng-template>
<div class="modal" [ngClass]="{'active': modalActive}">

    <div class="content">
        <i *ngIf="!isLoading" class="fas fa-times" (click)="modalActive = false"></i>
        <div class="flex column overflow-hidden">


            <div class="slider" id="onboarding-slider">
                <div class="flex column step" *ngIf="!showDeleteModal">
                    <span class="subtitle">{{'Create New Location' | translate}}</span>
                    <img src="../../assets/img/location.svg">
                    <span class="description">{{'A building, site, or point in space where something can be found.' | translate}}</span>
                    <input #locationInput (keyup.enter)="locationName ? createLocation() : false" [(ngModel)]="locationName" type="text" placeholder="Please enter your Location Name">

                    <span *ngIf="error" class="description errorText">{{error}}</span>
                </div>
                <div class="flex column step" *ngIf="showDeleteModal">

                    <img src="../../assets/img/delete-now.svg">
                    <span class="subtitle">{{'Delete Location' | translate}}</span>
                    <span class="description">{{'Everything will be deleted. The Location, all Rooms and Items. This is
                        permanent.' | translate}}</span>
                    <span *ngIf="error" class="description errorText">{{error}}</span>
                </div>




            </div>

            <button class="cta-button" style="background:var(--accent);font-weight:normal" [ngClass]="{'delete-button': showDeleteModal}" [disabled]="isLoading || !showDeleteModal && !locationName" (click)="!showDeleteModal ? createLocation() : deleteLocation()">
                <ng-container *ngIf="!isLoading">

                    <span *ngIf="!showDeleteModal">{{'Create Location' | translate}}</span>
                    <span *ngIf="showDeleteModal">{{'Delete Location' | translate}}</span>

                </ng-container>
                <img  *ngIf="isLoading" class="spin" src="../../../assets/img/loading.svg" style="width:20px;margin-top:0">
            </button>

        </div>
    </div>
    <div class="backdrop"></div>
</div>