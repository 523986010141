import { ThrowStmt } from '@angular/compiler';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { BookableService } from 'src/app/bookable.service';
import { GuestHashService } from 'src/app/guest-hash.service';
import { LoadingService } from 'src/app/loading.service';
import { MyBookingsService } from 'src/app/my-bookings.service';
import {
  IOrganisationObserver,
  OrganisationService,
} from 'src/app/organisation.service';
import { Md5 } from 'ts-md5';
import { UserService } from '../../user.service';

@Component({
  selector: 'app-single-booking',
  templateUrl: './single-booking.component.html',
  styleUrls: ['./single-booking.component.scss'],
})
export class SingleBookingComponent implements OnInit, IOrganisationObserver {
  event;
  orgaSlug;
  observerId: number = 32848238;
  user;
  showDeleteConfirm = false;
  showDeleteSuccess = false;
  origin;
  showSuperAdmin = false;

  constructor(
    private userBookingService: MyBookingsService,
    private guestService: GuestHashService,
    private loadingService: LoadingService,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private organisationService: OrganisationService,
    private userService: UserService
  ) {}

  onOrganisationChange(organisations: any) {
    this.event = JSON.parse(localStorage.getItem('activeBooking'));

    if (organisations?.length > 0) {
      this.orgaSlug = organisations[0].slug;
    }

    if (!this.event) {
      this.loadingService.stopLoading();
      const urlsplit = window.location.pathname.split('/');

      this.orgaSlug = urlsplit[2];

      if (urlsplit.length === 4) {
        this.router.navigateByUrl('/o/' + this.orgaSlug);
      } else {
        this.router.navigateByUrl('/');
      }
    } else {
      this.loadingService.stopLoading();
      const urlsplit = window.location.pathname.split('/');

      this.orgaSlug = urlsplit[2];
    }
  }

  ngOnInit(): void {
    this.loadingService.startLoading();
    this.organisationService.subscribe(this);
    this.user = this.guestService.getUser();

    this.activatedRoute.queryParams.subscribe((params) => {
      this.origin = params.origin;
    });

    this.showSuperAdmin = this.isSuperAdmin();
  }

  getBookingName(isOwner) {
    if (isOwner) {
      return this.event?.title;
    }
    return this.event?.title;
  }

  isOwnerOfBooking() {
    const md5 = new Md5();
    const result = md5.appendStr(this.guestService.getUser().id).end();

    return this.event.userid === result;
  }

  isSuperAdmin() {
    if (JSON.parse(localStorage.getItem('user')).token) {
      return this.userService.isSuperAdmin();
    }
    return false;
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.organisationService.unsubscribe(this);
  }

  routeToCalendar() {

    if (this.origin === 'my-bookings') {
      this.router.navigateByUrl('/my-bookings');
      return;
    } else {
      this.router.navigateByUrl('/o/' + this.orgaSlug);
      return;
    }
  }

  showDeleteBookingModal() {
    this.showDeleteConfirm = true;
  }

  deleteBooking() {
    this.loadingService.startLoading();

    if (!this.isSuperAdmin()) {
      this.userBookingService
      .deleteBooking(
        this.event.itemid,
        new Date(this.event.endTime).setUTCHours(0, 0, 0, 0),
        this.event.orgaid
      )
      .then((data) => {
        this.loadingService.stopLoading();
        this.showDeleteSuccess = true;
      })
      .catch((err) => {
        //todo err
        this.loadingService.stopLoading();
      });
    }
    else {
      this.userBookingService
      .adminDeleteBooking(
        this.event.itemid,
        this.event.username,
        new Date(this.event.endTime).setUTCHours(0, 0, 0, 0),
        this.event.orgaid
      )
      .then((data) => {
        this.loadingService.stopLoading();
        this.showDeleteSuccess = true;
      })
      .catch((err) => {
        //todo err
        this.loadingService.stopLoading();
      });
    }

  }

  confirmModal() {
    this.showDeleteConfirm = false;
    this.showDeleteSuccess = false;
    this.routeToCalendar();
  }
}
