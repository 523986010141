<div *ngIf="id.includes('piechart')" [style.height.px]="height" style="position: relative; ">
    <div class="piePercent-hold">
        {{piePercent}}%

    </div>
    <canvas [id]="id "></canvas>
</div>



<canvas *ngIf="id==='chart' " [id]="id "></canvas>