import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IonFab } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CONFIG } from 'src/config/config';
import { APP } from '../app.config';
import { LoadingService } from '../loading.service';
import { OrganisationService } from '../organisation.service';
import { UserService } from '../user.service';
import { ConfirmDialogComponent } from '../utils/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-superadmin',
  templateUrl: './superadmin.component.html',
  styleUrls: ['./superadmin.component.scss'],
})
export class SuperadminComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private translateService: TranslateService,
    private userService: UserService,
    private http: HttpClient,
    private organisationService: OrganisationService,
    private loadingService: LoadingService
  ) {}
  @Input() organisations;

  activeTabIndex = 0;
  searchInput;
  isLoading = false;
  error;
  results;
  activeOrga;
  plans;
  selectedOption;
  lang;
  modalActive = false;
  showDeleteModal = false;
  pricingPlanName;
  pricingPlanBookables = 10;
  pricingPlanNotes;
  activePlanEdit;
  modalDummyActive = false;
  dummyTexts = [
    { text: 'Create Organisation' },
    { text: 'Create Locations' },
    { text: 'Create Rooms' },
    { text: 'Create Bookables' },
    { text: 'Do some final magic' },
  ];
  populating = false;
  dummyLoadingText = 'Contact Server';
  ngOnInit(): void {
    this.getPricingPlans();
    this.searchOrganisation();
    this.lang = this.getLang();
    let oneMonthFuture = new Date();
    oneMonthFuture.setMonth(oneMonthFuture.getMonth() + 1);
    this.expirePlanDate = oneMonthFuture;
  }

  expirePlanDate = new Date();

  getLang() {
    // @ts-ignore
    if (window.Intl && typeof window.Intl === 'object') {
      // @ts-ignore
      let lang = Intl.getCanonicalLocales(window.navigator.language)[0];

      if (lang.indexOf('-') > 0) {
        lang = lang.split('-', 1)[0];
      }

      //if (lang === 'de') {
      return lang;
      /*} else {
                return 'en';
            }*/
    }
    return 'en';
  }

  searchOrganisation() {
    this.getOrgansationsBySearch();
  }

  days_between(date2) {
    // The number of milliseconds in one day
    const ONE_DAY = 1000 * 60 * 60 * 24;
    const end = new Date(date2);
    // Calculate the difference in milliseconds
    const differenceMs = end.getTime() - new Date().getTime();

    // Convert back to days and return
    return Math.round(differenceMs / ONE_DAY) > 0
      ? Math.round(differenceMs / ONE_DAY)
      : date2 === null ? 'Unknown' : 0;
  }

  setTabIndex(num) {
    this.activeTabIndex = num;

    if (num === 1) {
      this.getFeatureList();
    }
  }
  checkboxExpireCheck;

  private getOrgansationsBySearch() {
    let oneMonthFuture = new Date();
    oneMonthFuture.setMonth(oneMonthFuture.getMonth() + 1);
    this.expirePlanDate = oneMonthFuture;

    this.error = null;
    this.results = null;
    this.activeOrga = null;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.userService.getUser().token,
      }),
    };

    let url;
    if (!this.searchInput) {
      url = APP.baseurl + '/organisation/organisationSearch?&limit=25';
    } else if (this.searchInput === '*') {
      url =
        APP.baseurl +
        '/organisation/organisationSearch?search=' +
        '' +
        '&limit=1000';
    } else {
      url =
        APP.baseurl +
        '/organisation/organisationSearch?search=' +
        this.searchInput +
        '&limit=25';
    }

    this.isLoading = true;
    this.http
      .get(url, httpOptions)
      .toPromise()
      .then((data) => {
        // this.searchInput = null;
        this.isLoading = false;
        this.results = data;
      })
      .catch((err) => {
        this.error = err;
        this.isLoading = false;
      });
  }

  private getPricingPlans() {
    this.plans = null;

    this.error = null;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.userService.getUser().token,
      }),
    };

    this.isLoading = true;
    this.http
      .get(APP.baseurl + '/plan', httpOptions)
      .toPromise()
      .then((data) => {
        // this.searchInput = null;
        this.isLoading = false;
        this.plans = data;

        this.selectedOption = this.plans[0].internalId;
      })
      .catch((err) => {
        this.error = err;
        this.isLoading = false;
      });
  }

  changePlanInOrga(orga) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.selectedOption;

        this.error = null;
        this.isLoading = true;

        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            // 'x-organisation-id': orga._id
          }),
        };

        this.http
          .patch(
            APP.baseurl + '/organisation/organisationPlan/' + orga._id,
            {
              plan: this.selectedOption,
              planExpireDate: this.checkboxExpireCheck
                ? null
                : this.expirePlanDate,
            },

            httpOptions
          )
          .toPromise()
          .then((data) => {
            this.isLoading = false;
            this.getOrgansationsBySearch();
            this.organisationService.getOrganisation().then((data) => {
              this.organisationService.setActiveOrganisation(data);
            });
          })
          .catch((err) => {
            this.isLoading = false;
            this.error = err;
          });
      }
    });
  }

  addNewPlan() {
    this.modalActive = true;
    this.pricingPlanName = null;
    this.pricingPlanBookables = 10;
    this.pricingPlanNotes = null;
    this.activePlanEdit = null;
  }

  getDate(id) {
    return new Date(parseInt(id.substring(0, 8), 16) * 1000);
  }

  createNewPlan() {
    this.error = null;
    this.isLoading = true;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'x-organisation-id': orga._id
      }),
    };

    let internalid = this.pricingPlanName.replace(' ', '-').toLowerCase();

    if (internalid === 'freeplan') {
      internalid = 'freePlan';
    }
    if (internalid === 'proplan') {
      internalid = 'proPlan';
    }

    let data;
    if (this.pricingPlanNotes) {
      data = {
        name: this.pricingPlanName,
        bookableMaxCount: this.pricingPlanBookables,
        internalId: internalid,
        note: this.pricingPlanNotes,
        features: this.activeFeaturePlan,
      };
    } else {
      data = {
        name: this.pricingPlanName,
        bookableMaxCount: this.pricingPlanBookables,
        internalId: internalid,
        features: this.activeFeaturePlan,
      };
    }
    this.http
      .post(
        APP.baseurl + '/plan',
        data,

        httpOptions
      )
      .toPromise()
      .then((data) => {
        this.isLoading = false;
        this.getPricingPlans();
        this.pricingPlanBookables = 10;
        this.pricingPlanName = null;
        this.pricingPlanNotes = null;
        this.modalActive = false;
        this.activePlanEdit = null;
        this.activeFeaturePlan = null;
      })
      .catch((err) => {
        this.isLoading = false;
        this.error = err;
      });
  }

  activeFeaturePlan;
  editPlan(plan) {
    this.activePlanEdit = plan;
    this.pricingPlanName = plan.name;
    this.pricingPlanBookables = plan.bookableMaxCount;
    this.pricingPlanNotes = plan.note;
    this.modalActive = true;
    this.activeFeaturePlan = plan.features;
  }

  addToPlan(feature, event) {
    feature.isActive = event.target.checked;

    if (!this.activeFeaturePlan) {
      this.activeFeaturePlan = [];
    }
    this.activeFeaturePlan.forEach((element, index) => {
      if (element._id === feature._id) {
        this.activeFeaturePlan.splice(index, 1);
      }
    });

    this.activeFeaturePlan.push(feature);
  }
  updatePlan() {
    this.error = null;
    this.isLoading = true;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'x-organisation-id': orga._id
      }),
    };

    let data;

    if (this.pricingPlanNotes) {
      data = {
        name: this.pricingPlanName,
        bookableMaxCount: this.pricingPlanBookables,
        features: this.activeFeaturePlan,
        note: this.pricingPlanNotes,
      };
    } else {
      data = {
        name: this.pricingPlanName,
        bookableMaxCount: this.pricingPlanBookables,
        features: this.activeFeaturePlan,
      };
    }

    this.http
      .patch(
        APP.baseurl + '/plan/' + this.activePlanEdit._id,
        data,

        httpOptions
      )
      .toPromise()
      .then((data) => {
        this.isLoading = false;
        this.getPricingPlans();
        this.modalActive = false;
        this.activePlanEdit = null;
        this.pricingPlanBookables = 10;
        this.pricingPlanName = null;
        this.pricingPlanNotes = null;
      })
      .catch((err) => {
        this.isLoading = false;
        this.error = err;
      });
  }

  deletePlan() {
    this.error = null;
    this.isLoading = true;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'x-organisation-id': orga._id
      }),
    };

    this.http
      .delete(
        APP.baseurl + '/plan/' + this.activePlanEdit._id,

        httpOptions
      )
      .toPromise()
      .then((data) => {
        this.isLoading = false;
        this.getPricingPlans();
        this.modalActive = false;
        this.activePlanEdit = null;
        this.pricingPlanBookables = 10;
        this.pricingPlanName = null;
        this.pricingPlanNotes = null;
      })
      .catch((err) => {
        this.isLoading = false;
        this.error = err;
      });
  }

  addNewDummyOrganisation() {
    this.dummyLoadingText = 'Contact Server';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'x-organisation-id': orga._id
      }),
    };

    this.modalDummyActive = true;
    this.populating = true;

    this.http
      .post(
        APP.baseurl + '/seeder',
        {
          roomNumber: 2,
          locationNumber: 1,
        },

        httpOptions
      )
      .toPromise()
      .then((data) => {
        //@ts-ignore
        this.seedDummyData(data._id);
        let i = 0;
        this.dummyLoadingText = this.dummyTexts[i].text;

        let textinterval = setInterval(() => {
          if (i < this.dummyTexts.length) {
            i++;
            this.dummyLoadingText = this.dummyTexts[i].text;
          } else {
            clearInterval(textinterval);
            this.populating = false;
            this.searchOrganisation();
          }
        }, 1500);
      })
      .catch((err) => {
        this.dummyLoadingText = 'There was an error';
        this.populating = false;
      });
  }

  seedDummyData(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'x-organisation-id': orga._id
      }),
    };

    this.http
      .post(
        APP.baseurl + '/seeder/orga/' + id + '/bookables',
        {
          peopleNumber: 5,
          days: 3,
        },

        httpOptions
      )
      .toPromise()
      .then((data) => {})
      .catch((err) => {});
  }

  isNightly() {
    return CONFIG.env === 'nb';
  }

  setActiveOrga(orga) {
    let allorgas = this.organisationService.getActiveOrganisations();

    allorgas.forEach((element, index) => {
      if (element._id === orga._id) {
        allorgas.splice(index, 1);
      }
    });

    allorgas.unshift(orga);
    localStorage.setItem('lastOrga', JSON.stringify(orga._id));
    this.organisationService.setActiveOrganisation(allorgas);
  }

  featureList;

  getFeatureList() {
    this.loadingService.startLoading();
    ///plan/featureList

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'x-organisation-id': orga._id
      }),
    };

    this.http
      .get(
        APP.baseurl + '/plan/featureList/',

        httpOptions
      )
      .toPromise()
      .then((data) => {
        this.featureList = data;
        this.loadingService.stopLoading();
      })
      .catch((err) => {});
  }

  hasPlanFeature(planFeatureList, feature) {
    if (!planFeatureList || planFeatureList.length === 0) {
      return false;
    }

    let found = false;

    planFeatureList.forEach((element) => {
      if (element._id === feature._id) {
        if (element.isActive) {
          found = true;
        }
      }
    });
    return found;
  }

  deleteOrganisationModal(orga) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        showCancel: true,
        cta: this.translateService.instant('Delete Organisation'),
        dialogTitle: this.translateService.instant('Delete Organisation'),
        dialogText: this.translateService.instant(
          'Everything will be deleted.The Room and its Items. This is permanent.'
        ),
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.loadingService.startLoading();
        try {
          await this.organisationService.deleteOrganisation(orga);
          this.loadingService.stopLoading();
          const confirm = this.dialog.open(ConfirmDialogComponent, {
            maxWidth: '400px',
            data: {
              showCancel: false,
              cta: this.translateService.instant('Okay'),
              dialogTitle: this.translateService.instant('Successful'),
              dialogText: this.translateService.instant(
                'Organisation deleted successfully.'
              ),
            },
          });

          confirm.afterClosed().subscribe(res => {
            localStorage.removeItem('lastOrga');
            window.location.reload();
          })
         
         
        } catch (err) {
          console.log(err);
        }
      }
    });
  }
}
