<div [ngClass]="{'forbidden': isForbidden}">
    <div class="content bg-img">
        <div class="flex no-row-mobile">
            <h1 style="flex:1" class="orga-title">{{'Mobile Office' | translate}} <label class="switch" [ngClass]="{'disabled': loadingService.isItLoading()} ">
            <input type="checkbox" [disabled]="waitTimer || loadingService.isItLoading()" (change)="switchFeature()" [checked]="isActive" id="togBtn">
            <div class="slider round">
           
             <span class="on">{{'ON' | translate}}</span>
             <span class="off">{{'OFF' | translate}}</span>
            
            </div>
           </label>

            </h1>

        </div>

        <div class="flex column">
            <span style="
        color: #b6b6b7;align-self:baseline;
        margin-bottom: 10px;">{{'Give your users the opportunity to also track where they are, even if they don\'t book something that is within a specific location you created.' | translate}}</span>


        </div>





    </div>
    <ng-container *ngIf="isActive">
        <div class="content" style="display: flex;
justify-content: center;border-bottom: 1px solid #e8e8e8;">


            <div class="flex">

                <div class="flex column">
                    <div style="    text-align: center;
       
        margin-bottom: 10px;">{{'Today Mobile Office' | translate}} {{'Rate' | translate}}</div>
                    <app-chart [id]="'piechart'" [type]="'doughnut'" [height]="75" [data]="donutData" [labels]="donutStrings" *ngIf="donutData"></app-chart>
                </div>
                <div class="flex column">
                    <div style="    text-align: center;
      
        margin-bottom: 10px;">{{'Last 7 Days Mobile Office' | translate}} {{'Rate' | translate}}</div>
                    <app-chart [id]="'piechart2'" [type]="'doughnut'" [height]="75" [data]="donutData2" [labels]="donutStrings" *ngIf="donutData2"></app-chart>
                </div>
                <div class="flex column">
                    <div style="    text-align: center;
       
        margin-bottom: 10px;">{{'Since Start of Month Mobile Office' | translate}} {{'Rate' | translate}}</div>
                    <app-chart [id]="'piechart3'" [type]="'doughnut'" [height]="75" [data]="donutData3" [labels]="donutStrings" *ngIf="donutData3"></app-chart>
                </div>
                <div style="flex:1"></div>
            </div>


        </div>
        <div class="content">
            <h1>{{'Settings' | translate}}</h1>
            <span style="
    color: #b6b6b7;align-self:baseline;
    margin-bottom: 10px;">{{'Here you can add different places that are not within your created locations. Such as Mobile Office or other external locations.' | translate}}</span>
        </div>

        <div class="content flex ">

            <div class="link-container">
                <h2>{{'Places' | translate}}</h2>
                <ul>
                    <li *ngFor="let item of specialBookables" (click)="showCreatePlaceModal(item)" style="cursor: pointer;"><i class="fas fa-globe-europe"></i><a href="#">{{item.name | translate}}</a>

                </ul>
                <button (click)="showCreatePlaceModal()" class="alt-button" style="width: 100%;
        margin-top: 10px;">
            <i class="fas fa-plus" style="margin-right:5px"></i><span>{{'Add Place' | translate}}</span>
        </button>
            </div>
            <div style="flex: 0 0 25%"></div>
            <div class="hint" style="flex:1">
                <h1>{{'How it looks for your users' | translate}}</h1>
                <span style="
        color: #b6b6b7;align-self:baseline;
        margin-bottom: 10px;">{{'Users will see the following icon on your organisation link site' | translate}}</span>
                <img *ngIf="getLang() === 'de'" style="height: 120px; " src="../../../assets/img/mobileoffice-de.png">
                <img *ngIf="getLang() !== 'de'" style="height: 120px; " src="../../../assets/img/mobileoffice-en.png">
            </div>




        </div>


    </ng-container>
</div>


<div class="modal active" *ngIf="isForbidden">

    <div class="content" style="box-shadow: 0 3px 20px rgb(0 0 0 / 16%), 0 3px 20px rgb(0 0 0 / 23%);
    border-radius: 15px;">

        <div class="flex column overflow-hidden">



            <div class="flex column step" *ngIf="!showDeleteModal">
                <div style="font-size:100px">🙂</div>
                <span class="subtitle" style="margin-top:0px !important">{{'It\'s time to upgrade' | translate}}.</span>

                <span class="subtitle-sub">{{'You have reached the limit of your current plan. To use this feature, you need to choose a higher plan.' | translate}}</span>

                <span *ngIf="error" class="description errorText">{{error}}</span>
            </div>








            <button (click)="viewoptions()" class="create" style="background:var(--accent)">
                <ng-container *ngIf="!loadingService.isItLoading()">

       
                    <span >{{'View Options' | translate}}</span>

                </ng-container>
                <img  *ngIf="loadingService.isItLoading()" class="spin" src="../../../assets/img/loading.svg" style="width:20px;margin-top:0">
            </button>

            <p (click)="abort()" class="underline-hover" style="    margin-block-start: 0;
            color: var(--secondary);cursor:pointer">{{'No, thanks' | translate}}</p>

        </div>
    </div>
</div>
<div class="modal active" [ngClass]="{'active': modalActive}">

    <div class="content">
        <i *ngIf="!loadingService.isItLoading()" class="fas fa-times" (click)="closeModal()"></i>
        <div class="flex column overflow-hidden">



            <div class="flex column step" *ngIf="!showDeleteModal">
                <span class="subtitle">{{'Add Place' | translate}}</span>
                <img src="../../assets/img/location.svg">
                <span class="description">{{'Here you can add different places that are not within your created locations. Such as Mobile Office or other external locations.' | translate}}</span>
                <input #modalinput (keyup.enter)="specialBookableName && !activeElement &&  specialBookableName.length >= 3 ? createPlace() : specialBookableName && activeElement &&  specialBookableName.length >= 3 ? patchPlace() : false" [(ngModel)]="specialBookableName"
                    type="text" placeholder="Mobile Office">

                <span *ngIf="error" class="description errorText">{{error}}</span>
            </div>





            <button *ngIf="activeElement" class="alt-button" [disabled]="loadingService.isItLoading()" (click)="deletePlace()">
                <ng-container *ngIf="!loadingService.isItLoading()">

                  
                    <span>{{'Delete Place' | translate}}</span>

                </ng-container>
                <img  *ngIf="loadingService.isItLoading()" class="spin" src="../../../assets/img/loading.svg" style="width:20px;margin-top:0">
            </button>



            <button class="create" [ngClass]="{'delete-button': showDeleteModal}" [disabled]="loadingService.isItLoading() || !showDeleteModal && !specialBookableName || specialBookableName.length < 3" (click)="!activeElement ? createPlace() : patchPlace()">
                <ng-container *ngIf="!loadingService.isItLoading()">

                    <span *ngIf="!activeElement">{{'Create Place' | translate}}</span>
                    <span *ngIf="activeElement">{{'Save' | translate}}</span>

                </ng-container>
                <img  *ngIf="loadingService.isItLoading()" class="spin" src="../../../assets/img/loading.svg" style="width:20px;margin-top:0">
            </button>

        </div>
    </div>
    <div class="backdrop"></div>
</div>