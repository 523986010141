import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GuestHashService } from '../guest-hash.service';
import { Renderer } from '../_timetable/renderer';
import { Scope } from '../_timetable/_models/scope';
import { DashboardService } from '../dashboard.service';
import { Md5 } from 'ts-md5/dist/md5';
import { OrganisationService } from '../organisation.service';
import { UserService } from '../user.service';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { LoadingService } from '../loading.service';
import { TranslateService } from '@ngx-translate/core';
import { MyBookingsService } from '../my-bookings.service';
import { APP } from '../app.config';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  constructor(
    private guestService: GuestHashService,
    private router: Router,
    private dashboardService: DashboardService,
    private organisationService: OrganisationService,
    private userService: UserService,
    private GAService: GoogleAnalyticsService,
    private loadingService: LoadingService,
    private translateService: TranslateService,
    private userBookingService: MyBookingsService,
    private http: HttpClient
  ) {}

  activeDate;
  allOrganisations;
  firstLoad = true;
  slug;
  monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  @ViewChild('datepicker') datepicker;
  selector;
  renderer: Renderer;
  success = false;
  scope: Scope;
  locations;
  events = [];
  isLoading = false;
  activeLocation;
  activeOrganisation;
  canvas;
  showModal = false;
  activeItem;
  error;
  username;
  activeTab = 0;
  noLocations = false;
  wholeDayChecked = true;
  showTimetableInModal = false;
  showLocationDropdown = false;
  showMapFullscreen = false;
  now = new Date().getTime();
  showDeleteConfirm = false;
  showDeleteSuccess = false;
  lang;
  showSuperAdmin= false;

  timeslots = [
    '12:00 AM',
    '12:30 AM',
    '1:00 AM',
    '1:30 AM',
    '2:00 AM',
    '2:30 AM',
    '3:00 AM',
    '3:30 AM',
    '4:00 AM',
    '4:30 AM',
    '5:00 AM',
    '5:30 AM',
    '6:00 AM',
    '6:30 AM',
    '7:00 AM',
    '7:30 AM',
    '8:00 AM',
    '8:30 AM',
    '9:00 AM',
    '9:30 AM',
    '10:00 AM',
    '10:30 AM',
    '11:00 AM',
    '11:30 AM',
    '12:00 PM',
    '12:30 PM',
    '1:00 PM',
    '1:30 PM',
    '2:00 PM',
    '2:30 PM',
    '3:00 PM',
    '3:30 PM',
    '4:00 PM',
    '4:30 PM',
    '5:00 PM',
    '5:30 PM',
    '6:00 PM',
    '6:30 PM',
    '7:00 PM',
    '7:30 PM',
    '8:00 PM',
    '8:30 PM',
    '9:00 PM',
    '9:30 PM',
    '10:00 PM',
    '10:30 PM',
    '11:00 PM',
    '11:30 PM',
  ];
  timeslotsEnd = [
    '12:30 AM',
    '1:00 AM',
    '1:30 AM',
    '2:00 AM',
    '2:30 AM',
    '3:00 AM',
    '3:30 AM',
    '4:00 AM',
    '4:30 AM',
    '5:00 AM',
    '5:30 AM',
    '6:00 AM',
    '6:30 AM',
    '7:00 AM',
    '7:30 AM',
    '8:00 AM',
    '8:30 AM',
    '9:00 AM',
    '9:30 AM',
    '10:00 AM',
    '10:30 AM',
    '11:00 AM',
    '11:30 AM',
    '12:00 PM',
    '12:30 PM',
    '1:00 PM',
    '1:30 PM',
    '2:00 PM',
    '2:30 PM',
    '3:00 PM',
    '3:30 PM',
    '4:00 PM',
    '4:30 PM',
    '5:00 PM',
    '5:30 PM',
    '6:00 PM',
    '6:30 PM',
    '7:00 PM',
    '7:30 PM',
    '8:00 PM',
    '8:30 PM',
    '9:00 PM',
    '9:30 PM',
    '10:00 PM',
    '10:30 PM',
    '11:00 PM',
    '11:30 PM',
    '12:00 AM',
  ];

  showCreateReservation;

  selectedBookingSlots = [];

  showLocationChangeModal = false;

  bookedSlotsForActiveDay;

  ngOnInit(): void {
    this.GAService.send('User View Page');
    this.isLoading = true;
    localStorage.removeItem('activeBooking');
    if (localStorage.getItem('llid')) {
      this.setActiveLocation(JSON.parse(localStorage.getItem('llid')));
    } else {
      this.getOrgaIdBySlug();
    }
    this.lang = this.getLang();
    this.guestService.init();
    this.loadingService.startLoading();

    // @ts-ignore
    this.username = this.guestService.getUser().username;

    this.username ? false : (this.username = 'Anonymous');

    this.showSuperAdmin = this.isSuperAdmin();

    setTimeout(() => {
      this.initDate();
    });
  }

  getLang() {
    // @ts-ignore
    if (window.Intl && typeof window.Intl === 'object') {
      // @ts-ignore
      let lang = Intl.getCanonicalLocales(window.navigator.language)[0];

      if (lang.indexOf('-') > 0) {
        lang = lang.split('-', 1)[0];
      }

      //if (lang === 'de') {
      return lang;
      /*} else {
                return 'en';
            }*/
    }
    return 'en';
  }

  async getOrgaIdBySlug() {
    this.slug = this.router.url.slice(3, this.router.url.length);

    localStorage.setItem('os', JSON.stringify(this.slug));
    const self = this;
    await this.dashboardService
      .getOrgaBySlug(this.slug)
      .then((data) => {
        self.allOrganisations = data;
      })
      .catch(() => {
        // todo err
      });

    if (self.allOrganisations && self.allOrganisations.length > 0) {
      this.activeOrganisation = self.allOrganisations[0];
      this.organisationService.setActiveOrganisation(self.allOrganisations);
      this.getLocationsWithDetails();
    } else {
      this.firstLoad = false;
      this.loadingService.stopLoading();
      this.isLoading = false;
      this.router.navigateByUrl('/organisation-not-found');
    }
  }

  async getLocationsWithDetails() {
    this.dashboardService
      .getLocationsFromOrgaWithoutDetails(this.activeOrganisation._id)
      .then((data) => {
        this.locations = data;
        if (this.locations.length > 0) {
          if (!this.activeLocation) {
            this.activeLocation = this.locations[0];
          }
          this.getSlotsByLocationAndDate();
        } else {
          this.isLoading = false;
          this.noLocations = true;
        }
      })
      .catch(() => {
        // todo err
      });
  }

  getSlotsByLocationAndDate() {
    this.loadingService.startLoading();

    this.dashboardService
      .getSlotsByLocationAndDate(
        this.activeLocation._id,
        this.activeOrganisation._id,
        this.activeDate,
        true
      )
      .then((data) => {
        this.bookedSlotsForActiveDay = data;

        this.parseEventsIntoCalendar(this.bookedSlotsForActiveDay);

        this.getMobileOfficeBookings(this.activeDate);
      })
      .catch((err) => {
        // todo err
      });
  }

  mobileOfficeBookingData;
  isForbiddenMobileOffice = false;
  async getMobileOfficeBookings(date) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-organisation-id': this.activeOrganisation?._id,
      }),
    };

    let data = await this.http
      .get(APP.baseurl + '/mobileOffice/overview/' + date, httpOptions)
      .toPromise().catch(() => {
        this.isForbiddenMobileOffice = true;
      });

    this.mobileOfficeBookingData = [];

    let own = [];
    let other = [];

    if(data) {

    //@ts-ignore
    data.forEach((element, index) => {
      if (this.isOwnBooking(element.userIdHash)) {
        own.push(element);
      } else {
        other.push(element);
      }
    });
          
  }


    this.mobileOfficeBookingData = other;
    own.forEach((element) => {
      this.mobileOfficeBookingData.unshift(element);
    });

    // http://localhost:3000/mobileOffice/overview/2021-06
  }

  isDayToday(someDate) {
    const today = new Date();
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  }

  getMonth(date) {
    return this.translateService.instant(
      this.monthNames[new Date(date).getMonth()]
    );
  }

  parseEventsIntoCalendar(events) {
   

    let parsedEvents = [];

    events.forEach((event) => {
      if (event.blockedSlots.length > 0) {
        let day = new Date(event.blockedOnDay);

        event.blockedSlots.sort(function (a, b) {
          return a.slot - b.slot;
        });

        //remove duplicate slots
        for (let i = 0; i < event.blockedSlots.length - 1; i++) {
        
          if (event.blockedSlots[i + 1].slot === event.blockedSlots[i].slot) {
            event.blockedSlots.splice(i, 1);
          }
        }

        let res = event.blockedSlots.reduce(function (r, a) {
          r[a.userIdHash] = r[a.userIdHash] || [];
          r[a.userIdHash].push(a);
          return r;
        }, Object.create(null));

       

        for (let i = 0; i < Object.keys(res).length; i++) {
          let element = Object.keys(res)[i];

          let startSlot;
          let endSlot;

          for (let index = 0; index < res[element].length; index++) {
            if (!startSlot) {
              startSlot = res[element][index].slot;
            }

            if (!endSlot) {
              endSlot = startSlot + 1;
            }

            if (
              index + 1 < res[element].length &&
              res[element][index + 1].slot === res[element][index].slot + 1
            ) {
              endSlot = res[element][index + 1].slot;
            } else {
              endSlot = res[element][index].slot + 1;

              //create event
              this.createEventForCalendar(
                day,
                startSlot,
                endSlot,
                parsedEvents,
                event,
                res[element][index].userName,
                element,
                res[element][index].userIdHash,
                event.room
              );

              //nach dem create start / endslot auf null setzen
              startSlot = null;
              endSlot = null;
            }
          }
        }
      }
    });

    this.events = parsedEvents;
    this.activeDateEvents = [];
    this.events.forEach((theEvent) => {
      const today = new Date(this.activeDate);
      if (
        theEvent.startTime.getDate() == today.getDate() &&
        theEvent.startTime.getMonth() == today.getMonth() &&
        theEvent.startTime.getFullYear() == today.getFullYear()
      ) {
        this.activeDateEvents.push(theEvent);
      }
    });

    this.loadingService.stopLoading();
  }

  private createEventForCalendar(
    day: Date,
    startSlot: any,
    endSlot: number,
    parsedEvents: any[],
    event: any,
    username: string,
    id: string,
    userIdHash: string,
    room: string
  ) {
    let startTime = new Date(
      day.getFullYear(),
      day.getMonth(),
      day.getDate(),
      startSlot / 2,
      startSlot % 2 === 0 ? 0 : 30
    );

    let endTime = new Date(
      day.getFullYear(),
      day.getMonth(),
      day.getDate(),
      endSlot / 2,
      endSlot % 2 === 0 ? 0 : 30
    );

    parsedEvents.push({
      title: event.name,
      startTime: startTime,
      endTime: endTime,
      allDay: false,
      id: id,
      itemid: event.belongsToId,
      locationid: event.belongsToLocation,
      roomid: event.belongsToRoom,
      mapUrlLocation: event.mapUrlLocation,
      orgaid: event.belongsToOrganisation,
      location: event.location,
      userid: userIdHash,
      room,
      username,
    });
  }

  parseSlotsIntoItems(data) {
    this.activeLocation.rooms.forEach((rooms) => {
      rooms.bookableItems.forEach((bookable) => {
        bookable.slots = [];
      });
    });

    data.forEach((slotelement) => {
      this.activeLocation.rooms.forEach((element) => {
        element.bookableItems.forEach((bookable) => {
          if (bookable._id === slotelement.belongsToId) {
            bookable.slots = slotelement.blockedSlots;
            return;
          }
        });
      });
    });

    this.isLoading = false;
    this.firstLoad = false;
    this.loadingService.stopLoading();
  }

  initDate(): void {
    const date = new Date();

    const year = date.getFullYear().toString();
    const month =
      date.getMonth() + 1 < 10
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1;
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const datestring = year + '-' + month + '-' + day;

    this.activeDate = datestring;

    if (this.activeOrganisation && this.activeOrganisation.length > 0) {
      this.datepicker.value = datestring;
    }
  }

  validateEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  todayOrInFuture() {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    yesterday.setHours(0);
    yesterday.setMinutes(0);
    yesterday.setSeconds(0);
    const today = new Date(Date.parse(this.activeDate.replace(/-/g, '/')));
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);

    return today >= yesterday;
  }

  public trackByFunction(item) {
    if (!item) {
      return null;
    }
    return item.id;
  }

  scrollToMe() {
    setTimeout(() => {
      const objDiv = document.getElementById('modal');
      objDiv.scrollTop = 0;
    }, 200);
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  changeDate(add) {
    const date = new Date(this.activeDate);

    if (add > 0) {
      date.setTime(date.getTime() + 1000 * 60 * 60 * 24);
    } else {
      date.setTime(date.getTime() - 1000 * 60 * 60 * 24);
    }

    const year = date.getFullYear().toString();
    const month =
      date.getMonth() + 1 < 10
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1;
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const datestring = year + '-' + month + '-' + day;

    this.activeDate = datestring;
    this.activeDate = datestring;
    this.datepicker.value = this.activeDate;

    this.getSlotsByLocationAndDate();
  }

  dateChanged() {
    this.datepicker.value = this.activeDate;
  }

  bookItem(bookable) {
    this.activeItem = bookable;
    if (this.activeItem.slots.length > 0) {
      this.wholeDayChecked = false;
      this.showTimetableInModal = true;
    }

    this.showModal = true;
    window.scrollTo(0, 0);
    document.body.style.overflow = 'hidden';
  }

  cancelBooking() {
    this.activeItem = null;
    this.showModal = false;
    this.success = false;
    this.error = null;
    this.wholeDayChecked = true;
    this.showTimetableInModal = false;
    document.body.style.overflow = 'inherit';
  }

  selectslot(index) {
    if (this.selectedBookingSlots.includes(index)) {
      // delete slot
      this.selectedBookingSlots.splice(
        this.selectedBookingSlots.indexOf(index),
        1
      );
    } else {
      // add slot
      this.selectedBookingSlots.push(index);
    }
  }

  isSlotActive(index) {
    return this.selectedBookingSlots.includes(index);
  }

  blockItem(activeItem) {
    this.error = null;
    this.isLoading = true;
    const userid = this.guestService.getUser().id;
    const offset = 0;

    if (this.selectedBookingSlots.length === 0) {
      // nothing selected -> book whole day

      for (
        let index = this.activeOrganisation.dayBeginAtSlot;
        index <= this.activeOrganisation.dayEndsAtSlot;
        index++
      ) {
        this.selectedBookingSlots.push(index);
      }
    } else {
      for (let index = 0; index < this.selectedBookingSlots.length; index++) {
        this.selectedBookingSlots[index] += offset;
      }
    }

    const user = JSON.parse(localStorage.getItem('user'));

    user.username = this.username;
    localStorage.setItem('user', JSON.stringify(user));
    this.userService.user = user;
    this.userService.notify();

    this.dashboardService
      .blockBookable(
        userid,
        this.activeLocation._id,
        this.activeDate,
        this.selectedBookingSlots,
        activeItem._id,
        this.username,
        this.activeOrganisation._id
      )
      .then(() => {
        this.selectedBookingSlots = [];
        this.isLoading = false;
        this.showModal = false;
        this.wholeDayChecked = true;
        this.showTimetableInModal = false;
        this.getLocationsWithDetails();
        this.success = true;
      })
      .catch((err) => {
        // todo err
        this.isLoading = false;

        this.error = err;
        this.selectedBookingSlots = [];
      });
  }

  getRoomIdbyItemId(itemid) {
    let result;
    this.activeLocation.rooms.forEach((element) => {
      element.bookableItems.forEach((bookable) => {
        if (bookable._id === itemid) {
          result = element._id;
        }
      });
    });
    return result;
  }

  showTimeTable() {
    this.showTimetableInModal = !this.showTimetableInModal;

    if (!this.showTimetableInModal) {
      this.selectedBookingSlots = [];
    }
  }

  isSlotBooked(i) {
    i += 0; // offset for time
    let result;

    this.activeItem.slots.forEach((element) => {
      if (element.slot === i) {
        result = true;
      }
    });

    return result;
  }

  getSlotTakenByName(i) {
    i += 0; // offset for time
    let result;

    this.activeItem.slots.forEach((element) => {
      if (element.slot === i) {
        if (this.isOwnBooking(element.userIdHash)) {
          result = this.username;
        } else {
          result = element.userName;
        }
      }
    });

    return result;
  }

  changeChecked() {
    this.showTimetableInModal = !this.wholeDayChecked;
  }

  getLeftBlockedPosition(slotnumber) {
    let slot = slotnumber - this.activeOrganisation.dayBeginAtSlot;
    let sum = 0;

    for (
      let index = this.activeOrganisation.dayBeginAtSlot;
      index <= this.activeOrganisation.dayEndsAtSlot;
      index++
    ) {
      sum++;
    }

    const position = slot * (100 / sum);

    return position + '%';
  }

  getWidthOfSlots() {
    let sum = 0;

    for (
      let index = this.activeOrganisation.dayBeginAtSlot;
      index <= this.activeOrganisation.dayEndsAtSlot;
      index++
    ) {
      sum++;
    }

    const slot = 100 / sum;

    return slot + '%';
  }

  loadDashboard() {
    this.activeTab = 0;
    this.getOrgaIdBySlug();
  }

  async setActiveLocation(location) {
    this.showLocationChangeModal = false;

    this.loadingService.startLoading();
    localStorage.setItem('llid', JSON.stringify(location));

    await this.getOrgaIdBySlug();

    if (this.activeOrganisation._id === location.belongsToOrganisation) {
      this.activeLocation = location;
    } else {
      localStorage.removeItem('llid');
    }

    this.showLocationDropdown = false;
    this.loadingService.stopLoading();
  }

  timeSlotsTrackBy(index, item) {
    if (!item) {
      return null;
    }

    return index;
  }

  isOwnBooking(userhash) {
    const md5 = new Md5();
    const result = md5.appendStr(this.guestService.getUser().id).end();

    return userhash === result;
  }

  isSuperAdmin() {
    if (JSON.parse(localStorage.getItem('user')).token) {
      return this.userService.isSuperAdmin();
    }
    return false;
  }

  getMapUrl(mapUrl) {
    return mapUrl + '?' + new Date().getTime();
  }

  getTimeBySlot(slot, isStart) {
    if (isStart) {
      return this.timeslots[slot];
    } else {
      return this.timeslotsEnd[slot];
    }
  }

  getMedianTimeSlot(start, end) {
    return ((start + end) / 2).toFixed(0);
  }

  showChangeLocation() {
    this.showLocationChangeModal = true;
  }

  setActiveDate(event, forceRefresh?) {
    if (
      new Date(this.activeDate).setHours(0, 0) !==
        new Date(event).setHours(0, 0) ||
      forceRefresh
    ) {
      this.activeDate = new Date(event);
      this.getSlotsByLocationAndDate();
      this.getMobileOfficeBookings(this.activeDate);
    }
  }

  onCreateReservationClose(data) {
    this.showCreateReservation = false;
    this.setActiveDate(data.activeDate, true);
  }

  activeEvent;
  onEventSelected(event) {
    localStorage.setItem('activeBooking', JSON.stringify(event));
    this.activeEvent = event;

    /*
    this.router.navigate(
      ['/o/' + this.activeOrganisation.slug + '/' + event.title.toLowerCase()],
      {
        queryParams: { origin: 'calendar' },
      }
    );
    */
  }

  activeMobileBooking;

  onMobileOfficeSelected(event) {
    if (this.isOwnBooking(event.userIdHash)) {
      this.activeMobileBooking = event;
      this.showDeleteBookingModal();
    }
  }

  activeDateEvents;

  isActiveDate(someDate) {
    const today = new Date(this.activeDate);

    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  }

  showDeleteBookingModal() {
    this.showDeleteConfirm = true;
  }

  async deleteBooking() {
    this.loadingService.startLoading();

    if (this.activeMobileBooking) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'x-organisation-id': this.activeOrganisation?._id,
        }),
      };

      try {
        await this.http
          .delete(
            APP.baseurl +
              '/mobileOffice/block/' +
              this.activeMobileBooking._id +
              '/' +
              this.userService.user.id,
            httpOptions
          )
          .toPromise();

        this.activeMobileBooking = null;
        this.confirmModal();
      } catch (e) {
        this.loadingService.stopLoading();
      }
      return;
    }

if (!this.isSuperAdmin()) {
      this.userBookingService
      .deleteBooking(
        this.activeEvent.itemid,
        new Date(this.activeEvent.endTime).setUTCHours(0, 0, 0, 0),
        this.activeEvent.orgaid
      )
      .then((data) => {
        this.loadingService.stopLoading();
        this.showDeleteSuccess = true;
      })
      .catch((err) => {
        //todo err
        this.loadingService.stopLoading();
      });
    }
    else {
      this.userBookingService
      .adminDeleteBooking(
        this.activeEvent.itemid,
        this.activeEvent.username,
        new Date(this.activeEvent.endTime).setUTCHours(0, 0, 0, 0),
        this.activeEvent.orgaid
      )
      .then((data) => {
        this.loadingService.stopLoading();
        this.showDeleteSuccess = true;
      })
      .catch((err) => {
        //todo err
        this.loadingService.stopLoading();
      });
    }
  }

  confirmModal() {
    this.showDeleteConfirm = false;
    this.showDeleteSuccess = false;
    this.activeEvent = null;
    localStorage.removeItem('activeBooking');
    this.setActiveDate(this.activeDate, true);
  }
}
