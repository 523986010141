import { Injectable } from '@angular/core';


export interface ILoadingObserver {
  onLoadingChange(isLoading);
}


@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private observers = [];

  private isLoading = false;

  constructor() { }

  startLoading() {
    this.isLoading  = true;

    this.notify();
  }

  stopLoading() {
    this.isLoading = false;
    this.notify();
  }

  isItLoading() {
    return this.isLoading;
  }

  subscribe(observer: ILoadingObserver) {
    const idx = this.observers.indexOf(observer);
    if (idx === -1) {
      this.observers.push(observer);
      observer.onLoadingChange(this.isLoading);
    }
  }

  unsubscribe(observer: ILoadingObserver) {
    let idx = -1;
    for (const obs of this.observers) {
      if (obs === observer) {
        idx = this.observers.indexOf(obs);
      }
    }
    if (idx !== -1) {
      this.observers.splice(idx, 1);
    }
  }



  private notify() {
    this.observers.forEach((observer) => {
      observer.onLoadingChange(this.isLoading);
    });
  }

}
