<div class="flex noprint bg-img">
    <h1>Bookables</h1> <img *ngIf="isLoading" class="spin" src="../../../assets/img/loading.svg" style="width:30px;margin-top:0">
    <button class="add-button" (click)="showCreateBookable()"><i class="fas fa-plus"></i><span class="hide-mobile">Create New Bookable</span></button>
</div>
<span class="noprint">Select a Location:</span>
<select class="noprint" #l (change)="getLocationInfo(l.value)">
    <option [disabled]="location.rooms.length === 0" [selected]="activeLocation === location" [value]="location._id" *ngFor="let location of locations">{{location.name}}</option>
    
  </select>
<span class="noprint">Select a Room:</span>
<select class="noprint" #t (change)="getRoomInfo(t.value)">
    <option [selected]="activeRoom === room" [value]="room._id" *ngFor="let room of rooms">{{room.name}}</option>
    
  </select>
<!-- <button *ngIf="items && items.length > 0" class="add-button noprint" style="margin-top:0;float:right;background:white;color:black" (click)="printCodes()"><i class="fas fa-print"></i> Print QR Codes</button> -->

<div class="responsive-table ">
    <table class="noprint">

        <tr class="head">

            <th>Name</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
        </tr>


        <ng-container *ngFor="let item of items">
            <div style="margin-top:25px"></div>
            <tr>


                <!--<td class="clickable" style="text-transform: capitalize;" (click)="showQRCodeSingle(item)"> <i class="fas fa-qrcode"></i> {{item.name}}</td>-->
                <span style="margin-left:5px;padding:10px 20px;display:flex" (paste)="onPaste($event)" (keyup.enter)="onNewNameSave()" (input)="onNameChange(item,$event)" contenteditable="true">{{item.name}}</span>
                <td></td>
                <td></td>
                <td style="cursor:pointer;text-align: center;"><i (click)="deleteBookableModal(item._id)" class="far fa-trash-alt"></i> <i style="margin-left:15px" (click)="showQRCodeSingle(item)" class="fas fa-qrcode"></i></td>
                <td (click)="onNewNameSave()" style="margin-left: 25px;
                color: var(--primary);
                cursor:pointer;
                padding: 10px;
                font-size: 13px;
                text-decoration: underline;" *ngIf="lastChangeDelta && lastChangeDelta._id === item._id">
                    <ng-container *ngIf="!isLoading">Save</ng-container>
                    <ng-container *ngIf="isLoading"><img class="spin" src="../../../assets/img/loading.svg" style="width:20px;margin-top:0"></ng-container>
            </tr>






        </ng-container>

        <ng-container *ngIf="items && items.length === 0">
            <div class="empty-text">
                <i class="fas fa-scroll fa-4x"></i>
                <span class="title">No Bookables</span>
                <span class="cta" (click)="showCreateBookable()">Add Bookables to get started</span>
            </div>
        </ng-container>


    </table>
</div>

<div class="modal" [ngClass]="{'active': showQRModal}">

    <div class="content">
        <div class="flex column overflow-hidden printitem">
            <div style="text-align:center;display: block;font-size:2em;z-index: 2">{{activeItem?.name}}</div>
            <qr-code [value]="getSmallImgUrl(activeItem)" [size]="250"></qr-code>
            <!-- <img class="printimg" src="{{getSmallImgUrl(activeItem)}}"> -->
            <div style="color:var(--primary);position: relative;top:0px">Powered by Crewcus</div>

            <button class="noprint" [disabled]="isLoading" (click)="printSingle()">
                <ng-container *ngIf="!isLoading">
                   
                    <span class="noprint">Print Now</span>

                </ng-container>
                <img  *ngIf="isLoading" class="spin" src="../../../assets/img/loading.svg" style="width:20px;margin-top:0">
            </button>
            <button class="delete-button noprint" [disabled]="isLoading" (click)="showQRModal = false">
                <ng-container *ngIf="!isLoading">
                   
                    <span class="noprint">Close</span>

                </ng-container>
                <img  *ngIf="isLoading" class="spin" src="../../../assets/img/loading.svg" style="width:20px;margin-top:0">
            </button>
        </div>
    </div>
    <div class="backdrop"></div>
</div>


<div class="modal" [ngClass]="{'active': modalActive}">

    <div class="content">
        <i *ngIf="!isLoading" class="fas fa-times" (click)="modalActive = false"></i>
        <div class="flex column overflow-hidden">


            <div class="slider" id="onboarding-slider">
                <ng-container *ngIf="rooms.length > 0">
                    <div class="flex column step" *ngIf="!showDeleteModal">

                        <img src="../../assets/img/room.svg">
                        <span class="subtitle">Create New Bookable</span>
                        <input #bookableInput (keyup.enter)="bookableName.length >= 3 ? createBookable() : false" [(ngModel)]="bookableName" type="text" placeholder="Please enter a name (Table #1)">
                        <span class="description">Give your bookable a name. Such as 'Table #1'.</span>
                        <span *ngIf="error" class="description errorText">{{error}}</span>
                    </div>
                    <div class="flex column step" *ngIf="showDeleteModal">

                        <img src="../../assets/img/delete-now.svg">
                        <span class="subtitle">Delete Bookable</span>
                        <span class="description">The Bookable will be removed.</span>
                        <span *ngIf="error" class="description errorText">{{error}}</span>
                    </div>
                </ng-container>
                <div class="flex column step" *ngIf="rooms.length === 0">

                    <img src="../../assets/img/attention.svg">
                    <span class="subtitle">Room Missing</span>
                    <span class="description">You have to create a room first! Please go to the Rooms Tab.</span>
                    <span *ngIf="error" class="description errorText">{{error}}</span>
                </div>




            </div>

            <button [ngClass]="{'delete-button': showDeleteModal}" [disabled]="isLoading || !showDeleteModal && !bookableName &&  rooms.length > 0" (click)="!showDeleteModal ? rooms.length === 0 ? modalActive = false  : createBookable() : deleteBookable()">
                <ng-container *ngIf="!isLoading">
                    <ng-container *ngIf="rooms.length > 0">
                    <span *ngIf="!showDeleteModal">Create Bookable</span>
                    <span *ngIf="showDeleteModal">Delete Bookable</span>
                    </ng-container>
                    <span *ngIf="rooms.length === 0">Ok</span>

                </ng-container>
                <img  *ngIf="isLoading" class="spin" src="../../../assets/img/loading.svg" style="width:20px;margin-top:0">
            </button>

        </div>
    </div>
    <div class="backdrop"></div>
</div>