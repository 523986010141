<div class="content" *ngIf="settings">
    <ng-container *ngFor="let setting of settings">

        <div class="flex column row no-row-mobile">
            <div class="flex ">
                <div class="title">{{setting.title | translate}}</div>
                <ng-container *ngIf="setting.type === 'boolean'">
                    <label class="switch">
                        <input type="checkbox"  #t (change)="changeBool(setting.updateKey,t.checked)" [checked]="setting.value">
                        <span class="slider round"></span>
                      </label>
                </ng-container>
                <ng-container *ngIf="setting.type === 'string'">
                    <input #t (keyup)="changeString(setting.updateKey,t.value)" class="text-input" type="text" [value]="setting.value">
                </ng-container>
                <ng-container *ngIf="setting.type === 'timeSlot'">
                    <select #t (change)="setting.updateKey === 'dayBeginAtSlot' ? changeStart(setting.updateKey,t.value) : changeEnd(setting.updateKey,t.value)" class="text-input" [name]="setting.updateKey" id="from">
                        <ng-container *ngIf="setting.updateKey === 'dayBeginAtSlot'">
                        <ng-container *ngFor="let slot of timeslots;let i = index">
                        <option [selected]="i === setting.value" [value]="i">{{slot}}</option>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="setting.updateKey === 'dayEndsAtSlot'">
                        <ng-container *ngFor="let slot of timeslotsEnd;let i = index">
                        <option [selected]="i === setting.value" [value]="i">{{slot}}</option>
                        </ng-container>
                    </ng-container>
                    </select>
                </ng-container>
            </div>
            <div *ngIf="setting.info" class="row">
                <div class="info">{{setting.info | translate}}</div>
            </div>
        </div>



    </ng-container>
    <div class="flex">

        <button [disabled]="!hasChanges() || isLoading" class="cta-button" style="background:var(--accent);font-weight:normal" (click)="saveSettings()">
        {{'Save Settings' | translate}} <img  *ngIf="isLoading" class="spin" src="../../../assets/img/loading.svg" style="width:10px;margin-top:0">
    </button>
    </div>
    <div class="flex">

        <p *ngIf="success === 'success'" class="text">{{'Settings saved' | translate}}! <i class="fas fa-check success"></i></p>
        <p *ngIf="success === 'failed'" class="text">{{'Settings failed to save' | translate}}! ({{errorText}}) <i class="fas fa-times failed"></i></p>
    </div>
</div>