import { ConfigurableFocusTrap } from '@angular/cdk/a11y';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { config } from 'rxjs';
import { APP } from './app/app.config';

import { AppModule } from './app/app.module';
import { CONFIG } from './config/config';
import { environment } from './environments/environment';


if (CONFIG.env === 'prod' || CONFIG.env === 'qa') {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
