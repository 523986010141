<h2 mat-dialog-title>

    {{'Add Entry' | translate}}</h2>
<mat-dialog-content class="mat-typography">

    <form [formGroup]="selections">
        <mat-form-field style="width:100%" class="example-full-width" appearance="fill">
            <mat-label>{{'Leave a comment' | translate}}</mat-label>
            <textarea formControlName="message" matInput placeholder="{{'Ex. Payment received' | translate}}"></textarea>
        </mat-form-field>

        <!-- <section style="    display: flex;
    justify-content: flex-start;">
            <mat-checkbox formControlName="adminMessage" style="margin-right:15px">{{'Only visible for Admins'}}</mat-checkbox>
            <mat-checkbox formControlName="sendMail">{{'Send E-Mail' | translate}}</mat-checkbox>
        </section> -->

    </form>


</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="cancel-button" mat-button mat-dialog-close>{{'Cancel' | translate}} </button>
    <button class="cta-button" mat-button [mat-dialog-close]="selections" cdkFocusInitial>{{'Confirm' | translate}}</button>
</mat-dialog-actions>