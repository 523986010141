import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-mobile-office-floating',
  templateUrl: './mobile-office-floating.component.html',
  styleUrls: ['./mobile-office-floating.component.scss'],
})
export class MobileOfficeFloatingComponent implements OnInit {
  @Input()
  username;

  @Input()
  activeDate;

  @Input()
  activeLocation;

  showCreateReservation = false;
  hideInformation = false;

  @Output()
  onClose = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onCreateReservationClose(event) {
    this.showCreateReservation = false;
    this.onClose.emit({
      activeDate: this.activeDate,
    });
  }
}
