import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { fabric } from 'fabric';
import { APP } from '../app.config';
import { DashboardService } from '../dashboard.service';
import { GuestHashService } from '../guest-hash.service';
import { LocationService } from '../location.service';
import { OrganisationService } from '../organisation.service';
@Component({
  selector: 'app-table-manager',
  templateUrl: './table-manager.component.html',
  styleUrls: ['./table-manager.component.scss'],
})
export class TableManagerComponent implements OnInit {
  constructor(
    private guestHashService: GuestHashService,
    private locationService: LocationService,
    private organisationService: OrganisationService,
    private http: HttpClient,
    private dashboardService: DashboardService
  ) {}
  canvas;
  grid = 30;
  activeColor = '#424242';
  lastInbountX = 0;
  lastInbountY = 0;

  activeForm = 'rect';

  inputName;

  user;

  _clipboard;

  locations;
  activeRoom;
  activeLocation;

  selectedRoom;

  isBookable = true;

  isLoading = false;

  ngOnInit(): void {
    let self = this;
    this.isLoading = true;
    this.locationService
      .getAllLocations()
      .then((data) => {
        this.locations = data;

        if (!this.activeRoom) {
          this.getRoomInfo(this.locations[0]._id);
        }
      })
      .catch((err) => {
        //todo err
      });

    if (localStorage.getItem('user')) {
      this.user = JSON.parse(localStorage.getItem('user'));
      this.guestHashService.init();
    }

    this.canvas = new fabric.Canvas('c', {
      selection: true,
      preserveObjectStacking: true,
    });

    this.resizeCanvas(
      document.getElementById('map-builder').clientHeight,
      document.getElementById('map-builder').clientWidth * 2
    );

    this.canvas.on('object:moving', (options) => {
      if (
        Math.round(options.target.left / this.grid) * this.grid <=
          options.target.canvas.width - 30 &&
        Math.round(options.target.top / this.grid) * this.grid <=
          options.target.canvas.height - 15 &&
        Math.round(options.target.left / this.grid) * this.grid >= 0 &&
        Math.round(options.target.top / this.grid) * this.grid >= 0
      ) {
        this.lastInbountX =
          Math.round(options.target.left / this.grid) * this.grid;

        this.lastInbountY =
          Math.round(options.target.top / this.grid) * this.grid;

        options.target.set({
          left: this.lastInbountX,
          top: this.lastInbountY,
        });
      } else {
        options.target.set({
          left: this.lastInbountX,
          top: this.lastInbountY,
        });
      }
    });

    this.canvas.on('object:rotating', function (options) {
      var step = 90;
      options.target.angle = Math.round(options.target.angle / step) * step;
      if (options.target.angle !== 0) {
        options.target.angle = step;
        options.target.originX = 'left';
        options.target.originY = 'bottom';
      } else {
        options.target.originX = 'left';
        options.target.originY = 'top';
      }
    });

    this.canvas.on('object:moved', function (options) {
      self.saveHistory();
    });

    this.canvas.on('selection:updated', function (options) {
      self.activeColor = options.target.fill;
    });

    this.canvas.on('selection:created', function (options) {
      self.activeColor = options.target.fill;
    });

    this.canvas.on('object:scaled', function (options) {
      let scaleX = options.target.scaleX;
      let scaleY = options.target.scaleY;

      let resultX =
        scaleX * Math.round(options.target.width / 30) * 30 +
        ((scaleX * Math.round(options.target.width / 30) * 30) % 30);

      resultX = Math.trunc(resultX / 30) * 30;

      if (resultX === 0) {
        resultX = 30;
      }

      let resultY =
        scaleY * Math.round(options.target.height / 30) * 30 +
        ((scaleY * Math.round(options.target.height / 30) * 30) % 30);

      resultY = Math.trunc(resultY / 30) * 30;

      if (resultY === 0) {
        resultY = 30;
      }

      options.target.set({
        scaleX: 1,
        width: resultX,
      });

      options.target.set({
        scaleY: 1,
        height: resultY,
      });
      self.saveHistory();
    });

    var canvasWrapper = document.getElementById('map-builder');
    canvasWrapper.tabIndex = 1000;
    canvasWrapper.addEventListener('keydown', function (event) {
    

      //Delete Item on Backspace
      if (event.key === 'Backspace' && self.canvas.getActiveObject()) {
        self.delete();
      }

      if ((event.ctrlKey || event.metaKey) && event.key == 'c') self.Copy();
      if ((event.ctrlKey || event.metaKey) && event.key == 'v') self.Paste();
      if ((event.ctrlKey || event.metaKey) && event.key == 'z')
        self.backInHistory();
    });

    this.saveHistory();
  }

  historyStack = [];
  firstBackInHistory = true;

  backInHistory() {
    if (this.historyStack.length > 0) {
      let last = this.historyStack.pop();
      this.canvas.loadFromJSON(JSON.parse(last));
      if (this.firstBackInHistory) {
        this.firstBackInHistory = false;
        this.backInHistory();
      }
    } else {
      this.firstBackInHistory = true;
    }
  }

  saveHistory() {
    const save = JSON.stringify(this.canvas);

    this.historyStack.push(save);
  }

  resizeCanvas(height, width) {
    this.canvas.setHeight(height);
    this.canvas.setWidth(width);
    this.canvas.renderAll();
  }

  export() {
    var r = confirm('Do you want to download the current Map?');
    if (r == true) {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0');
      var yyyy = today.getFullYear();

      let date = dd + mm + yyyy;
      const save = this.canvas.toJSON();
      let toSave = {
        canvas: save,
        location: this.activeLocation.name,
      };

      var dataStr =
        'data:text/json;charset=utf-8,' +
        encodeURIComponent(JSON.stringify(toSave));
      var downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute('href', dataStr);
      downloadAnchorNode.setAttribute(
        'download',
        date + '_MyFloorplan' + '.Crewcus'
      );
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    } else {
      return;
    }
  }

  import() {
    var input = document.createElement('input');
    input.type = 'file';

    input.onchange = (e) => {
      // getting a hold of the file reference
      //@ts-ignore
      var file = e.target.files[0];

      // setting up the reader
      var reader = new FileReader();
      reader.readAsText(file, 'UTF-8');

      // here we tell the reader what to do when it's done reading...
      reader.onload = (readerEvent) => {
        var content = readerEvent.target.result; // this is the content!
        try {
          //@ts-ignore
          const parse = JSON.parse(content);

          this.canvas.loadFromJSON(parse.canvas);
        } catch (e) {
          //error loading file
        
          console.log('Error while loading the map from disk.');
        }
      };
    };

    input.click();
  }

  save() {
    const save = JSON.stringify(this.canvas);

    let toSave = {
      canvas: save,
      location: 'test',
    };
    localStorage.setItem('canvas', JSON.stringify(toSave));
  }

  loadCanvasandSettings() {
    const parse = JSON.parse(localStorage.getItem('canvas'));
    this.canvas.loadFromJSON(JSON.parse(parse.canvas));
    this.saveHistory();
  }

  load() {
    //fabricjs is weird ..
    this.loadCanvasandSettings();
    setTimeout(() => {
      this.loadCanvasandSettings();
    }, 10);
  }

  addTable(x?, y?) {
    let self = this;

    if (!this.activeColor) {
      this.activeColor = '#fa675b';
    }

    let table;
    if (this.activeForm === 'rect') {
      table = new fabric.Rect({
        left: x ? x : 0,
        top: y ? y : 0,
        width: 60,
        height: 30,
        fill: this.activeColor,
        originX: 'left',
        originY: 'top',
        lockScalingFlip: true,
        perPixelTargetFind: true,
        transparentCorners: true,
        centerScaling: true,
        centeredRotation: true,
      });
    } else if (this.activeForm === 'circle') {
      table = new fabric.Circle({
        radius: 15,
        fill: this.activeColor,
        top: x ? x : 100,
        left: y ? y : 100,
        originX: 'left',
        originY: 'top',
      });
    } else if (this.activeForm === 'text') {
      table = new fabric.IText(this.inputName, {
        left: 100,
        top: 100,
        fontSize: 18,
        fill: this.activeColor,
      });
    }

    if (self.inputName) {
      let name = JSON.parse(JSON.stringify(self.inputName));
    } else {
      let name = '';
    }

    let choice = { isBookable: self.isBookable };
    let isBookableChoice = JSON.parse(JSON.stringify(choice));
    let room = JSON.parse(JSON.stringify(self.selectedRoom));
    if (room._id) {
      room = room._id;
    }
  
    if (isBookableChoice) {
      this.activeColor = '#424242';
    } else {
      this.activeColor = '#e8e8e8';
    }



    //{...object, 'options': {hans: 'wurst'}}


    let temp = table.toObject;


    table.toObject = (function(toObject) {
      return function() {
        return fabric.util.object.extend(toObject.call(this), { options: {
          name,
          isDecoration: !isBookableChoice.isBookable,
          isMultiBookable: false,
          belongsToRoom: room,
        }});
      };
    })(table.toObject);


    // table.toObject = function () {
    //   let x = JSON.stringify(table);
    //   return {
    //     x,
    //     options: {
    //       name,
    //       isDecoration: !isBookableChoice.isBookable,
    //       isMultiBookable: false,
    //       belongsToRoom: room,
    //     },
    //   };
    // };



    // table = tabletest;

    table.on('selected', function () {
      if (self.activeColor !== table.fill) {
        self.saveHistory();
      }
      self.activeColor = table.fill;
    });

    this.canvas.add(table);
    this.canvas.renderAll();
    this.inputName = null;

    this.saveHistory();
  }

  delete() {
    this.canvas.getActiveObjects().forEach((element) => {
      this.canvas.remove(element);
    });
    this.canvas.discardActiveObject().renderAll();
    this.saveHistory();
  }

  changeColor() {
    if (this.canvas.getActiveObject()) {
      this.canvas.getActiveObject().set('fill', this.activeColor);
      this.canvas.renderAll();
      this.saveHistory();
    }
  }

  changeBookableOption() {
    if (this.isBookable) {
      this.activeColor = '#424242';
    } else {
      this.activeColor = '#e8e8e8';
    }
  }

  setActiveForm(form) {
    this.activeForm = form;
  }

  clickOnCanvas(e) {
    return;
    if (!this.canvas.getActiveObject()) {
      var rect = e.target.getBoundingClientRect();
      var x = e.clientX - rect.left; //x position within the element.
      var y = e.clientY - rect.top; //y position within the element.

      this.addTable(x, y);
    }
  }

  Copy() {
    let self = this;

    if (this.canvas.getActiveObject()) {
      this.canvas.getActiveObject().clone(function (cloned) {
        self._clipboard = cloned;
      });
    }
  }

  Paste() {
    let self = this;

    this._clipboard.clone(function (clonedObj) {
      self.canvas.discardActiveObject();
      clonedObj.set({
        left: clonedObj.left + 10,
        top: clonedObj.top + 10,
        evented: true,
      });
      if (clonedObj.type === 'activeSelection') {
        clonedObj.canvas = self.canvas;
        clonedObj.forEachObject(function (obj) {
          self.canvas.add(obj);
        });

        clonedObj.setCoords();
      } else {
        self.canvas.add(clonedObj);
      }
      self._clipboard.top += 10;
      self._clipboard.left += 10;
      self.canvas.setActiveObject(clonedObj);
      self.canvas.requestRenderAll();
    });
    self.saveHistory();
  }

  sendBackward() {
    this.canvas.sendBackwards(this.canvas.getActiveObject(), true);
  }

  bringForward() {
    this.canvas.bringForward(this.canvas.getActiveObject(), true);
  }

  sendToBack() {
    this.canvas.sendToBack(this.canvas.getActiveObject(), true);
  }

  bringToFront() {
    this.canvas.bringToFront(this.canvas.getActiveObject(), true);
  }

  getRoomInfo(id) {
    this.locations.forEach((element) => {
      if (element._id === id) {
        this.activeRoom = element.rooms;
        if (!this.selectedRoom) {
          this.selectedRoom = element.rooms[0];
        }

        this.activeLocation = element;
        this.loadMap(element);
      }
    });
  }

  loadMap(location) {


    const activeOrganisation = this.organisationService.getActiveOrganisations()[0];

    this.dashboardService
      .getLocationsFromOrgaWithDetails(
        location._id,
        activeOrganisation._id
      )
      .then((data) => {
        this.isLoading = false;
        //@ts-ignore
        this.canvas.loadFromJSON(data.roomDefinition);
        this.canvas.renderAll();
       
      })
      .catch((err) => {
        //todo err
      });
  }

  setActiveRoom(room) {

    this.selectedRoom = room;
  }

  saveToServer() {
    this.isLoading = true;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-organisation-id': this.organisationService.getActiveOrganisations()[0]
          ._id,
      }),
    };

    const save = this.canvas.toJSON();
    let toSave = {
      roomDefinition: save,
      name: this.activeLocation.name,
    };

    return this.http
      .put(
        APP.baseurl + '/location/' + this.activeLocation._id,
        toSave,
        httpOptions
      )
      .toPromise()
      .then((data) => {

   
        this.isLoading = false;
      })
      .catch((err) => {
        //todo err
      });
  }
}
