import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {
  @Output()
  activeDateChange = new EventEmitter();

  @Input()
  showEventDetail;
  @Input()
  activeDate;
  @Input()
  events;

  ngOnInit(): void {
    if (this.activeDate) {
      this.calendar.currentDate = new Date(this.activeDate);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activeDate) {
      this.calendar.currentDate = changes.activeDate.currentValue;
    }
  }

  eventSource;
  viewTitle;

  isToday: boolean;
  calendar = {
    dateFormatter: {
      formatMonthViewDay: function (date: Date) {
        return date.getDate().toString();
      },
    },
    mode: 'month',
    currentDate: new Date(),
    noEventsLabel: this.translasteService.instant('No Reservations'),
    locale: this.getLang() === 'de' ? 'de-DE' : 'en-GB',
  };

  constructor(
    private readonly platform: Platform,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private translasteService: TranslateService
  ) {}

  isDayToday(someDate) {
    const today = new Date();
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  }

  getLang() {
    // @ts-ignore
    if (window.Intl && typeof window.Intl === 'object') {
      // @ts-ignore
      let lang = Intl.getCanonicalLocales(window.navigator.language)[0];

      if (lang.indexOf('-') > 0) {
        lang = lang.split('-', 1)[0];
      }

      //if (lang === 'de') {
      return lang;
      /*} else {
                return 'en';
            }*/
    }
    return 'en';
  }

  loadEvents() {
    this.eventSource = this.createRandomEvents();
  }

  onViewTitleChanged(title) {
    this.viewTitle = title;
  }

  onEventSelected(event) {
    localStorage.setItem('activeBooking', JSON.stringify(event));
    this.router.navigate([this.router.url + '/' + event.title.toLowerCase()], {
      queryParams: { origin: 'calendar' },
    });
  }

  changeMode(mode) {
    this.calendar.mode = mode;
  }

  today() {
    this.calendar.currentDate = new Date();
    this.activeDateChange.emit(this.calendar.currentDate);
  }

  async onTimeSelected(ev) {
    if (ev.switchMonth === -1) {
      this.goToPreviousMonth(ev.selectedTime);
    } else if (ev.switchMonth === 1) {
      this.goToNextMonth(ev.selectedTime);
    }

    this.activeDateChange.emit(ev.selectedTime);

    // console.log('switch month' + ev.switchMonth + ' Selected time: ' + ev.selectedTime + ', hasEvents: ' +
    //   (ev.events !== undefined && ev.events.length !== 0) + ', disabled: ' + ev.disabled);
  }

  onCurrentDateChanged(event: Date) {
    if (this.showEventDetail) {
      this.cdr.detectChanges();
    }

    event = new Date(event);
    var today = new Date();
    today.setHours(0, 0, 0, 0);
    event.setHours(0, 0, 0, 0);

    this.isToday = today.getTime() === event.getTime();
  }

  createRandomEvents() {
    var events = [];
    for (var i = 0; i < 50; i += 1) {
      var date = new Date();
      var eventType = Math.floor(Math.random() * 2);
      var startDay = Math.floor(Math.random() * 90) - 45;
      var endDay = Math.floor(Math.random() * 2) + startDay;
      var startTime;
      var endTime;
      if (eventType === 0) {
        startTime = new Date(
          Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate() + startDay
          )
        );
        if (endDay === startDay) {
          endDay += 1;
        }
        endTime = new Date(
          Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate() + endDay
          )
        );
        events.push({
          title: 'All Day - ' + i,
          startTime: startTime,
          endTime: endTime,
          allDay: true,
        });
      } else {
        var startMinute = Math.floor(Math.random() * 24 * 60);
        var endMinute = Math.floor(Math.random() * 180) + startMinute;
        startTime = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() + startDay,
          0,
          date.getMinutes() + startMinute
        );
        endTime = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() + endDay,
          0,
          date.getMinutes() + endMinute
        );
        events.push({
          title: 'Event - ' + i,
          startTime: startTime,
          endTime: endTime,
          allDay: false,
        });
      }
    }
    return events;
  }

  onRangeChanged(ev) {
    console.log(
      'range changed: startTime: ' + ev.startTime + ', endTime: ' + ev.endTime
    );
  }

  markDisabled = (date: Date) => {
    var current = new Date();
    current.setHours(0, 0, 0);
    return date < current;
  };

  goToNextMonth(date?) {
    let now;
    if (date) {
      now = new Date(date);
    } else {
      now = new Date(this.calendar.currentDate);
      now.setMonth(now.getMonth() + 1, 1);
    }

    this.calendar.currentDate = now;
    this.activeDateChange.emit(this.calendar.currentDate);
  }

  goToPreviousMonth(date?) {
    let now;
    if (date) {
      now = new Date(date);
    } else {
      now = new Date(this.calendar.currentDate);
      now.setMonth(now.getMonth() - 1, 1);
    }

    this.calendar.currentDate = now;
    this.activeDateChange.emit(this.calendar.currentDate);
  }
}
