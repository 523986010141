<div class="modal" [ngClass]="{'active': activeModal}">


    <div class="content onboarding">

        <div class="flex column overflow-hidden">
            <span *ngIf="step === 1" class="title">{{'Welcome to' | translate}} Crewcus!</span>

            <div class="slider" id="onboarding-slider">
                <div class="flex column step" id="step-1">

                    <img src="../../assets/img/get_started.svg">
                    <span class="subtitle ">{{'Welcome' | translate}}!</span>
                    <span class="description">{{'To get started we need to set some things up. I will guide you trough these simple steps.' | translate}}</span>
                </div>

                <div class="flex column step" id="step-2">
                    <img src="../../assets/img/orga-name.svg ">
                    <span class="subtitle ">{{'Organisation' | translate}}</span>
                    <span style="color: red;
                        width: 55%;
    font-size: 11px;
                    margin-top: 5px;
                    padding: 0px 50px;" *ngIf="error">{{error}}</span>
                    <span class="description ">{{'First you have to create an organization. An organization later contains all your locations, rooms and bookable items.' | translate}}</span>
                    <input minlength="3" maxlength="40" #focusorga class="mobile-padding" (keyup.enter)="organisationName ? nextStep() : false" [(ngModel)]="organisationName" type="text" placeholder="{{'ACME GmbH' | translate}}">

                </div>


                <div class="flex column step" id="step-3">
                    <img src="../../assets/img/location.svg ">
                    <span class="subtitle ">{{'Create First Location' | translate}}</span>
                    <span style="color: red;
                    margin-top: 5px;
                    width: 55%;
    font-size: 11px;
                    padding: 0px 50px;" *ngIf="error">{{error}}</span>
                    <span class="description ">{{'Now you can create your first location. You can add more locations later in your dashboard.' | translate}}</span>
                    <input minlength="3" maxlength="40" #focuslocation class="mobile-padding" (keyup.enter)="locationName ? nextStep() : false" [(ngModel)]="locationName" type="text" placeholder="Berlin">

                </div>


                <div class="flex column step" id="step-4">
                    <img src="../../assets/img/room.svg ">
                    <span class="subtitle ">{{'Create First Room' | translate}}</span>
                    <span style="color: red;
                    margin-top: 5px;
                    padding: 0px 50px;" *ngIf="error">{{error}}</span>
                    <span class="description ">{{'Now you can create your first room. You can add more rooms later in your dashboard.' | translate}}</span>
                    <input minlength="3" maxlength="40" #focusroom class="mobile-padding" (keyup.enter)="roomName ? nextStep() : false" [(ngModel)]="roomName" type="text" placeholder="{{'Meeting Room' | translate}}">

                </div>

                <div class="flex column step" id="step-5">
                    <img src="../../assets/img/workplace-1.svg ">
                    <span class="subtitle ">{{'Create First Bookable' | translate}}</span>
                    <span style="color: red;
                    margin-top: 5px;
                    width: 55%;
    font-size: 11px;
                    padding: 0px 50px;" *ngIf="error">{{error}}</span>
                    <span class="description ">{{'Now you can create your first bookable item. You can add more bookables later in your dashboard.' | translate}}</span>
                    <input minlength="3" maxlength="40" #focusbookable class="mobile-padding" (keyup.enter)="bookableName ? nextStep() : false" [(ngModel)]="bookableName" type="text" placeholder="{{'Table #1' | translate }}">

                </div>

                <div class="flex column step" id="step-6">
                    <img src="../../assets/img/done.svg ">
                    <span class="subtitle ">{{'Successfull created' | translate}}</span>
                    <span class="description ">{{'Everything is ready now. You can add more Locations, Rooms and Bookable Items from the Menu to the left.' | translate}}</span>
                </div>


            </div>



            <div class="step-holder flex ">
                <div class="step " [ngClass]="{ 'filled': step===1, 'success': step > 1 } "></div>
                <div class="step " [ngClass]="{ 'filled': step===2 ,  'success': step > 2 } "></div>
                <div class="step " [ngClass]="{ 'filled': step===3 ,  'success': step > 3 } "></div>
                <div class="step " [ngClass]="{ 'filled': step===4 ,  'success': step > 4 } "></div>
                <div class="step " [ngClass]="{ 'filled': step===5 ,  'success': step > 5 } "></div>
                <div class="step " [ngClass]="{ 'filled': step===6 ,  'success': step > 6 } "></div>

            </div>
            <button (click)="nextStep()" [disabled]="step === 2 && !organisationName || isLoading">
                <ng-container *ngIf="!isLoading">
                
                <span *ngIf="step === 1">{{'Lets do this' | translate}}</span>
                <span *ngIf="step === 2">{{'Create Organisation' | translate}}</span>
                <span *ngIf="step !== 2 && step !== 1 && step !== 4 && step != 6">{{'Next' | translate}}</span>
                <span *ngIf="step === 4">{{'Lets create a location' | translate}}</span>
                <span *ngIf="step === 6">{{'Complete Setup' | translate}}</span>
               
            </ng-container>
           
         
            </button>
            <span *ngIf="step > 2" style="font-size:12px;color:var(--primary);cursor:pointer" (click)="finishAll()">{{'Skip Setup' | translate}}</span>
        </div>
    </div>
    <div class="backdrop"></div>
</div>