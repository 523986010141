<img src="../../../assets/img/crewcus-logo" style="max-width: 150px;">
<form (keyup.enter)="checkStep()" [formGroup]="detailsForm">

    <mat-horizontal-stepper linear (animationDone)="focusInput()" (selectionChange)="changeStep($event)" #stepper>

        <mat-step [completed]="true" label="{{'Welcome' | translate}}" state="done">

            <div class="flex flex-1">
                <div style="max-width: 40vw;align-self: center;">
                    <img style="width: 100%;" src="../../../assets/img/5556.jpg">
                </div>
                <div style="align-self:center">
                    <h2>{{'Welcome to crewcus' | translate}}!</h2>
                    <h3>{{'To get started we need to set some things up. I will guide you trough these simple steps.' | translate}}</h3>
                </div>
            </div>
            <div style="display: flex;
        justify-content: flex-end;;margin-top:15px">
                <!-- <button class="cta-button grey" mat-button matDialogClose>{{'Close' | translate}}</button> -->
                <button class="cta-button" mat-button matStepperNext>{{'Next' | translate}}</button>
            </div>
        </mat-step>

        <mat-step [completed]="this.detailsForm.get('organisationName').valid" label="{{'Organisation' | translate}}">

            <div class="flex flex-1">
                <div style="max-width: 40vw;align-self: center;">
                    <img style="width: 100%;" src="../../../assets/img/5556.jpg">
                </div>
                <div style="align-self:center">
                    <h2>{{'Create Organisation' | translate}}</h2>
                    <h3>{{'First you have to create an organization. An organization later contains all your locations, rooms and bookable items.' | translate}}</h3>
                    <mat-form-field style="margin-top:10px;width:100%" class="example-full-width" appearance="fill">
                        <mat-label>{{'Organisation Name' | translate}}</mat-label>
                        <input formControlName="organisationName" minlength="3" maxlength="40" #input1 matInput placeholder="Müller GmbH">
                        <mat-icon matSuffix *ngIf="this.detailsForm.get('organisationName').valid">done</mat-icon>
                        <mat-icon matSuffix *ngIf="this.detailsForm.get('organisationName').touched  && !this.detailsForm.get('organisationName').valid">do_not_disturb</mat-icon>
                        <mat-error *ngIf="this.detailsForm.get('organisationName').hasError('invalid')">
                            {{'Organisation Name already taken. Please choose another one.' | translate}}
                        </mat-error>
                        <mat-error *ngIf="this.detailsForm.get('organisationName').touched && !this.detailsForm.get('organisationName').valid && !this.detailsForm.get('organisationName').hasError('invalid')">
                            {{'Organisation Name too short. Name must be at least 4 characters long.' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>


            <div style="display: flex;
        justify-content: flex-end;;margin-top:15px">
                <button class="cta-button grey" mat-button matStepperPrevious>{{'Back' | translate}}</button>
                <button [disabled]="!detailsForm.get('organisationName').valid" class="cta-button" mat-button matStepperNext>{{'Create Organisation' | translate}}</button>
            </div>
        </mat-step>

        <mat-step [completed]="this.detailsForm.get('locationName').valid" label="{{'Location' | translate}}">

            <div class="flex flex-1">
                <div style="max-width: 40vw;align-self: center;">
                    <img style="width: 100%;" src="../../../assets/img/6316.jpg">
                </div>
                <div style="align-self:center">
                    <h2>{{'Create First Location' | translate}}</h2>
                    <h3>{{'Now you can create your first location. You can add more locations later in your dashboard.' | translate}}</h3>
                    <mat-form-field style="margin-top:10px;width:100%" class="example-full-width" appearance="fill">
                        <mat-label>{{'Location Name' | translate}}</mat-label>
                        <input formControlName="locationName" minlength="3" maxlength="40" #input2 matInput placeholder="Berlin">
                    </mat-form-field>
                </div>
            </div>


            <div style="display: flex;
        justify-content: flex-end;;margin-top:15px">
                <button class="cta-button grey" mat-button matStepperPrevious>{{'Back' | translate}}</button>
                <button [disabled]="!detailsForm.get('locationName').valid" class="cta-button" mat-button matStepperNext>{{'Create Location' | translate}}</button>
            </div>
        </mat-step>

        <mat-step [completed]="this.detailsForm.get('roomName').valid" label="{{'Room' | translate}}">

            <div class="flex flex-1">
                <div style="max-width: 40vw;align-self: center;">
                    <img style="width: 100%;" src="../../../assets/img/6316.jpg">
                </div>
                <div style="align-self:center">
                    <h2>{{'Create First Room' | translate}}</h2>
                    <h3>{{'Now you can create your first room. You can add more rooms later in your dashboard.' | translate}}</h3>
                    <mat-form-field style="margin-top:10px;width:100%" class="example-full-width" appearance="fill">
                        <mat-label>{{'Room Name' | translate}}</mat-label>
                        <input formControlName="roomName" minlength="3" maxlength="40" #input3 matInput placeholder="Meeting Room">
                    </mat-form-field>
                </div>
            </div>


            <div style="display: flex;
        justify-content: flex-end;;margin-top:15px">
                <button class="cta-button grey" mat-button matStepperPrevious>{{'Back' | translate}}</button>
                <button [disabled]="!detailsForm.get('roomName').valid" class="cta-button" mat-button matStepperNext>{{'Create Room' | translate}}</button>
            </div>
        </mat-step>

        <mat-step [completed]="this.detailsForm.get('bookableName').valid" label="{{'Bookable' | translate}}">

            <div class="flex flex-1">
                <div style="max-width: 40vw;align-self: center;">
                    <img style="width: 100%;" src="../../../assets/img/6316.jpg">
                </div>
                <div style="align-self:center">
                    <h2>{{'Create First Bookable' | translate}}</h2>
                    <h3>{{'Now you can create your first bookable item. You can add more bookables later in your dashboard.' | translate}}</h3>
                    <mat-form-field style="margin-top:10px;width:100%" class="example-full-width" appearance="fill">
                        <mat-label>{{'Bookable Name' | translate}}</mat-label>
                        <input formControlName="bookableName" minlength="3" maxlength="40" #input4 matInput placeholder="Table #1">
                    </mat-form-field>
                </div>
            </div>


            <div style="display: flex;
        justify-content: flex-end;;margin-top:15px">
                <button class="cta-button grey" mat-button matStepperPrevious>{{'Back' | translate}}</button>
                <button [disabled]="!detailsForm.get('bookableName').valid" class="cta-button" mat-button matStepperNext>{{'Create Bookable' | translate}}</button>
            </div>
        </mat-step>

        <mat-step label="{{'Finish' | translate}}">




            <div class="flex flex-1">
                <div style="max-width: 40vw;align-self: center;">
                    <img style="width: 100%;" src="../../../assets/img/6316.jpg">
                </div>
                <div style="align-self:center">
                    <h2>🎉 {{'Congratulations' | translate}} 🎉</h2>
                    <h3>{{'You have completed the introduction! As a small thank you, we will give you 30 days of Pro Plan without any obligations or automatic renewal. You will have no costs even after expiration! The crewcus team wishes Happy Booking!'
                        | translate}}</h3>
                    <p><i class="fa fa-check-circle-o" aria-hidden="true" style="margin-right:10px"></i>{{'We give you 30 days Pro Subscription for free without any obligations or automatic renewal' | translate}}</p>
                    <p><i class="fa fa-check-circle-o" aria-hidden="true" style="margin-right:10px"></i>{{'After expiration of the free trial period, you will automatically revert to the forever free plan' | translate}}</p>
                    <p><i class="fa fa-check-circle-o" aria-hidden="true" style="margin-right:10px"></i>{{'There are absolutely no costs for you during this time.' | translate}}</p>
                    <h3 *ngIf="error">
                        <p style="color:red" *ngFor="let err of error.error.message">{{err}}</p>
                        <p style="color:red">{{error.error.message}}</p>
                    </h3>

                </div>
            </div>


            <div style="display: flex;
        justify-content: flex-end;;margin-top:15px">
                <button *ngIf="error" class="cta-button grey" mat-button matStepperPrevious>{{'Back' | translate}}</button>
                <button [disabled]="!detailsForm.valid && this.loadingService.isItLoading() && error" class="cta-button" mat-button [mat-dialog-close]="orga">{{'Start using crewcus' | translate}}</button>
            </div>
        </mat-step>

    </mat-horizontal-stepper>
</form>