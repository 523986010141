import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { APP } from 'src/app/app.config';
import { LocationService } from 'src/app/location.service';
import {
  IOrganisationObserver,
  OrganisationService,
} from 'src/app/organisation.service';
import isEqual from 'lodash.isequal';

@Component({
  selector: 'app-map-upload',
  templateUrl: './map-upload.component.html',
  styleUrls: ['./map-upload.component.scss'],
})
export class MapUploadComponent implements OnInit, IOrganisationObserver {

  @Input() location; 


  afuConfig;
  observerId = 4;
  locations;
  organisation;
  activeLocation;
  showUpload = false;
  isLoading = true;
  now;

  constructor(
    private locationService: LocationService,
    private organisationService: OrganisationService,
    private http: HttpClient,
    private changeDet: ChangeDetectorRef
  ) {}

  onOrganisationChange(organisations: any) {
    // @ts-ignore
    if (
      organisations &&
      organisations.length > 0 &&
      !isEqual(this.organisation, organisations[0])
    ) {
      this.organisation = organisations[0];
      this.isLoading = false;
      if(this.location) {
        this.locations  = [this.location]
        this.activeLocation = this.location;
        return;
      }
      this.locationService.getAllLocations().then((data) => {
        this.locations = data;
        this.now = new Date().getTime();
        if (this.locations.length > 0) {
          this.setActiveLocation(this.locations[0]._id);
        }
      });
    }
  }

  ngOnInit(): void {
    this.organisationService.subscribe(this);
    this.activeLocation = this.location;
    this.setUploadConfig();
  }

  ngOnDestroy(): void {
    this.organisationService.unsubscribe(this);
  }

  setActiveLocation(locationid) {
    this.showUpload = false;
    this.activeLocation = locationid;

 
  }

  setUploadConfig() {
    this.afuConfig = {
      multiple: false,
      formatsAllowed: '.jpg,.png,.jpeg',
      maxSize: '5',
      uploadAPI: {
        url:
          APP.baseurl + '/location/' + this.activeLocation._id + '/mapAsFormData',
        method: 'POST',
        headers: {
          'x-organisation-id': this.organisation._id,
        },

        responseType: 'application/json',
      },
      theme: 'dragNDrop',
      hideResetBtn: true,
      hideSelectBtn: false,
      fileNameIndex: false,
      replaceTexts: {
        selectFileBtn: 'Browse File',
        resetBtn: 'Reset',
        uploadBtn: 'Upload',
        dragNDropBox: 'Drag and Drop Image here or',
        attachPinBtn: 'Attach Files...',
        afterUploadMsg_success: '',
        afterUploadMsg_error: 'Upload Failed !',
        sizeLimit: 'Size Limit',
      },
    };
  }

  UploadDone(event) {
    const data = JSON.parse(event.body);
    this.location =data;
    this.activeLocation = data;
    this.showUpload = false;
    this.locationService.getAllLocations().then(data => {
      this.locationService.setActiveLocations(data);
    })
   


    this.now = new Date().getTime();
  }


  locationTrackBy(index, item) {
    if (!item) {
      return null;
    }
    return item._id;
  }

  showUploadMask(bool) {
    this.showUpload = bool;
  }

  deleteMap() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-organisation-id': this.organisation._id,
      }),
    };

    this.http
      .delete(
        APP.baseurl + '/location/' + this.activeLocation._id + '/map',
        httpOptions
      )
      .toPromise()
      .then((data) => {
        
   this.location = data;
   this.activeLocation = data;

   this.locationService.getAllLocations().then(data => {
    this.locationService.setActiveLocations(data);
  })
 
      this.showUpload = false;
      this.now = new Date().getTime();
      })
      .catch((err) => {
        // todo err
      });
  }
}
