<!-- <app-survey style="z-index:1000">
</app-survey> -->
<app-loading-bar></app-loading-bar>
<div class="flex" style="min-height:94vh;">
    <div class="sidebar">


        <div class="select-wrapper organisation-name hide-mobile">

            <select #t (change)="setLastOrganisation(t.value)">
              <option [selected]=isFirst [value]=" organisation?._id " *ngFor="let organisation of organisations ; first as isFirst ">{{organisation?.organisationName}}</option>
              
            </select>
        </div>

        <span (click)="setActive('dashboard')" [ngClass]="{'active': activeNavigation === 'dashboard'}" class="item"><i class="fas fa-chalkboard"></i><span class="hide-mobile">{{'Dashboard' | translate}}</span></span>

        <span data-intro='Here you can add a new Location' (click)="selectLoc(null)" [ngClass]="{'active': activeNavigation === 'locations'}" class="item"><i class="fas fa-location-arrow"></i><span class="hide-mobile">{{'Locations' | translate}}</span>
        </span>
        <span (click)="selectLoc(location)" [ngClass]="{'active': activeNavigation === 'locations'}" class="item sub hide-mobile" *ngFor="let location of locations">
            <i *ngIf="!location.mapUrl" title="Location Map missing." style="margin-right: 3px;
                    color: #ffb352;" class="fa fas fa-exclamation-triangle"></i>
            {{location.name}}
        </span>
        <span (click)="setActive('organisation')" [ngClass]="{'active': activeNavigation === 'organisation'}" class="item"><i class="fas fa-cog"></i><span class="hide-mobile">{{'Global Settings' | translate}}</span></span>

        <span (click)="setActive('remoteoffice')" [ngClass]="{'active': activeNavigation === 'remoteoffice'}" class="item "><i class="fas fa-globe-europe"></i><span class="hide-mobile">{{'Mobile Office' | translate}} <span class="badge" [ngClass]="{'active': this.organisations[0].mobileOffice, 'inactive' : !this.organisations[0].mobileOffice }" *ngIf="this.organisations && this.organisations[0]">{{ (this.organisations[0].mobileOffice ? 'Active' : 'Inactive') | translate}}</span></span>
        </span>
        <span (click)="setActive('pricing')" [ngClass]="{'active': activeNavigation === 'pricing'}" class="item"><i class="fas fa-money-check"></i><span class="hide-mobile">{{'My Account' | translate}}</span></span>
        <span style="cursor:not-allowed;opacity:0.4" [ngClass]="{'active': activeNavigation === 'analytics'}" class="item hide-mobile"><i class="far fa-chart-bar"></i><span class="hide-mobile">{{'Analytics' | translate}} <span class="badge">{{'Soon' | translate}}</span></span>
        </span>
        <span *ngIf="userService.isSuperAdmin()" style="border-top:1px solid #404040;font-size:12px;margin-top:5px" class="item hide-mobile">{{'Admin Menu' | translate}}</span>
        <span (click)="setActive('superadmin')" *ngIf="userService.isSuperAdmin()" [ngClass]="{'active': activeNavigation === 'superadmin'}" class="item hide-mobile"><i class="fa fa-diamond"></i><span class="hide-mobile">{{'Superadmin' | translate}} Menu </span>
        </span>

        <span (click)="showOnboardinV2()" *ngIf="userService.isSuperAdmin()" class="item hide-mobile"><i class="far fa-plus-square"></i><span class="hide-mobile">{{'Show Onboarding' | translate}} </span>
        </span>

        <section *ngIf="userService.isSuperAdmin() && isNightly()" class="flex column envSelect">
            <label class="example-margin hide-mobile"><i class="fa fa-building-o" aria-hidden="true" style="margin-right:15px"></i>Environment</label>
            <mat-radio-group (change)="envChange($event)" ngDefaultControl [(ngModel)]="environments" class=" hide-mobile flex column rgroup">
                <mat-radio-button class="mb10" style="margin-top:10px" value="localhost">
                    Localhost
                </mat-radio-button>
                <mat-radio-button class="mb10" value="nightly">
                    Nightly
                </mat-radio-button>
                <mat-radio-button disabled class="mb10" value="qa">
                    QA
                </mat-radio-button>
                <mat-radio-button disabled class="mb10" value="prod">
                    Prod
                </mat-radio-button>
            </mat-radio-group>
        </section>





    </div>




    <div class="content-big " id="content-holder " style="border-radius: 0px; overflow: scroll; ">

        <ng-container *ngIf="activeNavigation==='dashboard' ">
            <app-dashboard-overview [organisations]="organisations "></app-dashboard-overview>
        </ng-container>

        <ng-container *ngIf="activeNavigation==='superadmin' ">
            <app-superadmin [organisations]="organisations "></app-superadmin>
        </ng-container>

        <!--
        <ng-container *ngIf="activeNavigation==='map' ">
            <app-table-manager></app-table-manager>
        </ng-container>
        -->

        <ng-container *ngIf="activeNavigation==='locations' ">
            <app-location [selectedLocation]="selectedLocation"></app-location>
        </ng-container>

        <ng-container *ngIf="activeNavigation==='rooms' ">
            <app-room></app-room>
        </ng-container>

        <ng-container *ngIf="activeNavigation==='organisation' ">
            <app-organisation [organisations]="organisations "></app-organisation>
        </ng-container>


        <ng-container *ngIf="activeNavigation==='items' ">
            <app-items [organisations]="organisations "></app-items>
        </ng-container>

        <ng-container *ngIf="activeNavigation==='pricing' ">
            <app-pricing-plans></app-pricing-plans>
        </ng-container>


        <ng-container *ngIf="activeNavigation==='remoteoffice'">
            <app-remote-office-admin [organisations]="organisations "></app-remote-office-admin>
        </ng-container>



    </div>

</div>


<div class="print-small ">

    <div class="flex " style="place-content: center;flex-wrap: wrap; ">
        <div *ngFor="let item of items " style="flex: 0 0 33%;position: relative; ">

            <p style="text-align: center; font-size: 10px;position: relative; top: 20px; ">{{item.name}}</p>
            <div>
                <img style="width: 150px;display: block; margin-left: auto; margin-right: auto " [src]="getSmallImgUrl(item) ">
            </div>
            <p style="text-align: center; font-size: 10px; color: #ef575f; position: relative; top: -22px; ">Powered by Crewcus</p>
        </div>
    </div>
</div>

<!-- <ng-container *ngIf="organisations?.length===0  || showOnboarding">
     <app-onboarding></app-onboarding> 

</ng-container> -->