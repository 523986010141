import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DashboardStatisticsService } from 'src/app/dashboard-statistics.service';
import { GoogleAnalyticsService } from 'src/app/google-analytics.service';
import { LoadingService } from 'src/app/loading.service';
import {
  IOrganisationObserver,
  OrganisationService,
} from 'src/app/organisation.service';
import { UserService } from 'src/app/user.service';
import { WhereismyteamService } from 'src/app/whereismyteam.service';

@Component({
  selector: 'app-dashboard-overview',
  templateUrl: './dashboard-overview.component.html',
  styleUrls: ['./dashboard-overview.component.scss'],
})
export class DashboardOverviewComponent
  implements OnInit, IOrganisationObserver
{
  activeOrganisation;
  statistics;
  shareUrl;
  teamdata;
  workload;
  nextDays = [];
  nextDaysWorkLoad = [];
  nextDaysWorkLoadLoading = true;
  observerId = 2;
  foreCastData = [];
  dayStrings;
  chartIntervals = [
    { name: this.translateService.instant('5 Days Forecast'), value: 5 },
    { name: this.translateService.instant('14 Days Forecast'), value: 14 },
    { name: this.translateService.instant('Last 7 Days'), value: -7 },
    { name: this.translateService.instant('Last 14 Days'), value: -14 },
    { name: this.translateService.instant('Last 30 Days'), value: -30},
 
  ];
  donutData;
  chartChoice = 'bar';
  donutStrings = ['Frei', 'Belegt'];
  user;

  constructor(
    private organisationService: OrganisationService,
    private dashboardStatisticsService: DashboardStatisticsService,
    private whereismyteamService: WhereismyteamService,
    public userService: UserService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private loadingService: LoadingService,
    private translateService: TranslateService
  ) {}

  onOrganisationChange(data: any) {
    if (data && data?.length > 0) {
      if (this.activeOrganisation !== data[0] || !this.activeOrganisation) {
        this.activeOrganisation = data[0];

        this.updateData();
      }
    } else {
      this.nextDays = [];
      this.nextDaysWorkLoad = [];
      this.activeOrganisation = null;
    }
  }

  ngOnInit(): void {
    this.loadingService.startLoading();

    this.googleAnalyticsService.send('Admin Dashboard');
    this.organisationService.subscribe(this);

    this.user = this.userService.getUser();
  }

  updateData() {
    this.getStatistics();
    this.getShareUrl();
    this.getWhereIsMyTeam();
    this.getWorkLoad(this.getToday());
    this.getForeCast(this.getToday(), 5);
  }

  setChartInterval(val) {
   
    // tslint:disable-next-line: radix
    if(val > 0) {
      this.getForeCast(this.getToday(), parseInt(val));
    } else {

      let d = new Date();
      d.setDate(d.getDate() - Math.abs(val));
      const year = d.getFullYear().toString();
      const month =
        d.getMonth() + 1 < 10
          ? '0' + (d.getMonth() + 1)
          : d.getMonth() + 1;
      const day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();
      const datestring = year + '-' + month + '-' + day;
      console.log("start now", datestring)
      this.getForeCast( datestring,Math.abs(val),true);
    }
    
  }

  ngOnDestroy(): void {
    this.organisationService.unsubscribe(this);
  }

  getForeCast(start, daysToAdd,skipAdd?) {
    let endDate;
    if(skipAdd) {
       endDate = this.addDays(start,daysToAdd);
       const year = endDate.getFullYear().toString();
       const month =
       endDate.getMonth() + 1 < 10
           ? '0' + (endDate.getMonth() + 1)
           : endDate.getMonth() + 1;
       const day = endDate.getDate() < 10 ? '0' + endDate.getDate() : endDate.getDate();
       const datestring = year + '-' + month + '-' + day;
       endDate = datestring;
    }else {
       endDate = this.getTodaysDate(this.addDays(start, daysToAdd));
    }
   

    this.dashboardStatisticsService
      .getForcast(this.activeOrganisation._id, start, endDate)
      .then((data) => {
        this.parseForeCast(start, endDate, data, daysToAdd);
      })
      .catch((err) => {
        // todo
      });
  }

  parseForeCast(start, end, data, daysToAdd) {
    this.foreCastData = [];
    this.foreCastData.length = daysToAdd + 1;
    this.foreCastData.fill(0);

    const weekdays = [];
    let i = 0;
    for (
      let day = new Date(start);
      day <= new Date(end);
      day.setDate(day.getDate() + 1)
    ) {
      weekdays[i] = JSON.parse(JSON.stringify(day));
      data.forEach((element) => {
        if (day.toISOString() === element.date) {
          // got data for this date

          const totalWorkload = (
            (element.totalUsedSlotsForFullOrga /
              element.totalAvailableSlotsForFullOrga) *
            100
          ).toPrecision(2);

          this.foreCastData[i] = totalWorkload;
        }
      });
      i++;
    }

    this.dayStrings = [];

    weekdays.forEach((weekday) => {
      this.dayStrings.push(this.getWeekDayBasedOnDateString(weekday));
    });

    this.nextDaysWorkLoadLoading = false;
  }

  addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  getStatistics() {
    this.dashboardStatisticsService
      .getStatistics(this.activeOrganisation._id)
      .then((data) => {
        this.statistics = data;
      })
      .catch((err) => {
        // todo err
      });
  }

  getShareUrl() {
    this.shareUrl =
      window.location.origin + '/o/' + this.activeOrganisation.slug;
  }

  getWhereIsMyTeam() {
    const time = new Date().getHours();

    this.whereismyteamService
      .getTeam(this.activeOrganisation._id, this.getTodaysDate(), time * 2)
      .then((data) => {
        this.teamdata = data;
      });
  }

  getTodaysDate(customDate?) {
    const date = customDate ? customDate : new Date();

    const year = date.getFullYear().toString();
    const month =
      date.getMonth() + 1 < 10
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1;
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const datestring = year + '-' + month + '-' + day;

    return datestring;
  }

  getCurrentSlot(slot) {
    let time = Math.round(slot) / 2;

    let min = '00';

    if (time % 0.5 === 0) {
      min = '30';
    }

    return (
      (time < 10 ? '0' + time : time) +
      ':' +
      min +
      (this.getLang() === 'en'
        ? time >= 12
          ? ' PM'
          : ' AM'
        : ' ' + this.translateService.instant('Uhr'))
    );
  }

  getLang() {
    // @ts-ignore
    if (window.Intl && typeof window.Intl === 'object') {
      // @ts-ignore
      let lang = Intl.getCanonicalLocales(window.navigator.language)[0];

      if (lang.indexOf('-') > 0) {
        lang = lang.split('-', 1)[0];
      }

    
      //if (lang === 'de') {
      return lang;
      /*} else {
                return 'en';
            }*/
    }
    return 'en';
  }

  getWorkLoad(date) {
    this.dashboardStatisticsService
      .getWorkLoad(this.activeOrganisation._id, date)
      .then((data) => {
        this.workload = data;

        const slotsBooked = this.workload.totalSlotsBooked
          ? this.workload.totalSlotsBooked
          : 0;

        this.donutData = [
          (100 - this.workload.totalWorkload * 100).toFixed(0),
          (this.workload.totalWorkload * 100).toFixed(0),
        ];

        this.loadingService.stopLoading();
      })
      .catch((err) => {
        // todo err
        this.loadingService.stopLoading();
      });
  }

  getToday() {
    const date = new Date();

    const year = date.getFullYear().toString();
    const month =
      date.getMonth() + 1 < 10
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1;
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const datestring = year + '-' + month + '-' + day;

    // this.getNextSixDays(datestring);
    // this.nextDays.push(datestring);
    return datestring;
  }

  getNextSixDays(datestring) {
    let activedate = datestring;
    this.nextDays = [];

    new Promise<void>((resolve) => {
      for (let index = 0; index < 5; index++) {
        const date = new Date(activedate);

        date.setTime(date.getTime() + 1000 * 60 * 60 * 24);

        const year = date.getFullYear().toString();
        const month =
          date.getMonth() + 1 < 10
            ? '0' + (date.getMonth() + 1)
            : date.getMonth() + 1;
        const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        const datestring = year + '-' + month + '-' + day;

        this.nextDays.push(datestring);
        activedate = datestring;
        resolve();
      }
    }).then(() => {
      this.nextDaysWorkLoad = [];
      this.nextDays.forEach((element) => {
        this.dashboardStatisticsService
          .getWorkLoad(this.activeOrganisation._id, element)
          .then((data) => {
            const result = {
              date: element,
              data,
            };
            this.addToWorkLoadAndSort(result);
            this.nextDaysWorkLoadLoading = false;
          })
          .catch((err) => {
            // todo err
          });
      });
    });
  }

  async addToWorkLoadAndSort(result) {
    if (this.nextDaysWorkLoad.indexOf(result) === -1) {
      this.nextDaysWorkLoad.push(result);
    }

    this.nextDaysWorkLoad.sort((a, b) => {
      if (a.date < b.date) {
        return -1;
      }
      if (a.date > b.date) {
        return 1;
      }
      return 0;
    });
  }

  round(num) {
    return Math.round(num);
  }

  getWeekDayBasedOnDateString(date) {
    const d = new Date(date);
    const dayName = d.toString().split(' ')[0];

    return this.translateService.instant(dayName);
  }

  returnHeightForPill(totalWorkload) {
    return totalWorkload * 100 + 'px';
  }
}
