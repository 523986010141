import { LocationStrategy } from '@angular/common';
import { ElementRef } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
  ValidationErrors,
  AsyncValidatorFn,
} from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Observable } from 'rxjs';
import { BookableService } from 'src/app/bookable.service';
import { LoadingService } from 'src/app/loading.service';
import { LocationService } from 'src/app/location.service';
import { OrganisationService } from 'src/app/organisation.service';
import { RoomService } from 'src/app/room.service';

import { map, switchMap } from 'rxjs/operators';
import { timer } from 'rxjs';
export function isNameAvailable(
  organisationService: OrganisationService
): AsyncValidatorFn {
  ///organisation/isOrganameInUse/ganz grobe orga geht ja

  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    if (control.value.length > 3) {
      return timer(200).pipe(
        switchMap(() => {
          return organisationService
            .isNameAvailable(control.value)
            .pipe(
              map((result: boolean) => (!result ? null : { invalid: true }))
            );
        })
      );

      // const obs = organisationService
      //   .isNameAvailable(control.value)
      //   .pipe(map((result: boolean) => (!result ? null : { invalid: true })))
      //   ;

      // return obs;
    }
  };
}

@Component({
  selector: 'app-onboarding-v2',
  templateUrl: './onboarding-v2.component.html',
  styleUrls: ['./onboarding-v2.component.scss'],
})
export class OnboardingV2Component implements OnInit {
  detailsForm = new FormGroup({
    organisationName: new FormControl('', {
      validators: [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(40),
      ],
      asyncValidators: [isNameAvailable(this.organisationService)],
      updateOn: 'change',
    }),
    locationName: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(40),
    ]),
    roomName: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(40),
    ]),
    bookableName: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(40),
    ]),
  });

  @ViewChild('input1') input1: ElementRef;
  @ViewChild('input2') input2: ElementRef;
  @ViewChild('input3') input3: ElementRef;
  @ViewChild('input4') input4: ElementRef;

  orga;
  location;
  room;

  @ViewChild('stepper') stepper: MatStepper;

  error;

  constructor(
    private bookableService: BookableService,
    private organisationService: OrganisationService,
    private locationService: LocationService,
    public loadingService: LoadingService,
    private roomService: RoomService,
    private locationStrategy: LocationStrategy
  ) {}

  ngOnInit(): void {
    this.preventBackButton();
  }

  preventBackButton() {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    });
  }

  checkStep() {
    if (
      this.stepper.selectedIndex === 2 &&
      !this.detailsForm.get('organisationName').valid
    ) {
      this.stepper.previous();
    } else if (
      this.stepper.selectedIndex === 3 &&
      !this.detailsForm.get('locationName').valid
    ) {
      this.stepper.previous();
    } else if (
      this.stepper.selectedIndex === 4 &&
      !this.detailsForm.get('roomName').valid
    ) {
      this.stepper.previous();
    } else if (
      this.stepper.selectedIndex === 5 &&
      !this.detailsForm.get('bookableName').valid
    ) {
      this.stepper.previous();
    }
  }

  focusInput() {
    if (this.stepper.selectedIndex === 1) {
      this.input1.nativeElement.focus();
    } else if (this.stepper.selectedIndex === 2) {
      this.input2.nativeElement.focus();
    } else if (this.stepper.selectedIndex === 3) {
      this.input3.nativeElement.focus();
    } else if (this.stepper.selectedIndex === 4) {
      this.input4.nativeElement.focus();
    }
  }

  async changeStep(event) {
    if (event.selectedIndex === 5) {
      this.loadingService.startLoading();

      //everything done, create everything now

      try {
        this.orga = await this.organisationService.createOrganisation(
          this.detailsForm.value.organisationName
        );

        this.location = await this.locationService.createLocation(
          this.detailsForm.value.locationName,
          this.orga
        );

        this.room = await this.roomService.createRoom(
          this.location._id,
          this.detailsForm.value.roomName,
          this.orga
        );

        await this.bookableService.createBookable(
          this.location._id,
          this.room.rooms[0]._id,
          this.detailsForm.value.bookableName,
          this.orga
        );

        //this.organisationService.setActiveOrganisation([this.orga]);
        this.loadingService.stopLoading();
      } catch (e) {
        this.error = e;
      }
    }
  }
}
