import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { LanguageService } from 'src/app/language.service';
import {
  IOrganisationObserver,
  OrganisationService,
} from 'src/app/organisation.service';
import { IUserObserver, UserService } from 'src/app/user.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent
  implements OnInit, IUserObserver, IOrganisationObserver
{
  constructor(
    private userService: UserService,
    private organisationService: OrganisationService,
    public router: Router,
    private cookiePolicyService: NgcCookieConsentService,
    private languageService: LanguageService
  ) {}

  onOrganisationChange(organisations: any) {
    if (organisations?.length > 0) {
      this.orgaSlug = organisations[0].slug;
      this.plan = organisations[0].plan;
      this.planExpire = organisations[0].planExpireDate;
    } else {
      this.orgaSlug = null;
    }
  }
  observerId: number = 3;

  user;
  firstRun = true;
  orgaSlug;
  lastLang;
  planExpire;
  plan;
  ngOnInit(): void {
    this.organisationService.subscribe(this);
    this.userService.subscribe(this);
    this.lastLang = this.languageService.getLang();
  }

  days_between(date2: string) {
    // The number of milliseconds in one day
    const ONE_DAY = 1000 * 60 * 60 * 24;
    const end = new Date(date2);

    // Calculate the difference in milliseconds
    const differenceMs = end.getTime() - new Date().getTime();

    // Convert back to days and return
    return Math.round(differenceMs / ONE_DAY) > 0
      ? Math.round(differenceMs / ONE_DAY)
      : -1;
  }

  getPlan() {
    if (this.plan) {
      if (this.plan === 'freePlan') {
        return 'Free';
      } else if (this.plan === 'pro-plan' || this.plan === 'proPlan') {
        return 'Pro';
      } else {
        return 'Enterprise';
      }
    } else {
      return 'Free';
    }
  }

  getPlanRemaining() {
    return this.planExpire;
  }

  ngOnDestroy(): void {
    this.organisationService.unsubscribe(this);
    this.userService.unsubscribe(this);
  }

  logout() {
    this.userService.logout();
  }

  getOrganisationSlug() {
    let self = this;
    if (this.user.token) {
      this.organisationService
        .getOrganisation()
        .then((data) => {
          if (localStorage.getItem('lastOrga')) {
            const orgaid = JSON.parse(localStorage.getItem('lastOrga'));

            let allOrgas = data;
            //@ts-ignore
            allOrgas.sort((a, b) =>
              a.organisationName.localeCompare(b.organisationName)
            );

            let result;
            let rIndex;
            //@ts-ignore
            allOrgas.forEach((orga, index) => {
              if (orga._id === orgaid) {
                rIndex = index;
                result = orga;
                return;
              }
            });

            //@ts-ignore
            allOrgas.splice(rIndex, 1);
            //@ts-ignore
            allOrgas.unshift(result);

            self.orgaSlug = allOrgas[0].slug;

            this.organisationService.setActiveOrganisation(allOrgas);
            return;
          }
          //@ts-ignore
          if (data.length > 0) {
            self.orgaSlug = data[0].slug;

            this.organisationService.setActiveOrganisation(data);
          } else {
            this.organisationService.setActiveOrganisation([]);
          }
        })
        .catch((err) => {
          if (err.status === 401) {

            if (this.router.url.indexOf('/o/') > -1) {
              
            } else {
              this.userService.logout(true);
            }
            
           
          }
        });
    }
  }

  onUserChange(user: any) {
    this.user = user;

    if (this.firstRun && !this.orgaSlug && this.user?.token) {
      this.firstRun = false;
      this.getOrganisationSlug();
    }
  }

  showSync() {
    this.router.navigate(['/sync-devices']);
  }

  showCookiePolicy() {
    this.cookiePolicyService.fadeIn();
  }

  switchLang(lang) {
    this.languageService.setLang(lang);
    this.lastLang = lang;
  }

  termsofuse() {
    if (this.lastLang === 'en') {
      window.open('https://crewcus.com/en/nutzungsbedingungen', '_blank');
    } else {
      window.open('https://crewcus.com/nutzungsbedingungen', '_blank');
    }
  }

  impress() {
    if (this.lastLang === 'en') {
      window.open('https://crewcus.com/en/impressum', '_blank');
    } else {
      window.open('https://crewcus.com/impressum', '_blank');
    }
  }

  privacypolicy() {
    if (this.lastLang === 'en') {
      window.open('https://crewcus.com/en/datenschutz', '_blank');
    } else {
      window.open('https://crewcus.com/datenschutz', '_blank');
    }
  }

  goToPricing() {
    localStorage.setItem('lastNav', JSON.stringify('pricing'));
    window.location.href =
      window.location.protocol +
      '//' +
      window.location.host +
      '/administration';
  }
}
