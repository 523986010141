<div class="hero-bg"></div>
<div class="content">
    <h1>Privacy Policy</h1>
    <p style="font-weight: bold;">REHAU's Commitment to Data Privacy Protection</p>

    <p>We want you to feel comfortable when you contact REHAU. For this reason the security of your personal data such as your name, address, telephone number or e-mail address which is created when you contact REHAU is important to us.</p>

    <p>This privacy statement is intended for anyone with which REHAU interacts including customers and prospects, visitors of our Web sites, users of our apps/applications, other users of our products or services and visitors to our sites. It contains the
        information described in Art. 13 and 14 GDPR.</p>

    <p style="font-weight: bold;">1. Terms</p>

    <p>The processing of personal date takes place within the guidelines of legal regulations.</p>
    <p>The Term "personal data" refers to all information relating to an identified or identifiable natural person. "Processing" means any operation or set of operation which is performed on personal date whether or not by automated means such as collection,
        recording, organisation, arrangement, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or association, qualification, erasure or destruction.</p>
    <p>Further data protection terms are used in accordance with the definitions in Art. 4 GDPR.</p>

    <p style="font-weight: bold;">2. Name and address of the controller</p>

    <p>Main establishment within the meaning of Art. 4 No. 16 a GDPR, Art 56 GDPR, in which decisions regarding the purposes and means of processing are made in a leading manner:</p>
    <p>REHAU AG + Co</p>
    <p>Rheniumhaus</p>
    <p>Otto-Hahn-Str.</p>
    <p>295111 Rehau</p>
    <p>Germany</p>

    <p style="font-weight: bold;">3. General processing purposes and legal bases</p>

    <p>REHAU collects and processes your personal date in particular in the following cases:</p>

    <p>If you contact us directly e.g. via our website, via REHAU customer service or if you visit us at our locations, participate in our events, customer satisfaction surveys and competitions and are interested in our products or services or have any other
        concerns.
    </p>
    <p>If you or your employer buy products or services directly from us.</p>
    <p>If you or your employer request information about our products and services (e.g. sending brochures or price lists).</p>
    <p>If you buy or use REHAU products.</p>
    <p>If you or your employer offer or sell us products or services.</p>
    <p>Please help us keep your information current by informing us of changes to your personal data, in particular your contact details.</p>
    <p>Insofar as REHAU processes personal data, this applies in particular to your name and your official contact data such as company, function, telephone number or e-mail address as well as contract and transaction date. Additional data categories may
        be collected for special purposes described in detail below.</p>
    <p>Besides the collection of your data from our contact forms on our website, we also collect the data directly from you or from generally accessible sources (e.g. commercial register, authorities and internet) to the necessary extent for the purpose.</p>

    <p style="font-weight: bold;">4. Individual processing purposes and legal bases</p>

    <p>In the following we inform you for which purposes REHAU processes which personal date. In the case of nonrecurring circumstances, REHAU will generally draw your attention to this information separately in the specific processing situation and, if
        necessary, provide additional information.</p>

    <p style="font-weight: bold;">4.1. Processing of data for the preparation, conclusion and performance of contracts</p>

    <p>As a manufacturing company, REHAU processes personal data within the scope of acquisition (also under 5.7.) and sales processes as well as for the execution of contracts.</p>
    <p>For this purpose, data is processed in particular for contract initiation, quotation processing, customer consulting procurement, production and delivery of goods, contract management and complaint processing. </p>
    <p>Within the context of these activities, the following data categories are processed in particular:</p>

    <p>Contact data/ personnel master data (surname first name, address, e-mail address etc.)</p>
    <p>Logistics data such as delivery address</p>
    <p>Contract data, payment data</p>
    <p>It may be necessary for us to pass on the data for the preparation of an offer and the execution of the contract to third parties who are integrated into the supply chain or otherwise required for the execution of the contract.</p>
    <p>For the purpose of credit checks of our business partners, we process data which we receive under the legal requirements of credit agencies (such as Schufa).</p>
    <p>If data is collected directly for the processing of an inquiry or the execution of a contract, REHAU will inform you which data is absolutely necessary. If it is not you yourself but your employer or another third party which is contractually connected
        with you or your employer which is the contracting party, the data will be processed on the basis of Art. 6 I f GDPR. The data will be processed insofar as the data is required for the execution of the contract or for the fulfilment of further
        legal e.g. product liability obligations and duties (e.g. duties to inform, clarify and warn).</p>
    <p>If processing is carried out on the basis of Art. 6 I f GDPR, you can always object the processing on grounds relating to your particular situation.</p>

    <p style="font-weight: bold;">4.2. Use of customer portal</p>

    <p>REHAU offers its customers the use of a customer portal to simplify the processing of orders for access to certain product configurators and other services. With the registration you will be informed which data is necessary for the use of the customer
        portal. Purpose and legal basis are described in section 5.1. Furthermore optional data will be used to contact the customer (according to section 5.8).</p>
    <p>When using the customer portal, the individual transactions are stored and archived in accordance with the legal storage regulations based on Art. 6 I c GDPR.</p>
    <p>You can terminate the use of the customer portal and the storage of your personal data in the customer portal at any time as long as they are not necessary for the fulfilment of a contract. Please contact the sales office responsible for you (link:
        “contact us” in the customer portal). The storage of the transactions remains unaffected.</p>

    <p style="font-weight: bold;">4.3. Application process (Art. 6 I b GDPR)</p>

    <p>For the application procedure there are additional data protection notices which will be communicated separately within the application. The following information also applies to all applications.</p>

    <p style="font-weight: bold;">4.4. Participation in events and on-site visits (Art. 6 I f GDPR)</p>

    <p>We are looking forward to meeting you personally during a visit on site or at one of our events. We process your personal data in order to organise and carry out events and to provide you with information material. In the case of events, we may forward
        the data to the speakers and participants. This is important to ensure the smooth running of the event. We also use the data collected in this process to evaluate and post-process our events. REHAU also uses the survey monkey functions to gain
        feedback. If you participate in a corresponding survey, the data protection information of survey monkey (https://www.surveymonkey.de/mp/legal/privacy-policy/) applies. For the documentation of the events as well as the press and public relations
        work picture and video recordings of the events can be provided. Personal (image) data will also be processed. You may object to this processing at any time for reasons arising from your particular situation. In this case we ask you to inform
        the photographers or organisers of these reasons in advance. They will take your objection into account and take the necessary measures.</p>

    <p style="font-weight: bold;">4.5. Use of services (consulting)</p>

    <p>In addition to our products and system solutions, REHAU offers various services. This also includes consulting and services which we offer by telephone, e-mail, contact form and possibly also on site. With this service we would like to support you
        in the selection of the REHAU systems and their application. Within the context of this service, the necessary data that you provide in connection with the service request, such as contact data / personal master data (surname, first name, address,
        e-mail address, etc.) may be stored together with the products concerned and the problem. In this way, we want to ensure that we can provide you with targeted further advice in the event of any queries at a later date on the basis of the previous
        service history. The basis of this storage is the justified interest in an effective and consistent consulting activity, even over time. Your data will only be stored in direct connection with the service case. As far as the service data are relevant
        for the defence against claims of liability, especially product liability, REHAU will also process them for this purpose. The data will be deleted if they are no longer required for this purpose, at the very latest after the end of the limitation
        periods existing for this liability. If the data is only processed on the basis of Art. 6 I f GDPR, you have the right to object the processing on grounds relating to your particular situation.</p>

    <p style="font-weight: bold;">4.6. Access to the company premises for the delivery of goods or performance of a service or work performance</p>

    <p>In this case, we collect not only your data which are directly necessary for the execution of a contractual relationship, such as name, first name, company, invoice data, vehicle identification data, but also the duration of your stay at REHAU on
        the basis of Art. 6 I f GDPR. In the event of an emergency and a necessary evacuation, the aim is to have knowledge of the persons staying in the building or on the premises. If you are on business, the duration of your stay can also be used to
        check and optimise internal processes and to check the conclusiveness of performance data (e.g. invoices). When goods are delivered, cameras are also used to monitor logistical processes at various locations. On the basis of legal requirements
        or Art. 6 I f GDPR to prove the fulfilment of road safety obligations, the safety instructions given to the visitor are also documented. Of course you can disagree with the uses on the basis of Art. 6 I f GDPR at any time on grounds relating to
        your particular situation. Video surveillance at our locations only takes place openly. Appropriate signs will point this out to you. This video surveillance serves to secure our production and our data processing systems. This ensures even greater
        protection of personal data.</p>

    <p style="font-weight: bold;">4.7. Corporate communication and external image (Art. 6 I f GDPR)</p>

    <p>As part of our participation in events, the visit to our trade fair presence and other events, images and video recordings of these events are made to document the event for press and public relations work and corporate communications. Personal (image)
        data is also processed in this process. The publication of the image material takes place both electronically in social media such as Facebook and in print media. Legal basis for this processing is Art. 6 I f GDPR for corporate communication as
        well as § 23 KunstUrhG as applicable. As far as factually possible and legally reasonable, reference will be made again to the photographs at each individual event. If the processing of the pictures takes place on the basis of Art. 6 I f GDPR,
        you can object to this use at any time on grounds relating to your particular situation. You can exercise this right by informing the photographer of these reasons in advance and he will take this into account in his work. We will be happy to
        provide you with details of the event in question.</p>

    <p style="font-weight: bold;">4.8. Compliance, law enforcement and crime prevention (Art. 6 I f GDPR)</p>

    <p>To the extent required by law, REHAU uses personal data to assert legal claims and to defend legal disputes. Within the context of the company's compliance requirements, the data may also be used to prevent, clarify or prevent criminal offences. In
        addition to the previously mentioned data categories insofar as they are required for the purpose, creditworthiness data, visit data, account data as well as correspondence, purchasing and sales data are also used for this purpose. REHAU also
        uses an internal whistle-blower system for name and anonymous reports of compliance violations. This data will be deleted or made completely anonymous in accordance with the applicable legislation or immediately after the respective case has been
        concluded. Security is generally also served by systems for building and plant security as well as for securing our data processing systems, such as access controls or video surveillance. The mentioned controls only take place openly at our sites.
        You can find out more about them in detail on site. We process your personal data further in connection with the usual checks of business partners within the framework of compliance requirements. Insofar as we have not requested your personal
        data directly from you, we have collected them from publicly accessible sources and databases as part of the due diligence. The data collected from these sources are processed exclusively for this purpose and deleted as soon as they are no longer
        required for this purpose. The processing is based on Art. 6 I c GDPR insofar as the due diligence is based on legal requirements or on Art. 6 I f GDPR the legitimate interest of the company in the evaluation of its business partners for the reduction
        of risks. If the processing is based on Art. 6 I f GDPR, you can object to this on grounds relating to your particular situation.
    </p>
    <p style="font-weight: bold;">4.9. Advertising communication and market research (Art. 6 I f GDPR)</p>

    <p>As far as legally permissible on the basis of Art. 6 I f GDPR or if you give us your consent (Art. 6 I a GDPR), we process your data in particular for advertising communication, customer satisfaction surveys, advertising campaigns and for the implementation
        of sweepstakes. In this way, we can further improve our range of products and services and act in a more targeted manner. Within the context of these activities, the necessary data such as contact data / personal master data (surname, first name,
        address, e-mail address, etc.) can be processed. Only as far as a permission is given further data which you provide to us for this purpose will be processed, e.g. interests, personal preferences, professional situation. In the case of an existing
        customer relationship or if you have consented to this, you will generally receive the above information by electronic mail. For business partners who are not consumers, the information is provided by telephone or analogue. For the purposes mentioned
        it may be necessary for us to pass on your personal data to companies of the REHAU Group in order to better respond to your wishes or to continuously improve our products and services. In each case you can disagree with the use of your data for
        purposes of direct advertising at any time according to Art. 21 Para. 2 GDPR or revoke a corresponding consent at any time according to Art. 7 Para. 3 GDPR with effect for the future. For the further development of our products, services and business
        processes as well as for market research, we analyse the data available to us on business transactions, contracts and enquiries. It is in no way possible to draw conclusions about individual natural persons. Of course we respect it if you do not
        want us to use your personal data to support our customer relationship - in particular for direct marketing or market research.
    </p>
    <p style="font-weight: bold;">4.10. Compliance with legal obligations (Art. 6 I c GDPR)</p>

    <p>REHAU is subject to a number of legal obligations regarding the processing and storage of personal data. These relate, for example, to commercial and tax retention regulations in accordance with the commercial code and the tax code. In order to meet
        these obligations, we process your data to the required extent and, if necessary, pass them on to the responsible authorities within the framework of statutory reporting obligations.
    </p>
    <p style="font-weight: bold;">4.11. Further processing purposes</p>

    <p>Data processing also takes place within the context of quality management to determine and improve customer satisfaction, to further develop products and services, to carry out research and development and to improve IT security and IT operations.
        The last point also includes processing to identify and prevent unauthorised access to personal data. Legal basis for the processing of these data is art. 6 I f GDPR. Insofar as no consent is given, no conclusions can be drawn about individual
        natural people. In individual cases this processing can be objected on grounds relating to your particular situation.
    </p>
    <p style="font-weight: bold;">4.12. Transfer of data to third parties</p>

    <p>For the aforementioned purposes, the data may be disclosed to third parties who assist the controller in the pursuit of the mentioned purposes. The passing on takes place in the context of an order processing within the sense of Art. 28 GDPR, a joint
        responsibility according to Art. 26 GDPR or as data transfer in the context of the order of professional services. For data transfer to recipients in third countries (see point 7 below).
    </p>
    <p style="font-weight: bold;">5. Duration of storage</p>

    <p>We store your personal data only as long as it is necessary for the purposes for which it was collected or as long as storage is required by law or within the framework of official regulations. We delete or block your data as soon as they are no longer
        needed. Furthermore, we will delete or block your data immediately in the event of a revocation of your consent and in the event of a justified objection to the processing.
    </p>
    <p style="font-weight: bold;">6. Planned transfer of data to third countries</p>

    <p>We may share your personal information with other REHAU companies for the purposes described in this privacy statement. The other companies may use your personal data in their own interest for the same purposes as we do. In particular, they may process
        your personal data for the above-mentioned purposes in their own interest. Within the REHAU Group, employees only have access to your personal data insofar as this is necessary for the performance of their duties. Data is transferred to branches
        outside the European Economic Area either on the basis of an adequacy decision of the Commission (Art. 45 Para. 3 GDPR) or on the basis of standard data protection clauses (Art. 46 II c GDPR) which contain appropriate guarantees for the data subject.
        The text of the standard data protection clauses is published at https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_en We may also disclose your personal information
        to third parties outside the REHAU companies to use technical or organisational services that we need to fulfil for the purposes mentioned above or for our other business activities. Our service providers are contractually obliged to process personal
        data exclusively on our behalf and according to our instructions. We also oblige our service providers to comply with technical and organisational measures that ensure the protection of personal data. If the service providers are located in countries
        where the applicable laws do not provide for the protection of personal data comparable to European law, we will contractually ensure that the relevant service providers comply with the statutory level of data protection (standard data protection
        clauses or EU-US Privacy Shield Framework). For more information, please contact our data protection officer.
    </p>
    <p style="font-weight: bold;">7. Online data usage/ visit of our websites</p>

    <p>In the following you will learn which information we may collect when you visit our websites and how we deal with it. Websites made by REHAU AG + Co. may contain links to websites of other providers which are not covered by this data protection declaration.
        When you visit our website, we store information about the browser and operating system you are using, the date and time of your visit and the IP address. However, this data cannot be assigned to you and is not personal. We do not collect any
        personal data via our websites without your knowledge. You alone decide whether or not you wish to disclose such data to us in the context of a registration, order or survey. We use this data to ensure a smooth connection and the comfortable use
        of our website. In addition, we use the following technologies on our website:
    </p>
    <p style="font-weight: bold;">7.1. Google Analytics - web analytics service</p>

    <p>This website uses Google Analytics, a web analytics service provided by Google, Inc. Google Analytics uses cookies which are text files placed on your computer to help the website analyse how users use the site. The information generated by the cookie
        about your use of the website will generally be transmitted to and stored by Google on servers in the United States. However, if IP anonymization is activated on this website, your IP address will be shortened by Google in advance within member
        states of the European Union or in other signatory states to the Agreement on the European Economic Area. Only in exceptional cases will the full IP address be transmitted to a Google server in the USA where it will be shortened. On behalf of
        the operator of this website, Google will use this information to evaluate your use of the website, compile reports on website activity and provide other services relating to website activity and internet usage to the website operator. The IP
        address transmitted by your browser as part of Google Analytics is not combined with other data from Google. You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may
        not be able to use the full functionality of this website. In addition, you can prevent Google from collecting the data generated by the cookie and related to your use of the website (including your IP address) and Google from processing this
        data by downloading and installing the browser plug-in available under the link http://tools.google.com/dlpage/gaoptout?hl=en.You can prevent Google Analytics from collecting data by clicking on the following link: You can prevent Google Analytics
        from collecting data by clicking on the following link. An opt-out cookie is set to prevent your data from being collected in the future when you visit this website: Deactivate Google Analytics An opt-out cookie is set to prevent your data from
        being collected in the future when you visit this website. Further information on the terms of use and data protection can be found at https://www.google.com/analytics/terms/gb.html and https://www.google.com/intl/en/policies/. We would like to
        point out that on our website Google Analytics has been extended by the code "gat._anonymizeIp();" in order to guarantee an anonymous recording of IP addresses (so-called IP masking).
    </p>
    <p style="font-weight: bold;">7.2. Google Tag Manager</p>

    <p>This website uses Google Tag Manager. The Tag Manager does not collect personally identifiable information. The tool triggers other tags that may themselves collect data. Google Tag Manager does not access this data. If deactivation is made at the
        domain or cookie level, it will persist for all tracking tags implemented with Google Tag Manager. Google's privacy policy for this tool can be found here: https://www.google.com/analytics/tag-manager/use-policy/
    </p>
    <p style="font-weight: bold;">7.3. Google Ads Re-marketing</p>

    <p>Our website uses the functions of Google Ads Re-marketing, hereby we advertise this website in the Google search results, as well as on third party websites. Provider is Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland ("Google").
        To this end, Google places a cookie in the browser of your terminal device, which automatically uses a pseudonymous cookie ID to enable interest-based advertising based on the pages you visit. Processing is based on our legitimate interest in
        the optimal marketing of our website in accordance with Art. 6 Para. 1 lit. f DSGVO. Further data processing will only take place if you have agreed to Google linking your internet and app browser history to your Google account and using information
        from your Google account to personalise advertisements you view on the web. In this case, if you are logged in to Google during your visit to our website, Google will use your data together with Google Analytics data to create and define target
        group lists for cross-device re-marketing. Google will temporarily link your personal data to Google Analytics data in order to create target groups. You can permanently disable the setting of ad preferences cookies by downloading and installing
        the browser plug-in available at the following link: https://www.google.com/settings/ads/onweb/
    </p>
    <p style="font-weight: bold;">7.4. Facebook-Plugin</p>

    <p>We use Facebook Inc.'s "visitor action pixel" on our website. This allows us to track users' behaviour after they click on a Facebook ad to redirect them to the vendor's Web site. This process is used to evaluate the effectiveness of Facebook ads
        for statistical and market research purposes and may help optimise future advertising efforts. The data collected is anonymous to us and therefore does not provide us with any information about the identity of the user. However, the data is stored
        and processed by Facebook so that a connection to the respective user profile is possible and Facebook can use the data for its own advertising purposes in accordance with the Facebook data usage guideline: https://www.facebook.com/about/privacy/.
        The information may enable Facebook and its partners to serve advertisements on and off Facebook. A cookie may also be stored on your computer for these purposes.
    </p>
    <p style="font-weight: bold;">7.5. Facebook Re-marketing</p>

    <p>On our website we use the re-marketing function "Custom Audiences" of Facebook Inc. (1601 S. California Ave, Palo Alto, CA 94304, USA; "Facebook"). This function serves the purpose of targeting the visitors of the website with interest-related advertising
        in the social network Facebook. For this purpose, the re-marketing tag of Facebook was implemented on the website. This tag establishes a direct connection to the Facebook servers when visiting the website. This transmits to the Facebook server
        which of our pages you have visited. Facebook associates this information with your personal Facebook user account. When you visit the social network Facebook, you will be shown personalised, interest-based Facebook ads. Processing is based on
        Art. 6 (1) lit. f DSGVO out of legitimate interest in the above-mentioned purpose.For reasons arising from your particular situation, you have the right at any time to object to this processing of your personal data based on Art. 6 (1) f DSGVO.
        To do this, you can deactivate the re-marketing function "Custom Audiences". Further information on the collection and use of data by Facebook, on your rights in this regard and on ways of protecting your privacy can be found in Facebook's data
        protection information at https://www.facebook.com/about/privacy/.
    </p>
    <p style="font-weight: bold;">7.6. YouTube-Plugin</p>

    <p>We use the plug-ins of YouTube LLC (represented by Google Inc.), for the integration of videos on our websites. If you use our website, a connection to the YouTube servers will be established and the plugin will be displayed. This will transmit to
        the YouTube server which of our pages you have visited. If you are registered as a member of YouTube, YouTube will assign this information to your personal user account. If you use the plugin, this information will also be assigned to your user
        account, for example after clicking the start button of a video. You can prevent this assignment by logging out of your YouTube user account and other YouTube LLC and Google Inc. user accounts before using our websites and by deleting the corresponding
        company cookies. For more information about YouTube (Google) data processing and privacy practices, please visit: https://www.google.de/intl/en-GB/policies/privacy/
    </p>
    <p style="font-weight: bold;">7.7. Microsoft-Plugin</p>

    <p>Our website uses conversion tracking from Microsoft Corporation. Microsoft Bing Ads places a cookie on your computer if you have reached our website via a Microsoft Bing display. Microsoft Bing and we can recognise in this way that someone clicked
        on an advertisement was redirected to our website and reached a previously determined target page (conversion page). We only know the total number of users who clicked on a Bing ad and were redirected to the conversion page. No personal information
        about the user's identity is shared. If you do not wish to participate in the tracking procedure, you can also refuse to set a cookie as required for this - for example via the browser setting which generally deactivates the automatic setting
        of cookies. Further information on data protection and the cookies used by Microsoft Bing can be found on the Microsoft website: https://privacy.microsoft.com/en-us/privacystatement
    </p>
    <p style="font-weight: bold;">7.8. REHAU Account / cidaas</p>

    <p>When using our service "REHAU Account" (e.g. on https://accounts.rehau.com or within mobile apps), we will ask you for a minimal set of personal data which is needed and stored for the service delivery. The underlying service – product name "cidaas"
        – is provided by our data processor Widas ID GmbH, Maybachstraße 2, 71299 Wimsheim, Germany.For further information, please visit: https://www.cidaas.com/privacy-policy/
    </p>
    <p>Automatically collected information that is not personal data When you access our websites, information is occasionally collected automatically (i.e. not via registration) that is not related to a specific person (e.g. type of Internet browser and
        operating system used; domain name of the website from which you came; number of visits, average time spent on the site, pages viewed). REHAU uses this information to analyse the popularity of our websites and to improve their content. When you
        visit one of our websites, we may store information on your computer in the form of a "cookie" that will automatically recognise you the next time you visit. Cookies allow us, for example, to adapt a website to your interests or to save your password
        so that you do not have to re-enter it each time. If you generally refuse the use of cookies, you have the option to set your Internet browser so that it deletes cookies from your computer hard drive, blocks all cookies or warns you before a cookie
        is stored.
    </p>
    <p style="font-weight: bold;">7.9. Cookies</p>

    <p>Cookies are small text files that are usually placed on the PC from a website. Cookies are used for a variety of purposes. However, they are never risky because they lack any "active" ability. They cannot therefore execute any malicious applications.
        They contain almost exclusively information which is necessary for a comfortable Internet use. In addition, we have set ourselves a deadline for deleting cookies. Without your renewed permission these are stored maximally 12 months starting from
        the first collection. To this end, we take technical steps to ensure automatic deletion. Classic examples of cookie tasks: login data, shopping cart storage, user analysis, form fields. Information that can be stored in cookies: Lifetime, server
        name, Unique ID, content data. Use of cookies:
    </p>
    <p>Session cookies Session cookies are only technical cookies that are necessary for the proper functioning of our website. Tracking cookies Tracking cookies are used - of course in a completely anonymous form - to evaluate user behaviour when visiting
        our website. REHAU and, if necessary, the responsible body receive valuable information on how the website is used, which helps REHAU and, if necessary, the responsible body to better focus on the interests of visitors. Commercial cookies In addition
        to our own cookies, we use third-party cookies to display personalised advertising on our and other websites. This process is called "Re-targeting". It is based on your activity on our website. Third-party cookies The plugins used on our website
        also use their own cookies. You can find out about the types and purposes of cookies on the pages provided by the third party providers. 8. Data security
    </p>
    <p>We have technical and organisational security procedures in place to protect the security of your personal data and to protect your personal data against unauthorised or unlawful processing and/or against accidental loss, alteration, disclosure or
        access.
    </p>
    <p style="font-weight: bold;">8. Your rights</p>

    <p>Compliance with data protection regulations is monitored by the following bodies, to which anyone can turn:
    </p>
    <p>Data protection officer of the REHAU AG + Co. (Head office within the meaning of Art. 4 Nr. 16 GDPR):</p>
    <p>Dr. Alexander Walter</p>
    <p>REHAU AG + Co</p>
    <p>Rheniumhaus</p>
    <p>Otto-Hahn-Str. 2</p>
    <p>95111 Rehau</p>
    <p>Germany</p>
    <p>Phone: +49 9283-770</p>
    <p>E-mail: datenschutz@rehau.com</p>

    <p>Lead supervisory authority within the meaning of Art. 56 GDPR: </p>
    <p>Bavarian State Office for Data Protection</p>
    <p>Promenade 27</p>
    <p>91522 Ansbach</p>
    <p>Germany</p>

    <p>You also have the possibility to check REHAU's compliance with data protection regulations yourself. You are entitled to the following rights:
    </p>
    <p>Information Law</p>
    <p>Right to access your processed data</p>
    <p>Right to object</p>
    <p>For all processing operations based on Art. 6 I f GDPR (see above), you can object the processing on grounds relating to your particular situation.</p>
    <p>Right for disagreement against direct advertising</p>
    <p>You have the right to object at any time against the processing of your data for the purpose of direct marketing. This also applies to profiling in connection with such direct marketing.</p>
    <p>Right of correction, deletion and limitation</p>
    <p>Right of data portability</p>
    <p>Right to lodge a complaint with a supervisory authority</p>
    <p>If you have any questions about these rights with regarding the processing of your personal data, you can contact our data protection officer, who is also available in the event of requests for information suggestions or complaints. Upon request,
        REHAU will inform you as soon as possible in writing if and which of your personal data we have stored in accordance with applicable law. Should incorrect information be stored despite our efforts to ensure that the data is correct and up-to-date,
        we will correct it at your request.</p>
</div>