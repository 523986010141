import { Injectable } from '@angular/core';

declare var gtag;
@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor() {}

  isActive() {
    return document.cookie.indexOf('cookieconsent_status=allow') > -1;
  }

  send(page) {
    if (this.isActive()) {
     
      gtag('event', page);
    }
  }
}
