import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APP } from './app.config';

@Injectable({
  providedIn: 'root',
})
export class DashboardStatisticsService {
  
  constructor(private http: HttpClient) {}

  getStatistics(orgaid) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-organisation-id': orgaid,
      }),
    };

    return this.http
      .get(APP.baseurl + '/statistics/dashboard', httpOptions)
      .toPromise();
  }

  getForcast(orgaid,start,end) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-organisation-id': orgaid,
      }),
    };

    return this.http
      .get(APP.baseurl + '/statistics/workloadDetailed/' + start + '/' + end, httpOptions)
      .toPromise();
  }

  getWorkLoad(orgaid, datestring) {

  
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-organisation-id': orgaid,
      }),
    };

    return this.http
      .get(APP.baseurl + '/statistics/workload/' + datestring + "/1", httpOptions)
      .toPromise();

   
  }


}
