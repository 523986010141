<app-loading-bar></app-loading-bar>

<div class="flex column" *ngIf="event" style="font-size:medium">

    <h1 style="padding: 0px 15px;font-size:xx-large">{{event?.title }}
        <span class="tag" *ngIf="isOwnerOfBooking()">{{'Your Booking' | translate}}</span>
    </h1>
    <div style="max-height: 30vh;min-height:30vh;
    overflow: hidden;display:flex">
        <img style="width:100%;object-fit: contain;" [ngClass]="{'placeholderImage': !event?.mapUrlLocation}" [src]="event?.mapUrlLocation ? event?.mapUrlLocation : '../../../assets/img/crewcus-logo' ">
    </div>
    <div style="padding: 10px 15px;">
        <div>{{'Location' | translate}}:
            <div style="font-weight:bold">{{event?.location}} {{'- ' + event?.room}}</div>
        </div>
    </div>
    <div class="flex" style="place-content: space-between;">
        <div style="padding: 10px 15px;">{{'Start of Reservation' | translate}}:
            <div>{{'End of Reservation' | translate}}:</div>
        </div>

        <div style="padding: 10px 15px;">
            <div>{{event?.startTime | date: 'medium':undefined:'de-DE'}}</div>
            <div>{{event?.endTime | date: 'medium':undefined:'de-DE'}}</div>
        </div>





    </div>

    <div class="flex" style="place-content: space-between;">
        <div style="padding: 10px 15px;">{{'Booked by' | translate}}:

        </div>

        <div style="padding: 10px 15px;">
            <div>{{isOwnerOfBooking() ? this.user.username : event?.username}}</div>

        </div>





    </div>

    <button *ngIf="isOwnerOfBooking()" (click)="showDeleteBookingModal()" class="secondary rebrand">{{'Delete Booking' | translate}}</button>
    <button *ngIf="showSuperAdmin" (click)="showDeleteBookingModal()" class="secondary rebrand">[Superadmin] {{'Delete Booking' | translate}}</button>

    <button (click)="routeToCalendar()" class="primary rebrand">{{'Back' | translate}}</button>

</div>

<div class="modal" [ngClass]="{'active': showDeleteConfirm}">

    <div class="content  ">
        <i class="fas fa-times" (click)="showDeleteConfirm = false"></i>
        <div class="flex column overflow-hidden">


            <div class="slider" id="onboarding-slider">

                <div class="flex column step" *ngIf="!showDeleteSuccess">
                    <img style="margin: auto;
                    width: 100%;object-fit: contain;" src="../../../assets/img/booking.svg">
                    <span class="subtitle">{{'Confirm Delete' | translate}}</span>

                    <div class="description">
                        {{'Do you really want to delete this reservation? This process cannot be undone.' | translate}}
                    </div>

                    <div class="button-holder">
                        <button (click)="deleteBooking()" class="primary rebrand red">{{'Delete' | translate}}</button>
                    </div>

                </div>

                <div class="flex column step" *ngIf="showDeleteSuccess">
                    <img style="margin: auto;
                    width: 100%;object-fit: contain;" src="../../../assets/img/booking.svg">
                    <h1 style="text-align: center;">{{'Delete Successful' | translate}}!</h1>

                    <div class="button-holder">
                        <button (click)="confirmModal()" class="primary rebrand">{{'OK' | translate}}</button>
                    </div>

                </div>


            </div>


        </div>
    </div>
    <div class="backdrop"></div>
</div>