import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from '@ionic/angular';
import { LanguageService } from './language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(private ccService: NgcCookieConsentService, private translateService: TranslateService, private languageService: LanguageService) {
 
  }

  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;

  private GID = 'G-CZ0G0MRSRE';
  disableGoogleAnalytics() {
    window['ga-disable-G-CZ0G0MRSRE'] = true;
  }

  enableGoogleAnalytics() {
    //@ts-ignore
    gtag('js', new Date());
    //@ts-ignore
    gtag('config', this.GID, {
      cookie_flags: 'SameSite=None;Secure' ,
      anonymize_ip: true,
    });
    window['ga-disable-' + this.GID] = false;
  }

  ngOnInit(): void {

    this.translateService//
    .get(['cookie.header', 'cookie.message', 'cookie.dismiss', 'cookie.allow', 'cookie.deny', 'cookie.link', 'cookie.policy'])
    .subscribe(data => {

      this.ccService.getConfig().content = this.ccService.getConfig().content || {} ;
      // Override default messages with the translated ones
      this.ccService.getConfig().content.header = data['cookie.header'];
      this.ccService.getConfig().content.message = data['cookie.message'];
      this.ccService.getConfig().content.dismiss = data['cookie.dismiss'];
      this.ccService.getConfig().content.allow = data['cookie.allow'];
      this.ccService.getConfig().content.deny = data['cookie.deny'];
      this.ccService.getConfig().content.link = data['cookie.link'];
      this.ccService.getConfig().content.policy = data['cookie.policy'];

      this.ccService.destroy(); // remove previous cookie bar (with default messages)
      this.ccService.init(this.ccService.getConfig()); // update config with translated messages
    });


    window['ga-disable-' + this.GID] = null;
    if (!this.ccService.hasConsented()) {
      this.disableGoogleAnalytics();
    } else {
      this.enableGoogleAnalytics();
    }


    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event) => {
        if (event.status === 'allow') {
          this.enableGoogleAnalytics();
        } else {
          this.disableGoogleAnalytics();
        }
      
      }
    );
  }
  ngOnDestroy(): void {}



}
