import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APP } from './app.config';
import { GuestHashService } from './guest-hash.service';

@Injectable({
  providedIn: 'root',
})
export class MyBookingsService {
  user;
  orga;

  constructor(
    private guestService: GuestHashService,
    private http: HttpClient
  ) {
    this.user = this.guestService.getUser();
  }

  async getUserBookings(activeOrganisation) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-organisation-id': activeOrganisation._id,
      }),
    };

    return this.http
      .get(
        APP.baseurl + '/reservationItem/userBookings/' + this.user.id,
        httpOptions
      )
      .toPromise();
  }

  deleteBooking(id, date, activeOrganisation) {

    // http://localhost:3000/reservationItem/5f8eb38141d527c4209ac4f1/2020-10-12
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-organisation-id': activeOrganisation,
      }),
      body: {
        // make sure newest user is used. this.user.id can still hold the old value after a sync
        userId: JSON.parse(localStorage.getItem('user')).id,
      },
    };



    return this.http
      .delete(APP.baseurl + '/reservationItem/' + id  + '/' + date, httpOptions)
      .toPromise();
  }

  adminDeleteBooking(id, username, date, activeOrganisation) {

    // http://localhost:3000/reservationItem/5f8eb38141d527c4209ac4f1/2020-10-12
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-organisation-id': activeOrganisation,
      }),
      body: {
        username: username,
      },
    };



    return this.http
      .delete(APP.baseurl + '/reservationItem/admin/' + id  + '/' + date, httpOptions)
      .toPromise();
  }
}
