<div class="flex" style="place-content: space-evenly;padding-top:10px;border-top:1px solid #e4e4e4">

    <div (click)="setActiveTab(1)" [ngClass]="{'active': activeTab === 1}" class="flex column" style="cursor:pointer;min-width: 150px;align-items: center;">
        <i class="fa fa-calendar-check-o" aria-hidden="true"></i>
        <div *ngIf="activeTab !== 1" style="    text-transform: uppercase;color: #b3b3b3;
        font-size: x-small;">{{'Calendar' | translate}}</div>
        <div *ngIf="activeTab === 1" class="activeDot"></div>
    </div>

    <div (click)="setActiveTab(2)" [ngClass]="{'active': activeTab === 2}" class="flex column" style="cursor:pointer;min-width: 150px;align-items: center;">
        <i class="fa fa-list" aria-hidden="true"></i>
        <div *ngIf="activeTab !== 2" style="    text-transform: uppercase; color: #b3b3b3;
        font-size: x-small;">{{'my bookings' | translate}}</div>
        <div *ngIf="activeTab === 2" class="activeDot"></div>
    </div>
</div>