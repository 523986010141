import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from 'src/app/user.service';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements OnInit {
  @Input() timelineData;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  lang;
  constructor(public userService: UserService) {}
  displayedColumns: string[] = ['icon','date', 'info', 'status'];


  ngAfterViewInit() {
  
    this.timelineData.paginator = this.paginator;
  }


  ngOnInit(): void {
    this.timelineData = new MatTableDataSource(this.timelineData);
    this.lang = this.getLang();
   
  }
  getLang() {
    // @ts-ignore
    if (window.Intl && typeof window.Intl === 'object') {
      // @ts-ignore
      let lang = Intl.getCanonicalLocales(window.navigator.language)[0];

      if (lang.indexOf('-') > 0) {
        lang = lang.split('-', 1)[0];
      }

      //if (lang === 'de') {
      return lang;
      /*} else {
                return 'en';
            }*/
    }
    return 'en';
  }


  trimMessage(message) {

   let res =  message.split("with expire Date ");

    return res;
  }
}
