<h2 mat-dialog-title>{{'What do you want to do?' | translate}}</h2>
<mat-dialog-content class="mat-typography">
    <div style="display: flex;
flex-direction: column;">
        <button class="noSelect cta-button" mat-button [mat-dialog-close]="'pro'" cdkFocusInitial>{{'Change Subscription' | translate}}</button>
        <button class="noSelect cancel-button" mat-button [mat-dialog-close]="'cancel'">{{'Cancel Subscription' | translate}} </button>

    </div>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button style="    color: var(--third);
    border: none;" class="noSelect cancel-button" mat-button mat-dialog-close>{{'Cancel' | translate}} </button>

</mat-dialog-actions>