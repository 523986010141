<app-loading-bar></app-loading-bar>
<div class="modal" *ngIf="!success">
    <h1>Mobile Check-In</h1>
    <div style="    height: 30vh;
    max-height: 30vh;display:flex">
        <img src="../../assets/img/crewcus-logo" class="placeholderImage">
    </div>
    <h4 *ngIf="errorMessage" style="color:red;padding: 0px 15px;margin-block-start: 0;
    margin-block-end: 0;
    ">{{errorMessage | translate}}</h4>
    <div style="padding: 10px 15px; display: flex;
    flex-direction: column;font-size:medium">
        <div>{{'Location' | translate}}:
            <div style="font-weight:bold">{{this.activeItem.locationFull.name }}</div>
        </div>

        <div>{{'Bookable' | translate}}:
            <p style="font-weight:bold">{{this.activeItem.name}}</p>
        </div>



        <div class="flex" style="place-content: space-between;">
            <div>{{'Start of Reservation' | translate}}:
                <div>{{'End of Reservation' | translate}}:</div>
            </div>

            <div>
                <div>{{startTime | date: 'medium':undefined:lang}}</div>
                <div>{{endTime | date: 'medium':undefined:lang}}</div>
            </div>





        </div>
        <input required [(ngModel)]="username" style="margin-bottom:10px" type="email" placeholder="Your Alias" [value]="username">
        <button style="align-self: center;" class="rebrand secondary" (click)="cancel()">{{'Cancel Check-In' | translate}}</button>

    </div>
    <button (click)="bookItemNow()" [disabled]="!username || loadingService.isItLoading()  || !this.activeItem.name" class="rebrand primary">
        <ng-container *ngIf="!isLoading">{{'Check-In Now' | translate}} <span *ngIf="autoCheckin">(..in {{automaticCheckin}} {{'Seconds' | translate}})</span></ng-container>
        <img *ngIf="isLoading" class="spin" src="../../../assets/img/loading.svg" style="width:20px">
        
    </button>

</div>

<div class="modal" *ngIf="success">
    <h1>Mobile Check-In</h1>
    <div style="    height: 30vh;
    max-height: 30vh;display:flex">
        <img src="../../assets/img/success.svg" class="placeholderImage">
    </div>
    <h4 *ngIf="errorMessage" style="color:red;padding: 0px 15px;margin-block-start: 0;
    margin-block-end: 0;
    ">{{errorMessage | translate}}</h4>
    <div style="padding: 10px 15px; display: flex;
    flex-direction: column;font-size:medium">
        <div>{{'Location' | translate}}:
            <div style="font-weight:bold">{{this.activeItem.locationFull.name }}</div>
        </div>

        <div>{{'Bookable' | translate}}:
            <p style="font-weight:bold">{{this.activeItem.name}}</p>
        </div>



        <div class="flex" style="place-content: space-between;">
            <div>{{'Start of Reservation' | translate}}:
                <div>{{'End of Reservation' | translate}}:</div>
            </div>

            <div>
                <div>{{startTime | date: 'medium':undefined:lang}}</div>
                <div>{{endTime | date: 'medium':undefined:lang}}</div>
            </div>





        </div>
        <h1 style="color: var(--accent);
        text-align: center;">{{'Booking Successful' | translate}}!</h1>

        <button (click)="cancel()" [disabled]="loadingService.isItLoading()" class="rebrand primary">
        <ng-container *ngIf="!isLoading">{{'Go To Dashboard' | translate}}</ng-container>
        <img *ngIf="isLoading" class="spin" src="../../../assets/img/loading.svg" style="width:20px">
        
    </button>

    </div>