<ng-container *ngIf="data">
    <div class="flex column" style="margin-right:10px;position: relative;">
        <svg [title]="name" style="align-self:center;border-radius:100px" [attr.viewBox]="'0 0 ' + SIZE + ' ' + SIZE" fill="none" xmlns="http://www.w3.org/2000/svg" [attr.width]="inputSize" [attr.height]="inputSize" [attr.name]="name" [attr.size]="inputSize">
      <mask
        id="mask__beam"
        maskUnits="userSpaceOnUse"
        x=0
        y=0
        [attr.width]="SIZE"
        [attr.height]="SIZE"
      >
        <rect
          [attr.width]="SIZE"
          [attr.height]="SIZE"
          rx=20
          fill="white"
        />
      </mask>
      <g mask="url(#mask__beam)" fill="transparent">
        <rect
          [attr.width]="SIZE"
          [attr.height]="SIZE"
          rx=20
          [attr.fill]="data.backgroundColor"
        />
        <rect
          x="0"
          y="0"
          [attr.width]="SIZE"
          [attr.height]="SIZE"
          [attr.transform]="'translate(' + data.wrapperTranslateX + ' ' + data.wrapperTranslateY + ') rotate(' + data.wrapperRotate + ' ' + SIZE / 2 + ' ' + SIZE / 2  + ') scale(' + data.wrapperScale + ')'"
          [attr.fill]="data.wrapperColor"
          [attr.rx]="data.isCircle ? SIZE : SIZE/6"
        />
        <g
          [attr.transform]="'translate(' + data.faceTranslateX + ' ' + data.faceTranslateY + ') rotate('+ data.faceRotate + ' ' + SIZE / 2 + ' ' + SIZE / 2  +')'"
        >
          <path *ngIf="data.isMouthOpen" [attr.d]="'M15 ' + (19 + data.mouthSpread) + 'c2 1 4 1 6 0'" [attr.stroke]="data.faceColor" fill="none" stroke-linecap="round" />
          <path *ngIf="!data.isMouthOpen" [attr.d]="'M13,'+ (19 + data.mouthSpread) + ' a1,0.75 0 0,0 10,0'" [attr.fill]="data.faceColor" />
          <rect [attr.x]="14 - data.eyeSpread" y=14 width=1.5 height=2 rx=1 stroke="none" [attr.fill]="data.faceColor" />
          <rect [attr.x]="20 + data.eyeSpread" y=14 width=1.5 height=2 rx=1 stroke="none" [attr.fill]="data.faceColor" />
        </g>
      </g>
    </svg>
        <div class="name" style="white-space: nowrap;" [title]="name">{{name}}</div>
        <div class="name" style="color: var(--third)">{{location}}</div>
        <div class="delete" style="    position: absolute;
        right: 0;" *ngIf="isOwner()"><i class="fa fa-times-circle" style="color: red !important;
    background: white;
    border-radius: 85px;
    -webkit-text-stroke-color: transparent;cursor:pointer" aria-hidden="true"></i></div>
    </div>
</ng-container>