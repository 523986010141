<div class="modal active">

    <div class="content big  ">

        <div class="flex column overflow-hidden">


            <div class="slider" id="onboarding-slider">

                <div class="flex column step" style="position: relative;">
                    <i class="fas fa-times" (click)="close()" style="    position: absolute;
                    background: white;
                    top: -5px;
                    right: -5px;
                    border: 8px solid white;
                    border-radius: 7px;
                }"></i>
                    <div class="step-holder flex">
                        <div class="date" [ngClass]="{'underline': step === 1}"><i class="far fa-calendar-alt"></i> {{activeDate | date: undefined:undefined:'de-DE'}}</div>
                        <div *ngIf="bookWholeDay && !isMobileOffice" class="time" [ngClass]="{'underline': step === 2}"><i class="far fa-clock"></i> {{('Whole Day' | translate)}}</div>
                        <div *ngIf="!bookWholeDay && !isMobileOffice" class="time" [ngClass]="{'underline': step === 2}"><i class="far fa-clock"></i> {{isEnglishFormat ? checkInHour[currentStartHourIndex] % 12 === 0 ? 12 : checkInHour[currentStartHourIndex] % 12 : checkInHour[currentStartHourIndex] }}:{{checkInMinute[currentStartMinuteIndex]}} -
                            {{isEnglishFormat ? checkInHour[currentEndHourIndex] % 12 === 0 ? 12 : checkInHour[currentEndHourIndex] % 12 : checkInHour[currentEndHourIndex] }}:{{checkInMinute[currentEndMinuteIndex]}}
                        </div>
                        <div *ngIf="isMobileOffice" class="time" [ngClass]="{'underline': step === 2}"><i class="far fa-clock"></i> {{('Mobile Office' | translate)}}</div>
                        <div class="item" [ngClass]="{'underline': step === 3}"><i class="fas fa-dice-d6"></i> {{'Select Item' | translate}}</div>
                    </div>

                    <ng-container *ngIf="step === 1">
                        <app-calendar [activeDate]="activeDate" [showEventDetail]="false" (activeDateChange)="setActiveDate($event)"></app-calendar>

                        <div *ngIf="!isMobileOffice" class="flex" style="width:90%">
                            <input id="check-day" style="flex: 0 0 8%" type="checkbox" [(ngModel)]="bookWholeDay" [checked]="bookWholeDay">
                            <label for="check-day" style="cursor:pointer;padding-top: 7px;
                        padding-left: 15px;">{{'Book the whole day' | translate}}</label>
                        </div>
                    </ng-container>
                    <div *ngIf="step === 2" style="    padding: 15px 20px;">
                        <div class="step-2">
                            {{'Please select a start and end time for your reservation' | translate}}
                        </div>

                        <div class="check-in">
                            <h1 class="start-label">
                                Check-In:
                            </h1>
                            <div class="flex check-in-inner">
                                <div class="check-in-hour main flex">
                                    <div class="time-display">
                                        <div class="previous-hour">{{isEnglishFormat ? getPreviousHour(checkInHour,currentStartHourIndex) % 12 === 0 ? 12 : getPreviousHour(checkInHour,currentStartHourIndex) % 12 : getPreviousHour(checkInHour,currentStartHourIndex) }} </div>
                                        {{isEnglishFormat ? checkInHour[currentStartHourIndex] % 12 === 0 ? 12 : checkInHour[currentStartHourIndex] % 12 : checkInHour[currentStartHourIndex] }}
                                        <div class="next-hour">{{isEnglishFormat? getNextHour(checkInHour,currentStartHourIndex) % 12 === 0 ? 12: getNextHour(checkInHour,currentStartHourIndex) % 12 : getNextHour(checkInHour,currentStartHourIndex) }} </div>
                                    </div>
                                    <div class="selectors flex column">
                                        <i (click)="previousHour('start-hour')" class="fa fa-chevron-up fa-2x"></i>
                                        <i (click)="nextHour('start-hour')" class="fa fa-chevron-down fa-2x"></i>
                                    </div>
                                </div>
                                <div>:</div>
                                <div class="check-in-minute main flex">
                                    <div class="time-display">
                                        <div class="previous-hour">{{getPreviousHour(checkInMinute,currentStartMinuteIndex)}} </div>
                                        {{checkInMinute[currentStartMinuteIndex]}}
                                        <div class="next-hour">{{getNextHour(checkInMinute,currentStartMinuteIndex)}} </div>
                                    </div>
                                    <div class="selectors flex column">
                                        <i (click)="previousHour('start-minute')" class="fa fa-chevron-up fa-2x"></i>
                                        <i (click)="nextHour('start-minute')" class="fa fa-chevron-down fa-2x"></i>
                                    </div>
                                </div>
                                <div></div>
                                <div class="check-in-ampm main flex column">
                                    <div class="ampm-switch" [ngClass]="{'active-switch': AMPMSwitch === 'AM'}">{{'AM' | translate}}</div>
                                    <div class="ampm-switch" [ngClass]="{'active-switch': AMPMSwitch === 'PM'}">{{'PM' | translate}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="check-out">
                            <h1 class="start-label">
                                Check-Out: {{isEnglishFormat}}
                            </h1>
                            <div class="flex check-in-inner">
                                <div class="check-in-hour main flex">
                                    <div class="time-display">
                                        <div class="previous-hour">{{isEnglishFormat ? getPreviousHour(checkInHour,currentEndHourIndex) % 12 === 0 ? 12 : getPreviousHour(checkInHour,currentEndHourIndex) % 12 : getPreviousHour(checkInHour,currentEndHourIndex) }}</div>
                                        {{isEnglishFormat ? checkInHour[currentEndHourIndex] % 12 === 0 ? 12 : checkInHour[currentEndHourIndex] % 12 : checkInHour[currentEndHourIndex] }}
                                        <div class="next-hour">{{isEnglishFormat? getNextHour(checkInHour,currentEndHourIndex) % 12 === 0 ? 12: getNextHour(checkInHour,currentEndHourIndex) % 12 : getNextHour(checkInHour,currentEndHourIndex) }}</div>
                                    </div>
                                    <div class="selectors flex column">
                                        <i (click)="previousHour('end-hour')" class="fa fa-chevron-up fa-2x"></i>
                                        <i (click)="nextHour('end-hour')" class="fa fa-chevron-down fa-2x"></i>
                                    </div>
                                </div>
                                <div>:</div>
                                <div class="check-in-minute main flex">
                                    <div class="time-display">
                                        <div class="previous-hour">{{getPreviousHour(checkInMinute,currentEndMinuteIndex)}}</div>
                                        {{checkInMinute[currentEndMinuteIndex]}}
                                        <div class="next-hour">{{getNextHour(checkInMinute,currentEndMinuteIndex)}}</div>
                                    </div>
                                    <div class="selectors flex column">
                                        <i (click)="previousHour('end-minute')" class="fa fa-chevron-up fa-2x"></i>
                                        <i (click)="nextHour('end-minute')" class="fa fa-chevron-down fa-2x"></i>
                                    </div>
                                </div>
                                <div></div>
                                <div class="check-in-ampm main flex column">
                                    <div class="ampm-switch" [ngClass]="{'active-switch': AMPMSwitchEnd === 'AM'}">{{'AM' | translate}}</div>
                                    <div class="ampm-switch" [ngClass]="{'active-switch': AMPMSwitchEnd === 'PM'}">{{'PM' | translate}}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="step === 3" style="    padding: 15px 20px;">
                        <div class="step-3">
                            {{'Please select a the item you would like to book' | translate}}
                            <span (click)="showMap(true)" style="text-decoration: underline;
                            color: var(--primary);cursor:pointer">{{'Show Location Map' | translate}}</span>

                            <h1>{{'Available Items' | translate }} ({{availableItems.length}}):</h1>

                            <p style="color:red" *ngIf="areBookablesDeactivated()">{{'Some bookables were hidden because the maximum number of your plan was exceeded' | translate}}</p>

                            <div class="table-container" *ngIf="!isMobileOffice">
                                <div *ngFor="let room of activeLocation.rooms">
                                    <div style="margin-bottom:15px">{{room.name}}</div>

                                    <ng-container *ngFor="let ritem of room.bookableItems">


                                        <ng-container *ngFor="let item of availableItems">
                                            <div (click)="setActiveItem(item)" [ngClass]="{'disabled-card': null === item._id,'active-card': selectedItem?._id === item._id && selectedItem?._id !== null }" class="card" *ngIf="item._id === ritem._id "> {{item.name}}</div>

                                        </ng-container>

                                    </ng-container>

                                    <ng-container *ngIf="room.bookableItems.length === 0">
                                        <div style="font-size: 18px;">{{'No Item available' | translate}}</div>
                                    </ng-container>



                                </div>
                            </div>

                            <div class="table-container" *ngIf="isMobileOffice && availableItems">




                                <ng-container *ngFor="let item of availableItems">
                                    <div (click)="setActiveItem(item)" [ngClass]="{'active-card': selectedItem?._id === item?._id}" class="card"> {{item?.name}}</div>

                                </ng-container>



                                <ng-container *ngIf="availableItems?.length === 0">
                                    <div style="font-size: 18px;">{{'No Item available' | translate}}</div>
                                </ng-container>




                            </div>


                        </div>
                    </div>

                    <div *ngIf="step === 4" style="padding:15px 20px;width: 90%;">
                        <img style="margin: auto;
                        width: 100%;" src="../../../assets/img/booking.svg">
                        <h1>Summary</h1>
                        <div class="date" [ngClass]="{'underline': step === 1}"><i class="far fa-calendar-alt"></i> {{activeDate | date: undefined:undefined:'de-DE'}}</div>
                        <div *ngIf="bookWholeDay" class="time" [ngClass]="{'underline': step === 2}"><i class="far fa-clock"></i> {{('Whole Day' | translate)}}</div>
                        <div *ngIf="!bookWholeDay" class="time" [ngClass]="{'underline': step === 2}"><i class="far fa-clock"></i> {{isEnglishFormat ? checkInHour[currentStartHourIndex] % 12 === 0 ? 12 : checkInHour[currentStartHourIndex] % 12 : checkInHour[currentStartHourIndex] }}:{{checkInMinute[currentStartMinuteIndex]}} -
                            {{isEnglishFormat ? checkInHour[currentEndHourIndex] % 12 === 0 ? 12 : checkInHour[currentEndHourIndex] % 12 : checkInHour[currentEndHourIndex] }}:{{checkInMinute[currentEndMinuteIndex]}}
                        </div>
                        <div class="item" [ngClass]="{'underline': step === 3}"><i class="fas fa-dice-d6"></i> {{selectedItem?.name}}</div>

                        <div style="margin-top:15px">{{'Under which name do you want to book?' | translate }}</div>
                        <input type="text" [(ngModel)]="username" [value]="username">
                        <div style="color:#ff3100" *ngIf="error">Error: {{error | translate }}</div>
                    </div>

                    <div *ngIf="step === 999" style="padding:15px 20px; width:90%;">
                        <img style="margin: auto;
                        width: 100%;" src="../../../assets/img/booking.svg">
                        <h1 style="text-align: center;">{{'Booking Successful' | translate}}!</h1>
                    </div>
                    <div class="flex" style="width:90%">
                        <button *ngIf="step === 1" (click)="close()" class="alt-button">
                  
                    
                        <span >{{'Cancel' | translate}}</span>
                            </button>
                        <button *ngIf="step > 1 && step < 4" (click)="previousStep()" class="alt-button">
                  
                    
                        <span >{{'Back' | translate}}</span>
                    </button>
                        <button *ngIf="step === 4" (click)="previousStep()" class="alt-button">
                  
                    
                        <span >{{'Change' | translate}}</span>
                    </button>
                        <div style="flex: 0 0 5%"></div>
                        <button *ngIf="step < 4" [disabled]="step === 3 && !selectedItem" (click)="nextStep()" class="create">
                  
                    
                        <span >{{'Next' | translate}}</span>
                    </button>
                        <button *ngIf="step === 4" [disabled]="step === 3 && !selectedItem || !username" (click)="nextStep()" class="create">
                  
                    
                        <span >{{'Book Now' | translate}}</span>
                    </button>
                        <button *ngIf="step === 999 && username !== null" (click)="close()" class="create">
                  
                    
                        <span >{{'Done' | translate}}</span>
                    </button>
                    </div>
                </div>


            </div>


        </div>
    </div>
    <div class="location-map" *ngIf="showMapPicture" (click)="showMap(false)"><img [src]="activeLocation.mapUrl"></div>
    <div class="backdrop"></div>
</div>