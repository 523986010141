<!-- <app-survey style="z-index:1000" *ngIf="!showModal && !success">
</app-survey> -->
<app-loading-bar></app-loading-bar>

<div *ngIf="allOrganisations?.length > 0" class="calendar-desktop">
    <div class="calendar-desktop-flex-25">
        <div class="flex" style="    background: var(--accent);
    color: white;">
            <div>
                <div class="orga-name">{{slug.replaceAll('-',' ')}}</div>
                <div class="flex location-hold">
                    <!-- <i style="margin-right:5px" class="fas fa-search-location"></i> -->
                    <div style="margin-right:15px">{{activeLocation?.name}}</div>
                    <div (click)="showChangeLocation()" style="text-decoration: underline;
    color: var(--primary);cursor:pointer">{{'Change' | translate}}</div>
                </div>
            </div>
            <div class="new-reservation-hold hide-desktop">
                <button style="padding: 10px 15px;
                background: white !important;
                color: black;" class="new-reservation-button" (click)="showCreateReservation = true">{{'New Reservation' | translate}}</button>

            </div>
        </div>

        <div class="schedule-item hide-desktop flex column" *ngIf="mobileOfficeBookingData?.length > 0" style="border-bottom: 1px solid #ececec">
            <div class="desktop-item no-hover-bg" style="border-bottom: none;">

                <div class="schedule-item-content flex column">


                    <div class="schedule-item-title">{{'Mobile Office' | translate}} ({{mobileOfficeBookingData?.length}})</div>

                </div>
            </div>


            <div class="flex" style="padding:5px 15px;overflow-x:auto">


                <ng-container *ngFor="let mobileOfficeBooking of mobileOfficeBookingData">
                    <ng-boring-avatars-beam-avatar (click)="onMobileOfficeSelected(mobileOfficeBooking)" [userhash]="mobileOfficeBooking.userIdHash" [location]="mobileOfficeBooking.name" [name]="mobileOfficeBooking.userName" [inputSize]="40"></ng-boring-avatars-beam-avatar>
                </ng-container>



            </div>

        </div>

        <app-calendar [events]="events" [activeDate]="activeDate" [showEventDetail]="true" (activeDateChange)="setActiveDate($event)"></app-calendar>

        <div class="flex column only-desktop" style="flex:1">
            <h1 style="text-align: center;" *ngIf="activeLocation?.mapUrl">{{'Location Map' | translate}}</h1>
            <img *ngIf="activeLocation" style="object-fit: contain;
            max-width: 25vw;
            align-self: center; margin-top: auto;margin-bottom: auto;" [ngClass]="{'placeholderPadding': !activeLocation?.mapUrl}" [src]="activeLocation?.mapUrl ? activeLocation?.mapUrl : '../../assets/img/crewcus-logo' ">


        </div>
    </div>
    <div class="only-desktop desktop-flex-1" style="overflow-y: scroll;">
        <div class="flex">
            <div class="schedule">{{'Reservations' | translate}} {{activeDate | date: 'mediumDate':undefined:lang }} {{'(' + (activeDateEvents ? activeDateEvents.length : '0') + ')'}}: </div>
            <div class="new-reservation-hold only-desktop">
                <button class="rebrand primary" (click)="showCreateReservation = true">{{'New Reservation' | translate}}</button>

            </div>
        </div>

        <div class="schedule-item flex column" *ngIf="mobileOfficeBookingData?.length > 0" style="border-bottom: 1px solid #ececec">
            <div class="desktop-item no-hover-bg" style="border-bottom: none;">

                <div class="schedule-item-content flex column">


                    <div class="schedule-item-title">{{'Mobile Office' | translate}} ({{mobileOfficeBookingData?.length}})</div>

                </div>
            </div>


            <div class="flex" style="padding:5px 15px;overflow-x:auto">


                <ng-container *ngFor="let mobileOfficeBooking of mobileOfficeBookingData">
                    <ng-boring-avatars-beam-avatar (click)="onMobileOfficeSelected(mobileOfficeBooking)" [userhash]="mobileOfficeBooking.userIdHash" [location]="mobileOfficeBooking.name" [name]="mobileOfficeBooking.userName" [inputSize]="40"></ng-boring-avatars-beam-avatar>
                </ng-container>



            </div>

        </div>

        <div class="schedule-item flex" *ngIf="activeDateEvents?.length === 0">
            <div class="desktop-item">

                <div class="schedule-item-content flex column">

                    <div class="room-and-time"> </div>
                    <div class="schedule-item-title">{{'No Reservations' | translate}}</div>
                    <div class="schedule-item-user">{{'There are no reservations for the selected date and/or location' | translate}}</div>
                </div>
            </div>
        </div>

        <div (click)="onEventSelected(event)" class="schedule-item flex" [ngClass]="{'active-element': event.itemid === activeEvent?.itemid}" *ngFor="let event of activeDateEvents">

            <div class="desktop-item">
                <div style="width: 25px">
                    <div class="dot"></div>
                </div>
                <div class="schedule-item-content flex column">

                    <div class="room-and-time">{{isDayToday(event.endTime) ? ('Today' | translate) : event.startTime | date: 'mediumDate':undefined:lang }}, {{event.startTime | date: 'shortTime':undefined:lang}} - {{event.endTime | date: 'shortTime':undefined:lang}} </div>
                    <div class="schedule-item-title">{{event.room}} - {{event.title}}</div>
                    <div class="schedule-item-user">{{event.username}}</div>
                </div>
            </div>

        </div>


    </div>

    <div [ngStyle]="{'flex': activeEvent ? '0 0 25%' : '0'}" style="
    border-left: 1px solid var(--fourth);    transition-duration: .3s;
    transform: translateX(0vw);    background: white;" [ngClass]="{'show-sidebar-right': activeEvent}" class="only-desktop flex">


        <div class="flex column" *ngIf="activeEvent" style="font-size:medium;position: relative">

            <div (click)="activeEvent = null" class="close-right"><i class="fa fas fa-times"></i></div>
            <h1 style="padding: 0px 15px;font-size:xx-large">{{activeEvent?.title }}
                <span class="tag" *ngIf="isOwnBooking(activeEvent?.userid)">{{'Your Booking' | translate}}</span>
            </h1>
            <div style="max-height: 30vh;min-height:30vh;
        overflow: hidden;display:flex">
                <img style="width:100%;object-fit: contain;max-width: 250px;
                margin: auto;" [ngClass]="{'placeholderImage': !activeEvent?.mapUrlLocation}" [src]=" '../../../assets/img/crewcus-logo' ">
            </div>
            <div style="padding: 10px 15px;">
                <div>{{'Location' | translate}}:
                    <div style="font-weight:bold">{{activeEvent?.location}} {{'- ' + activeEvent?.room}}</div>
                </div>
            </div>
            <div class="flex" style="place-content: space-between;">
                <div style="padding: 10px 15px;">{{'Start of Reservation' | translate}}:
                    <div>{{'End of Reservation' | translate}}:</div>
                </div>

                <div style="padding: 10px 15px;">
                    <div>{{activeEvent?.startTime | date: 'medium':undefined:lang}}</div>
                    <div>{{activeEvent?.endTime | date: 'medium':undefined:lang}}</div>
                </div>





            </div>

            <div class="flex" style="place-content: space-between;">
                <div style="padding: 10px 15px;">{{'Booked by' | translate}}:

                </div>

                <div style="padding: 10px 15px;">
                    <div>{{isOwnBooking(activeEvent?.userid) ? username : activeEvent?.username}}</div>

                </div>





            </div>

            <button *ngIf="isOwnBooking(activeEvent?.userid)" (click)="showDeleteBookingModal()" class="secondary rebrand">{{'Delete Booking' | translate}}</button>
            <button *ngIf="showSuperAdmin" (click)="showDeleteBookingModal()" class="secondary rebrand">[Superadmin] {{'Delete Booking' | translate}}</button>


        </div>

    </div>

    <app-create-reservation [username]="username" [bookedSlotsForActiveDay]="bookedSlotsForActiveDay" [activeLocation]="activeLocation" *ngIf="showCreateReservation" [activeDate]="activeDate" (onClose)="onCreateReservationClose($event)"></app-create-reservation>


    <div class="modal" [ngClass]="{'active': showLocationChangeModal}">

        <div class="content  ">
            <i class="fas fa-times" (click)="showLocationChangeModal = false"></i>
            <div class="flex column overflow-hidden">


                <div class="slider" id="onboarding-slider">

                    <div class="flex column step">
                        <span class="subtitle">{{'Select Location' | translate}}</span>

                        <div class="location-hold-modal">
                            <div *ngFor="let location of locations">

                                <span style="cursor:pointer" (click)="setActiveLocation(location)" [ngClass]="{'active-item': location._id === activeLocation._id }">{{location.name}}</span>
                            </div>
                        </div>

                    </div>


                </div>


            </div>
        </div>
        <div class="backdrop"></div>
    </div>
</div>




<div *ngIf="allOrganisations?.length === 0" style="padding:0px 15px">
    <img style="max-height:20vh;width:100%" src="../../assets/img/office-work.svg">
    <h1 class="loading-placeholder-text">{{'There is nothing here. Sorry.' | translate}}</h1>
    <div class="loading-placeholder-text">{{'If you think you have the correct URL to your workplace, please contact your Administrator.' | translate}}</div>
</div>

<app-tab-bar [activeTab]="1"></app-tab-bar>


<div class="modal" [ngClass]="{'active': showDeleteConfirm}">

    <div class="content  ">
        <i class="fas fa-times" (click)="showDeleteConfirm = false"></i>
        <div class="flex column overflow-hidden">


            <div class="slider" id="onboarding-slider">

                <div class="flex column step" *ngIf="!showDeleteSuccess">
                    <img style="margin: auto;
                    width: 100%;object-fit: contain;" src="../../../assets/img/booking.svg">
                    <span class="subtitle">{{'Confirm Delete' | translate}}</span>

                    <div class="description">
                        {{'Do you really want to delete this reservation? This process cannot be undone.' | translate}}
                    </div>

                    <div class="button-holder">
                        <button (click)="deleteBooking()" class="primary rebrand red">{{'Delete' | translate}}</button>
                    </div>

                </div>

                <div class="flex column step" *ngIf="showDeleteSuccess">
                    <img style="margin: auto;
                    width: 100%;object-fit: contain;" src="../../../assets/img/booking.svg">
                    <h1 style="text-align: center;">{{'Delete Successful' | translate}}!</h1>

                    <div class="button-holder">
                        <button (click)="confirmModal()" class="primary rebrand">{{'OK' | translate}}</button>
                    </div>

                </div>


            </div>


        </div>
    </div>
    <div class="backdrop"></div>
</div>


<app-mobile-office-floating (onClose)="onCreateReservationClose($event)" [username]="username" [activeDate]="activeDate" [activeLocation]="activeLocation" *ngIf="activeOrganisation?.mobileOffice && !this.isForbiddenMobileOffice"></app-mobile-office-floating>