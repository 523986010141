import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { APP } from 'src/app/app.config';
import { LanguageService } from 'src/app/language.service';
import { LoadingService } from 'src/app/loading.service';
import {
  IOrganisationObserver,
  OrganisationService,
} from 'src/app/organisation.service';

@Component({
  selector: 'app-remote-office-admin',
  templateUrl: './remote-office-admin.component.html',
  styleUrls: ['./remote-office-admin.component.scss'],
})
export class RemoteOfficeAdminComponent
  implements OnInit, IOrganisationObserver
{
  donutData;
  donutData2;
  donutData3;
  donutStrings = [
    this.translateService.instant('In House'),
    this.translateService.instant('Mobile Office'),
  ];

  isActive = false;
  modalActive = false;
  showDeleteModal = false;
  activeOrga;
  specialBookables;
  error;
  specialBookableName;
  activeElement;
  isForbidden;
  @ViewChild('modalinput') modalInput;

  constructor(
    private organisationService: OrganisationService,
    private http: HttpClient,
    public loadingService: LoadingService,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private router: Router
  ) {}
  observerId: number = 93993990023;

  onOrganisationChange(organisations: any) {
    if (organisations && organisations[0]) {
      this.activeOrga = organisations[0];
      this.isActive = organisations[0].mobileOffice;

      if (this.isActive) {
        this.getSpecialBookables();
        this.getMobileOfficeQuotaToday();
        this.getMobileOfficeSevenDaysQuotaToday();
        this.getMobileOfficeMonthQuotaToday();
      }
      this.loadingService.stopLoading();
    }
  }

  ngOnInit(): void {
    this.loadingService.startLoading();
    this.organisationService.subscribe(this);
  }

  getLang() {
    return this.languageService.getLang();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.organisationService.unsubscribe(this);
  }

  mobileOfficeQuotaDay;

  async getMobileOfficeQuotaToday() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-organisation-id': this.activeOrga._id,
      }),
    };

    const now = new Date();

    let month =
      now.getMonth() + 1 < 10 ? '0' + (now.getMonth() + 1) : now.getMonth() + 1;

    let day = now.getDate() < 10 ? '0' + now.getDate() : now.getDate();

    let datestring = now.getFullYear() + '-' + month + '-' + day;

    this.mobileOfficeQuotaDay = await this.http
      .get(
        APP.baseurl + '/statistics/mobileVsNonMobileDay/' + datestring,
        httpOptions
      )
      .toPromise();

    this.donutData = [
      100 - this.mobileOfficeQuotaDay.percentageMobile,
      this.mobileOfficeQuotaDay.percentageMobile,
    ];
  }

  async getMobileOfficeSevenDaysQuotaToday() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-organisation-id': this.activeOrga._id,
      }),
    };

    const now = new Date();

    let month =
      now.getMonth() + 1 < 10 ? '0' + (now.getMonth() + 1) : now.getMonth() + 1;

    let day = now.getDate() < 10 ? '0' + now.getDate() : now.getDate();

    let datestring = now.getFullYear() + '-' + month + '-' + day;

    this.mobileOfficeQuotaDay = await this.http
      .get(
        APP.baseurl + '/statistics/mobileVsNonMobile7Days/' + datestring,
        httpOptions
      )
      .toPromise();

    this.donutData2 = [
      100 - this.mobileOfficeQuotaDay.percentageMobile,
      this.mobileOfficeQuotaDay.percentageMobile,
    ];
  }

  async getMobileOfficeMonthQuotaToday() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-organisation-id': this.activeOrga._id,
      }),
    };

    const now = new Date();

    let month =
      now.getMonth() + 1 < 10 ? '0' + (now.getMonth() + 1) : now.getMonth() + 1;

    let day = now.getDate() < 10 ? '0' + now.getDate() : now.getDate();

    let datestring = now.getFullYear() + '-' + month + '-' + day;

    this.mobileOfficeQuotaDay = await this.http
      .get(
        APP.baseurl + '/statistics/mobileVsNonMobileMonth/' + datestring,
        httpOptions
      )
      .toPromise();

    this.donutData3 = [
      100 - this.mobileOfficeQuotaDay.percentageMobile,
      this.mobileOfficeQuotaDay.percentageMobile,
    ];
  }

  waitTimer;
  switchFeature() {
    if (!this.waitTimer) {
      this.loadingService.startLoading();
      this.isActive = !this.isActive;
      this.organisationService.changeOfficeFeature(this.isActive).catch(() => {
        this.isForbidden = true;
        this.loadingService.stopLoading();
      });
      this.waitTimer = setTimeout(() => {
        this.waitTimer = null;
      }, 1000);
    }
  }

  async getSpecialBookables() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-organisation-id': this.activeOrga._id,
      }),
    };

    this.specialBookables = await this.http
      .get(APP.baseurl + '/mobileOffice', httpOptions)
      .toPromise()
      .catch(() => {
        this.isForbidden = true;
      });
  }

  async createPlace() {
    if (this.specialBookableName && !this.showDeleteModal) {
      this.loadingService.startLoading();

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'x-organisation-id': this.activeOrga._id,
        }),
      };

      try {
        const result = await this.http
          .post(
            APP.baseurl + '/mobileOffice/mobileBookable',
            { name: this.specialBookableName },
            httpOptions
          )
          .toPromise();

        this.specialBookableName = null;
        this.specialBookables.push(result);
        this.closeModal();
        this.loadingService.stopLoading();
      } catch (e) {
        this.error = e.error;
        this.loadingService.stopLoading();
      }
    }
  }

  async patchPlace() {
    if (
      this.specialBookableName &&
      !this.showDeleteModal &&
      this.activeElement
    ) {
      this.loadingService.startLoading();

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'x-organisation-id': this.activeOrga._id,
        }),
      };

      try {
        const result = await this.http
          .patch(
            APP.baseurl +
              '/mobileOffice/mobileBookable/' +
              this.activeElement._id,
            { name: this.specialBookableName },
            httpOptions
          )
          .toPromise();

        this.specialBookables.forEach((element, index) => {
          if (element._id === this.activeElement._id) {
            element.name = this.specialBookableName;
          }
        });

        this.specialBookableName = null;

        this.closeModal();
        this.loadingService.stopLoading();
      } catch (e) {
        this.error = e.error;
        this.loadingService.stopLoading();
      }
    }
  }

  async deletePlace() {
    if (!this.showDeleteModal && this.activeElement) {
      this.loadingService.startLoading();

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'x-organisation-id': this.activeOrga._id,
        }),
      };

      try {
        const result = await this.http
          .delete(
            APP.baseurl +
              '/mobileOffice/mobileBookable/' +
              this.activeElement._id,

            httpOptions
          )
          .toPromise();

        this.specialBookables.forEach((element, index) => {
          if (element._id === this.activeElement._id) {
            this.specialBookables.splice(index, 1);
          }
        });

        this.specialBookableName = null;

        this.closeModal();
        this.loadingService.stopLoading();
      } catch (e) {
        this.error = e.error;
        this.loadingService.stopLoading();
      }
    }
  }

  showCreatePlaceModal(element?) {
    this.modalActive = true;
    this.showDeleteModal = false;
    this.error = null;
    if (element) {
      this.activeElement = element;
      this.specialBookableName = this.activeElement.name;
    }
    // setTimeout(() => {
    //   this.modalInput.nativeElement.focus();
    // }, 1);
  }

  closeModal() {
    this.modalActive = false;
    this.specialBookableName = null;
    this.error = null;
    this.activeElement = null;
  }

  abort() {
    localStorage.setItem('lastNav', JSON.stringify('dashboard'));
    window.location.reload();
  }

  viewoptions() {
    localStorage.setItem('lastNav', JSON.stringify('pricing'));
    window.location.reload();
  }
}
