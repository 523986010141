<div class="flex bg-img">
    <h1>Rooms</h1> <img *ngIf="!locations" class="spin" src="../../../assets/img/loading.svg" style="width:30px;margin-top:0">
    <button class="cta-button" style="background:var(--accent);font-weight:normal" (click)="showCreateRoom()"><i class="fas fa-plus"></i><span class="hide-mobile">Create New Room</span></button>
</div>
Select a location:
<select #t (change)="getRoomInfo(t.value)">
    <option [selected]="activeLocation === location" [value]="location._id" *ngFor="let location of locations">{{location.name}}</option>
    
  </select>
<div class="responsive-table">
    <table>

        <tr class="head">
            <th>Name</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>

        </tr>


        <ng-container *ngFor="let room of activeRoom">
            <div style="margin-top:25px"></div>
            <tr>

                <span style="margin-left:5px;padding:10px 20px;display:flex" (paste)="onPaste($event)" (keyup.enter)="onNewNameSave()" (input)="onNameChange(room,$event)" contenteditable="true">{{room.name}}</span>
                <td></td>
                <td></td>
                <td style="cursor:pointer;text-align: center;"><i (click)="deleteRoomModal(room._id)" class="far fa-trash-alt"></i></td>
                <td (click)="onNewNameSave()" style="margin-left: 25px;
                color: var(--primary);
                cursor:pointer;
                padding: 10px;
                font-size: 13px;
                text-decoration: underline;" *ngIf="lastChangeDelta && lastChangeDelta._id === room._id">
                    <ng-container *ngIf="!isLoading">Save</ng-container>
                    <ng-container *ngIf="isLoading"><img class="spin" src="../../../assets/img/loading.svg" style="width:20px;margin-top:0"></ng-container>

                </td>
            </tr>


        </ng-container>


    </table>
</div>


<div class="modal" [ngClass]="{'active': modalActive}">

    <div class="content">
        <i *ngIf="!isLoading" class="fas fa-times" (click)="modalActive = false"></i>
        <div class="flex column overflow-hidden">


            <div class="slider" id="onboarding-slider">
                <ng-container *ngIf="!locationMissing">
                    <div class="flex column step" *ngIf="!showDeleteModal">

                        <img src="../../assets/img/room.svg">
                        <span class="subtitle">Create New Room</span>
                        <input #roomInput (keyup.enter)="roomName ? createRoom() : false" [(ngModel)]="roomName" type="text" placeholder="Please enter your Room Name">
                        <span class="description">Give your room a name. Such as 'Meeting Room'.</span>
                        <span *ngIf="error" class="description errorText">{{error}}</span>
                    </div>
                    <div class="flex column step" *ngIf="showDeleteModal">

                        <img src="../../assets/img/delete-now.svg">
                        <span class="subtitle">Delete Room</span>
                        <span class="description">Everything will be deleted.The Room and its Items. This is
                        permanent.</span>
                        <span *ngIf="error" class="description errorText">{{error}}</span>
                    </div>
                </ng-container>
                <div class="flex column step" *ngIf="locationMissing">

                    <img src="../../assets/img/attention.svg">
                    <span class="subtitle">Location Missing</span>
                    <span class="description">You have to create a location first! Please go to the Location Tab.</span>
                    <span *ngIf="error" class="description errorText">{{error}}</span>
                </div>




            </div>

            <button [ngClass]="{'delete-button': showDeleteModal}" [disabled]="isLoading || !showDeleteModal && !roomName && !locationMissing" (click)="!showDeleteModal ? locationMissing ? modalActive = false  : createRoom() : deleteRoom()">
                <ng-container *ngIf="!isLoading">
                    <ng-container *ngIf="!locationMissing">
                    <span *ngIf="!showDeleteModal">Create Room</span>
                    <span *ngIf="showDeleteModal">Delete Room</span>
                    </ng-container>
                    <span *ngIf="locationMissing">Ok</span>

                </ng-container>
                <img  *ngIf="isLoading" class="spin" src="../../../assets/img/loading.svg" style="width:20px;margin-top:0">
            </button>

        </div>
    </div>
    <div class="backdrop"></div>
</div>