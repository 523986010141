import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from 'src/app/dashboard.service';
import { GoogleAnalyticsService } from 'src/app/google-analytics.service';
import { GuestHashService } from 'src/app/guest-hash.service';
import { LoadingService } from 'src/app/loading.service';
import { LocationService } from 'src/app/location.service';
import { MyBookingsService } from 'src/app/my-bookings.service';
import {
  IOrganisationObserver,
  OrganisationService,
} from 'src/app/organisation.service';

@Component({
  selector: 'app-my-bookings',
  templateUrl: './my-bookings.component.html',
  styleUrls: ['./my-bookings.component.scss'],
})
export class MyBookingsComponent implements OnInit, IOrganisationObserver {
  @Input() locations;

  constructor(
    private myBookingsService: MyBookingsService,
    private guestService: GuestHashService,
    private GAService: GoogleAnalyticsService,
    private organisationService: OrganisationService,
    private loadingService: LoadingService,
    private router: Router,
    private dashboardService: DashboardService
  ) {}

  gotData = false;
  observerId: number = 32892988233;
  onOrganisationChange(organisations: any) {
    if (
      localStorage.getItem('os') &&
      JSON.parse(localStorage.getItem('os')) !== 'null'
    ) {
      this.dashboardService
        .getOrgaBySlug(JSON.parse(localStorage.getItem('os')))
        .then((data) => {
          this.activeOrganisation = data[0];
          this.gotData = true;
          if (!this.gotData) {
            this.organisationService.setActiveOrganisation(data);
            this.gotData = true;
          }

          this.getMyBookings();
        })
        .catch(() => {
          // todo err
          this.loadingService.stopLoading();
          this.router.navigateByUrl('/');
        });
    } else {
      localStorage.removeItem('os');
      this.loadingService.stopLoading();
      this.router.navigateByUrl('/');
    }
  }

  activeOrganisation;
  bookingData;

  activeOpenSlot = null;
  isLoading = false;
  showModal = false;
  activeItem;
  error;
  activeDate;
  user;
  timeslots = [
    '12:00 AM',
    '12:30 AM',
    '1:00 AM',
    '1:30 AM',
    '2:00 AM',
    '2:30 AM',
    '3:00 AM',
    '3:30 AM',
    '4:00 AM',
    '4:30 AM',
    '5:00 AM',
    '5:30 AM',
    '6:00 AM',
    '6:30 AM',
    '7:00 AM',
    '7:30 AM',
    '8:00 AM',
    '8:30 AM',
    '9:00 AM',
    '9:30 AM',
    '10:00 AM',
    '10:30 AM',
    '11:00 AM',
    '11:30 AM',
    '12:00 PM',
    '12:30 PM',
    '1:00 PM',
    '1:30 PM',
    '2:00 PM',
    '2:30 PM',
    '3:00 PM',
    '3:30 PM',
    '4:00 PM',
    '4:30 PM',
    '5:00 PM',
    '5:30 PM',
    '6:00 PM',
    '6:30 PM',
    '7:00 PM',
    '7:30 PM',
    '8:00 PM',
    '8:30 PM',
    '9:00 PM',
    '9:30 PM',
    '10:00 PM',
    '10:30 PM',
    '11:00 PM',
    '11:30 PM',
  ];

  lang;
  events;

  ngOnInit(): void {
    this.loadingService.startLoading();
    this.GAService.send('My Bookings Page');
    this.organisationService.subscribe(this);
    this.lang = this.getLang();
    this.user = this.guestService.getUser();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.organisationService.unsubscribe(this);
  }

  getLang() {
    // @ts-ignore
    if (window.Intl && typeof window.Intl === 'object') {
      // @ts-ignore
      let lang = Intl.getCanonicalLocales(window.navigator.language)[0];

      if (lang.indexOf('-') > 0) {
        lang = lang.split('-', 1)[0];
      }

      //if (lang === 'de') {
      return lang;
      /*} else {
                return 'en';
            }*/
    }
    return 'en';
  }

  getMyBookings() {
    this.myBookingsService
      .getUserBookings(this.activeOrganisation)
      .then((data) => {
        this.bookingData = data;

        this.parseEventsIntoCalendar(this.bookingData);
      })
      .catch((err) => {
        //todo err
        this.loadingService.stopLoading();
      });
  }

  parseEventsIntoCalendar(events) {
    let parsedEvents = [];

    events.forEach((event) => {
      if (event.blockedSlots.length > 0) {
        let day = new Date(event.blockedOnDay);

        event.blockedSlots.sort(function (a, b) {
          return a.slot - b.slot;
        });

        const res = event.blockedSlots.reduce(function (r, a) {
          r[a.userIdHash] = r[a.userIdHash] || [];
          r[a.userIdHash].push(a);
          return r;
        }, Object.create(null));

        for (let i = 0; i < Object.keys(res).length; i++) {
          let element = Object.keys(res)[i];

          let startSlot;
          let endSlot;

          for (let index = 0; index < res[element].length; index++) {
            if (!startSlot) {
              startSlot = res[element][index].slot;
            }

            if (!endSlot) {
              endSlot = startSlot + 1;
            }

            if (
              index + 1 < res[element].length &&
              res[element][index + 1].slot === res[element][index].slot + 1
            ) {
              endSlot = res[element][index + 1].slot;
            } else {
              endSlot = res[element][index].slot + 1;

              //create event
              this.createEventForCalendar(
                day,
                startSlot,
                endSlot,
                parsedEvents,
                event,
                res[element][index].userName,
                element,
                res[element][index].userIdHash,
                event.room
              );

              //nach dem create start / endslot auf null setzen
              startSlot = null;
              endSlot = null;
            }
          }
        }
      }
    });

    this.events = parsedEvents.sort((a, b) => {
      return a.startTime - b.startTime;
    });

    this.loadingService.stopLoading();
  }

  private createEventForCalendar(
    day: Date,
    startSlot: any,
    endSlot: number,
    parsedEvents: any[],
    event: any,
    username: string,
    id: string,
    userIdHash: string,
    room: string
  ) {
    let startTime = new Date(
      day.getFullYear(),
      day.getMonth(),
      day.getDate(),
      startSlot / 2,
      (startSlot / 2) % 0.5 === 0 ? 0 : 30
    );

    let endTime = new Date(
      day.getFullYear(),
      day.getMonth(),
      day.getDate(),
      endSlot / 2,
      (endSlot / 2) % 0.5 === 0 ? 0 : 30
    );

    parsedEvents.push({
      title: event.name,
      startTime: startTime,
      endTime: endTime,
      allDay: false,
      id: id,
      itemid: event.belongsToId,
      locationid: event.belongsToLocation,
      roomid: event.belongsToRoom,
      orgaid: event.belongsToOrganisation,
      location: event.location,
      userid: userIdHash,
      room,
      username,
      mapUrlLocation: event.mapUrlLocation,
    });
  }

  getLocationName(id) {
    let result;
    this.locations.forEach((element) => {
      if (element._id === id) {
        result = element.name;
      }
    });
    return result;
  }

  getRoomForItem(item) {
    let roomId = item.belongsToRoom;
    let result;

    this.locations.forEach((location) => {
      location.rooms.forEach((room) => {
        if (room._id === roomId) {
          result = room.name;
        }
      });
    });

    return result;
  }

  getTimeForSlot(slot) {
    return this.timeslots[slot.slot] + ' - ' + this.timeslots[slot.slot + 1];
  }

  toggleSlots(index) {
    if (this.activeOpenSlot === null) {
      this.activeOpenSlot = index;
    } else {
      this.activeOpenSlot = null;
    }
  }

  deleteBooking(item, date) {
    this.activeItem = item;
    this.activeItem.slots.sort((a, b) => {
      return a.slot - b.slot;
    });
    this.activeDate = date;
    this.showModal = true;
    this.error = null;
  }

  confirmDelete() {
    this.error = null;
    this.isLoading = true;
    this.myBookingsService
      .deleteBooking(
        this.activeItem.belongsToId,
        this.activeDate,
        this.activeOrganisation
      )
      .then((data) => {
        this.showModal = false;

        this.getMyBookings();
        this.activeDate = null;
        this.activeItem = null;
        this.isLoading = false;
      })
      .catch((err) => {
        this.isLoading = false;
        this.error = err.error;
      });
  }

  cancelDelete() {
    this.showModal = false;
    this.activeDate = null;

    this.activeItem = null;
  }

  scrollToMe() {
    setTimeout(() => {
      var objDiv = document.getElementById('modal');
      objDiv.scrollTop = 0;
    }, 200);
  }

  onEventSelected(event) {
    localStorage.setItem('activeBooking', JSON.stringify(event));

    this.router.navigate(
      ['/o/' + this.activeOrganisation.slug + '/' + event.title.toLowerCase()],
      {
        queryParams: { origin: 'my-bookings' },
      }
    );
  }

  isToday(someDate) {
    const today = new Date();
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  }
}
