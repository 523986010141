import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GuestHashService {
  constructor() {}

  user;

  init() {
    this.getUser();
  }

  makeid() {
    let result = '';
    let characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < 32; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  getUser() {
    if (!localStorage.getItem('user')) {
      this.user = { id: this.makeid(), username: '', lastSeen: Date.now() };
      this.saveUser();
      this.getUser();
    } else {
      this.user = JSON.parse(localStorage.getItem('user'));
      this.user.lastSeen = Date.now();
      if(!this.user.id) {
        this.user.id = this.makeid();
      }
      this.saveUser();
    }
    return this.user;
  }

  saveUser() {
    localStorage.setItem('user', JSON.stringify(this.user));
  }
}
