import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APP } from './app.config';
import { OrganisationService } from './organisation.service';


export interface ILocationObserver {
  observerId: number;
  onLocationChange(locations);
}


@Injectable({
  providedIn: 'root',
})
export class LocationService {

  private observers: ILocationObserver[] = [];

  private locations;

  constructor(
    private http: HttpClient,
    private organisationService: OrganisationService
  ) {}

  getAllLocations(orgaid?) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-organisation-id': orgaid ? orgaid:  this.organisationService.getActiveOrganisations()[0]
          ?._id,
      }),
    };

    
    return this.http.get(APP.baseurl + '/location', httpOptions).toPromise();
  }

  createLocation(name, orga?) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-organisation-id': orga ? orga._id : this.organisationService.getActiveOrganisations()[0]
          ?._id,
      }),
    };

    return this.http
      .post(
        APP.baseurl + '/location',
        {
          name,
        },
        httpOptions
      )
      .toPromise();
  }

  deleteLocation(toDeleteId: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-organisation-id': this.organisationService.getActiveOrganisations()[0]
          ?._id,
      }),
    };

    return this.http
      .delete(APP.baseurl + '/location/' + toDeleteId, httpOptions)
      .toPromise();
  }

  async getLocationNameById(id,orgaid?) {
    let locations;
    let result;

   
   await this.getAllLocations(orgaid ? orgaid : null).then((data) => {
      locations = data;
     
      locations.forEach((location) => {
        if (location._id === id) {
          result = location;
         
        }
      });
    });

    return result;
  }

  updateLocation(location,orga?) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-organisation-id': orga ? orga : this.organisationService.getActiveOrganisations()[0]
          ?._id,
      }),
    };

 

    return this.http
      .patch(
        APP.baseurl + '/location/' + location._id, {name: location.name},
        
        httpOptions
      )
      .toPromise();

  }


  subscribe(observer: ILocationObserver) {
    const idx = this.observers.indexOf(observer);
    if (idx === -1) {
      this.observers.push(observer);
      
      
        observer.onLocationChange(this.locations);
      
     
    }
  }

  unsubscribe(observer: ILocationObserver) {
    let idx = -1;
    for (const obs of this.observers) {
      if (obs.observerId === observer.observerId) {
        idx = this.observers.indexOf(obs);
      }
    }
    if (idx !== -1) {
      this.observers.splice(idx, 1);
    }
  }

  setActiveLocations(locations) {
    this.locations = locations;
    this.notify();
  }


  notify() {

    this.observers.forEach((observer) => {
      observer.onLocationChange(this.locations);
    });
  }
}
