import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { APP } from './app.config';
import { GuestHashService } from './guest-hash.service';
import { OrganisationService } from './organisation.service';
import jwt_decode from "jwt-decode";

export interface IUserObserver {
  observerId: number;
  onUserChange(user);
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private guestHashService: GuestHashService,
    private organisationService: OrganisationService,
    private router: Router,
    private http: HttpClient
  ) {}
  user;
  private observers: IUserObserver[] = [];
  getUser(noNotify?) {
    if (localStorage.getItem('user')) {
      this.user = JSON.parse(localStorage.getItem('user'));
     
      this.guestHashService.init();
     
    } else {
      this.guestHashService.init();
      this.user = JSON.parse(localStorage.getItem('user'));
     
    }
    if(noNotify) {
      return this.user;
    
    }
    this.notify(this.user);
    return this.user
   
  }

  logout(autoLogout?) {
    return new Promise((resolve) => {
      this.http
        .post(APP.cidaas_revoke + this.user.token + "&post_logout_redirect_uri=" + APP.baseurl + "/auth/login", {})
        .toPromise();
      this.user.token = null;
      this.user.username = null;
      localStorage.setItem('user', JSON.stringify(this.user));
      localStorage.removeItem('lastNav');
      localStorage.removeItem('lastOrga');
      this.organisationService.clearActiveOrganisation();
      if(autoLogout) {
        console.log("auto logout")
        this.router.navigate(['/o/', { status: 'You have been automatically logged out for security reasons' }]);
      } else {
        console.log("normal logout")
        this.router.navigate(['/o/']);
      }
      resolve();
    }).then(() => {
      this.notify();
    });
  }

  register(email, password) {
    return this.http
      .post(APP.baseurl + '/auth/register', {
        username: email,
        password,
      })
      .toPromise();
  }

  login(email, password) {
    return this.http
      .post(APP.baseurl + '/auth/login', {
        username: email,
        password,
      })
      .toPromise();
  }

  subscribe(observer: IUserObserver) {
  
    const idx = this.observers.indexOf(observer);
    if (idx === -1) {
      this.observers.push(observer);
      observer.onUserChange(this.getUser(true));
  
    }
  }

  unsubscribe(observer: IUserObserver) {
    let idx = -1;
    for (const obs of this.observers) {
      if (obs.observerId === observer.observerId) {
        idx = this.observers.indexOf(obs);
      }
    }
    if (idx !== -1) {
      this.observers.splice(idx, 1);
    }
  }

  notify(user?) {

    if(!this.user && !user) {
      this.getUser();
      return;
    }
    
    this.observers.forEach((observer) => {
      observer.onUserChange(user ? user : this.user);
    });
  }


  isSuperAdmin() {
   var decoded: any = jwt_decode( this.user.token); 
   //@ts-ignore
   if (decoded.roles) {
    return decoded.roles.includes('CREWCUS_SUPERADMIN');
   }
   else {
    console.log("roles missing from token");
    let sub = decoded.sub;
    let userinfo = this.http
    .get(APP.baseurl + '/groupmgmt-srv/users/' + sub)
    .toPromise();
    console.log(userinfo);
   }
   
  }
}
