import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { QrCheckInComponent } from './qr-check-in/qr-check-in.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SurveyComponent } from './survey/survey.component';
import { TableManagerComponent } from './table-manager/table-manager.component';
import { NavbarComponent } from './utils/navbar/navbar.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { OnboardingComponent } from './onboarding/onboarding/onboarding.component';
import { HeaderInterceptor } from './HeaderInterceptor';
import { OrganisationComponent } from './admin-dashboard/organisation/organisation.component';
import { LocationComponent } from './admin-dashboard/location/location.component';
import { RoomComponent } from './admin-dashboard/room/room.component';
import { ItemsComponent } from './admin-dashboard/items/items.component';
import { MyBookingsComponent } from './dashboard/my-bookings/my-bookings.component';
import { DashboardOverviewComponent } from './admin-dashboard/dashboard-overview/dashboard-overview.component';
import { TermsOfUseComponent } from './imprint/terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './imprint/privacy-policy/privacy-policy.component';
import { MapUploadComponent } from './utils/map-upload/map-upload.component';
import { AngularFileUploaderComponent } from './utils/angular-file-upload/angular-file-upload.component';
import { QRCodeModule } from 'angular2-qrcode';
import { RequestSyncComponent } from './device-sync/request-sync/request-sync.component';
import { AcceptSyncComponent } from './device-sync/accept-sync/accept-sync.component';
import { SyncDevicesSelectionComponent } from './device-sync/sync-devices-selection/sync-devices-selection.component';
import { SyncDevicesButtonComponent } from './device-sync/sync-devices-button/sync-devices-button.component';
import { ChartComponent } from './utils/chart/chart.component';
import {
  NgcCookieConsentModule,
  NgcCookieConsentConfig,
} from 'ngx-cookieconsent';
import { OrgaSettingsComponent } from './admin-dashboard/orga-settings/orga-settings.component';
import { LoadingBarComponent } from './utils/loading-bar/loading-bar.component';
import { LocationDetailComponent } from './admin-dashboard/location/location-detail/location-detail.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { NgCalendarModule } from 'ionic2-calendar';
import { CalendarComponent } from './utils/calendar/calendar.component';
import { CreateReservationComponent } from './utils/create-reservation/create-reservation.component';
import localeDe from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';
import { SingleBookingComponent } from './dashboard/single-booking/single-booking.component';
import { TabBarComponent } from './utils/tab-bar/tab-bar.component';
import { MaintenanceComponent } from './utils/maintenance/maintenance.component';
import { SuperadminComponent } from './superadmin/superadmin.component';
import { PricingPlansComponent } from './admin-dashboard/pricing-plans/pricing-plans.component';
import { NotFoundComponent } from './utils/not-found/not-found.component';
import { RemoteOfficeAdminComponent } from './admin-dashboard/remote-office-admin/remote-office-admin.component';
import { MobileOfficeFloatingComponent } from './dashboard/mobile-office-floating/mobile-office-floating.component';
import { AvatarComponent } from './utils/avatar/avatar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmDialogComponent } from './utils/confirm-dialog/confirm-dialog.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { SelectDialogPricingComponent } from './utils/select-dialog-pricing/select-dialog-pricing.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TimelineComponent } from './utils/timeline/timeline.component';
import { MatChipsModule } from '@angular/material/chips';
import { AddHistoryDialogComponent } from './utils/add-history-dialog/add-history-dialog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ChangePlanStepperComponent } from './utils/change-plan-stepper/change-plan-stepper.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatPaginatorModule } from '@angular/material/paginator';
import { OnboardingV2Component } from './utils/onboarding-v2/onboarding-v2.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { MomentModule } from 'ngx-moment';
import * as moment from 'moment';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: window.location.hostname,
  },
  position: 'bottom',

  palette: {
    popup: {
      background: '#ffffff',
      text: '#000000',
      link: '#ffffff',
    },
    button: {
      background: 'var(--primary)',
      text: '#ffffff',
      border: 'transparent',
    },
  },
  type: 'opt-out',
  content: {
    message:
      'This website uses cookies to ensure you get the best experience on our website. Please let us know if you agree to all of these cookies.',
    dismiss: 'Got it!',
    deny: 'No, thanks',
    allow: 'Yes, I agree',
    link: 'Learn more',
    href: 'imprint/privacy-notice',
    policy: 'Cookie Policy',
  },
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

registerLocaleData(localeDe);



if (localStorage.getItem('activelang')) {
  moment.locale(JSON.parse(localStorage.getItem('activelang')));
}

// @ts-ignore
if (window.Intl && typeof window.Intl === 'object') {
  // @ts-ignore
  let lang = Intl.getCanonicalLocales(window.navigator.language)[0];

  if (lang.indexOf('-') > 0) {
    lang = lang.split('-', 1)[0];
  }

  //if (lang === 'de') {
  moment.locale(lang);
  /*} else {
            return 'en';
        }*/
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    AdminDashboardComponent,
    QrCheckInComponent,
    SurveyComponent,
    TableManagerComponent,
    NavbarComponent,
    LandingPageComponent,
    OnboardingComponent,
    OrganisationComponent,
    LocationComponent,
    RoomComponent,
    ItemsComponent,
    MyBookingsComponent,
    DashboardOverviewComponent,
    TermsOfUseComponent,
    PrivacyPolicyComponent,
    MapUploadComponent,
    AngularFileUploaderComponent,
    RequestSyncComponent,
    AcceptSyncComponent,
    SyncDevicesSelectionComponent,
    SyncDevicesButtonComponent,
    ChartComponent,
    OrgaSettingsComponent,
    LoadingBarComponent,
    LocationDetailComponent,
    CalendarComponent,
    CreateReservationComponent,
    SingleBookingComponent,
    TabBarComponent,
    MaintenanceComponent,
    SuperadminComponent,
    PricingPlansComponent,
    NotFoundComponent,
    RemoteOfficeAdminComponent,
    MobileOfficeFloatingComponent,
    AvatarComponent,
    ConfirmDialogComponent,
    SelectDialogPricingComponent,
    TimelineComponent,
    AddHistoryDialogComponent,
    ChangePlanStepperComponent,
    OnboardingV2Component,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    QRCodeModule,
    MatTabsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgcCookieConsentModule.forRoot(cookieConfig),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    NgCalendarModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatTableModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatChipsModule,
    MatCheckboxModule,
    MatStepperModule,
    MatPaginatorModule,
    MatBadgeModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatIconModule,
    MomentModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
