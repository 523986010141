<h2 mat-dialog-title>{{dialogTitle ? (dialogTitle | translate) : 'Confirm Action' | translate}}</h2>
<mat-dialog-content class="mat-typography">


    <p>{{dialogText ? (dialogText | translate): 'Completing this action will permanently alter the organisation. You only want to do this after completing all the necessary precautions.' | translate}}</p>


</mat-dialog-content>
<mat-dialog-actions align="end">
    <button *ngIf="showCancel" class="cancel-button" mat-button mat-dialog-close>{{'Cancel' | translate}} </button>
    <button class="cta-button" mat-button [mat-dialog-close]="true" cdkFocusInitial>{{cta ? (cta | translate) : 'Confirm' | translate}}</button>
</mat-dialog-actions>