import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { GoogleAnalyticsService } from 'src/app/google-analytics.service';
import { GuestHashService } from 'src/app/guest-hash.service';
import { SyncDevicesService } from '../sync-devices.service';

@Component({
  selector: 'app-accept-sync',
  templateUrl: './accept-sync.component.html',
  styleUrls: ['./accept-sync.component.scss'],
})
export class AcceptSyncComponent implements OnInit {
  @ViewChild('one') one;
  @ViewChild('two') two;
  @ViewChild('three') three;
  @ViewChild('four') four;
  @ViewChild('five') five;

  disableAll = false;
  successSync = false;
  redirectNumber = 10;
  syncFailed = false;

  interval;
  constructor(
    private syncDeviceService: SyncDevicesService,
    private guestHashService: GuestHashService,
    private location: Location,
    private GAService: GoogleAnalyticsService
  ) {}

  ngOnInit(): void {
    this.GAService.send('Accept Sync Page');
    setTimeout(() => {
      this.one.nativeElement.focus();
    }, 1);
  }

  next(num) {
    if (num === 1) {
      let number = this.one.nativeElement.value;
      let lastone = +number.toString().split('').pop();
      this.one.nativeElement.value = lastone;
      this.two.nativeElement.focus();
    } else if (num === 2) {
      let number = this.two.nativeElement.value;
      let lastone = +number.toString().split('').pop();
      this.two.nativeElement.value = lastone;
      this.three.nativeElement.focus();
    } else if (num === 3) {
      let number = this.three.nativeElement.value;
      let lastone = +number.toString().split('').pop();
      this.three.nativeElement.value = lastone;
      this.four.nativeElement.focus();
    } else if (num === 4) {
      let number = this.four.nativeElement.value;
      let lastone = +number.toString().split('').pop();
      this.four.nativeElement.value = lastone;
      this.five.nativeElement.focus();
    } else if (num === 5) {
      let number = this.five.nativeElement.value;
      let lastone = +number.toString().split('').pop();
      this.five.nativeElement.value = lastone;
      this.five.nativeElement.blur();
      this.disableAll = true;
      this.claimWithCode();
    }
  }

  back(num) {
    this.syncFailed = false;
    if (num === 1) {
      //
    } else if (num === 2) {
      this.one.nativeElement.focus();
      this.one.nativeElement.value = null;
      this.two.nativeElement.value = null;
    } else if (num === 3) {
      this.two.nativeElement.focus();
      this.two.nativeElement.value = null;
      this.three.nativeElement.value = null;
    } else if (num === 4) {
      this.three.nativeElement.focus();
      this.three.nativeElement.value = null;
      this.four.nativeElement.value = null;
    } else if (num === 5) {
      if (this.five.nativeElement.value) {
        this.five.nativeElement.value = null;
      } else {
        this.four.nativeElement.focus();
        this.four.nativeElement.value = null;
      }
    }
  }

  claimWithCode() {
    // make code

    try {
      clearInterval(this.interval);

      const code =
        '' +
        this.one.nativeElement.value +
        this.two.nativeElement.value +
        this.three.nativeElement.value +
        this.four.nativeElement.value +
        this.five.nativeElement.value;

      // claimIdByCode
      this.syncDeviceService
        .claimIdByCode(code)
        .then((data) => {
          this.syncFailed = false;
          this.successSync = false;

          if (data) {
            // got it
            // @ts-ignore
            const userid = data.userId;
             // @ts-ignore
            const username = data.alias;
            const user = this.guestHashService.getUser();
            user.id = userid;
            user.username = username;
            this.guestHashService.user = user;
            this.guestHashService.saveUser();
            this.successSync = true;

            this.interval = setInterval(() => {
              this.redirectNumber = this.redirectNumber - 1;

              if (this.redirectNumber === 0) {
                clearInterval(this.interval);
                this.location.back();
              }
            }, 1000);
          } else {
            // invalid
            this.syncFailed = true;
          }

          this.disableAll = false;
        })
        .catch((e) => {
          this.disableAll = false;
          this.syncFailed = true;
        });
    } catch (e) {
      this.disableAll = false;
      this.syncFailed = true;
      e;
    }
  }

  goBack() {
    clearInterval(this.interval);
    this.syncDeviceService.setSelection(null);
    
  }
}
