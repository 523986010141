<div class="content flex-column">
    <h1 style="text-align: center;" *ngIf="!waitForCode">Sync Pin</h1>
    <span class="advice">Enter the generated PIN on the second device you want to syncronize.</span>
    <div *ngIf="!waitForCode" class="pin" [ngClass]="{'expired': expireDate <= 0}">
        <span *ngFor="let number of pin">{{number}}</span>

    </div>

    <i *ngIf="waitForCode || pin.length === 0" class="fas fa-sync fa-spin fa-3x"></i>

    <ng-container *ngIf="waitForCode">
        <span class="newCodeAdvice">A new code is being generated.<br>This make that up to a minute to complete.</span>
    </ng-container>
    <ng-container *ngIf="expireDate >=0 ">
        <p *ngIf="expireDate > 0">Expires in</p>

        <span *ngIf="expireDate >= 0" style="font-size:2em">{{expireDateString}}</span>
        <progress *ngIf="!waitForCode" value="{{progressPercent}}" max="100"></progress>
    </ng-container>
</div>

<p (click)="back()" class="go-back">I want to go back</p>