<div _ngcontent-dmb-c150="" class="flex column">



    <div class="tab">
        <button (click)="activeTabIndex = 0" [ngClass]="{'active': activeTabIndex === 0}" class="tablinks">{{'Organisations' | translate}}</button>
        <button (click)="setTabIndex(1)" [ngClass]="{'active': activeTabIndex === 1}" class="tablinks">{{'Pricing Plans' | translate}}</button>
        <button (click)="addNewDummyOrganisation()" *ngIf="isNightly()" [ngClass]="{'active': activeTabIndex === 2}" class="tablinks" style="float:right">{{'Create Demo Data' | translate}}</button>
    </div>

    <div *ngIf="activeTabIndex === 0" class="tabcontent">

        <input (change)="results = null" [disabled]="isLoading" (keyup.enter)="searchOrganisation()" [(ngModel)]="searchInput" class="search" type="text" placeholder="{{'Organisation or E-Mail' | translate}}" />

        <button [disabled]="isLoading" (click)="searchOrganisation()" class="add-button"><i class="fas fa-search" style="margin-right: 10px;" aria-hidden="true"></i><span _ngcontent-gns-c150="">{{'Search' | translate}}</span></button>
        <img *ngIf="isLoading" class="spin" src="../../../assets/img/loading.svg" style="width: 20px;
        padding-top: 0;
        filter: brightness(0.3);">
        <p *ngIf="error" style="color:red;">{{error.error.message}}</p>

        <div *ngIf="results" class="results">
            <h2 *ngIf="searchInput">Found {{results?.length}} results for Search '{{searchInput}}'. </h2>
            <h2 *ngIf="!searchInput">{{'Last 25 Organisations created' | translate}} (Total Organisations: {{organisations?.length}}):</h2>

            <div class="flex" style="flex-wrap: wrap;justify-content: flex-start;">
                <div class="orga-list-item" (click)="activeOrga = result._id" *ngFor="let result of results">
                    <p class="pill" [ngClass]="{'enterprise': result.plan !== 'proPlan' && result.plan !== 'freePlan' && result.plan !== 'pro-plan','paid': result.plan !== 'freePlan', 'expired': result.planExpireDate && days_between(result.planExpireDate) === -1 }">{{result.plan}}</p>
                    <p style="margin-block-start: -10px;     white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;   font-size: 18px;" [title]="result.organisationName">{{result.organisationName}}</p>
                    <p style="font-size: 12px;
                margin-block-start: 0;
                color: var(--third);">{{'Owner' | translate}}: {{result.owner[0]?.mail}} </p>
                    <p style="font-size: 12px;
                margin-block-start: 0;
                color: var(--pink);">{{'Created' | translate}}: {{getDate(result._id) | date: 'short':undefined:lang }}</p>
                    <p style="font-size: 12px;
                 margin-block-start: 0;
                 ">{{'Expires' | translate}}: <span *ngIf="result.planExpireDate">{{result.planExpireDate | date: 'short':undefined:lang }} <span *ngIf="days_between(result.planExpireDate) > 0">({{days_between(result.planExpireDate)}} {{'Days left' | translate}})</span>
                        <span *ngIf="days_between(result.planExpireDate) === -1" style="color:red">({{'Expired' | translate}})</span>
                        </span>
                        <span *ngIf="!result.planExpireDate">{{'No Expiration' | translate}}</span>
                    </p>
                    <p style="font-size:12px;margin-block-start: 0;">
                        {{'Last Admin Activity' | translate}} <span *ngIf="result.lastAdminActivity; else unknownadmin">{{result.lastAdminActivity  | amTimeAgo}}</span>
                        <ng-template #unknownadmin>{{'Unknown' | translate}}</ng-template>
                    </p>
                    <p style="font-size:12px;margin-block-start: 0;">
                        {{'Last User Activity' | translate}} <span *ngIf="result.lastUserActivity ;else unknownuser">{{result.lastUserActivity | amTimeAgo}}</span>
                        <ng-template #unknownuser>{{'Unknown' | translate}}</ng-template>
                    </p>


                    <div *ngIf="activeOrga === result._id">
                        <div class="flex column">
                            <label style="margin-right: 15px;">{{'Change' | translate}} {{result.plan}} {{'to' | translate}}:</label>
                            <select #t [(ngModel)]="selectedOption" style="margin-top:15px">
                      <option  *ngFor="let plan of plans" [value]="plan.internalId">{{plan.name}}(Max. Bookables:{{plan.bookableMaxCount}}<span *ngIf="plan.note">| {{'Note' | translate}}: {{plan.note}}</span>)</option>
                  
                    </select>
                            <label style="margin-right: 15px;margin-top:15px">{{'Expires' | translate}}</label>
                            <input style="    width: 100%; 
                        height: 30px;" type="date" [disabled]="checkboxExpireCheck" [valueAsDate]="expirePlanDate" (change)="expirePlanDate = $event.target.valueAsDate">
                            <div style="margin-top:15px">

                                <label style="margin-right: 15px;margin-top:15px"> <input type="checkbox" [checked]="checkboxExpireCheck" (change)="checkboxExpireCheck = !checkboxExpireCheck"> {{'This plan has no expiration date' | translate}}</label>
                            </div>
                            <button class="cta-button" style="margin-left:0px;background:var(--secondary);font-weight: normal;" (click)="changePlanInOrga(result,selectedOption)">{{'Save New Plan' | translate}}</button>

                            <button class="cta-button" style="margin-left:0px; background: var(--pink);font-weight: normal;" (click)="setActiveOrga(result)">{{'Set Active Organisation' | translate}}</button>

                            <button class="cta-button" style="margin-left:0px;background:var(--accent);font-weight: normal;" (click)="deleteOrganisationModal(result)">{{'Delete Organisation' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="activeTabIndex === 1" class="tabcontent">
        <div class="flex">
            <h2>{{'All Pricing Plans' | translate}}</h2> <button [disabled]="isLoading" (click)="addNewPlan()" class="add-button"><i class="fas fa-plus" style="margin-right: 10px;" aria-hidden="true"></i><span _ngcontent-gns-c150="">{{'Add New' | translate}}</span></button>
        </div>
        <div class="flex" style="flex-wrap: wrap;justify-content: flex-start;">
            <div class="orga-list-item" *ngFor="let plan of plans" (click)="editPlan(plan)" style="margin-top:25px;margin-bottom:10px;">
                <p class="pill" style="margin-block-start: -10px;     white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis; background: var(--secondary);  font-size: 18px;margin-block-end:0">{{plan.name}} </p>
                <p style="font-size: 12px;
                   margin-block-start: 0;
                   color: var(--third);">{{'Created' | translate}}: {{getDate(plan._id) | date: 'short':undefined:lang }}</p>
                <p style="font-size: 12px;
            margin-block-start: 0;display:flex;
            color: var(--secondary);"><span style="flex:1">Max. Bookables:</span> <span style="flex: 0 0 30%;text-align: right;">{{plan.bookableMaxCount}}</span> </p>

                <p style="font-size: 12px;
             margin-block-start: 0;display:flex;
             color: var(--secondary);" *ngFor="let feature of featureList"><span style="flex:1">{{feature.name | translate}} </span><span *ngIf="hasPlanFeature(plan.features,feature);else not"><i class="fa fa-check" style="    color: var(--accent) !important;" aria-hidden="true"></i> </span>
                    <ng-template #not><i class="fa fa-times" style="    color: red !important;-webkit-text-stroke-color: red !important;" aria-hidden="true"></i></ng-template>
                </p>
                <p *ngIf="plan.note" style="font-size: 12px;
   margin-block-start: 0;
   color: var(--accent);">{{'Note' | translate}}: {{plan.note}} </p>



            </div>
        </div>
    </div>

    <div *ngIf="activeTabIndex === 2" class="tabcontent">
        <div class="flex">
            <h2>{{'Create Demo Organisations with or without Dummy Data' | translate}}</h2>

        </div>
        <div class="flex">
            <button style="margin-left:0px" [disabled]="isLoading" (click)="addNewDummyOrganisation()" class="add-button"><i class="fas fa-plus" style="margin-right: 10px;" aria-hidden="true"></i><span _ngcontent-gns-c150="">{{'Create Dummy Organisation' | translate}}</span></button>
        </div>
    </div>

</div>


<div class="modal" [ngClass]="{'active': modalActive}">

    <div class="content">
        <i *ngIf="!isLoading" class="fas fa-times" (click)="modalActive = false"></i>
        <div class="flex column overflow-hidden" style="padding-left:25px">


            <div class="slider" id="onboarding-slider">
                <div class="flex column step" *ngIf="!showDeleteModal">
                    <span class="subtitle" style="text-align: left;">{{'Create new Pricing Plan' | translate}}</span>

                    <span class="description">{{'Pricing Plan Name' | translate}}:</span>
                    <input [(ngModel)]="pricingPlanName" type="text" placeholder="Pricing Plan Name">

                    <span class="description">{{'Maximum Bookables' | translate}}:</span>
                    <input [(ngModel)]="pricingPlanBookables" type="number" placeholder="Max. Bookables">

                    <p style="font-size: 12px;
                    margin-block-start: 0;display:flex;
                    color: var(--secondary);" *ngFor="let feature of featureList"><span style="flex:1;    position: relative;
    top: 22px;">{{feature.name | translate}} </span><input style="flex: 0 0 30%;
    width: 15% !important;
    height: 20px;
    position: relative;
    margin-top: 20px;" type="checkbox" (change)="addToPlan(feature,$event)" [checked]="hasPlanFeature(activeFeaturePlan,feature)"> </p>



                    <span class="description">{{'Notes' | translate}} (Optional):</span>
                    <input [(ngModel)]="pricingPlanNotes" type="text" placeholder="Example: 25$/Month">



                    <span *ngIf="error" class="description errorText">{{error}}</span>
                </div>





            </div>

            <button style="margin: 10px 0px;
            border: 1px solid #f35656;
            background: white;
            color: #f35656;
            margin-top: 25px;
            margin-bottom: 50px;" (click)="deletePlan()" *ngIf="activePlanEdit">{{'Delete Plan' | translate}}</button>

            <button style="margin:10px 0px" class="create" [ngClass]="{'delete-button': activePlanEdit}" [disabled]="isLoading || !pricingPlanName" (click)="!activePlanEdit ? createNewPlan() : updatePlan()">
                <ng-container *ngIf="!isLoading">

                    <span *ngIf="!activePlanEdit">{{'Create Plan' | translate}}</span>
                    <span *ngIf="activePlanEdit">{{'Save Plan' | translate}}</span>

                </ng-container>
                <img  *ngIf="isLoading" class="spin" src="../../../assets/img/loading.svg" style="width:20px;margin-top:0">
            </button>

        </div>
    </div>
    <div class="backdrop"></div>
</div>


<div class="modal" [ngClass]="{'active': modalDummyActive}">

    <div class="content">
        <!-- <i *ngIf="!isLoading" class="fas fa-times" (click)="modalDummyActive = false"></i> -->
        <div class="flex column overflow-hidden" style="padding-left:25px">

            <img *ngIf="populating" src="../../assets/img/loading-clock.svg" style="    width: 75px;
            align-self: center;">
            <img *ngIf="!populating" src="../../assets/img/done-simple.svg" style="    width: 75px;
             align-self: center;">

            <div *ngIf="populating" class="flex" style="align-self: center">
                <h2>{{dummyLoadingText | translate}}...</h2>
            </div>

            <div *ngIf="!populating && dummyLoadingText !== 'There was an error'" class="flex" style="align-self: center">
                <h2 style="text-align: center;">{{'Dummy Organisation created successfully' | translate}}</h2>
            </div>

            <div *ngIf="!populating && dummyLoadingText === 'There was an error'" class="flex" style="align-self: center">
                <h2 style="text-align: center;">{{'There was an error' | translate}}</h2>
            </div>






            <button style="margin:10px 0px" class="create" *ngIf="!populating" (click)="modalDummyActive = false">
                

                    <span >{{'Ok' | translate}}</span>
                 

             
               
            </button>

        </div>
    </div>
    <div class="backdrop"></div>
</div>