import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { APP } from 'src/app/app.config';
import { DashboardService } from 'src/app/dashboard.service';
import { GuestHashService } from 'src/app/guest-hash.service';
import {
  IOrganisationObserver,
  OrganisationService,
} from 'src/app/organisation.service';
import { UserService } from 'src/app/user.service';

@Component({
  selector: 'app-create-reservation',
  templateUrl: './create-reservation.component.html',
  styleUrls: ['./create-reservation.component.scss'],
})
export class CreateReservationComponent
  implements OnInit, IOrganisationObserver
{
  @Output()
  onClose = new EventEmitter();

  @Input()
  activeLocation;

  @Input()
  username;

  @Input()
  bookedSlotsForActiveDay;

  @Input()
  activeDate;

  @Input()
  isMobileOffice;

  bookWholeDay = true;
  step = 1;

  error;
  selectedItem;

  checkInHour = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23,
  ];
  checkInMinute = ['00', 30];

  currentStartHourIndex;

  currentStartMinuteIndex = 0;
  currentEndHourIndex;
  currentEndMinuteIndex = 0;
  AMPMSwitch = 'AM';
  AMPMSwitchEnd = 'AM';

  showMapPicture = false;

  constructor(
    private organisationService: OrganisationService,
    private dashboardService: DashboardService,
    private guestService: GuestHashService,
    private userService: UserService,
    private router: Router,
    private http: HttpClient
  ) {}
  observerId: number = 12828233;
  activeOrganisation;

  isEnglishFormat = false;

  availableItems = [];

  async getMobileOfficeBookables() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-organisation-id': this.activeOrganisation._id,
      }),
    };

    return this.http
      .get(APP.baseurl + '/mobileOffice', httpOptions)
      .toPromise();
  }

  generateAvailableItems() {
    let allItems = [];

    if (this.isMobileOffice) {
      return;
    }

    //get all bookable items
    this.activeLocation.rooms.forEach((room) => {
      room.bookableItems.forEach((bookable) => {
        allItems.push(bookable);
      });
    });

    //get start end end date of booking
    let startSlot;
    let endSlot;
    if (!this.bookWholeDay) {
      startSlot = this.checkInHour[this.currentStartHourIndex] * 2;
      endSlot = this.checkInHour[this.currentEndHourIndex] * 2;
    } else {
      startSlot =
        this.activeOrganisation.dayBeginAtSlot +
        this.getCurrentHourIndex(this.checkInHour);
      endSlot = this.activeOrganisation.dayEndsAtSlot;
    }

    this.bookedSlotsForActiveDay.forEach((bookedItem) => {
      allItems.forEach((item, sindex) => {
        if (bookedItem.belongsToId === item._id) {
          bookedItem.blockedSlots.forEach((blockedSlot) => {
            for (let index = startSlot; index < endSlot; index++) {
              if (
                blockedSlot.slot === index &&
                allItems.filter((e) => e._id === bookedItem.belongsToId)
                  .length > 0
              ) {
                allItems.splice(sindex, 1);
              }
            }
          });
        }
      });
    });

    //set allItems which are free by rooms

    this.availableItems = allItems;
  }

  onOrganisationChange(organisations: any) {
    if (organisations.length > 0) {
      const slug = this.router.url.slice(3, this.router.url.length);
      organisations.forEach((orga, index) => {
        if (orga.slug === slug) {
          this.activeOrganisation = organisations[index];

          //orga settings begin and end slot needs to be done here
          this.checkInHour = this.checkInHour.slice(
            this.activeOrganisation.dayBeginAtSlot / 2,
            Math.ceil(this.activeOrganisation.dayEndsAtSlot / 2) + 1
          );
          this.currentStartHourIndex = this.getCurrentHourIndex(
            this.checkInHour
          );
          this.currentEndHourIndex =
            this.getCurrentHourIndex(this.checkInHour) + 1;
        }
      });
    }
  }

  ngOnInit(): void {
    const language = this.getLang();

    switch (language) {
      case 'en':
        this.isEnglishFormat = true;
        break;
    }

    this.organisationService.subscribe(this);

    const user = JSON.parse(localStorage.getItem('user'));
    this.username = user.username;

    if (this.isMobileOffice) {
      this.availableItems = [];

      this.getMobileOfficeBookables().then((data) => {
        //@ts-ignore
        data.forEach((element) => {
          this.availableItems.push(element);
        });
      });

      return;
    }

    this.getSlotsByLocationAndDate();
  }

  nextHour(mode) {
    if (mode === 'start-hour') {
      if (this.currentStartHourIndex + 1 < this.checkInHour.length - 1) {
        this.currentStartHourIndex++;
      } else {
        this.currentStartHourIndex = 0;
      }
    } else if (mode === 'start-minute') {
      if (
        this.currentStartMinuteIndex + 1 < this.checkInMinute.length &&
        this.currentStartHourIndex + 1 !== this.checkInHour.length
      ) {
        this.currentStartMinuteIndex++;
      } else {
        this.currentStartMinuteIndex = 0;
      }
    } else if (mode === 'end-hour') {
      if (this.currentEndHourIndex + 1 < this.checkInHour.length) {
        this.currentEndHourIndex++;
      } else {
        this.currentEndHourIndex = 0;
      }
    } else if (mode === 'end-minute') {
      if (
        this.currentEndMinuteIndex + 1 < this.checkInMinute.length &&
        this.currentEndHourIndex + 1 < this.checkInHour.length - 1
      ) {
        this.currentEndMinuteIndex++;
      } else {
        this.currentEndMinuteIndex = 0;
      }
    }
    if (this.checkInHour[this.currentStartHourIndex] >= 12) {
      this.AMPMSwitch = 'PM';
    } else {
      this.AMPMSwitch = 'AM';
    }
    if (this.checkInHour[this.currentEndHourIndex] >= 12) {
      this.AMPMSwitchEnd = 'PM';
    } else {
      this.AMPMSwitchEnd = 'AM';
    }

    if (
      this.currentStartHourIndex === this.currentEndHourIndex &&
      this.currentStartMinuteIndex >= this.currentEndMinuteIndex
    ) {
      if (this.currentEndHourIndex + 1 < this.checkInHour.length) {
        this.currentEndHourIndex++;
      } else {
        this.currentEndHourIndex = 0;
      }
    }
  }

  previousHour(mode) {
    if (mode === 'start-hour') {
      if (this.currentStartHourIndex - 1 >= 0) {
        this.currentStartHourIndex--;
      } else {
        this.currentStartHourIndex = this.checkInHour.length - 2;
      }
    } else if (mode === 'start-minute') {
      if (
        this.currentStartMinuteIndex - 1 >= 0 &&
        this.currentStartHourIndex + 1 !== this.checkInHour.length
      ) {
        this.currentStartMinuteIndex--;
      } else {
        this.currentStartMinuteIndex = this.checkInMinute.length - 1;
      }
    } else if (mode === 'end-hour') {
      if (this.currentEndHourIndex - 1 >= 0) {
        this.currentEndHourIndex--;
      } else {
        this.currentEndHourIndex = this.checkInHour.length - 1;
        if (this.currentEndHourIndex === this.checkInHour.length - 1) {
          this.currentEndMinuteIndex = 0;
        }
      }
    } else if (mode === 'end-minute') {
      if (
        this.currentEndMinuteIndex - 1 >= 0 &&
        this.currentEndHourIndex !== this.checkInHour.length - 1
      ) {
        this.currentEndMinuteIndex--;
      } else {
        if (this.currentEndHourIndex === this.checkInHour.length - 1) {
          return;
        }
        this.currentEndMinuteIndex = this.checkInMinute.length - 1;
      }
    }

    if (this.checkInHour[this.currentStartHourIndex] >= 12) {
      this.AMPMSwitch = 'PM';
    } else {
      this.AMPMSwitch = 'AM';
    }
    if (this.checkInHour[this.currentEndHourIndex] >= 12) {
      this.AMPMSwitchEnd = 'PM';
    } else {
      this.AMPMSwitchEnd = 'AM';
    }
  }

  getCurrentHourIndex(arr) {
    const now = new Date();

    let index = 0;

    arr.forEach((el, i) => {
      if (el === now.getHours()) {
        index = i;
      }
    });

    this.currentStartHourIndex = index;

    if (this.checkInHour[index] > 12) {
      this.AMPMSwitch = 'PM';
    } else {
      this.AMPMSwitch = 'AM';
    }

    return index;
  }

  getPreviousHour(arr, index) {
    if (index > 0) {
      return arr[index - 1];
    } else {
      return arr[arr.length - 1];
    }
  }

  getNextHour(arr, index) {
    if (index < arr.length - 1) {
      return arr[index + 1];
    } else {
      return arr[0];
    }
  }

  close() {
    this.onClose.emit({
      activeDate: this.activeDate,
    });
  }

  setActiveDate(event) {
    if (this.activeDate !== new Date(event).toISOString().substring(0, 10)) {
      this.activeDate = new Date(event).toISOString().substring(0, 10);
      this.getSlotsByLocationAndDate();
    }
  }

  getSlotsByLocationAndDate() {
    this.dashboardService
      .getSlotsByLocationAndDate(
        this.activeLocation._id,
        this.activeOrganisation._id,
        this.activeDate
      )
      .then((data) => {
        this.bookedSlotsForActiveDay = data;
      })
      .catch(() => {
        // todo err
      });
  }

  nextStep() {
    this.error = null;
    if (this.step < 4) {
      if (this.step === 1) {
        this.getSlotsByLocationAndDate();
      }
      if (this.step === 1 && this.bookWholeDay) {
        this.step = 3;
      } else {
        this.step++;
      }
      if (this.step === 3) {
        this.generateAvailableItems();
      }
    } else {
      this.bookNow();
    }
  }

  previousStep() {
    if (this.step > 1) {
      if (this.step === 3 && this.bookWholeDay) {
        this.step = 1;
      } else {
        this.step--;
      }
    }
  }

  setAMPM(val) {
    this.AMPMSwitch = val;
  }

  getLang() {
    // @ts-ignore
    if (window.Intl && typeof window.Intl === 'object') {
      // @ts-ignore
      let lang = Intl.getCanonicalLocales(window.navigator.language)[0];

      if (lang.indexOf('-') > 0) {
        lang = lang.split('-', 1)[0];
      }

      if (localStorage.getItem('activelang')) {
        return JSON.parse(localStorage.getItem('activelang'));
      }

      //if (lang === 'de') {
      return lang;
      /*} else {
                return 'en';
            }*/
    }
    return 'en';
  }

  setActiveItem(item) {
    this.selectedItem = item;
  }

  showMap(bool) {
    this.showMapPicture = bool;
  }

  bookNow() {
    this.error = null;
    //booking date
    this.activeDate = new Date(this.activeDate).toISOString().substring(0, 10);

    //is whole day

    //username

    //get slots to book
    let slots = this.getBookingSlotsForBackend(
      this.bookWholeDay,
      this.checkInHour[this.currentStartHourIndex],
      this.checkInMinute[this.currentStartMinuteIndex],
      this.checkInHour[this.currentEndHourIndex],
      this.checkInMinute[this.currentEndMinuteIndex]
    );

    const userid = this.guestService.getUser().id;

    const user = JSON.parse(localStorage.getItem('user'));
    user.username = this.username;
    localStorage.setItem('user', JSON.stringify(user));
    this.userService.user = user;
    this.userService.notify();

    if (this.isMobileOffice) {
      //http://localhost:3000/mobileOffice/block

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'x-organisation-id': this.activeOrganisation._id,
        }),
      };

      const data = {
        userId: userid,
        blockedOnDay: this.activeDate,
        belongsToId: this.selectedItem._id,
        userName: this.username,
      };

      this.http
        .post(APP.baseurl + '/mobileOffice/block', data, httpOptions)
        .toPromise()
        .then((data) => {
          this.step = 999;
        })
        .catch((err) => {
          // todo err
          this.error = err.error.message;
        });

      return;
    }

    this.dashboardService
      .blockBookable(
        userid,
        this.activeLocation._id,
        this.activeDate,
        slots,
        this.selectedItem._id,
        this.username,
        this.activeOrganisation._id
      )
      .then((data) => {
        this.step = 999;
      })
      .catch((err) => {
        // todo err
        this.error = err.error.message;
      });
  }

  getBookingSlotsForBackend(
    isWholeDay,
    startHour?,
    startMin?,
    endHour?,
    endMin?
  ) {
    let slots = [];

    if (isWholeDay) {
      for (
        let index = this.activeOrganisation.dayBeginAtSlot;
        index <= this.activeOrganisation.dayEndsAtSlot;
        index++
      ) {
        slots.push(index);
      }
      return slots;
    } else {
      let start = startHour * 2 + (startMin === 30 ? 1 : 0);
      let end = endHour * 2 + (endMin === 30 ? 1 : 0);

      for (let index = start; index < end; index++) {
        slots.push(index);
      }

      return slots;
    }
  }

  areBookablesDeactivated() {
    let isDisabled = false;

    this.availableItems.forEach((element) => {
      if (element._id === null) {
        isDisabled = true;
      }
    });

    return isDisabled;
  }
}
