import { Component, OnInit } from '@angular/core';
import {
  IOrganisationObserver,
  OrganisationService,
} from 'src/app/organisation.service';
import { OrgaSettingsService } from 'src/app/orga-settings.service';
import isEqual from 'lodash.isequal';
import { LoadingService } from 'src/app/loading.service';
@Component({
  selector: 'app-orga-settings',
  templateUrl: './orga-settings.component.html',
  styleUrls: ['./orga-settings.component.scss'],
})
export class OrgaSettingsComponent implements OnInit, IOrganisationObserver {
  constructor(
    private organisationService: OrganisationService,
    private orgaSettingsService: OrgaSettingsService,
    private loadingService: LoadingService
  ) {}
  observerId = 100101;
  activeOrganisation;
  settings;
  oldSettings;
  timeslots = [
    '12:00 AM',
    '12:30 AM',
    '1:00 AM',
    '1:30 AM',
    '2:00 AM',
    '2:30 AM',
    '3:00 AM',
    '3:30 AM',
    '4:00 AM',
    '4:30 AM',
    '5:00 AM',
    '5:30 AM',
    '6:00 AM',
    '6:30 AM',
    '7:00 AM',
    '7:30 AM',
    '8:00 AM',
    '8:30 AM',
    '9:00 AM',
    '9:30 AM',
    '10:00 AM',
    '10:30 AM',
    '11:00 AM',
    '11:30 AM',
    '12:00 PM',
    '12:30 PM',
    '1:00 PM',
    '1:30 PM',
    '2:00 PM',
    '2:30 PM',
    '3:00 PM',
    '3:30 PM',
    '4:00 PM',
    '4:30 PM',
    '5:00 PM',
    '5:30 PM',
    '6:00 PM',
    '6:30 PM',
    '7:00 PM',
    '7:30 PM',
    '8:00 PM',
    '8:30 PM',
    '9:00 PM',
    '9:30 PM',
    '10:00 PM',
    '10:30 PM',
    '11:00 PM',
    '11:30 PM',
  ];
  timeslotsEnd = [
    '12:30 AM',
    '1:00 AM',
    '1:30 AM',
    '2:00 AM',
    '2:30 AM',
    '3:00 AM',
    '3:30 AM',
    '4:00 AM',
    '4:30 AM',
    '5:00 AM',
    '5:30 AM',
    '6:00 AM',
    '6:30 AM',
    '7:00 AM',
    '7:30 AM',
    '8:00 AM',
    '8:30 AM',
    '9:00 AM',
    '9:30 AM',
    '10:00 AM',
    '10:30 AM',
    '11:00 AM',
    '11:30 AM',
    '12:00 PM',
    '12:30 PM',
    '1:00 PM',
    '1:30 PM',
    '2:00 PM',
    '2:30 PM',
    '3:00 PM',
    '3:30 PM',
    '4:00 PM',
    '4:30 PM',
    '5:00 PM',
    '5:30 PM',
    '6:00 PM',
    '6:30 PM',
    '7:00 PM',
    '7:30 PM',
    '8:00 PM',
    '8:30 PM',
    '9:00 PM',
    '9:30 PM',
    '10:00 PM',
    '10:30 PM',
    '11:00 PM',
    '11:30 PM',
    '12:00 AM',
  ];
  errorText;
  updateJson = {};
  isLoading = false;
  success;
  onOrganisationChange(organisations: any) {
    if (organisations?.length > 0) {
      this.activeOrganisation = organisations[0];
      if (!this.hasChanges()) {
        this.loadOrganisationSettings();
      }
    }
  }

  ngOnInit(): void {
    this.organisationService.subscribe(this);
  }

  loadOrganisationSettings() {
    this.loadingService.startLoading();
    this.orgaSettingsService
      .getOrganisationSettings(this.activeOrganisation._id)
      .then((data) => {
        // @ts-ignore
        if (data.length > 0) {
          this.settings = data[0].settings;
        }
        this.loadingService.stopLoading();
      })
      .catch((err) => {
        // todo error
        this.loadingService.stopLoading();
      });
  }

  changeStart(updateKey, val) {
    // tslint:disable-next-line: radix
    let num = parseInt(val);
    var obj = {
      [updateKey]: num,
    };
    this.toUpdateJSON(obj);
  }

  ngOnDestroy(): void {
 this.organisationService.unsubscribe(this);
    
  }

  changeEnd(updateKey, val) {
    // tslint:disable-next-line: radix
    let num = parseInt(val);
    var obj = {
      [updateKey]: num,
    };
    this.toUpdateJSON(obj);
  }

  changeBool(updateKey, bool) {
    var obj = {
      [updateKey]: bool,
    };
    this.toUpdateJSON(obj);
  }

  changeString(updateKey, string) {
    var obj = {
      [updateKey]: string,
    };
    if (string.length > 0) {
      this.toUpdateJSON(obj);
    }
  }

  toUpdateJSON(newItem) {
    this.success = null;
    this.updateJson = { ...this.updateJson, ...newItem };
  }

  hasChanges() {
    return Object.keys(this.updateJson).length > 0;
  }
  saveSettings() {
    this.loadingService.startLoading();
    this.errorText = null;
    this.orgaSettingsService
      .saveSettings(this.activeOrganisation._id, this.updateJson)
      .then((data) => {
        this.organisationService.activeOrganisations[0] = data;
        this.organisationService.notify();
        this.loadingService.stopLoading();
        this.success = 'success';
        this.updateJson = {};
      })
      .catch((err) => {
        this.loadingService.stopLoading();
        this.success = 'failed';
        this.errorText = err.error.message
      });
  }
}
