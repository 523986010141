import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { APP } from 'src/app/app.config';
import { LoadingService } from 'src/app/loading.service';
import {
  IOrganisationObserver,
  OrganisationService,
} from 'src/app/organisation.service';
import { UserService } from 'src/app/user.service';
import { AddHistoryDialogComponent } from 'src/app/utils/add-history-dialog/add-history-dialog.component';
import { ChangePlanStepperComponent } from 'src/app/utils/change-plan-stepper/change-plan-stepper.component';
import { ConfirmDialogComponent } from 'src/app/utils/confirm-dialog/confirm-dialog.component';
import { SelectDialogPricingComponent } from 'src/app/utils/select-dialog-pricing/select-dialog-pricing.component';

@Component({
  selector: 'app-pricing-plans',
  templateUrl: './pricing-plans.component.html',
  styleUrls: ['./pricing-plans.component.scss'],
})
export class PricingPlansComponent implements OnInit, IOrganisationObserver {
  detailsForm = new FormGroup({
    gender: new FormControl('', [Validators.required, Validators.minLength(1)]),
    firstName: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(30),
    ]),
    lastName: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(30),
    ]),
    companyName: new FormControl('', []),
    taxId: new FormControl('', []),
    street: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(30),
    ]),
    zip: new FormControl('', [
      Validators.required,
      Validators.minLength(4),
      Validators.maxLength(5),
    ]),
    city: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(30),
    ]),
    country: new FormControl('', [
      Validators.minLength(1),
      Validators.required,
    ]),
    billingMail: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
      Validators.email,
    ]),
  });

  timelineData;

  userplan;
  activePlanTitle = '';
  displayedColumns: string[] = ['name', 'status', 'planExpireDate', 'action'];
  pricingPlansCopy;
  pricingplans = [
    {
      title: 'free plan',
      price: 0,
      locations: 'Unlimited',
      rooms: 'Unlimited',
      bookables: 10,
      statistics: 'Standard',
      support: false,
      featureRequests: false,
      supportedLanguages: 2,
      sso: false,
      color: '#000',
      isActive: true,
      homeoffice: false,
    },
    {
      title: 'pro plan',
      price: 99,
      locations: 'Unlimited',
      rooms: 'Unlimited',
      bookables: 75,
      statistics: 'Standard',
      support: 'E-Mail',
      featureRequests: true,
      supportedLanguages: 2,
      sso: false,
      color: '#bb6fce',
      isActive: false,
      homeoffice: true,
    },
    {
      title: 'enterprise plan',
      price: 'Request',
      locations: 'Unlimited',
      rooms: 'Unlimited',
      bookables: 'Unlimited',
      statistics: 'Custom',
      support: 'E-Mail',
      featureRequests: true,
      supportedLanguages: 2,
      sso: true,
      color: '#ffd000',
      isActive: false,
      homeoffice: true,
    },
  ];

  lang;

  constructor(
    private dialog: MatDialog,
    private http: HttpClient,
    private organisationService: OrganisationService,
    public loadingService: LoadingService,
    private translateService: TranslateService,
    public userService: UserService,
    private ref: ChangeDetectorRef
  ) {}

  observerId: number = 32828;
  activeOrga;

  onOrganisationChange(organisations: any) {
    if (!this.pricingPlansCopy) {
      this.pricingPlansCopy = JSON.parse(JSON.stringify(this.pricingplans));
    }

    if (organisations && organisations.length > 0) {
      this.pricingplans = JSON.parse(JSON.stringify(this.pricingPlansCopy));
      this.getUserPlan(organisations[0]);
      this.getAddress(organisations[0]);
      this.getOrderHistory(organisations[0]);
      this.activeOrga = organisations[0];
      this.getFeatureList();
    }
  }

  ngOnInit(): void {
    this.organisationService.subscribe(this);
    this.loadingService.startLoading();

    this.lang = this.getLang();
  }

  getLang() {
    // @ts-ignore
    if (window.Intl && typeof window.Intl === 'object') {
      // @ts-ignore
      let lang = Intl.getCanonicalLocales(window.navigator.language)[0];

      if (lang.indexOf('-') > 0) {
        lang = lang.split('-', 1)[0];
      }

      //if (lang === 'de') {
      return lang;
      /*} else {
                return 'en';
            }*/
    }
    return 'en';
  }

  ngOnDestroy(): void {
    this.organisationService.unsubscribe(this);
  }

  async getUserPlan(organisation) {
    this.loadingService.startLoading();
    ///organisation/usedPlan

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-organisation-id': organisation._id,
      }),
    };

    this.userplan = [
      await this.http
        .get(APP.baseurl + '/organisation/usedPlan', httpOptions)
        .toPromise(),
    ];

    let foundPlan = false;

    // this.pricingplans.forEach((plan) => {
    //   if (
    //     plan.bookables === this.userplan[0].bookableMaxCount ||
    //     (this.userplan[0].name === 'freePlan' && plan.title === 'free plan')
    //   ) {
    //     plan.isActive = true;

    //     //  plan.title = this.userplan.name;
    //     foundPlan = true;
    //     this.activePlanTitle = plan.title;
    //     plan.bookables = this.userplan[0].bookableMaxCount;
    //   } else {

    //     if (
    //       plan.bookables === this.userplan[0].bookableMaxCount ||
    //       (this.userplan[0].name === 'proPlan' && plan.title === 'pro plan')
    //     ) {

    //       foundPlan = true;
    //       this.activePlanTitle = plan.title;
    //       plan.bookables = this.userplan[0].bookableMaxCount;
    //     } else {
    //       //enterprise
    //       this.activePlanTitle = this.pricingplans[2].title;
    //     }

    //   }
    // });

    this.loadingService.stopLoading();
  }

  showCancelSubscription() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        showCancel: false,
        cta: this.translateService.instant('Okay'),
        dialogTitle: this.translateService.instant('Information'),
        dialogText: this.translateService.instant(
          'To cancel your subscription, please contact info@crewcus.com'
        ),
      },
    });
  }

  showUpgradePlan() {
    const subRef = this.dialog.open(ChangePlanStepperComponent, {
      minHeight: '82vh',
      maxWidth: '60vw',
      data: {
        address: this.activeOrga.billingAddress,
        mail: this.activeOrga.owner[0]?.mail,
      },
    });

    subRef.afterClosed().subscribe((result) => {
      if (result.address) {
        this.activeOrga.billingAddress = {};

        (this.activeOrga.billingAddress.gender = result.address.gender),
          (this.activeOrga.billingAddress.firstName = result.address.firstName),
          (this.activeOrga.billingAddress.lastName = result.address.lastName),
          (this.activeOrga.billingAddress.companyName =
            result.address.companyName),
          (this.activeOrga.billingAddress.taxId = result.address.taxId),
          (this.activeOrga.billingAddress.street = result.address.street),
          (this.activeOrga.billingAddress.zip = result.address.zip),
          (this.activeOrga.billingAddress.city = result.address.city),
          (this.activeOrga.billingAddress.country = result.address.country),
          (this.activeOrga.billingAddress.billingMail =
            result.address.billingMail);

        this.detailsForm.setValue({
          gender: result.address.gender,
          firstName: result.address.firstName,
          lastName: result.address.lastName,
          companyName: result.address.companyName,
          taxId: result.address.taxId,
          street: result.address.street,
          zip: result.address.zip,
          city: result.address.city,
          country: result.address.country,
          billingMail: result.address.billingMail,
        });
        this.updateProfile(true).then(async () => {
          if (result.plan === 'pro') {
            await this.upgradeRequest(false);

            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
              maxWidth: '400px',
              data: {
                showCancel: false,
                cta: this.translateService.instant('Okay'),
                dialogTitle: this.translateService.instant('Information'),
                dialogText: this.translateService.instant(
                  'We have received your upgrade request and will send you an email with the details in the next few minutes. In the meantime, we have already unlocked 14 days of Pro Subscription for you. As soon as we have received your payment, you will be upgraded to your chosen plan.'
                ),
              },
            });
          } else {
            await this.upgradeRequest(true);

            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
              maxWidth: '400px',
              data: {
                showCancel: false,
                cta: this.translateService.instant('Okay'),
                dialogTitle: this.translateService.instant('Information'),
                dialogText: this.translateService.instant(
                  'We have received your upgrade request and will get back to you shortly. Thank you for the request.'
                ),
              },
            });
          }

          this.getUserPlan(this.activeOrga);
          this.getAddress(this.activeOrga);
          this.getOrderHistory(this.activeOrga);
        });
      }
    });
  }

  showCancelInfo() {
    const dialogRef = this.dialog.open(SelectDialogPricingComponent, {
      maxWidth: '400px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'cancel') {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          maxWidth: '400px',
          data: {
            showCancel: false,
            cta: this.translateService.instant('Okay'),
            dialogTitle: this.translateService.instant('Information'),
            dialogText: this.translateService.instant(
              'To cancel your subscription, please contact info@crewcus.com'
            ),
          },
        });
      } else if (result === 'pro') {
        const subRef = this.dialog.open(ChangePlanStepperComponent, {
          minHeight: '82vh',
          maxWidth: '60vw',
          data: {
            address: this.activeOrga.billingAddress,
          },
        });

        subRef.afterClosed().subscribe((result) => {
          console.log(result);

          if (result.address) {
            this.detailsForm.setValue({
              gender: result.address.gender,
              firstName: result.address.firstName,
              lastName: result.address.lastName,
              companyName: result.address.companyName,
              taxId: result.address.taxId,
              street: result.address.street,
              zip: result.address.zip,
              city: result.address.city,
              country: result.address.country,
              billingMail: result.address.billingMail,
            });
            this.updateProfile(true).then(async () => {
              if (result.plan === 'pro') {
                await this.upgradeRequest(false);
                this.getUserPlan(this.activeOrga);
                this.getAddress(this.activeOrga);
                this.getOrderHistory(this.activeOrga);
              } else {
                await this.upgradeRequest(true);
                this.getUserPlan(this.activeOrga);
                this.getAddress(this.activeOrga);
                this.getOrderHistory(this.activeOrga);
              }
            });
          }
        });
      }
    });
  }

  async upgradeRequest(isEnterprise) {
    //
    this.loadingService.startLoading();

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-organisation-id':
          this.organisationService.activeOrganisations[0]._id,
      }),
    };

    await this.http
      .post(
        APP.baseurl + '/organisation/payment/planUpgradeRequest',
        { customPlan: isEnterprise, paymentPeriod: 'monthly' },

        httpOptions
      )
      .toPromise()
      .then((res) => {
        this.loadingService.stopLoading();
      });
  }

  async updateProfile(nodialog?) {
    ///payment/address

    this.loadingService.startLoading();
    ///organisation/usedPlan

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-organisation-id':
          this.organisationService.activeOrganisations[0]._id,
      }),
    };

    if (
      this.detailsForm.value.companyName &&
      this.detailsForm.value.companyName.length > 0
    ) {
      this.detailsForm.value.businessCustomer = true;
    } else {
      this.detailsForm.value.businessCustomer = false;
    }

    await this.http
      .put(
        APP.baseurl + '/organisation/payment/address',
        this.detailsForm.value,
        httpOptions
      )
      .toPromise()
      .then((res) => {
        this.loadingService.stopLoading();

        if (!nodialog) {
          const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            maxWidth: '400px',
            data: {
              showCancel: false,
              cta: this.translateService.instant('Okay'),
              dialogTitle: this.translateService.instant('My Account'),
              dialogText: this.translateService.instant(
                'Your profile has been saved.'
              ),
            },
          });
        }
      });
  }

  getAddress(orga) {
    if (orga.billingAddress) {
      this.detailsForm.setValue({
        gender: orga.billingAddress.gender,
        firstName: orga.billingAddress.firstName,
        lastName: orga.billingAddress.lastName,
        companyName: orga.billingAddress.companyName,
        taxId: orga.billingAddress.taxId,
        street: orga.billingAddress.street,
        zip: orga.billingAddress.zip,
        city: orga.billingAddress.city,
        country: orga.billingAddress.country,
        billingMail: orga.billingAddress.billingMail,
      });
    } else {
      this.detailsForm.reset();

      this.detailsForm.setValue({
        gender: null,
        firstName: null,
        lastName: null,
        companyName: null,
        taxId: null,
        street: null,
        zip: null,
        city: null,
        country: null,
        billingMail: orga.owner[0]?.mail,
      });
    }
  }

  async getOrderHistory(organisation) {
    ///orders/60d9905a36c6910008a33f42

    ///orders/getOrder
    this.loadingService.startLoading();

    if (this.userService.isSuperAdmin()) {
      ///organisation/usedPlan

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'x-organisation-id': organisation._id,
        }),
      };

      this.timelineData = await this.http
        .get(APP.baseurl + '/orders/' + organisation._id, httpOptions)
        .toPromise();

      if (this.timelineData && this.timelineData.history) {
        this.timelineData.history.reverse();
      }
      this.loadingService.stopLoading();
    } else {
      ///organisation/usedPlan

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'x-organisation-id': organisation._id,
        }),
      };

      this.timelineData = await this.http
        .get(APP.baseurl + '/orders/getOrder', httpOptions)
        .toPromise();

      this.loadingService.stopLoading();
    }
  }

  addHistory() {
    const dialogRef = this.dialog.open(AddHistoryDialogComponent, {
      minWidth: '600px',
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.loadingService.startLoading();

        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-organisation-id': this.activeOrga._id,
          }),
        };

        this.timelineData = await this.http
          .post(
            APP.baseurl + '/orders/historyMessage/' + this.activeOrga._id,
            result.value,
            httpOptions
          )
          .toPromise();

        this.loadingService.stopLoading();

        this.getOrderHistory(this.activeOrga);
      }
    });
  }

  changeStatus(event) {
    console.log(event);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        showCancel: true,
        cta: this.translateService.instant('Change Status'),
        dialogTitle: this.translateService.instant('Change Status Request'),
        dialogText: this.translateService.instant(
          'The status will be changed immediately. Are you sure?'
        ),
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        //paymentStatus/60d9905a36c6910008a33f42

        this.loadingService.startLoading();

        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-organisation-id': this.activeOrga._id,
          }),
        };

        await this.http
          .patch(
            APP.baseurl + '/orders/paymentStatus/' + this.activeOrga._id,
            { paymentStatus: event.value, sendMail: false },
            httpOptions
          )
          .toPromise();

        this.loadingService.stopLoading();

        this.getUserPlan(this.activeOrga);
        this.getAddress(this.activeOrga);
        this.getOrderHistory(this.activeOrga);
      }
    });
  }

  hasPlanFeature(planFeatureList, feature) {
    if (!planFeatureList || planFeatureList.length === 0) {
      return false;
    }

    let found = false;

    planFeatureList.forEach((element) => {
      if (element._id === feature._id) {
        element.name = feature.name;
        if (element.isActive) {
          found = true;
        }
      }
    });
    return found;
  }

  featureList;
  getFeatureList() {
    this.loadingService.startLoading();
    ///plan/featureList

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'x-organisation-id': orga._id
      }),
    };

    this.http
      .get(
        APP.baseurl + '/plan/featureList/',

        httpOptions
      )
      .toPromise()
      .then((data) => {
        this.featureList = data;
        this.loadingService.stopLoading();
      })
      .catch((err) => {});
  }
}
