import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BookableService } from 'src/app/bookable.service';
import { GoogleAnalyticsService } from 'src/app/google-analytics.service';
import { LocationService } from 'src/app/location.service';
import { IOrganisationObserver, OrganisationService } from 'src/app/organisation.service';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss'],
})
export class ItemsComponent implements OnInit, IOrganisationObserver {
  constructor(
    private locationService: LocationService,
    private bookableService: BookableService,
    private organisationService: OrganisationService,
    private GAService: GoogleAnalyticsService
  ) {}
  observerId = 103;
  onOrganisationChange(organisations: any) {
    if(organisations && organisations[0]) {
      this.activeRoom = null;
      this.activeLocation = null;
      this.locations = null;
      this.items = null;
      this.isLoading = false;
      this.bookableName = null;
      this.error = null;
      this.showDeleteModal = false;
      this.modalActive = false;
      this.toDeleteId = null;
      this.printNow = false;
      this.activeItem = null;
      this.showQRModal = null;
      this.getAllLocations();
      this.activeOrganisation = this.organisations[0];
    }
  }

  @Input() organisations;
  @ViewChild('bookableInput') input;
  activeRoom;
  rooms = [];
  activeLocation;
  locations;
  items;
  isLoading = false;
  bookableName;
  error;
  showDeleteModal = false;
  modalActive = false;
  toDeleteId;
  printNow = false;
  activeOrganisation;
  activeItem;
  showQRModal;

  ngOnInit(): void {
    this.GAService.send('Admin - Bookable Page');
    this.organisationService.subscribe(this);
 
    let self = this;
    window.onafterprint = function () {
      self.printNow = false;
    };
  }

  ngOnDestroy(): void {
    this.organisationService.unsubscribe(this);
  }


  getAllLocations() {
    this.isLoading = true;
    this.locationService.getAllLocations().then((data) => {
      this.locations = data;
      if (!this.activeLocation) {
        this.locations.forEach((element) => {
          if (element.rooms.length > 0) {
            this.activeLocation = element;
            return;
          }
        });
        this.activeLocation = this.locations[0];
      } else {
        this.getLocationInfo(this.activeLocation._id);
        return;
      }

      if (this.activeLocation) {
        this.rooms = this.activeLocation.rooms;
        if (!this.activeRoom) {
          this.activeRoom = this.rooms[0];
        }
        if (this.activeRoom?.bookableItems) {
          this.items = this.activeRoom.bookableItems;
        }

        if (
          this.locations.length === 0 ||
          (this.locations.length === 1 && this.rooms.length === 0) ||
          (this.locations.length === 1 &&
            this.rooms.length === 1 &&
            this.items.length === 0)
        ) {
          this.modalActive = true;
        }
      } else {
        //no room here
        this.modalActive = true;
      }

      this.isLoading = false;
    });
  }

  getRoomInfo(id) {
    this.rooms.forEach((element) => {
      if (element._id === id) {
        this.activeRoom = element;
        this.getRoomInformation(this.activeRoom);
      }
    });
  }

  getLocationInfo(id) {
    this.locations.forEach((element) => {
      if (element._id === id) {
        let locationChange = true;
        if (this.activeLocation._id === element._id) {
          //same location dont change active room
          locationChange = false;
        }
        this.activeLocation = element;
        this.rooms = this.activeLocation.rooms;
        if (locationChange) {
          this.activeRoom = this.rooms[0];
        } else {
          this.rooms.forEach((element) => {
            if (this.activeRoom._id === element._id) {
              this.activeRoom = element;
            }
          });
        }

        this.getRoomInformation(this.activeRoom);
      }
    });
  }

  getRoomInformation(room) {
    this.items = room.bookableItems;
    this.isLoading = false;
  }

  createBookable() {
    this.isLoading = true;
    this.bookableService
      .createBookable(
        this.activeLocation._id,
        this.activeRoom._id,
        this.bookableName
      )
      .then((data) => {
        //success

        this.isLoading = false;
        this.modalActive = false;
        this.bookableName = null;
        this.getAllLocations();
      })
      .catch((err) => {
        this.error = err.error.message;
        this.isLoading = false;
      });
  }

  deleteBookableModal(id) {
    this.error = null;
    this.showDeleteModal = true;
    this.modalActive = true;
    this.toDeleteId = id;
  }

  deleteBookable() {
    this.isLoading = true;
    this.bookableService
      .deleteBookable(
        this.activeLocation._id,
        this.activeRoom._id,
        this.toDeleteId
      )
      .then((data) => {
        this.getAllLocations();
        this.isLoading = false;
        this.toDeleteId = null;
        this.modalActive = false;
        this.showDeleteModal = false;
      })
      .catch((err) => {
        //todo err
        this.isLoading = false;
      });
  }

  showCreateBookable() {
    this.modalActive = true;
    this.showDeleteModal = false;
    this.error = null;
    this.toDeleteId = null;
    setTimeout(() => {
      this.input.nativeElement.focus();
    }, 1);
  }

  getSmallImgUrl(item) {
    if (item) {
      return (
      //  'https://chart.googleapis.com/chart?chs=500x500&cht=qr&chl=' +
        window.location.origin +
        '/checkin/' +
        this.activeLocation._id +
        '/' +
        this.activeRoom._id +
        '/' +
        item._id +
        '/' +
       this.urlify(item.name) +
        '/' +
        this.activeOrganisation._id +
        '/' +
        this.activeOrganisation.slug
      );
    } else {
      return null;
    }
  }

  urlify(a) {
    return a
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^-+|-+$/g, '-')
      .replace(/^-+|-+$/g, '');
  }

  printCodes() {
    this.printNow = true;
    setTimeout(() => {
      window.print();
    }, 100);
  }

  showQRCodeSingle(item) {
    this.activeItem = item;
    this.showQRModal = true;
  }

  printSingle() {
   
      window.print();

  }


  lastChangeDelta;
  newNameDelta;

  onNameChange(item,$event) {

    this.newNameDelta = $event.target.innerHTML;
   if(!this.lastChangeDelta) {
    this.lastChangeDelta  = item;
   }
     
    
  }


  onNewNameSave() {

    //@ts-ignore
    document.activeElement.blur();

    if(this.newNameDelta.length > 0) {
      this.newNameDelta = this.newNameDelta.replace(/<br>+/g, '');
      this.newNameDelta = this.newNameDelta.replace(/<div>+/g, '');
      this.newNameDelta = this.newNameDelta.replace(/<\/div>+/g, '');
      this.newNameDelta = this.newNameDelta.replace(/\&nbsp;+/g, '');

      console.log(this.newNameDelta)

    this.lastChangeDelta.name = this.newNameDelta;

    this.error = null;
    this.isLoading = true;

    this.bookableService.updateBookable(this.activeLocation,this.activeRoom,this.lastChangeDelta).then(() => {
      this.error = null;
      this.isLoading = false;
      this.lastChangeDelta = false;
      this.newNameDelta = null;

    }).catch(err => {
      this.error = "Location Name updated failed";
      this.isLoading = false;
      console.log("Location Name updated failed");
    })
          
  }
  }

/**
 * Prevent Rich Text to be copyed into the contenteditable field
 * @param $event 
 */
  onPaste($event) {
 
    $event.preventDefault();
    $event.stopPropagation();
    var plaintext = $event.clipboardData.getData('text/plain');
    document.execCommand('inserttext', false, plaintext);


  }
}
