import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { APP } from '../app.config';
import { GoogleAnalyticsService } from '../google-analytics.service';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss'],
})
export class SurveyComponent implements OnInit {
  constructor(private http: HttpClient, private GAService: GoogleAnalyticsService) {}

  isOpen = false;
  email;
  text;
  feedBackSuccess = false;
  isLoading = false;
  errorMessage;
  category = 'Please choose a category';

  ngOnInit(): void {
    this.email = this.getEmail();
  }

  openSurvey() {
    this.GAService.send('Survey Open');
    this.isOpen = true;
  }

  closeSurvey() {
    this.isOpen = false;
    this.isLoading = false;
    this.feedBackSuccess = false;
    this.text = '';
  }

  sendFeedback() {
    this.isLoading = true;
    this.errorMessage = null;
    //https://rh6mimbjxf.execute-api.eu-west-1.amazonaws.com/dev/reservationItem/feedback

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    const message = '[🌍 Env]: ' + window.location.href  + '\\n [🎉 Category]: ' + this.category + '\\n [✉️ Message]: ' + this.text;

   
    this.http
      .post(
        APP.baseurl + '/reservationItem/feedback',
        {
          from: this.email,
          //@ts-ignore
          content: message.replaceAll("\\n","\n"),
        },
        httpOptions
      )
      .toPromise()
      .then((data) => {
        this.isLoading = false;
        // show success
        this.feedBackSuccess = true;
      })
      .catch((err) => {
        if (err.status === 201) {
          this.feedBackSuccess = true;
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        console.log('Error Feedback', err);
        this.errorMessage = err.error.message;
        this.isLoading = false;
      });
  }

  getEmail() {
    if (localStorage.getItem('user')) {
      const user = JSON.parse(localStorage.getItem('user'));

      if (user.username) {
        return user.username;
      }

      return '';
    }
  }
}
