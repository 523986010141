import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APP } from './app.config';

export interface IOrganisationObserver {
  observerId: number;
  onOrganisationChange(organisations);
}

@Injectable({
  providedIn: 'root',
})
export class OrganisationService {
  constructor(private http: HttpClient) {}
  private observers: IOrganisationObserver[] = [];
  activeOrganisations;
  organisations;

  getOrganisation() {
    return this.http.get(APP.baseurl + '/organisation').toPromise();
  }

  isNameAvailable(name) {
    return this.http.get(APP.baseurl + '/organisation/isOrganameInUse/' +name);
  }


  createOrganisation(name) {
    return this.http
      .post(APP.baseurl + '/organisation', {
        organisationName: name,
      })
      .toPromise();
  }

  setActiveOrganisation(orga) {
    
    
  
      this.activeOrganisations = orga;
      this.notify();
   
  }

  getActiveOrganisations() {
    if (this.activeOrganisations) {
      return this.activeOrganisations;
    }
  }

  deleteOrganisation(orga) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-organisation-id': orga._id,
      }),
    };

    return this.http
      .delete(APP.baseurl + '/organisation', httpOptions)
      .toPromise();
  }

  subscribe(observer: IOrganisationObserver) {
    const idx = this.observers.indexOf(observer);
    if (idx === -1) {
      this.observers.push(observer);
      
      
        observer.onOrganisationChange(this.activeOrganisations);
      
     
    }
  }

  unsubscribe(observer: IOrganisationObserver) {
    let idx = -1;
    for (const obs of this.observers) {
      if (obs.observerId === observer.observerId) {
        idx = this.observers.indexOf(obs);
      }
    }
    if (idx !== -1) {
      this.observers.splice(idx, 1);
    }
  }

  clearActiveOrganisation() {
    this.activeOrganisations = null;
  }

  notify() {

    this.observers.forEach((observer) => {
      observer.onOrganisationChange(this.activeOrganisations);
    });
  }


  async changeOfficeFeature(isActive) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-organisation-id': this.activeOrganisations[0]._id,
      }),
    };
try {

   const orga = await this.http
      .patch(APP.baseurl + '/mobileOffice', {"active":isActive}, httpOptions)
      .toPromise();
  
      this.activeOrganisations[0] = orga;
      this.notify();

    }catch(e){
      console.log("e",e)
      
      throw e;
    }
   
     

    
  }
}
