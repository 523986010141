import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsService } from 'src/app/google-analytics.service';
import { LoadingService } from 'src/app/loading.service';
import { LocationService } from 'src/app/location.service';
import {
  IOrganisationObserver,
  OrganisationService,
} from 'src/app/organisation.service';
import { SlackService } from 'src/app/slack.service';
import { UserService } from 'src/app/user.service';
@Component({
  selector: 'app-organisation',
  templateUrl: './organisation.component.html',
  styleUrls: ['./organisation.component.scss'],
})
export class OrganisationComponent implements OnInit, IOrganisationObserver {
  @Input() organisations;
  isLoading;

  modalActive = false;
  activeOrganisation;
  error;
  shareUrl;

  constructor(
    private organisationService: OrganisationService,
    private slackService: SlackService,
    private GAService: GoogleAnalyticsService,
    private locationService: LocationService,
    public userService: UserService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private loadingService: LoadingService
  ) {}

  onOrganisationChange(organisations: any) {
    if (organisations && organisations[0]) {
      this.activeOrganisation = organisations[0];
      this.getShareUrl();
    }
  }
  observerId: number = 100;

  ngOnInit(): void {
    this.GAService.send('Admin - Organisation Page');
    this.organisationService.subscribe(this);
  }

  ngOnDestroy(): void {
    this.organisationService.unsubscribe(this);
  }

 
  getShareUrl() {
    this.shareUrl =
      window.location.origin + '/o/' + this.activeOrganisation.slug;
  }


}
