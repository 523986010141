<!-- <div class="timeline">

    <div class="timeline-item" *ngFor="let item of timelineData;first as isFirst">
        <div class="timeline-badge" [ngClass]="{'colored': isFirst, 'orange': item.status === 'Pending', 'green': item.status === 'Success'}"><i class="fas fa-{{item.icon}}"></i></div>
        <div class="timeline-panel">
            <div class="timeline-heading">
                <div class="flex-container">
                    <h4 class="timeline-title" style="flex:1">{{item.title}}</h4>
                    <span class="text-muted"> {{item.date | date:undefined:undefined:lang}} </span>
                </div>
                <p class="description">
                    {{item.description}}
                </p>
            </div>
        </div>

    </div>




</div> -->


<table mat-table [dataSource]="timelineData" class="mat-elevation-z8">


    <ng-container matColumnDef="icon">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let element" style="text-transform: capitalize;">

        </td>
    </ng-container>




    <!-- Position Column -->
    <ng-container matColumnDef="order">
        <th mat-header-cell *matHeaderCellDef> {{'Order #' | translate}}</th>
        <td mat-cell *matCellDef="let element" style="text-transform: capitalize;"> {{element?._id}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef> {{'Date' | translate}} </th>
        <td mat-cell *matCellDef="let element">
            <span> {{element?.created_at | date: 'short':undefined:lang}} </span></td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container *ngIf="userService.isSuperAdmin()" matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> {{'Admin' | translate}} </th>
        <td mat-cell *matCellDef="let element">
            <span>{{element?.byUser | translate}}</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="info">
        <th mat-header-cell *matHeaderCellDef> {{'Info' | translate}} </th>
        <td style="padding:15px 40px 15px 0px" mat-cell *matCellDef="let element" [title]="trimMessage(element?.message)[1] ? ('Expire: ' + trimMessage(element?.message)[1]) : ''">

            <div>{{trimMessage(element?.message)[0] | translate}}</div>
            <div *ngIf="element?.adminMessage || element?.mailSent" style="display:flex; margin-right:15px"> <span title="E-Mail sent to customer!" class="pill" *ngIf="element?.mailSent" style="margin-right:5px;    background: var(--primary);"><i style="-webkit-text-stroke-color: white;
                color: white !important;" *ngIf="element?.mailSent" class="fa fa-envelope-o" aria-hidden="true"></i></span> <span style="    background: var(--pink);" title="Only visible for Admins" class="pill" *ngIf="element?.adminMessage">A</span>
            </div>
        </td>
    </ng-container>

    <!-- <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> {{'Actions' | translate}} </th>
        <td mat-cell *matCellDef="let element" (click)=" showCancelInfo() " style="
 
cursor: pointer;"> <span style=" color: var(--accent);">{{'Change' | translate}}</span> </td>
    </ng-container> -->

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

</table>
<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of periodic elements">
</mat-paginator>