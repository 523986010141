import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APP } from './app.config';

@Injectable({
  providedIn: 'root',
})
export class OrgaSettingsService {
  saveSettings(orgaid, toUpdateJson) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-organisation-id': orgaid,
      }),
    };

    return this.http
      .patch(APP.baseurl + '/organisation', toUpdateJson, httpOptions)
      .toPromise();
  }

  constructor(private http: HttpClient) {}

  getOrganisationSettings(orgaid: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-organisation-id': orgaid,
      }),
    };

    return this.http
      .get(
        APP.baseurl + '/organisation/settings',

        httpOptions
      )
      .toPromise();
  }
}
