<!-- <app-survey style="z-index:1000">
</app-survey> -->
<app-loading-bar></app-loading-bar>


<div class="schedule" *ngIf="events">{{'My' | translate}} {{'Reservations' | translate}} {{'(' + events?.length + ')'}} </div>
<div style="padding: 15px 20px;border-bottom: 1px solid #ececec;cursor:pointer" (click)="onEventSelected(event)" class="schedule-item flex hover-item" *ngFor="let event of events">
    <div style="width: 25px">
        <div class="dot"></div>
    </div>
    <div class="schedule-item-content flex column ">

        <div class="room-and-time">{{isToday(event.startTime) ? ('Today' | translate) : event.startTime | date: 'mediumDate':undefined:lang }}, {{event.startTime | date: 'shortTime':undefined:lang}} - {{event.endTime | date: 'shortTime':undefined:lang}} </div>
        <div class="schedule-item-title">{{event.location}} - {{event.title}}</div>
        <div class="schedule-item-user">{{event.username}}</div>
    </div>
</div>

<app-tab-bar [activeTab]="2"></app-tab-bar>