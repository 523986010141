import { Component, Inject, Input, OnInit } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  dialogTitle;
  dialogText;
  showCancel = true;
  cta;

  constructor(@Inject(MAT_DIALOG_DATA) public data) {}

  ngOnInit(): void {
    if (this.data) {
      this.dialogTitle = this.data.dialogTitle;
      this.dialogText = this.data.dialogText;
      this.showCancel = this.data.showCancel;
      this.cta = this.data.cta;
    }
  }
}
