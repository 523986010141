import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit {
  @Input() labels: [];
  @Input() data: [];
  @Input() type: string;
  @Input() id: string;
  @Input() height: string;
  @Input() font: string;
  piePercent;

  chart;
  constructor(private cRef: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    if (this.id) {
      this.parseData();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.parseData();
  }

  parseData() {
    if (document.getElementById(this.id)) {
      //@ts-ignore
      const ctx = document.getElementById(this.id).getContext('2d');
      this.chart ? this.chart.destroy() : false;

      var style = getComputedStyle(document.body);
      var greencolor = style.getPropertyValue('--accent');
      var redcolor = style.getPropertyValue('--pink');
      var hoverColor = style.getPropertyValue('--primary');

      if (this.type === 'doughnut') {
 //@ts-ignore
        this.piePercent = isNaN([this.data[1]]) ? 0 : [this.data[1]] ;
      
        this.cRef.detectChanges();
        //@ts-ignore
        this.chart = new Chart(ctx, {
          type: this.type,
          data: {
            labels: this.labels,
            datasets: [
              {
                data: this.data,
                backgroundColor: [greencolor, redcolor],
                hoverBackgroundColor: [hoverColor,hoverColor]
              },
            ],
          },
       
          options: {
            legend: {
              display: false,
            },
            cutoutPercentage: 70,
            maintainAspectRatio: false,
            responsive: true,
          },
        });
        return;
      }

      //@ts-ignore
      this.chart = new Chart(ctx, {
        type: this.type,
        data: {
          labels: this.labels,
          datasets: [
            {
              data: this.data,
              backgroundColor: greencolor,
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  suggestedMax: 100,
                  stepSize: 25,
                  callback: (value) => {
                    return value + '%';
                  },
                },
                gridLines: {
                  display: false,
                  color: '#e2e2e26e',
                },
              },
            ],
            xAxes: [
              {
                gridLines: {
                  display: false,
                  color: '#e2e2e26e',
                },
              },
            ],
          },
        },
      });
    }
  }
}
