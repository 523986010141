<div class="content flex-column" *ngIf="!successSync">
    <h1 style="text-align: center;">Sync Device</h1>
    <span class="advice">Enter a Sync PIN on to syncronize with your other device.</span>
    <div class="pin">

        <input [disabled]="disableAll" (keydown.backspace)="back(1)" (input)="next(1)" #one type="number" min="0" max="9" maxlength="1 ">
        <input [disabled]="disableAll" (keydown.backspace)="back(2)" (input)="next(2)" #two type="number" min="0" max="9" maxlength="1">
        <input [disabled]="disableAll" (keydown.backspace)="back(3)" (input)="next(3)" #three type="number" min="0" max="9" maxlength="1">
        <input [disabled]="disableAll" (keydown.backspace)="back(4)" (input)="next(4)" #four type="number" min="0" max="9" maxlength="1">
        <input [disabled]="disableAll" (keydown.backspace)="back(5)" (input)="next(5)" #five type="number" min="0" max="9" maxlength="1">

    </div>

    <div class="flex-column" *ngIf="syncFailed && !successSync">
        <i class="far fa-times-circle fa-4x"></i>
        <span class="advice">Your submitted Sync Pin is not valid. Please check if the code is correct and not expired.</span>


    </div>

    <!-- <i *ngIf="waitForCode || pin.length === 0" class="fas fa-sync fa-spin fa-3x"></i> -->


</div>

<div class="content flex-column" *ngIf="successSync">
    <h1 style="text-align: center;">Sync Status</h1>
    <div class="flex-column" *ngIf="successSync">
        <span class="advice">You are now permanently synced with your other device.</span>
        <i class="far fa-check-circle fa-4x"></i>
        <span class="advice" style="margin-top:25px">You will be redirected in {{redirectNumber}} seconds</span>
    </div>



</div>


<p (click)="goBack()" class="go-back">I want to go back</p>