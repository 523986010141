import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-add-history-dialog',
  templateUrl: './add-history-dialog.component.html',
  styleUrls: ['./add-history-dialog.component.scss']
})
export class AddHistoryDialogComponent implements OnInit {

  selections: FormGroup;



  isAdmin;
  sendEmail;

  constructor(fb: FormBuilder) {

    this.selections = fb.group({
      adminMessage: false,
      sendMail: false,
      message: ''
    });


   }

  ngOnInit(): void {

   


  }

}
