import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'src/app/google-analytics.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(private GAService: GoogleAnalyticsService) { }

  ngOnInit(): void {
    this.GAService.send('Privacy Policy Page');
  }

}
