import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GoogleAnalyticsService } from 'src/app/google-analytics.service';
import { LoadingService } from 'src/app/loading.service';
import { ILocationObserver, LocationService } from 'src/app/location.service';
import {
  IOrganisationObserver,
  OrganisationService,
} from 'src/app/organisation.service';
@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
})
export class LocationComponent
  implements OnInit, IOrganisationObserver, ILocationObserver
{
  constructor(
    private locationService: LocationService,
    private organisationService: OrganisationService,
    private GAService: GoogleAnalyticsService,
    private loadingService: LoadingService
  ) {}

  observerId = 101;

  @Input() selectedLocation;
  onOrganisationChange(organisations: any) {
    if (organisations && organisations[0]) {
      this.isLoading = false;
      this.error = null;
      this.showDeleteModal = false;
      this.modalActive = false;
      this.toDeleteId = null;
      this.locationName = null;

      this.loadingService.stopLoading();
    }
  }

  onLocationChange(locations) {
    this.locations = locations;
  }

  locations;
  modalActive = false;
  error;
  isLoading = false;
  locationName;
  showDeleteModal = false;
  toDeleteId;
  activeLocation;

  @ViewChild('locationInput') input;

  ngOnInit(): void {
    this.loadingService.startLoading();
    this.locationService.subscribe(this);

    if (this.selectedLocation) {
      this.setActiveLocation(this.selectedLocation);
    }

    this.GAService.send('Admin - Location Page');
    this.organisationService.subscribe(this);
    // this.getAllLocations();
  }

  ngOnDestroy(): void {
    this.organisationService.unsubscribe(this);
    this.locationService.unsubscribe(this);
  }

  getAllLocations() {
    this.locationService.getAllLocations().then((data) => {
      this.locations = data;
      if (this.locations?.length === 0) {
        this.modalActive = true;
      }

      this.locationService.setActiveLocations(this.locations);

      this.loadingService.stopLoading();
    });
  }

  createLocation() {
    this.error = null;
    this.isLoading = true;
    this.locationService
      .createLocation(this.locationName)
      .then((data) => {
        this.isLoading = false;
        this.modalActive = false;
        this.setActiveLocation(data);
        this.getAllLocations();
        this.locationName = null;
      })
      .catch((err) => {
        this.isLoading = false;
        this.error = err.error.message;
        this.loadingService.stopLoading();
      });
  }

  deleteLocationModal(id) {
    this.showDeleteModal = true;
    this.modalActive = true;
    this.toDeleteId = id;
  }

  showCreateLocation() {
    this.modalActive = true;
    this.showDeleteModal = false;
    this.error = null;
    this.toDeleteId = null;
    setTimeout(() => {
      this.input.nativeElement.focus();
    }, 1);
  }

  deleteLocation() {
    if (this.toDeleteId) {
      this.error = null;
      this.isLoading = true;
      this.locationService
        .deleteLocation(this.toDeleteId)
        .then((data) => {
          this.isLoading = false;

          this.modalActive = false;
          this.showDeleteModal = false;
          this.toDeleteId = null;
          this.setActiveLocation(null);
        })
        .catch((err) => {
          this.isLoading = false;
          this.error =
            'There was an error deleting the Location. If this problem persists, please contact support.';
        });
    }
  }

  lastChangeDelta;
  newNameDelta;

  onNameChange(location, $event) {
    this.newNameDelta = $event.target.innerHTML;
    if (!this.lastChangeDelta) {
      this.lastChangeDelta = location;
    }
  }

  onNewNameSave() {
    //@ts-ignore
    document.activeElement.blur();

    if (this.newNameDelta.length > 0) {
      this.newNameDelta = this.newNameDelta.replace(/<br>+/g, '');
      this.newNameDelta = this.newNameDelta.replace(/<div>+/g, '');
      this.newNameDelta = this.newNameDelta.replace(/<\/div>+/g, '');
      this.newNameDelta = this.newNameDelta.replace(/\&nbsp;+/g, '');

      this.lastChangeDelta.name = this.newNameDelta;

      this.error = null;
      this.isLoading = true;

      this.locationService
        .updateLocation(this.lastChangeDelta)
        .then(() => {
          this.error = null;
          this.isLoading = false;
          this.lastChangeDelta = false;
          this.newNameDelta = null;
        })
        .catch((err) => {
          this.error = 'Location Name updated failed';
          this.isLoading = false;
          console.log('Location Name updated failed');
        });
    }
  }

  /**
   * Prevent Rich Text to be copyed into the contenteditable field
   * @param $event
   */
  onPaste($event) {
    $event.preventDefault();
    $event.stopPropagation();
    var plaintext = $event.clipboardData.getData('text/plain');
    document.execCommand('inserttext', false, plaintext);
  }

  getBookableCount(location) {
    let sum = 0;

    location.rooms.forEach((element) => {
      sum += element.bookableItems.length;
    });

    return sum;
  }

  setActiveLocation(location) {
    this.activeLocation = location;

    if (!location) {
      this.getAllLocations();
    }
  }
}
