import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sync-devices-button',
  templateUrl: './sync-devices-button.component.html',
  styleUrls: ['./sync-devices-button.component.scss']
})
export class SyncDevicesButtonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
