<img src="../../../assets/img/crewcus-logo" style="max-width: 150px;">

<mat-horizontal-stepper #stepper>
    <mat-step label="{{'Select a Plan' | translate}}" state="done">
        <div class="flex column">



            <div style="display: flex;justify-content: space-evenly;">
                <div (click)="selectPlan(plan)" class="select-plan" [ngClass]="{'active': selectedPlan === plan}" *ngFor="let plan of pricingplans" style="cursor:pointer;    width: 200px; margin:15px;
                box-shadow: 0 3px 6px rgb(0 0 0 / 0%), 0 3px 6px rgb(0 0 0 / 18%);
                padding: 25px;">


                    <h2 [ngStyle]="{'color': plan.color}">{{plan.title}}</h2>
                    <h3 style="margin-bottom:25px">{{plan.title === 'pro plan' ? ('Good for start' | translate) : ('Tailored for you' | translate)}}</h3>
                    <p style="font-size: 16px;color:#adadad"><span style="color:#3e3e3e;font-weight: bold;">{{plan.bookables}}</span> {{'Bookables' | translate}}</p>
                    <p style="font-size: 16px;color:#adadad"><span style="color:#3e3e3e;font-weight: bold;">{{plan.statistics}}</span> {{'Statistics' | translate}}</p>
                    <p style="font-size: 13px;color:#adadad;margin-top:25px"><span style="color:#3e3e3e;font-size:24px;">{{plan.price}}<span *ngIf="plan.title === 'pro plan'">€</span></span> {{'/ month' | translate}}</p>
                    <button (click)="selectPlan(plan,stepper)" class="cta-button" style="width: 100%;">{{'Choose Plan' | translate}}</button>


                    <p style="margin-top:25px; font-weight: bold;"><span style="margin-right: 10px;
                        color: #4caf50;
                       ">✓</span> {{'Unlimited' | translate}} <span style="color:#3e3e3e;font-size:14px;">{{'Locations' | translate}}</span></p>
                    <p style=" font-weight: bold;"><span style="margin-right: 10px;
                        color: #4caf50;
                       ">✓</span> {{'Unlimited' | translate}} <span style="color:#3e3e3e;font-size:14px;">{{'Rooms' | translate}}</span></p>
                    <p style=" font-weight: bold;"><span style="margin-right: 10px;
                            color: #4caf50;
                           ">✓</span> {{'Mobile Office' | translate}} <span style="color:#3e3e3e;font-size:14px;">{{'Feature' | translate}}</span></p>
                    <p style=" font-weight: bold;"><span style="margin-right: 10px;
                            color: #4caf50;
                           ">✓</span> {{'E-Mail Support' | translate}} </p>
                </div>
            </div>

        </div>
        <div style="display: flex;
        justify-content: flex-end;;margin-top:15px">
            <button class="cta-button grey" mat-button matDialogClose>{{'Close' | translate}}</button>
            <button [disabled]="!selectedPlan" class="cta-button" mat-button matStepperNext>{{'Next' | translate}}</button>
        </div>
    </mat-step>

    <mat-step label="{{'Enter Address' | translate}}">

        <mat-dialog-content>
            <form [formGroup]="detailsForm" style="display: flex;
            flex-direction: column;">

                <mat-form-field appearance="fill">
                    <mat-label>{{'Title' | translate}} *</mat-label>
                    <mat-select formControlName="gender">
                        <mat-option value="male">{{'Mr' | translate}}</mat-option>
                        <mat-option value="female">{{'Mrs' | translate}}</mat-option>
                        <mat-option value="other">{{'Mx' | translate}}</mat-option>
                    </mat-select>

                </mat-form-field>

                <div class="flex" style="justify-content: space-between;">
                    <mat-form-field style="flex: 0 0 50%;margin-right:15px" appearance="fill">
                        <mat-label>{{'First Name' | translate}} *</mat-label>
                        <input matInput cdkFocusInitial formControlName="firstName">

                    </mat-form-field>

                    <mat-form-field appearance="fill" style="flex:1">
                        <mat-label>{{'Last Name' | translate}} *</mat-label>
                        <input matInput cdkFocusInitial formControlName="lastName">

                    </mat-form-field>
                </div>

                <div class="flex" style="justify-content: space-between;">
                    <mat-form-field appearance="fill" style="flex: 0 0 50%; margin-right:15px">
                        <mat-label>{{'Company' | translate}}</mat-label>
                        <input matInput cdkFocusInitial formControlName="companyName">

                    </mat-form-field>

                    <mat-form-field appearance="fill" style="flex: 1">
                        <mat-label>{{'Tax Id' | translate}}</mat-label>
                        <input matInput cdkFocusInitial formControlName="taxId">

                    </mat-form-field>
                </div>
                <mat-form-field appearance="fill">
                    <mat-label>{{'Address' | translate}} *</mat-label>
                    <input matInput cdkFocusInitial formControlName="street">

                </mat-form-field>

                <div class="flex" style="justify-content: space-between;">
                    <mat-form-field appearance="fill" style="flex: 0 0 33%;margin-right:15px">
                        <mat-label>{{'Zip' | translate}} *</mat-label>
                        <input matInput cdkFocusInitial formControlName="zip">

                    </mat-form-field>
                    <mat-form-field appearance="fill" style="flex: 1">
                        <mat-label>{{'City' | translate}} *</mat-label>
                        <input matInput cdkFocusInitial formControlName="city">

                    </mat-form-field>



                </div>


                <mat-form-field appearance="fill">
                    <mat-label>{{'Country' | translate}} *</mat-label>
                    <mat-select formControlName="country">
                        <mat-option value="germany">{{'Germany' | translate}}</mat-option>
                        <mat-option value="austria">{{'Austria' | translate}}</mat-option>
                    </mat-select>

                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>{{'E-Mail' | translate}} *</mat-label>
                    <input matInput cdkFocusInitial formControlName="billingMail">

                </mat-form-field>


                <p style="font-size:12px">* {{'Required' | translate}}</p>


            </form>
        </mat-dialog-content>

        <div style="display: flex;
        justify-content: flex-end;margin-top:15px">
            <button class="cta-button grey" mat-button matStepperPrevious>{{'Back' | translate}}</button>
            <button (click)="setForm(detailsForm)" [disabled]="!detailsForm.valid" class="cta-button" mat-button matStepperNext>{{'Next' | translate}}</button>
        </div>

    </mat-step>

    <mat-step label="{{'Summary' | translate}}">

        <div class="plan-card">

            <div class="flex" style="align-items: center;">
                <div class="card-icon"><img src="../../../assets/img/crewcus-logo"></div>
                <div class="flex column">
                    <h2>{{selectedPlan?.title}}</h2>
                    <h3 *ngIf="selectedPlan?.title === 'pro plan'"><span style="font-size:18px;color:black">{{selectedPlan?.price}}</span><span style="font-size:18px;color:black" *ngIf="selectedPlan?.title === 'pro plan'"> €</span> <span style="color:#adadad"> {{'/ month' | translate}}</span></h3>
                    <h3 *ngIf="selectedPlan?.title !== 'pro plan'"><span style="font-size:18px;color:black">{{'We will get in contact with you soon' | translate}}</span></h3>
                    <p *ngIf="selectedPlan?.title === 'pro plan'"><i class="fa fa-check-circle-o" aria-hidden="true"></i> {{'Suitable for medium sized offices' | translate}}</p>
                    <p *ngIf="selectedPlan?.title !== 'pro plan'"><i class="fa fa-check-circle-o" aria-hidden="true"></i> {{'Tailored just for your company' | translate}}</p>
                </div>
            </div>

        </div>

        <div class="info flex column" *ngIf="selectedPlan?.title === 'pro plan'" style="    margin: 25px 0px;">
            <div class="flex"> <span class="left">{{'Payment method' | translate}}</span><span class="right">{{'Billed' | translate}}</span></div>
            <div class="flex"> <span class="left">{{'Plan Duration' | translate}}</span><span class="right">{{'Monthly Subscription' | translate}}</span></div>
            <div style="border-bottom:1px solid #ccc" class="flex" *ngIf="selectedPlan?.title === 'pro plan'"> <span class="left">{{'Total Monthly' | translate}}</span><span class="right">{{selectedPlan?.price}} €</span></div>
            <div style="border-bottom:1px solid #ccc" class="flex" *ngIf="selectedPlan?.title !== 'pro plan'"> <span class="left">{{'Total Monthly' | translate}}</span><span class="right">{{'Individual Offer' | translate}} </span></div>
        </div>

        <form [formGroup]="checkboxes">
            <p>
                <mat-checkbox formControlName="privacy">{{'I accept the' | translate}} <a href="https://crewcus.com/datenschutz/" target="_blank">{{'Privacy Policy' | translate}}</a></mat-checkbox>
            </p>

            <p>
                <mat-checkbox formControlName="terms">{{'I accept the' | translate}} <a href="https://crewcus.com/nutzungsbedingungen/" target="_blank">{{'Terms of Use' | translate}}</a></mat-checkbox>
            </p>
            <p style="color:#aaa">{{'All prices includes Tax and Vat' | translate}}</p>
        </form>
        <div style="display: flex;
        justify-content: flex-end;;margin-top:15px">
            <button class="cta-button grey" mat-button matStepperPrevious>{{'Back' | translate}}</button>
            <button [mat-dialog-close]="{address: tempForm?.value, plan : 'pro'}" *ngIf="selectedPlan?.title === 'pro plan'" [disabled]="!checkboxes.valid || !detailsForm.valid" class="cta-button" mat-button matDialogClose>{{'Order Now' | translate}}</button>
            <button [mat-dialog-close]="{address: tempForm?.value, plan : 'enterprise'}" *ngIf="selectedPlan?.title !== 'pro plan'" [disabled]="!checkboxes.valid || !detailsForm.valid" class="cta-button" mat-button matDialogClose>{{'Request Offer' | translate}}</button>
        </div>



    </mat-step>

</mat-horizontal-stepper>