<div class="content bg-img">
    <div class="flex no-row-mobile">
        <h1 style="flex:1" class="orga-title">{{'Global Settings' | translate}}</h1>

    </div>
    <div class="flex">
        <p style="color: #1d1d1d">Administrator: {{activeOrganisation?.owner[0].mail}}</p>
    </div>
    <div class="flex column" *ngIf="activeOrganisation">
        <span style="
        color: #b6b6b7;align-self:baseline;
        margin-bottom: 10px;">{{'Give this link to your team members. This is the link where you can book your items.' | translate}}</span>
        <div class="flex">
            <i style="padding-top:8px" class="fas fa-external-link-alt"></i> <a href="{{shareUrl}}" alt="organisation-link" class="orga-link" target="_blank">{{shareUrl}}</a>
        </div>
    </div>



</div>

<app-orga-settings></app-orga-settings>