import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APP } from './app.config';

@Injectable({
  providedIn: 'root'
})
export class SlackService {

  constructor(private http: HttpClient) { }


  sendMessagetoServer(message) {
    this.http
      .post(
        APP.baseurl + '/reservationItem/feedback',
        {
          from: 'info@crewcus.com',
          content: message
        }
      )
      .toPromise()
      .then((data) => {
  
      })
      .catch((err) => {
       
      });
  }
}
