import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IOrganisationObserver, OrganisationService } from 'src/app/organisation.service';

@Component({
  selector: 'app-tab-bar',
  templateUrl: './tab-bar.component.html',
  styleUrls: ['./tab-bar.component.scss']
})
export class TabBarComponent implements OnInit, IOrganisationObserver {

  @Input()
  activeTab;

  orgaSlug;

  constructor(private router: Router, private organisationService: OrganisationService) { }
  observerId: number = 129292929;

  onOrganisationChange(organisations: any) {
   
    if (organisations?.length > 0) {

      this.orgaSlug = organisations[0].slug;
    } else {
      this.orgaSlug = null;
    }
  }

  ngOnInit(): void {
    this.organisationService.subscribe(this);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.organisationService.unsubscribe(this);
  }

  setActiveTab(num) {
    this.activeTab = num;

    if(num === 2) {
      this.router.navigate(['my-bookings']);
    } else {
      this.router.navigateByUrl('/o/' + this.orgaSlug);
    }
  }
}
