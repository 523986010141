import { Component, Input, OnInit } from '@angular/core';
import {
  getBoolean,
  getContrast,
  getNumber,
  getRandomColor,
  getUnit,
} from '../../utils/utilities';
import { Md5 } from 'ts-md5/dist/md5';

interface IBeamData {
  backgroundColor: string;
  eyeSpread: number;
  faceColor: string;
  faceRotate: number;
  faceTranslateX: number;
  faceTranslateY: number;
  isCircle: boolean;
  isMouthOpen: boolean;
  mouthSpread: number;
  wrapperColor: string;
  wrapperRotate: number;
  wrapperScale: number;
  wrapperTranslateX: number;
  wrapperTranslateY: number;
}

@Component({
  selector: 'ng-boring-avatars-beam-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
  @Input() name: string | undefined;
  @Input() location: string | undefined;
  @Input() inputSize: number | undefined;
  @Input() userhash: string | undefined;

  colors = [' #FFD002', '#07d6a0', '#BB6FCE'];

  public SIZE = 36;
  public data: IBeamData | undefined;

  ngOnInit() {
    if (this.name === undefined) {
      throw new Error("Input 'name' must be defined.");
    }

    if (this.colors === undefined) {
      throw new Error("Input 'name' must be defined.");
    }

    this.data = this.generateData(this.name, this.colors);
  }

  isOwner() {
    const md5 = new Md5();

    let user = JSON.parse(localStorage.getItem('user'));

    const result = md5.appendStr(user.id).end();

    return result === this.userhash;
  }

  generateData(name: string, colors: Array<string>): IBeamData {
    const numFromName = getNumber(name);
    const range = colors && colors.length;
    const wrapperColor = getRandomColor(numFromName, colors, range);
    const preTranslateX = getUnit(numFromName, 10, 1);
    const wrapperTranslateX =
      preTranslateX < 5 ? preTranslateX + this.SIZE / 9 : preTranslateX;
    const preTranslateY = getUnit(numFromName, 10, 2);
    const wrapperTranslateY =
      preTranslateY < 5 ? preTranslateY + this.SIZE / 9 : preTranslateY;

    return {
      wrapperColor: wrapperColor,
      faceColor: getContrast(wrapperColor),
      backgroundColor: getRandomColor(numFromName + 13, colors, range),
      wrapperTranslateX: wrapperTranslateX,
      wrapperTranslateY: wrapperTranslateY,
      wrapperRotate: getUnit(numFromName, 360, null),
      wrapperScale: 1 + getUnit(numFromName, this.SIZE / 12, null) / 10,
      isMouthOpen: getBoolean(numFromName, 2),
      isCircle: getBoolean(numFromName, 1),
      eyeSpread: getUnit(numFromName, 5, null),
      mouthSpread: getUnit(numFromName, 3, null),
      faceRotate: getUnit(numFromName, 10, 3),
      faceTranslateX:
        wrapperTranslateX > this.SIZE / 6
          ? wrapperTranslateX / 2
          : getUnit(numFromName, 8, 1),
      faceTranslateY:
        wrapperTranslateY > this.SIZE / 6
          ? wrapperTranslateY / 2
          : getUnit(numFromName, 7, 2),
    };
  }
}
