<div class="content">




    <div class="flex-column">
        <div class="flex mobile-column">
            <div class="card " style="flex:0 33.3%">
                <div class="title">{{'Your Organisation Link' | translate}}</div>
                <span style="
                color: #b6b6b7;align-self:baseline;
                margin-bottom: 10px;">{{'Give this link to your team members. This is the link where you can book your items.' | translate}}</span>
                <div class="content">
                    <i class="fas fa-external-link-alt"></i> <a href="{{shareUrl}}" alt="organisation-link" class="orga-link" target="_blank">{{shareUrl}}</a>
                </div>
            </div>

            <!-- <div class="title">{{'Overview Statistics' | translate}}</div> -->
            <div class="content numbers flex mobile-column" style="justify-content: center; width: 100%;">
                <div class="card" style="flex:1">
                    <div class="desc"><i class="fas fa-location-arrow"></i> {{'Locations' | translate}}</div>
                    <div class="flex-column" style="height: 100%;
                    place-content: center;">
                        <div class="count" *ngIf="statistics">{{statistics?.locationCount ? statistics?.locationCount : 0 }}</div>
                        <div class="count" *ngIf="!statistics"><img class="spin" src="../../../assets/img/loading.svg" style="width:30px;margin-top:0"></div>

                    </div>
                </div>
                <div class="card" style="flex:1">
                    <div class="desc"><i class="fas fa-cube"></i> {{'Rooms' | translate}}</div>
                    <div class="flex-column" style="height: 100%;
                    place-content: center;">
                        <div class="count" *ngIf="statistics">{{statistics?.roomCount ? statistics?.roomCount : 0 }}</div>
                        <div class="count" *ngIf="!statistics"><img class="spin" src="../../../assets/img/loading.svg" style="width:30px;margin-top:0"></div>

                    </div>
                </div>
                <div class="card" style="flex:1">
                    <div class="desc"><i class="fas fa-scroll"></i> {{'Bookables' | translate}}</div>
                    <div class="flex-column" style="height: 100%;
                    place-content: center;">
                        <div class="count" *ngIf="statistics">{{statistics?.itemsCount ? statistics?.itemsCount : 0}}</div>
                        <div class="count" *ngIf="!statistics"><img class="spin" src="../../../assets/img/loading.svg" style="width:30px;margin-top:0"></div>

                    </div>
                </div>
            </div>

        </div>


        <div class="flex mobile-column chart-column">
            <div class="card" style=" flex: 1">
                <div class="title" style="text-align:left">{{'Total Occupancy Overview' | translate}}
                    <select style="float:right" #t (change)="setChartInterval(t.value)">
                        <option [value]="interval.value" *ngFor="let interval of chartIntervals ">{{interval.name | translate}}</option>
                        </select>
                    <i *ngIf="chartChoice === 'bar'" (click)="chartChoice = 'line'" class="fas fa-chart-line"></i>
                    <i *ngIf="chartChoice === 'line'" (click)="chartChoice = 'bar'" class="far fa-chart-bar"></i>
                    <img class="spin " *ngIf="nextDaysWorkLoadLoading" src="../../../assets/img/loading.svg " style="width:20px;margin-top:0 "></div>

                <app-chart [id]="'chart'" [type]="chartChoice" [data]="foreCastData" [labels]="dayStrings" *ngIf="dayStrings?.length > 0"></app-chart>

            </div>

            <div class="card" style=" flex: 1">
                <div class="title" style="text-align:left">{{'Total Occupancy Overview' | translate}} {{'Today' | translate}}</div>
                <app-chart [id]="'piechart'" [type]="'doughnut'" [data]="donutData" [labels]="donutStrings" *ngIf="donutData"></app-chart>

            </div>


        </div>

    </div>

    <div class="flex-column">
        <div class="flex-dashboard">


            <div class="card" style="flex: 0 0 30%">

                <div class="title">{{'Detailed Occupancy' | translate}}</div>
                <div class="content" style="flex-direction: column;" *ngIf="workload">

                    <div *ngFor="let room of workload.rooms" class="flex-rooms">
                        <div style="flex:1">{{room.name}}</div>
                        <div style="font-weight: bolder;">
                            <i *ngIf="round(room.workload * 100) >= 75" class="fas fa-exclamation-circle"></i> {{round(room.workload * 100)}}%</div>
                    </div>

                </div>
                <div class="content" *ngIf="!workload">
                    <img class="spin" src="../../../assets/img/loading.svg" style="width:50px;margin-top:0">
                </div>

            </div>





            <div class="card card-last">
                <div class="title">{{'Where is my Team right now?' | translate}}</div>
                <div class="content" style="flex-direction: column;" *ngIf="teamdata">

                    <div class="log" *ngFor="let team of teamdata">{{team.userName}} {{'occupied' | translate}} '{{team.blockedItem}}' ({{team.location? team.location : 'Mobile Office' | translate}})</div>
                    <div class="log" *ngIf="teamdata?.length === 0">{{'No one currently occupies a bookable item.' | translate}}</div>
                </div>
                <div class="content" *ngIf="!teamdata">
                    <img class="spin" src="../../../assets/img/loading.svg" style="width:50px;margin-top:0">
                </div>
            </div>





        </div>
    </div>














</div>