import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SyncDevicesService } from '../sync-devices.service';

@Component({
  selector: 'app-sync-devices-selection',
  templateUrl: './sync-devices-selection.component.html',
  styleUrls: ['./sync-devices-selection.component.scss']
})
export class SyncDevicesSelectionComponent implements OnInit {

  constructor(private location: Location, public syncService: SyncDevicesService) { }



  ngOnInit(): void {
  }


  back() {
    if(this.syncService.selection) {
      this.syncService.setSelection(null);
      return;
    }
    this.location.back();
  }
  ngOnDestroy(): void {
    this.syncService.setSelection(null);
    
  }
}
