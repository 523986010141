import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { APP } from '../app.config';
import { DashboardService } from '../dashboard.service';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { GuestHashService } from '../guest-hash.service';
import { LoadingService } from '../loading.service';
import { LocationService } from '../location.service';

@Component({
  selector: 'app-qr-check-in',
  templateUrl: './qr-check-in.component.html',
  styleUrls: ['./qr-check-in.component.scss'],
})
export class QrCheckInComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private guestService: GuestHashService,
    private http: HttpClient,
    private router: Router,
    private GAService: GoogleAnalyticsService,
    private locationService: LocationService,
    public loadingService: LoadingService,
    private translateService: TranslateService,
    private dashboardService: DashboardService
  ) {}

  activeItem = {
    location: '',
    room: '',
    id: '',
    name: '',
    orgaid: '',
    slug: '',
    locationFull: [],
  };
  activeDate;
  user;
  username;
  errorMessage;
  isLoading;
  success = false;
  automaticCheckin = 5;
  autoCheckin = false;
  timer;
  lang;
  orgaSettings;
  startTime;
  endTime;

  async ngOnInit(): Promise<void> {
    this.loadingService.startLoading();
      this.lang = this.getLang();
    this.GAService.send('QR Check-In');
    this.initDate();
    this.guestService.init();

    if (localStorage.getItem('user')) {
      this.user = JSON.parse(localStorage.getItem('user'));
      if (this.user.username) {
        this.username = this.user.username;
        this.autoCheckin = true;
      }
    }

  



  
    this.activeItem.location = this.route.snapshot.paramMap.get('loc');
    this.activeItem.room = this.route.snapshot.paramMap.get('room');
    this.activeItem.id = this.route.snapshot.paramMap.get('id');
    this.activeItem.name = this.route.snapshot.paramMap.get('name');
    this.activeItem.orgaid = this.route.snapshot.paramMap.get('orgaid');
    this.activeItem.slug = this.route.snapshot.paramMap.get('slug');

    this.activeItem.locationFull = await this.locationService.getLocationNameById(
      this.activeItem.location,
      this.activeItem.orgaid
    );

    this.activeItem.name = this.getBokkableNameById(
      this.activeItem.id,
      this.activeItem.locationFull
    );

    if(!this.activeItem.name) {
      clearInterval(this.timer);
      this.errorMessage = this.translateService.instant('This is not a valid QR-Code. Please contact your Administrator.')
    }

    await this.dashboardService
    .getOrgaBySlug( this.activeItem.slug)
    .then((data) => {
      this.orgaSettings = data;
      this.startTime = new Date().setHours(this.orgaSettings[0].dayBeginAtSlot/2,0,0);
      this.endTime = new Date().setHours(this.orgaSettings[0].dayEndsAtSlot/2 + 1,0,0);
    })
    .catch(() => {
      // todo err
    });


    this.loadingService.stopLoading();

    if (this.username && this.autoCheckin) {
      this.timer = setInterval(() => {
        if (this.automaticCheckin > 1) {
          this.automaticCheckin--;
        } else {
          this.bookItemNow();
          clearInterval(this.timer);
        }
      }, 1000);
    }



  }

  getLang() {
    // @ts-ignore
    if (window.Intl && typeof window.Intl === 'object') {
      // @ts-ignore
      let lang = Intl.getCanonicalLocales(window.navigator.language)[0];

      if (lang.indexOf('-') > 0) {
        lang = lang.split('-', 1)[0];
      }

      //if (lang === 'de') {
      return lang;
      /*} else {
                return 'en';
            }*/
    }
    return 'en';
  }


  initDate(): void {
    const date = new Date();

    const year = date.getFullYear().toString();
    const month =
      date.getMonth() + 1 < 10
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1;
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const datestring = year + '-' + month + '-' + day;

    this.activeDate = datestring;

    // @ts-ignore
  }

  private getBokkableNameById(bookableId: string, theLocationArray: any[]) {

    const merged = [].concat(
          //@ts-ignore
      ...theLocationArray.rooms?.map((x) => x.bookableItems)
    );
    //get name by item ID
    return merged.filter((bookable) => bookable._id === bookableId)[0]?.name;
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  bookItemNow() {

    clearInterval(this.timer);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-organisation-id': this.activeItem.orgaid,
      }),
    };

    this.errorMessage = null;
    this.user.username = this.username;
    localStorage.setItem('user', JSON.stringify(this.user));

    this.isLoading = true;

    let slots = [];
  
    for (let index = this.orgaSettings[0]?.dayBeginAtSlot; index <= this.orgaSettings[0]?.dayEndsAtSlot; index++) {
      slots.push(index);
      
    }

    let body = {
      userId: this.user.id,
      belongsToLocationId: this.activeItem.location,
      blockedOnDay: this.activeDate,
      blockedSlots: slots,
      belongsToId: this.activeItem.id,
      userName: this.username,
    };

    if(slots.length > 0) {
      this.http
      .post(APP.baseurl + '/reservationItem/block', body, httpOptions)
      .toPromise()
      .then((data) => {
        this.isLoading = false;
        // show success
        this.success = true;
      })
      .catch((err) => {
        console.log('Error Booking', err);
        this.errorMessage = err.error.message;
        this.isLoading = false;
      });
    }

 
  }

  cancel() {
    clearInterval(this.timer);
    this.router.navigate(['/o/' + this.activeItem.slug]);
  }
}
